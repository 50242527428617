<template lang="pug">
div
  .issue-edit-form.justify-center(v-if="dataHasLoaded")
    handle-form(
      v-if="refetchReady",
      @close-form="closeForm",
      :parentData="{ method: 'update', item: 'issue', item_id: row.id, callback_params: callback_params, form_title: form_data.form_header.title.edit + ' №' + row.id, title_icon_path: title_icon_path, path: path['issue'], grid: grid, data: form_data }"
    )

    .new-edit-form(v-if="!refetchReady")
</template>

<script>
import handleForm from "../../shared/forms/Form";

import { useEmitter } from "@/services/useEmitter";
import { handleError } from "@/services/handleErrors";

export default {
  components: {
    handleForm,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.$store.state.paths,
      grid: this.parentData.grid,
      title_icon_path: "edit_header.svg",
      row: this.parentData.row,
      callback_params: this.parentData.callback_params,
      form_data: [],

      dataHasLoaded: false,

      emitter: useEmitter(),

      refetchReady: true,
    };
  },

  created() {
    this.loadFormData();
    this.$emit("row-is-active", true);
  },

  mounted() {
    this.emitter.on("changeFormByField", async data => await this.refetchAndReformat(data));
  },

  beforeMount() {},

  beforeDestroy() {
    this.emitter.off("changeFormByField");
  },

  methods: {
    closeForm(data) {
      this.$emit("close-form", data);
    },

    async refetchAndReformat(param) {
      const formDataPath = `${this.path["issue"]}/form_data`;

      const params = { [param.name]: param.value };

      try {
        this.refetchReady = false;

        const { data } = await this.$backend.index(formDataPath, { params });

        const previousValues = _.cloneDeep(this.$store.state.grid["issues"].form);

        // фильтруем поля, у которых есть дети, выводим массив их имен
        const dependants = this.form_data.fields
          .filter(elem => !!elem.watch?.parents?.find(el => el.parent === param.name))
          ?.map(el => el.name);

        dependants.forEach(el => this.$store.commit("resetFormField", { grid_name: "issues", field: el }));

        this.form_data = data;

        const previousKeys = Object.keys(previousValues);

        this.refetchReady = true;

        if (previousKeys.length > 0) {
          // this.restoreUserValues(previousKeys, previousValues);
        }
      } catch (e) {
        await handleError(e);
      }
    },

    restoreUserValues(previousKeys, previousValues) {
      // восстановление пользовательских значений в форму
      previousKeys.forEach(key => {
        this.form_data.fields.forEach((field, index) => {
          if (field["name"] === key) {
            if (previousValues[key].field) {
              this.form_data.fields[index].value = previousValues[key].field.value
                ? previousValues[key].field.value
                : previousValues[key].field;
            }
          }
        });
      });
    },

    loadFormData() {
      const formDataPath = `${this.path["issue"]}/form_data`;

      let params = {};
      if (this.row) {
        params["id"] = this.row.id;
      }

      this.$backend
        .index(formDataPath, { params: params })
        .then(({ data }) => {
          this.form_data = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },
  },
};
</script>

<style lang="scss"></style>
