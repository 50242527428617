import Vue from "vue";
import ActionCableVue from "actioncable-vue";

const opts = {
  debug: true,
  debugLevel: "error",
  connectionUrl: process.env.VUE_APP_ACTIONCABLE_URL,
  connectImmediately: true,
};

Vue.use(ActionCableVue, opts);

export default ActionCableVue;
