<template lang="pug">
.card(:class="cardClassObject") 
  .card__header
    transition(name="fade")
      .label(v-show="!hidden") {{ service.name }}

  .card__body
    transition(name="card--fade")
      .chart-container(v-show="!hidden")
        .chart-container__donut
          q-skeleton(v-if="loading", type="circle", size="90px")
          .donut(v-else)
            .donut__text {{ totalCount }}
            .donut__case
        .chart-container__statuses
          div(v-if="loading || expandingAnimating")
            q-skeleton.q-mb-sm(v-for="i in 9", :key="i", width="150px")
          ul(v-else)
            li(v-for="(item, name) in issueCounts", :key="name") 
              svg.statuses__icon(
                xmlns="http://www.w3.org/2000/svg",
                width="6",
                height="6",
                viewBox="0 0 6 6",
                fill="none"
              )
                circle(cx="3", cy="3", r="3", :fill="item.color")

              span.statuses__name {{ name }} <span class="statuses__count">{{ item.count }} </span>

    .metrics-container(v-if="expand") 
      div(v-if="loadingMetrics")
        .metrics__loader__item(v-for="i in 4", :key="i")
          q-skeleton(size="42px")
          q-skeleton.q-ml-sm(width="250px", height="42px")

      .metric__item(v-for="metric in metricList") 
        .item__icon
          q-icon(name="schedule")
        .item__content
          .item__content__name
            .item__label {{ metric.label }}
            .item__value(:class="{ 'item__value--invalid': isValueInvalid(metric) }") {{ metricValue(metric) }}
          .item__content__details
            .details__min {{ minMetric(metric) }}
            .details__description {{ localesContent.serviceMetric.description }}
              service-tooltip(:details="metric.details")
            .details__max {{ maxMetric(metric) }}

  .card__footer
    transition(name="fade")
      div(v-show="!hidden")
        div(v-if="loading")
          q-skeleton.q-mr-md(width="130px")
        div(v-if="!loading") 
          .label.label--inactive(v-if="expand", @click="hide") 
            span {{ localesContent.serviceMetric.hide }}
            i.label__icon.fas.fa-lg.fa-angle-left
          .label.label--active(v-else, @click="show") 
            span {{ localesContent.serviceMetric.show }}
            i.label__icon.fas.fa-lg.fa-angle-right
</template>

<script setup>
import { ref, computed } from "vue";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { buildUrlParams } from "@/services/urlParams";
import ServiceTooltip from "./serviceTooltip.vue";

const props = defineProps({
  loading: { type: Boolean, required: true }, // Loading state
  totalCount: { type: Number, required: true }, // Total count of issues
  service: { type: Object, required: true }, // Current service object
  issueCounts: { type: Object, required: true }, // List of available issue names with it's count
  localesContent: { type: Object, required: true }, // Locales data for component
  filtersContent: { type: Object, required: true }, // Filters for the service
  hidden: { type: Boolean, required: false, default: false }, // Is card hidden. Triggers animation
});

const emit = defineEmits(["show", "hide"]);

const transitionSpeedInMS = 300; // Must MATCH $transition-speed scss variable

const expand = ref(false);
const metrics = ref([]);
const loadingMetrics = ref(false);
const expandingFinished = ref(false);
const expandingAnimating = ref(false);

const cardClassObject = computed(() => {
  return {
    "card--expand": expand.value, // base class for before and after expanding animation
    "card--expand--animating": expand.value && expandingAnimating.value,
    "card--expand--finished": expand.value && expandingFinished.value, // after transition animation finished
    "card--hide": props.hidden,
  };
});

// To only show list when loader is removed
const metricList = computed(() => (loadingMetrics.value ? [] : metrics.value));

const isValueInvalid = metric => {
  const min = metric.min;
  const max = metric.max;
  const value = metric.value;
  const lessThenMin = typeof min === "number" && value < min;
  const moreThenMax = typeof max === "number" && value > max;
  const invalid = lessThenMin || moreThenMax;

  return invalid;
};

const show = async () => {
  expand.value = true;
  emit("show", props.service.id);
  expandingAnimating.value = true;

  // Set expanded finished state after transition animations finished
  setTimeout(() => {
    expandingAnimating.value = false;
    expandingFinished.value = true;
  }, transitionSpeedInMS);

  await loadMetrics();
};

const hide = () => {
  expand.value = false;
  expandingFinished.value = false;
  emit("hide", props.service.id);
};

const metricValue = metric => (metric.unit ? `${metric.value} ${metric.unit}` : metric.value);
const minMetric = metric => (metric.min ? `${props.localesContent.serviceMetric.min} ${metric.min}` : "");
const maxMetric = metric => (metric.max ? `${props.localesContent.serviceMetric.max} ${metric.max}` : "");

const loadMetrics = async () => {
  try {
    loadingMetrics.value = true;
    const workflowId = props.service.workflow_id;
    const params = buildUrlParams({ filters: props.filtersContent, serviceId: props.service.id });

    const response = await backend.index(`/api/v3/workflows/${workflowId}/metrics?${params}`);
    metrics.value = response.data;
  } catch (error) {
    await handleError(error);
  } finally {
    loadingMetrics.value = false;
  }
};
</script>

<script>
export default {
  name: "ServiceCard",
};
</script>

<style scoped lang="scss">
$transition-speed: 0.3s; // Must MATCH transitionSpeedInMS js variable
$card-height: 410px;
$card-body-height: $card-height - 150px;
$card-scrollable-statues: $card-body-height - 20px;

.card {
  background-color: var(--issue-dashboard-service-card-background);
  width: calc(100% / 3 - 20px);
  margin-right: 30px;
  height: $card-height;
  border-radius: 10px;
  transition: width $transition-speed ease-in-out;

  &:last-child {
    margin-right: 0;
  }

  .card--fade-enter-active,
  .card--fade-leave-active {
    transition: opacity $transition-speed;
  }
  .card--fade-enter,
  .card--fade-leave-to {
    opacity: 0;
  }

  .card__header {
    height: 70px;
    border-bottom: 0.5px solid #b5bbc9;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .label {
      color: var(--issue-dashboard-service-card-header);
      font-weight: 600;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .card__body {
    height: $card-body-height;

    .chart-container {
      display: flex;

      .chart-container__donut {
        width: 45%;
        padding-top: 50px;
        display: flex;
        justify-content: center;

        .donut {
          position: relative;
          width: 110px;
          height: 110px;

          .donut__text {
            top: 20px;
            left: 20px;
            width: 70px;
            height: 70px;
            background: var(--issue-dashboard-service-card-background);
            color: var(--issue-dashboard-metrics-card-color);
            position: absolute;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            font-size: 20px;
          }

          .donut__case {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: #5b7aff;
            position: absolute;
            top: 0;
            left: 0;
            background-clip: border-box;
            overflow: hidden;
          }
        }
      }

      .chart-container__statuses {
        width: 55%;
        .statuses__count {
          padding-left: 4px;
          font-weight: bold;
        }

        ul {
          list-style-type: none;
          height: $card-scrollable-statues;
          overflow-y: scroll;
          overflow-x: hidden;

          li {
            color: var(--issue-dashboard-metrics-card-color);
            line-height: 23px;
            display: flex;
            align-items: center;

            .statuses__name {
              &:first-letter {
                text-transform: capitalize;
              }
            }

            .statuses__icon {
              margin-right: 10px;
              min-width: 6px;
            }
          }
        }
      }
    }
  }

  .card__footer {
    height: 70px;
    border-top: 0.5px solid #b5bbc9;
    display: flex;
    justify-content: right;
    align-items: center;

    .label {
      cursor: pointer;
      margin-right: 15px;
    }

    .label--active {
      color: var(--issue-dashboard-service-card-footer-label-active-color);
    }

    .label--inactive {
      color: var(--issue-dashboard-service-card-footer-label-inactive-color);
      margin-right: 15px;
    }

    .label__icon {
      margin-left: 15px;
    }
  }
}

.card--expand {
  width: 100%;
  transition: $transition-speed width ease-in-out;
  margin-right: 0;

  .card__body {
    display: flex;

    .chart-container {
      width: 40%;
      background-color: var(--issue-dashboard-service-card-chart-background);
      transition: background-color $transition-speed ease-in-out;

      .chart-container__donut {
        width: 30%;
        min-width: 110px;
      }

      .chart-container__statuses {
        width: 70%;
      }
    }

    .metrics-container {
      width: 60%;
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      margin: 20px;
      flex-flow: column wrap;

      .metrics__loader__item {
        display: flex;
        margin-bottom: 10px;
      }

      .metric__item {
        display: flex;
        width: 48%;

        .item__icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--issue-dashboard-service-card-metric-icon-background);
          width: 42px;
          height: 42px;
          border-radius: 9px;
          font-size: 24px; // icon size
          color: #304ffe;
        }

        .item__content {
          padding-left: 12px;
          justify-content: space-between;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 85%;

          .item__content__name {
            justify-content: space-between;
            display: flex;
            font-size: 13px;

            .item__label {
              color: var(--issue-dashboard-service-card-metric-item-label-color);
              font-weight: 700;
              flex: 5;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }

            .item__value {
              color: #5b7aff;
              font-weight: 700;
              flex: 2;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              text-align: end;
            }

            .item__value--invalid {
              color: #fa7167;
            }
          }
          .item__content__details {
            display: flex;
            font-size: 11px;
            color: #979797;
            text-transform: lowercase;
            cursor: default;

            .details__min {
              flex: 1; // shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }

            .details__description {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .details__max {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.card--expand--animating {
  .metrics-container {
    overflow: hidden;
  }
}

.card--expand--finished {
  .card__body {
    .chart-container {
      .chart-container__statuses {
        ul {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          overflow-y: hidden !important;
          overflow-x: scroll !important;

          li {
            width: 50%;
          }
        }
      }
    }
  }

  .metrics-container {
    overflow-y: hidden;
    overflow-x: scroll;
  }
}

.card--hide {
  width: 0%;
  margin: 0;
  transition: $transition-speed width ease-in-out;
}
</style>
