<template lang="pug">
.form-icon-area
  .form-field-label
    span {{ label }}
    span.form-field-require-tag(v-show="required", :title="notifies.required_field")
      | *
  q-btn.btn-icon(flat, @click="openIconsModal()")
    img.selected-icon(v-if="iconPath", :src="iconPath")

  q-dialog(v-model="iconsModal")
    q-banner#banner-dialog.form-icon-area-banner.wrapper
      template(slot="avatar")
        .form-icon-area.selectable-icon-area
          span.selectable-icon-wrapper(v-for="icon in iconOptions", :key="icon.id", :title="icon.id")
            img.selectable-icon(:src="icon.path", @click="selectIcon(icon)")
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "@/store";
import { handleError } from "@/services/handleErrors";
import backend from "@/api";

const props = defineProps({
  /**
   * label отвечает за текст над полем
   */
  label: { type: String, default: "", required: true },
  /**
   * optionPath хранит в себе текст для составления пути запроса на получение коллекции компонента
   */
  optionsPath: { type: String, default: "", required: true },
  /**
   * optionsParams это объект параметров которые необходимо отправить при запросе коллекции
   */
  optionsParams: { type: Object, default: null, required: false },
  /**
   * required используется в надобности делать поле обязательным и помечать красной *
   */
  required: { type: Boolean, default: false, required: false },
  /**
   * initialValue нужен чтобы задать начальное значение поля
   */
  initialValue: { type: String, default: "", required: false },
});

const emit = defineEmits([
  /**
   * Срабатывает при изменении значения поля иконки. Параметр: id (айди иконки приходящей в коллекции, тип String)
   */
  "change",
]);

const store = useStore();

const iconsCollectionPath = computed(() => store.state.paths[props.optionsPath]);

const iconsModal = ref(false);
const iconPath = ref("");
const iconName = ref(props.initialValue || "");
const iconOptions = ref([]);

onMounted(async () => {
  await loadOptions();
});

const loadOptions = async () => {
  try {
    const { data } = await backend.collection(`${iconsCollectionPath.value}/collection`, props.optionsParams);
    iconOptions.value = data.options;
    setInitialValue();
  } catch (error) {
    await handleError(error);
  }
};

const selectIcon = icon => {
  iconPath.value = icon.path;
  selectIcon.value = icon.id;
  iconsModal.value = false;
  emit("change", { value: icon.id });
};

const setInitialValue = () => {
  const icon = iconOptions.value.find(item => item.id === props.initialValue);
  if (icon) {
    iconPath.value = icon.path;
    iconName.value = icon.id;
  }
};

const openIconsModal = () => {
  iconsModal.value = true;
};
</script>

<style scoped lang="scss">
@import "../../../../assets/styles/forms/fields/icon";
.form-icon-area-banner.wrapper {
  visibility: visible;
}
</style>
