<template lang="pug">
div
  #issue-new-form.issue-new-form.justify-center(v-if="dataHasLoaded")
    handle-form(
      v-if="refetchReady",
      @close-form="closeForm",
      :parentData="{ method: 'create', item: 'issue', callback_params: callback_params, form_title: form_data.form_header.title.add, title_icon_path: title_icon_path, path: path['issue'], grid: 'issues', data: form_data }"
    )
    .new-edit-form(v-if="!refetchReady")

  #modal-background-form
  ui-prompts(v-if="dataHasLoaded", :parentData="{ areas_key: 'add_issue' }")
</template>

<script>
import handleForm from "../../shared/forms/Form";
import uiPrompts from "../../shared/ui_prompts/index.vue";

import { useEmitter } from "@/services/useEmitter";
import { handleError } from "@/services/handleErrors";

import { useStore } from "@/store";

import _ from "lodash";

export default {
  components: {
    handleForm,
    uiPrompts,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.$store.state.paths,
      title_icon_path: "add_header.svg",
      form_data: [],
      callback_params: this.parentData.callback_params,

      dataHasLoaded: false,

      emitter: useEmitter(),
      refetchReady: true,
    };
  },

  created() {
    this.loadFormData();
  },

  mounted() {
    this.emitter.on("changeFormByField", async data => await this.refetchAndReformat(data));
  },

  beforeMount() {},

  beforeDestroy() {
    this.emitter.off("changeFormByField");
  },

  methods: {
    closeForm(data) {
      this.$emit("close-form", data);
    },

    async refetchAndReformat(param) {
      const formDataPath = `${this.path["issue"]}/form_data`;

      const params = { [param.name]: param.value };

      try {
        this.refetchReady = false;

        const { data } = await this.$backend.index(formDataPath, { params });

        const previousValues = _.cloneDeep(this.$store.state.grid["issues"].form);

        // фильтруем поля, у которых есть дети, выводим массив их имен
        const dependants = this.form_data.fields
          .filter(elem => !!elem.watch?.parents?.find(el => el.parent === param.name))
          ?.map(el => el.name);

        dependants.forEach(el => this.$store.commit("resetFormField", { grid_name: "issues", field: el }));

        this.form_data = data;

        const previousKeys = Object.keys(previousValues);

        this.refetchReady = true;

        if (previousKeys.length > 0) {
          // this.restoreUserValues(previousKeys, previousValues);
        }
      } catch (e) {
        await handleError(e);
      }
    },

    restoreUserValues(previousKeys, previousValues) {
      // восстановление пользовательских значений в форму
      previousKeys.forEach(key => {
        this.form_data.fields.forEach((field, index) => {
          if (field["name"] === key) {
            if (previousValues[key].field) {
              this.form_data.fields[index].value = previousValues[key].field.value
                ? previousValues[key].field.value
                : previousValues[key].field;
            }
          }
        });
      });
    },

    loadFormData() {
      const formDataPath = `${this.path["issue"]}/form_data`;

      const params = {};

      if (this.$route.query["service_id"]) params["service_id"] = this.$route.query["service_id"];

      this.$backend
        .index(formDataPath, { params })
        .then(({ data }) => {
          this.form_data = data;

          const callback_keys = Object.keys(this.callback_params);

          if (callback_keys.length > 0) {
            callback_keys.forEach(key => {
              this.form_data.fields.forEach(field => {
                if (field["name"] === key) {
                  field["value"] = this.callback_params[key];
                }
              });
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },
  },
};
</script>

<style lang="scss"></style>
