import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const account = {
  state: {
    current_user: null,
    auth_token: null,
  },

  mutations: {
    logIn(state, data) {
      state.current_user = data.user;
      state.auth_token = data.token;
    },

    logOut(state) {
      state.current_user = null;
      state.auth_token = null;
    },

    setDefaultLocale(state, locale) {
      state.default_locale = locale;
    },

    updateCurrentUser(state, user) {
      state.current_user = user;
    },
  },

  actions: {},
};
