<template lang="pug">
div
  .form-field-label
    span {{ label }}
  .form-field.form-input
    q-input(filled, v-model="color", readonly, :rules="['anyColor']")
      template(v-slot:append)
        q-icon.cursor-pointer.color-icon(
          name="colorize",
          :style="{ textDecoration: 'underline', marginTop: '-5px', textDecorationColor: color }"
        )
          q-popup-proxy(cover, transition-show="scale", transition-hide="scale")
            q-color.color-change(no-footer, :value="color", @input="e => updateColor(e)")
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({ color: { type: String, default: "black" }, label: { type: String, default: "Цвет" } });
const emit = defineEmits(["update:color"]);

const color = ref(props.color || "black");

const updateColor = e => {
  color.value = e;
  emit("update:color", e);
};
</script>

<style lang="scss">
.color-change {
  .q-tabs__content--align-justify .q-tab {
    border-radius: 0 !important;
    .q-icon {
      color: grey;
    }
  }
  .q-tab-panels.q-panel-parent {
    width: 240px !important;
  }
  .q-color-picker__tune-tab {
    padding: 10px !important;
  }
  .q-color-picker__header-content--dark .q-tab__label {
    color: white !important;
  }
  .q-color-picker__header-content--light .q-tab__label {
    color: black !important;
  }
}
</style>
