<template lang="pug">
.dates-field.form-field.form-multiselect.filter(@click="modal = true")
  q-icon.dates-field__icon(name="event") 
  .dates-field__modal.row.items-center.dropdown-btn {{ label }}
    q-menu(
      v-model="modal",
      transition-show="scale",
      transition-hide="scale",
      @before-show="onShow",
      @hide="onHide",
      content-class="dates-field__modal__menu"
    )
      q-date.dates-field__q-date(
        :value="dates",
        range,
        :mask="mask",
        :locale="currentDatesLocale",
        :title="modalTitle",
        @input="onInput"
      )
        .dates-field__buttons
          q-btn.dates-field__buttons__delete(
            v-if="datesPresent",
            @click="onReset()",
            :label="currentLocales.delete",
            flat
          )
          q-btn.dates-field__buttons__cancel(v-else, @click="hideModal", :label="currentLocales.cancel", flat)
          q-btn.dates-field__buttons__primary(@click="onApply", :label="currentLocales.apply", flat)
</template>

<script setup>
import { ref, computed } from "vue";
import { currentLocale } from "@/services/useLocales";
import { dynamicIssuesLocales } from "@/services/useLocales";
import { unitsSpelling } from "@/services/useNumberSpeller";
import { parse, differenceInDays } from "date-fns";
import i18n from "@/plugins/vue-i18n/index";

const props = defineProps({
  dateFrom: { type: String },
  dateTo: { type: String },
});

const mask = "DD.MM.YYYY";

const emit = defineEmits(["change"]);

const modal = ref(false);
const dates = ref({ from: undefined, to: undefined });

const currentLocales = computed(() => dynamicIssuesLocales.value.dates_filter);
const currentDatesLocale = computed(() => i18n["messages"][currentLocale.value]["date"]);
const datesPresent = computed(() => dates.value.from && dates.value.to);
const label = computed(() => {
  if (props.dateFrom && props.dateTo) {
    return `${props.dateFrom} - ${props.dateTo}`;
  } else {
    return currentLocales.value.dates;
  }
});

const modalTitle = computed(() => {
  const dateFrom = dates.value?.from;
  const dateTo = dates.value?.to;
  const parseMask = "dd.MM.yyyy";

  if (dateFrom && dateTo) {
    const parsedDateFrom = parse(dateFrom, parseMask, new Date());
    const parsedDateTo = parse(dateTo, parseMask, new Date());
    const daysDiff = differenceInDays(parsedDateTo, parsedDateFrom) + 1;
    const daysForms = currentLocales.value.days_forms;
    const daysWord = unitsSpelling(
      daysDiff,
      { nom: daysForms.nom, gen: daysForms.gen, plu: daysForms.plu },
      currentLocale.value,
    );

    return `${daysDiff} ${daysWord}`;
  } else {
    return "";
  }
});

const onInput = val => {
  if (val !== null) {
    const isObject = typeof val === "object";

    // For some reason if user clicks single date multiple time a single date we receive a String with single date.
    // So we have to have a workaround for it.
    if (isObject) {
      dates.value = val;
    }
  } else {
    dates.value = { from: undefined, to: undefined }; // set defaults
  }
};

// Set internal value from parent
const onShow = () => {
  if (props.dateFrom && props.dateTo) {
    dates.value = { from: props.dateFrom, to: props.dateTo };
  }
};

const onHide = () => {
  dates.value = { from: undefined, to: undefined };
};

const hideModal = () => {
  modal.value = false;
  onHide();
};

const onReset = () => {
  dates.value = { from: undefined, to: undefined };
  modal.value = false;
  emit("change", dates.value);
};

// Update dates in parent
const onApply = () => {
  modal.value = false;
  emit("change", dates.value);
};
</script>

<style lang="scss">
.dates-field {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__modal {
    color: var(--dynamic-issues-dates-field-label-color);
    font-size: 13px;

    &__menu {
      overflow: hidden;
      border-radius: 20px;
    }
  }

  &__icon {
    color: var(--dynamic-issues-dates-field-icon-color);
    width: 15%;
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;

    &__delete,
    &__cancel {
      color: var(--dynamic-issues-dates-field-delete-button-color);
    }

    &__primary {
      color: var(--dynamic-issues-dates-field-primary-button-color);
    }
  }
}

// These styles can't be scoped. It's injected in the end body of html.
.dates-field__q-date {
  height: 430px;
  box-shadow: none;
  background: var(--dynamic-issues-dates-field-popup-background);
  border-radius: 0;
  color: var(--dynamic-issues-dates-field-popup-color);

  .q-date__header {
    background-color: var(--dynamic-issues-dates-field-popup-main-background);
  }

  .q-date__content {
    .q-btn {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  .q-date__calendar-item {
    .bg-primary {
      background-color: var(--dynamic-issues-dates-field-popup-main-background) !important;
    }
  }

  .q-date__calendar-days {
    .text-primary {
      color: var(--dynamic-issues-dates-field-popup-days-color) !important;
    }
  }
}
</style>
