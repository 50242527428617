<template lang="pug">
div
  .workflow-fields__field
    .form-field-label
      span {{ workflowConstructorLocales.transition_form_fields }}
    .form-field.form-multiselect
      Multiselect(
        :value="activityValidationFieldsValue",
        :track-by="'id'",
        :label="'title'",
        :options="activityValidationFieldsOptions",
        placeholder="",
        :multiple="true",
        :hideSelected="true",
        :show-labels="false",
        open-direction="bottom",
        :allow-empty="true",
        :close-on-select="false",
        @select="selectBasicField",
        @remove="removeBasicField"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}
        span(slot="noOptions")
          i {{ notifies.no_options_list }}

  .workflow-fields__field
    .form-field-label
      span {{ workflowConstructorLocales.select_required_form_fields }}
    .form-field.form-multiselect
      Multiselect(
        :value="activityValidationFieldsRequiredValue",
        :track-by="'id'",
        :label="'title'",
        :options="activityValidationFieldsValue",
        placeholder="",
        :multiple="true",
        :hideSelected="true",
        :show-labels="false",
        open-direction="bottom",
        :allow-empty="true",
        :close-on-select="false",
        @select="selectRequiredField",
        @remove="removeRequiredField"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}
        span(slot="noOptions")
          i {{ notifies.no_options_list }}
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { workflowConstructorLocales } from "@/services/useLocales";

import Multiselect from "vue-multiselect";
import backend from "@/api";
import { handleError } from "@/services/handleErrors";

const props = defineProps({
  activity: { type: Object, default: () => {} },
  workflowId: { type: Number, default: null },
});

const emit = defineEmits(["activityValidationChanged"]);

const requestFields = ref([]);

const activityValidationFieldsOptions = ref([]);
const activityValidationFieldsValue = ref([]);

const activityValidationFieldsRequiredValue = ref([]);

const getActivityValidationFieldsValue = computed(() => {
  if (props.activity?.id) {
    return props.activity?.validations.map(elem => {
      return {
        id: elem.attribute,
        title: elem.attribute_title,
        validation_id: elem.id,
      };
    });
  } else {
    return [];
  }
});

const getActivityValidationFieldsOptions = async () => {
  try {
    activityValidationFieldsOptions.value = (
      await backend.collection("api/v3/fields/collection", { workflow_id: props.workflowId })
    ).data.options;
  } catch (e) {
    await handleError(e);
  }
};

const getActivityValidationFieldsRequiredValue = computed(() => {
  if (props.activity?.id) {
    const activitysWithRequired = props.activity?.validations.filter(elem => {
      return elem.type === "required";
    });

    return activitysWithRequired.map(elem => ({
      id: elem.attribute,
      title: elem.attribute_title,
      validation_id: elem.id,
    }));
  } else {
    return [];
  }
});

const emitActivityFieldsUpdated = () => {
  emit("activityValidationChanged", requestFields.value);
};

const selectBasicField = event => {
  activityValidationFieldsValue.value.push(event);

  const requestIndex = requestFields.value.findIndex(el => el.attribute === event.id);

  if (requestIndex === -1 && !event.validation_id)
    requestFields.value.push({
      entity_type: "Activity",
      entity_id: props.activity?.id,
      attribute: event.id,
      type: null,
    });

  emitActivityFieldsUpdated();
};

const selectRequiredField = event => {
  activityValidationFieldsRequiredValue.value.push(event);

  const requestIndex = requestFields.value.findIndex(el => el.attribute === event.id);

  if (requestIndex !== -1) {
    requestFields.value[requestIndex] = {
      entity_type: "Activity",
      entity_id: props.activity?.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      type: "required",
    };
  } else {
    requestFields.value.push({
      entity_type: "Activity",
      entity_id: props.activity?.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      type: "required",
    });
  }

  emitActivityFieldsUpdated();
};

const removeBasicField = event => {
  const index = activityValidationFieldsValue.value.findIndex(el => el.id === event.id);

  if (index !== -1) {
    activityValidationFieldsValue.value.splice(index, 1);
  }

  const reqIndex = activityValidationFieldsRequiredValue.value.findIndex(el => el.id === event.id);

  if (reqIndex !== -1) {
    activityValidationFieldsRequiredValue.value.splice(reqIndex, 1);
  }

  const requestIndex = requestFields.value.findIndex(el => el.attribute === event.id);

  if (requestIndex !== -1 && !event.validation_id) {
    requestFields.value.splice(requestIndex, 1);
  } else {
    requestFields.value.push({
      entity_type: "Activity",
      entity_id: props.activity?.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      _destroy: true,
    });
  }

  emitActivityFieldsUpdated();
};

const removeRequiredField = event => {
  const index = activityValidationFieldsRequiredValue.value.findIndex(el => el.id === event.id);

  if (index !== -1) {
    activityValidationFieldsRequiredValue.value.splice(index, 1);
  }

  const requestIndex = requestFields.value.findIndex(el => el.attribute === event.id);

  if (requestIndex !== -1) {
    requestFields.value[requestIndex] = {
      entity_type: "Activity",
      entity_id: props.activity?.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      type: null,
    };
  } else {
    requestFields.value.push({
      entity_type: "Activity",
      entity_id: props.activity?.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      type: null,
    });
  }

  emitActivityFieldsUpdated();
};

onMounted(async () => {
  await getActivityValidationFieldsOptions();
  activityValidationFieldsValue.value = getActivityValidationFieldsValue.value;
  activityValidationFieldsRequiredValue.value = getActivityValidationFieldsRequiredValue.value;
});
</script>
