<template lang="pug">
.header
  .header-title-block.row
    template(v-if="isBackButtonVisible")
      .col-1.go-back-button
        q-btn(round, flat, icon="chevron_left", @click="goBack")
      .col-5.header-title.q-my-auto {{ data.header.title }}
    .col-6.header-title(v-else) {{ data.header.title }}
    .col-6.header-actions
      component(
        v-if="parentActions.length > 0",
        v-for="action in parentActions",
        :key="action",
        :is="getActionComponent(action)",
        :parentData="{ grid, item, load_filters_params: load_filters_params, appendPath, encodeNestedData }",
        @submit="data => $emit('submit', data)"
      )

      theme-handler
      locale-handler(@reload-data="reloadData")
      profile(@reload-data="reloadData")

  .header-title-spacer

  .header-filters-block.row(v-if="!hide_filters || !hide_buttons")
    slot

    div(:class="filtersGroupClass")
      .row.grid-filters-items
        div(:class="searchClass", v-if="!hideQuery")
          query-filter(
            v-if="isQueryAvailable()",
            @search-all-filter-reset="searchAllFilterReset",
            @focus="onSearchAllFocus",
            :parentData="{ grid, data: [isQueryAvailable()] }"
          )

        .col-3(v-if="isSelectedDateLabelAvailable()")
          selected-date-label(v-if="isSelectedDateLabelAvailable()", :label="selectedDateLocale")

        slot(name="filters")
          div(:class="filtersContainerClass")
            grid-filters(
              ref="grid-filters",
              v-if="!hide_filters",
              :parentData="{ path, grid, data: data.filters_data, header: data.header }",
              :class="filtersClass"
            )

    grid-button-group(
      v-if="!hide_buttons",
      :parentData="{ grid: grid, grid_key: grid_key, buttons: data.buttons }",
      :class="buttonsGroupClass"
    )
</template>

<script>
import { useEmitter } from "@/services/useEmitter";
import gridButtonGroup from "../grid/gridButtonGroup";
import gridFilters from "../grid/gridFilters";
import Profile from "../../view/profile";
import ThemeHandler from "../../view/theme/edit";
import LocaleHandler from "../../view/locale/edit";
import headerActions from "./header/actions";
import queryFilter from "@/components/shared/filters/query_filter.vue";
import selectedDateLabel from "@/components/shared/filters/selected_date_label.vue";

export default {
  components: {
    Profile,
    ThemeHandler,
    LocaleHandler,
    gridButtonGroup,
    gridFilters,
    headerActions,
    queryFilter,
    selectedDateLabel,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      item: this.parentData.item,
      grid_key: this.parentData.grid_key,
      hide_filters: this.parentData.hide_filters,
      hide_buttons: this.parentData.hide_buttons,
      hideQuery: this.parentData.hideQuery || false,
      load_filters_params: this.parentData.load_filters_params || {},
      filtersClass: this.parentData.headerFiltersClass || "",
      filtersContainerClass: this.parentData.headerFiltersContainerClass || "col-4",
      searchClass:
        this.parentData.headerSearchClass === "" ? "" : this.parentData.headerSearchClass || "col-8 no-padding",
      filtersGroupClass:
        this.parentData.headerFiltersGroupClass === ""
          ? ""
          : this.parentData.headerFiltersGroupClass || "grid-filters col-6",
      buttonsGroupClass: this.parentData.headerButtonsGroupClass === "" ? "" : this.parentData.headerButtonsGroupClass,
      appendPath: this.parentData.appendPath || "",
      encodeNestedData: this.parentData.encodeNestedData || false,
      emitter: useEmitter(),
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },

    parentActions() {
      return this.parentData.actions || [];
    },

    isBackButtonVisible() {
      return this.grid === "workflows" && this.$route.params.id;
    },

    selectedDateLocale() {
      const storedDate = this.$store.state.grid.cleaning_journals.filters.cleaning_work_started_at;
      const serverInitialDate = this.getFilterDataByName("selected_date_label").label;
      const output = storedDate ? storedDate : serverInitialDate; // stored_date returns if it's not blank

      return output;
    },
  },

  methods: {
    getActionComponent(action) {
      return headerActions[action];
    },

    updateFilter(attr) {
      this.$refs["grid-filters"].updateFilter(attr);
    },

    reloadData() {
      this.$root.$emit("reload-navbar");
      this.$emit("reload-data");
      this.$root.$emit("reload-hidden-message");
    },

    goBack() {
      this.$router.go(-1);
    },

    getFilterDataByName(filterName) {
      const list = this.data.filters_data.filters;
      return list ? list.find(f => f.name === filterName) : null;
    },

    isSelectedDateLabelAvailable() {
      return this.getFilterDataByName("selected_date_label");
    },

    isQueryAvailable() {
      return this.getFilterDataByName("query");
    },

    searchAllFilterReset() {
      this.$root.$emit("search-all-filter-reset");
      this.emitter.emit("search-all-filter-reset");
    },

    onSearchAllFocus() {
      this.$emit("search-all-filter-focus");
    },
  },
};
</script>

<style scoped lang="scss">
.go-back-button {
  width: 60px;
  color: var(--header-title-color);
}

.grid-filters-items {
  align-items: center;
  width: 100%;
}

.no-left-padding {
  padding-left: 0;
}
</style>
