<template lang="pug">
.floor-plan
  .form-field-label
    span {{ label }}
  span.valid-error-message.floor-plan-error(v-if="error") {{ error }}

  .spinner-container(v-if="loading")
    q-spinner(color="primary", size="3em")

  .form-field.form-file(v-show="!loading && !has_map && !preview_image")
    .file-drag-drop-area(:id="`file-field-${name}`", @click="openFileInput")
      img.file-field-icon(:src="require('../../../../assets/icons/form/fields/file_field_icon.svg')")
      span.drop-files {{ field_locales.sublabel }}
      q-file(
        v-show="false",
        filled,
        v-model="file",
        :label="label",
        :for="`file-input-${name}`",
        :accept="`.jpg, .png, .jpeg`"
      )

  div(v-show="!loading && preview_image && !has_map")
    .floor-plan-thumb
      img.floor-plan-thumb-img(:src="preview_image")
      .floor-plan-thumb-buttons
        q-btn(dense, flat, icon="save", @click="saveImage")
        q-btn(dense, flat, icon="close", @click="clearPlan")
  div(v-show="!loading && has_map")
    floorMap(
      v-if="has_map",
      :parentData="{ floor_id: floor_id, building_id: building_id, editable: true }",
      @clear="clearPlan"
    )
</template>

<script>
import existingFilesArea from "../../helpers/existingFilesArea";
import floorMap from "../../floorMap.vue";

export default {
  components: {
    existingFilesArea,
    floorMap,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      label: this.parentData.data[0].label,
      file: null,
      name: this.parentData.data[0].name,
      floor_id: this.parentData.item_id,
      building_id: this.parentData.building_id,
      loading: false,
      has_map: false,
      preview_image: "",
      dragAndDropCapable: false,
      error: "",
    };
  },

  computed: {
    field_locales() {
      return this.locales.file_field[this.current_locale];
    },
  },

  created() {},

  beforeMount() {
    this.loading = true;
    this.$backend
      .index(`/api/v3/floor_plans?floor_id=${this.floor_id}`)
      .then(() => {
        this.has_map = true;
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
      });
  },

  mounted() {
    const field = document.getElementById(`file-field-${this.name}`);
    const input = document.getElementById(`file-input-${this.name}`);
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach(
        function (evt) {
          field.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false,
          );
        }.bind(this),
      );
      field.addEventListener(
        "drop",
        function (e) {
          let file = e.dataTransfer.files[0];
          this.getFile(file);
        }.bind(this),
      );
      input.addEventListener(
        "change",
        function (e) {
          let file = e.target.files[0];
          this.getFile(file);
        }.bind(this),
      );
    }
  },

  methods: {
    setField(val) {
      this.file = val;
    },

    openFileInput() {
      const input = document.getElementById(`file-input-${this.name}`);
      input.click();
    },

    getFile(val) {
      const file = val;
      this.file = file;
      this.getImage(file);
    },

    determineDragAndDropCapable() {
      let div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },

    clearPlan() {
      this.file = null;
      this.preview_image = "";
      this.has_map = false;
      this.error = "";
    },

    getImage(file) {
      let reader = new FileReader();
      reader.addEventListener("load", res => {
        this.preview_image = res.currentTarget.result;
      });
      reader.readAsDataURL(file);
    },

    saveImage() {
      this.loading = true;
      this.$backend
        .collection(`/api/v3/floor_plans`, { floor_plan: { floor_id: this.floor_id, plan: this.file } })
        .then(res => {
          this.has_map = true;
          this.preview_image = "";
          this.error = "";
          this.loading = false;
        })
        .catch(err => {
          this.error = err.response.data.errors.plan[0];
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/file";
@import "../../../../assets/styles/floor-plan/main";
</style>
