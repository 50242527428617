import workerDisabled from "../../../../assets/icons/map/controls/workers_filter/disabled.svg";
import workerEnabled from "../../../../assets/icons/map/controls/workers_filter/enabled.svg";

import buildingDisabled from "../../../../assets/icons/map/controls/buildings_filter/disabled.svg";
import buildingEnabled from "../../../../assets/icons/map/controls/buildings_filter/enabled.svg";

import vehicleDisabled from "../../../../assets/icons/map/controls/vehicles_filter/disabled.svg";
import vehicleEnabled from "../../../../assets/icons/map/controls/vehicles_filter/enabled.svg";

import emergencyDisabled from "../../../../assets/icons/map/controls/emergencies_filter/disabled.svg";
import emergencyEnabled from "../../../../assets/icons/map/controls/emergencies_filter/enabled.svg";

const HTML = "<div class='map-filter-panel'></div>";

class FilterPanel {
  constructor(api, map, enabled, onChange) {
    this.control = new api.Control(map.getWrapee(), HTML, { position: "topRight" });
    const div = this.control.getContainer().querySelector(".map-filter-panel");

    const enabledSet = new Set([...enabled]);

    const onButtonToggle = filterName => newVal => {
      newVal ? enabledSet.add(filterName) : enabledSet.delete(filterName);
      const setCopy = new Set(enabledSet);
      onChange(setCopy);
    };

    const workers = new PanelButton(
      workerEnabled,
      workerDisabled,
      enabledSet.has("workers"),
      onButtonToggle("workers"),
    );
    const buildings = new PanelButton(
      buildingEnabled,
      buildingDisabled,
      enabledSet.has("building"),
      onButtonToggle("buildings"),
    );
    const emergencies = new PanelButton(
      emergencyEnabled,
      emergencyDisabled,
      enabledSet.has("emergencies"),
      onButtonToggle("emergencies"),
    );

    const vehicles = new PanelButton(
      vehicleEnabled,
      vehicleDisabled,
      enabledSet.has("vehicles"),
      onButtonToggle("vehicles"),
    );

    div.appendChild(emergencies.getHtmlElement());
    div.appendChild(buildings.getHtmlElement());
    div.appendChild(workers.getHtmlElement());
    div.appendChild(vehicles.getHtmlElement());
  }
}

class PanelButton {
  constructor(iconEnabled, iconDisabled, enabled, onChange) {
    this.enabled = enabled;
    this.onChange = onChange;
    this.iconDisabled = iconDisabled;
    this.iconEnabled = iconEnabled;

    const container = document.createElement("div");
    container.className = "map-filter-panel__button";

    const img = document.createElement("img");
    img.src = this.enabled ? iconEnabled : iconDisabled;

    container.appendChild(img);
    container.onclick = () => this.toggle();

    this.img = img;
    this.container = container;
  }

  isEnabled() {
    return this.enabled;
  }

  getHtmlElement() {
    return this.container;
  }

  toggle() {
    this.enabled = !this.enabled;
    this.img.src = this.enabled ? this.iconEnabled : this.iconDisabled;
    this.onChange(this.enabled);
  }
}

export default FilterPanel;
