import Vue from "vue";
import VueRouter from "vue-router";
import OldDashboard from "../components/view/old_dashboard/index";
import Login from "../components/shared/Login";
import gridRoutes from "./modules/grids";
import Main from "../components/shared/Main";
import store from "../store/index";
import Map from "../components/view/map/index";
import SalesForm from "../components/view/sales/form";
import WorkflowConstructor from "../components/view/workflows/show";
import Dashboard from "../components/view/dashboard/index";
import IssueCenter from "../components/view/issue_center/index";
import Expenses from "../components/view/expenses_dashboard/index";
import DynamicIssues from "../components/view/dynamic_issues/index";

Vue.use(VueRouter);

let baseRoutes = [
  {
    name: "Main",
    path: "/",
    component: Main,
  },
  {
    name: "OldDashboard",
    path: "/old_dashboard",
    component: OldDashboard,
  },
  {
    name: "map",
    path: "/map",
    component: Map,
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
  },
  {
    name: "SalesForm",
    path: "/sales-form",
    component: SalesForm,
  },
  // WORKFLOW_CONSTRUCTOR
  {
    name: "WorkflowConstructor",
    path: "/workflows/constructor/:id",
    component: WorkflowConstructor,
  },
  {
    name: "Dashboard",
    path: "/facility/:facility_id/dashboard/:type",
    component: Dashboard,
  },
  {
    name: "IssueCenter",
    path: "/issue_center/:tabMode",
    component: IssueCenter,
  },
  {
    name: "expenses",
    path: "/facility/:facility_id/expenses_dashboard",
    component: Expenses,
  },
  {
    name: "DynamicIssues",
    path: "/facility/:facility_id/dynamic_issues",
    component: DynamicIssues,
  },
];

let routes = baseRoutes.concat(gridRoutes);

export const router = new VueRouter({
  mode: "history",
  hashbang: false,
  routes,
});

router.beforeResolve((to, from, next) => {
  const publicPages = ["Login", "SalesForm"];
  const authRequired = !publicPages.includes(to.name);
  const isAuthenticated = store.state.account.auth_token && store.state.account.current_user;
  let facilityId = "";
  if (isAuthenticated) {
    facilityId = store.state.account.current_user.facility_ids[0];
  }

  if (authRequired && !isAuthenticated) {
    next({ name: "Login" });
  } else if (isAuthenticated && ["/", "/login", "/dashboard"].includes(to.path)) {
    // redirect old dashboard url to a new
    next({ name: "Dashboard", params: { facility_id: facilityId, type: "issues" } });
  } else if (isAuthenticated && ["/dynamic_issues"].includes(to.path)) {
    next({ name: "DynamicIssues", params: { facility_id: facilityId } });
  } else if (isAuthenticated && ["/expenses_dashboard"].includes(to.path)) {
    next({ name: "expenses", params: { facility_id: store.state.account.current_user.default_expenses_facility } });
  } else {
    next();
  }
});

export function useRoute() {
  return router.currentRoute;
}

export function useRouter() {
  return router;
}
