<template lang="pug">
div
  table.tech-map-tab-table.issues-table
    thead
      tr
        th(v-for="column in tab_data.columns", :key="column.name")
          span.column-header-title {{ column.label }}
    tbody
      tr(v-for="issue_data in data", @click="showPprIssue(issue_data['id'], issue_data['type'])")
        td(v-for="td in issue_data.rows", :key="getTdKey(issue_data, td)", :class="td.key", :style="td.style")
          span {{ tryFormatToDate(td.value) }}
</template>

<script>
import { format } from "date-fns";

export default {
  components: {},

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      tab_data: this.parentData.tab_data,
      row_id: this.parentData.row_id,
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },
  },

  created() {},

  methods: {
    showPprIssue(id, type) {
      const path = type == "MaintenanceIssue" ? "issues" : "ppr_issues";
      this.$router.push({
        name: path,
        query: {
          show: true,
          id: id,
        },
      });
    },
    tryFormatToDate(str) {
      if (typeof str === "string") {
        const dateArr = str.split("T");

        if (dateArr.length > 1) {
          const date = new Date(str);
          if (date != "Invalid Date") {
            return format(date, "dd.MM.yyyy, HH:mm");
          }
          return str;
        }
      }

      return str;
    },
    getTdKey(data, td) {
      return `${data.id}_${td.key}`;
    },
  },
};
</script>

<style lang="scss"></style>
