<template lang="pug">
.meter-type-chart-swiper-area(v-if="meter_types.length > 0")
  .spinner-container.grid-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  swiper(v-show="!loading", ref="MeterTypeChartSwiper", :options="swiperOption")
    meter-type-chart.swiper-slide(
      :ref="`meter-type-chart-${(meter_type && meter_type.id) || ''}`",
      v-for="(meter_type, i) in meter_types",
      :key="i",
      :parentData="{ grid: grid, grid_key: grid_key, meter_type: meter_type, theme: theme }"
    )

  //div(v-if="meter_types.length > 3" class="swiper-button-prev" slot="button-prev")
  //div(v-if="meter_types.length > 3" class="swiper-button-next" slot="button-next")
</template>

<script>
import MeterTypeChart from "./MeterTypeChart";
import { Swiper } from "vue-awesome-swiper";

export default {
  components: {
    Swiper,
    MeterTypeChart,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: true,
      path: this.$store.state.paths.measuring_statistic,
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      windowWidth: "",
      data: [],
      options: {
        spaceBetween: 30,
        slidesPerView: "auto",
        slidesPerGroup: 1,
        grabCursor: true,
        loop: false,
        freeMode: true,
        // speed: 10000,
        mousewheel: {
          forceToAxis: true,
        },
        autoplay: false,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // }
      },
    };
  },

  computed: {
    swiperOption: {
      get() {
        return this.options;
      },

      set(attr) {
        this.options[attr["key"]] = attr["value"];
      },
    },

    meter_types() {
      return this.data;
    },
  },

  created() {
    this.loadData();
  },

  beforeDestroy() {},

  methods: {
    reloadData() {
      this.loadData();
      setTimeout(() => {
        this.meter_types.forEach(type => {
          let ref = this.$refs[`meter-type-chart-${type.id}`];
          if (ref) {
            ref[0].loadChartData();
          }
        });
      }, 800);
    },

    loadData(params = {}) {
      params.filters = (this.filters && this.generateFiltersParams()) || {};

      if (this.$route.query.building_id) {
        if (!params.filters["building_id"]) {
          params.filters["building_id"] = this.$route.query.building_id;
        }
      }

      this.$backend
        .collection(`${this.path}/meter_types_statistic`, params)
        .then(({ data }) => {
          this.data = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 800);
        });
    },
  },
};
</script>

<style lang="scss">
.meter-type-chart-swiper-area {
  .spinner-container.grid-spinner {
    height: 349px !important;
  }
}
</style>
