<template lang="pug">
.text-field
  .form-field-label
    span {{ `${label}` }}
    span.form-field-require-tag(v-show="required")
      | *

  .form-field.form-text(:class="invalidClass")
    q-input(
      filled,
      no-error-icon,
      v-model="value",
      @input="changeValue",
      :placeholder="placeholder",
      :label-slot="false",
      autocomplete="off",
      type="textarea",
      input-class="text-field-area",
      :autogrow="autogrow"
    )
      q-icon.cancel-text-field(name="cancel", v-if="value && !readonly", @click.stop="resetValue")

  .valid-error-message(v-if="invalid") {{ errorMessage }}
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";

const props = defineProps({
  label: { type: String, default: "", required: true },
  required: { type: Boolean, default: false, required: false },
  initialValue: { type: String || null, default: null, required: false },
  placeholder: { type: String, default: "", required: false },
  readonly: { type: Boolean, default: false, required: false },
  /**
   * Параметр для проверки надобности использовать autogrow в textarea
   */
  autogrow: { type: Boolean, default: false, required: false },
  /**
   * Флаг отвечающий за валидность поля. Включает подсветку с ошибкой и текстом errorMessage
   */
  invalid: { type: Boolean, default: false, required: false },
  /**
   * Текст отображаемый при невалидном поле
   */
  errorMessage: { type: String, default: "", required: false },
  /**
   * Нужен для случаев с autogrow. Если поле будет показано уже со значением, autogrow не применится
   */
  formLoaded: { type: Boolean, default: false, required: false },
});

const emit = defineEmits(["change", "reset"]);

const value = ref("");

const formLoaded = computed(() => props.formLoaded);
const invalidClass = computed(() => (props.invalid ? "valid-error" : ""));

// if autogrow input has value before show, autogrow not working and start working only after changes
onMounted(() => {
  if (!props.autogrow) {
    value.value = props.initialValue;
  }
});

const changeValue = () => {
  emit("change", { value: value.value });
};

const resetValue = () => {
  value.value = null;
  emit("reset");
  changeValue();
};

// watch for check loaded form and set initial value when form loaded and field was showed
// need only for autogrow
watch(formLoaded, (newVal, oldVal) => {
  if (newVal === true && props.autogrow) {
    value.value = props.initialValue;
  }
});
</script>

<script>
export default {
  name: "TextField",
};
</script>

<style scoped lang="scss">
.text-field {
  :deep(.text-field-area) {
    padding-right: 20px;
  }
}
</style>
