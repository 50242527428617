<template lang="pug">
table.tech-map-tab-table.issues-table
  thead
    tr
      th
        span.column-header-title Исполнитель
      th
        span.column-header-title Начало (план)
      th
        span.column-header-title № недели
      th
        span.column-header-title Статус
      th
        span.column-header-title Подпись
  tbody
    tr(v-for="tab in tabs")
      td(v-for="row in tab.rows") 
        span {{ row.value || "–" }}
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tabs: this.parentData.tabs,
    };
  },

  mounted() {},
};
</script>
