<template lang="pug">
.equipment-show-card
  .spinner-container.form-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  div(v-if="!loading")
    q-card-section
      component(
        ref="actions",
        v-if="showData['actions']",
        :is="getShowComponent('actions')",
        @refresh-table="refreshTable",
        @open-form="openForm",
        :parentData="{ row: row, grid: grid, grid_key: gridKey, path: path, data: showData['actions'] }"
      )

    q-card-section
      component(
        ref="blocks",
        v-if="showData['blocks']",
        :is="getShowComponent('blocks')",
        :parentData="{ row: row, show_details: false, data: showData['blocks'] }"
      )
</template>

<script setup>
import { ref } from "vue";

import { handleError } from "@/services/handleErrors";
import backend from "@/api";

import showComponents from "@/components/shared/show_card_components/index";

const props = defineProps({
  parentData: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["refresh-table", "open-form"]);

const path = ref(props.parentData.path);
const grid = ref(props.parentData.grid);
const gridKey = ref(props.parentData.grid_key);
const rowId = ref(props.parentData.row_id);
const tabName = ref(props.parentData.tab_name);

const row = ref({});
const showData = ref([]);

const loading = ref(true);

const loadShowData = async (params = {}) => {
  try {
    const data = (await backend.show(path.value, rowId.value, { params })).data;

    row.value = data.common.data;
    showData.value = data.common.tabs.find(tab => tab.name === tabName.value).data;
  } catch (e) {
    await handleError(e);
  } finally {
    loading.value = false;
  }
};

const refreshTable = data => {
  emit("refresh-table", data);
};

const refreshRow = data => {
  row.value = data.attr.data || data.attr;
};

const openForm = data => {
  emit("open-form", data);
};

loadShowData();

defineExpose({ refreshRow });
</script>

<style lang="scss">
.equipment-show-card {
  min-height: 70vh;

  .spinner-container.form-spinner {
    height: 70vh;
  }

  .remain-block {
    margin-top: 50px !important;
  }
}
</style>
