<template lang="pug">
.floor-plan-toolbar(v-if="loaded")
  .floor-plan-toolbar__item
    .floor-plan-toolbar__item-label.form-field-label 
      span {{ floorPlanLocales.dimensions }}
    .floor-plan-toolbar__sizebar 
      .form-field.form-input
        | {{ `${round(currentDot.width)} x ${round(currentDot.height)}` }}
  .floor-plan-toolbar__item
    .floor-plan-toolbar__item-label.form-field-label 
      span {{ floorPlanLocales.rotate }}
    .floor-plan-toolbar__rotate
      .form-field.form-input
        .floor-plan-toolbar__rotate-text {{ currentDot.rotate }}
        q-icon.floor-plan-toolbar__rotate-icon-left(
          name="refresh",
          @mousedown="startRotate('left')",
          @mouseup="stopRotate",
          @mouseleave="stopRotate"
        )
        q-icon.floor-plan-toolbar__rotate-icon-right(
          name="refresh",
          @mousedown="startRotate('right')",
          @mouseup="stopRotate",
          @mouseleave="stopRotate"
        )
  .floor-plan-toolbar__item.floor-plan-toolbar-figure__type
    .floor-plan-toolbar__item-label.form-field-label 
      span {{ floorPlanLocales.figure_type }}
    .form-multiselect.form-field
      multiselect(
        v-model="figureType",
        track-by="value",
        label="label",
        :multiple="false",
        placeholder="",
        :show-labels="false",
        :options="figureTypeOptions",
        closeOnSelect,
        hideSelected,
        @input="figureTypeSelect"
      )
  .floor-plan-toolbar__item.floor-plan-toolbar-figure__border-size
    .floor-plan-toolbar__item-label.form-field-label 
      span {{ floorPlanLocales.border_width }}
    .form-multiselect.form-field
      multiselect(
        v-model="currentDot.border_width",
        :multiple="false",
        placeholder="",
        :show-labels="false",
        :options="figureBorderSizeOptions",
        closeOnSelect,
        hideSelected,
        @input="updateDot()"
      )
  .floor-plan-toolbar__item.floor-plan-toolbar-figure__colorpicker
    ColorPicker(:color="currentDot.color", @update:color="updateColor", :label="floorPlanLocales.color")
  .floor-plan-toolbar__item.floor-plan-toolbar-figure__remove
    q-icon(name="o_delete", @click="removeDot")
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { floorPlanLocales } from "@/services/useLocales";
import Multiselect from "vue-multiselect";
import ColorPicker from "../../../shared/general_components/ColorPicker.vue";
import backend from "@/api";

const props = defineProps({
  dot: { type: Object, default: () => {} },
});

const emit = defineEmits(["remove"]);

const currentDot = ref(props.dot);
const figureTypeOptions = computed(() => [
  {
    value: "rectangle",
    label: floorPlanLocales.value.rectangle,
  },
  {
    value: "circle",
    label: floorPlanLocales.value.circle,
  },
]);
const figureBorderSizeOptions = computed(() => [1, 2, 3, 4, 5, 6]);
const figureType = ref({});
const setIntervalVar = ref();
const loaded = ref(false);

onBeforeMount(() => {
  figureType.value = figureTypeOptions.value.find(item => item.value == currentDot.value.figure_type);
  loaded.value = true;
});

const figureTypeSelect = val => {
  currentDot.value.figure_type = val.value;
  updateDot();
};

const updateColor = e => {
  currentDot.value.color = e;
  updateDot();
};

const updateDot = async () => {
  const plan_point = {
    figure_type: currentDot.value.figure_type,
    color: currentDot.value.color,
    border_width: currentDot.value.border_width,
    rotate: currentDot.value.rotate,
  };
  try {
    const res = await backend.update(`api/v3/plan_points`, currentDot.value.id, { plan_point });
  } catch (e) {
    console.log(e.message);
  }
};

const round = number => {
  return Math.round(parseFloat(number) * 100) / 100;
};

const startRotate = side => {
  const el = event.target;
  setIntervalVar.value = setInterval(() => {
    if (side === "left") {
      currentDot.value.rotate--;
    } else {
      currentDot.value.rotate++;
    }
  }, 30);
};

const stopRotate = () => {
  clearInterval(setIntervalVar.value);
  updateDot();
};

const removeDot = () => {
  emit("remove");
};
</script>

<style lang="scss">
@import "../../../../assets/styles/floor-plan/main";
</style>
