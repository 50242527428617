import Vue from "vue";

Vue.mixin({
  computed: {
    editable_data: {
      get() {
        let grid = this.$store.state.grid[this.grid];
        if (grid) {
          return grid["tech_map"][this.tech_map_key];
        } else {
          return undefined;
        }
      },
      set(value) {
        this.$store.commit("addEquipmentTechMap", {
          grid_name: this.grid,
          tech_map_key: this.tech_map_key,
          attr: value,
        });
      },
    },
  },

  methods: {
    setCheckable(row, i) {
      this.checkable = {
        index: i,
        row: row,
      };
    },

    setPropertiesTableHeight(id) {
      let table = document.getElementById(id).querySelector("tbody");
      Array.from(table.rows).forEach((row, i) => {
        let properties_table = row.querySelector("td.properties-td").querySelector("table.properties-table");
        if (properties_table) {
          properties_table.style.height = row.clientHeight + 1 + "px";
        }
      });
    },
  },
});
