import Vue from "vue";

Vue.mixin({
  computed: {},

  methods: {
    addWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    activeBadge(attr, add = true) {
      let current = document.getElementById(
        `${attr.id || attr.item}-badge-${(this[attr.item] && this[attr.item].id) || ""}`,
      );
      let next = document.getElementById(`${attr.id || attr.item}-badge-${(attr.new_item && attr.new_item.id) || ""}`);

      if (next) {
        Array.from(document.getElementsByClassName(`${attr.id || attr.item}-badge`))
          .filter(el => {
            return el.classList.contains("active-badge");
          })
          .map(el => {
            el.classList.remove("active-badge");
          });

        if (add) {
          next.classList.add("active-badge");
        }
      }
    },
  },
});
