<template lang="pug">
div
  .select-filters.form-field.form-multiselect.filter(@click.stop="toggleFilters", :class="selectFiltersClass")
    .row.items-center.select-filters__label {{ labelText }}
    .row.items-center.select-filters__icon
      inline-svg(v-if="showing", :src="require(`@/assets/icons/dynamic-issues/index/arrow-up.svg`)")
      inline-svg(v-else, :src="require(`@/assets/icons/dynamic-issues/index/arrow-down.svg`)")

  q-menu(
    content-class="select-filters__menu",
    v-model="showing",
    transition-show="jump-down",
    transition-hide="jump-up"
  )
    div
      q-list.select-filters__list(v-if="list.length")
        q-infinite-scroll.select-filters__list-scroll(
          @load="onLoad",
          :offset="50",
          scroll-target=".select-filters__list"
        )
          q-item.select-filters__list__item(v-for="item in list", :key="item.value")
            q-icon.select-filters__pin(@click="triggerIsDefault(item.value, item.is_default)")
              inline-svg(:src="item.is_default ? pinnedIcon : unpinnedIcon")
            span.select-filters__title(@click="selectFilter(item)") {{ item.label }}
            q-icon.select-filters__delete(@click="openFiltersDeleteForm(item.value)")
              inline-svg(:src="trash")
        template(v-slot:loading)
          .row.justify-center.q-my-md
            q-spinner-dots(color="primary", size="30px")
      q-list.select-filters__list(v-else) 
        q-item.select-filters__list__item 
          i {{ notifies.no_options_list }}

  filters-delete-form(
    :id="deleteFilterId",
    :show="showFiltersDeleteForm",
    @closeForm="closeFiltersDeleteForm",
    @delete="onDeleteFilter"
  )
</template>

<script setup>
import { ref, computed, onUnmounted } from "vue";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { dynamicIssuesLocales } from "@/services/useLocales";
import { useStore } from "@/store";
import _ from "lodash";
import FiltersDeleteForm from "./FiltersDeleteForm.vue";

import pinnedIcon from "@/assets/icons/pin__pinned.svg";
import unpinnedIcon from "@/assets/icons/pin__unpinned.svg";
import trash from "@/assets/icons/trash.svg";

const props = defineProps({
  /* 
  Идентификатор фасилити
  */
  facilityId: { type: Number, required: true },
  /* 
  Объект с сохраненным фильтром, который содержит label и value текущего фильтра
  */
  selectedFilter: { type: Object, required: false, default: null },
});

const emit = defineEmits([
  /*
  При выборе фильтра, отправляет идентификатор
  */
  "selectFilter",
]);

const store = useStore();
const path = store.state.paths["dynamic_issue_filters"];

const loading = ref(false);
const showing = ref(false);
const list = ref([]);
const page = ref(1);
const count = ref(0);

const deleteFilterId = ref(null);
const showFiltersDeleteForm = ref(false);

const maxPage = computed(() => Math.ceil(count.value / 20));

const hasNextPage = computed(() => page <= maxPage);

const selectFiltersClass = computed(() => ({
  "select-filters--active": showing.value,
  "select-filters--loading": loading.value,
}));

const text = computed(() =>
  loading.value ? dynamicIssuesLocales.value.select_fields.loading : dynamicIssuesLocales.value.filters.save_filters,
);

const labelText = computed(() => props.selectedFilter?.label || text.value);

const toggleFilters = async () => {
  resetValue();
  if (loading.value == true) {
    return;
  }

  if (showing.value == true && loading.value == false) {
    showing.value = false;
    return;
  }

  await loadFilters();
  showing.value = true;
};

const hideFields = () => {
  showing.value = false;
};

const onDeleteFilter = async id => {
  try {
    const response = await backend.destroy("/api/v3/dynamic/issue_filters", id);

    deleteFilterId.value = null;
    showFiltersDeleteForm.value = false;

    list.value = list.value.filter(el => el.value.toString() !== id.toString());
  } catch (e) {
    await handleError(e);
  }
};

const openFiltersDeleteForm = id => {
  deleteFilterId.value = id.toString();
  showFiltersDeleteForm.value = true;
};

const closeFiltersDeleteForm = () => {
  deleteFilterId.value = null;
  showFiltersDeleteForm.value = false;
};

const onLoad = async (index, done) => {
  if (index < maxPage.value) {
    await loadFilters();
    done();
  }
};

const loadFilters = async () => {
  try {
    loading.value = true;

    const params = {
      facility_id: props.facilityId,
      infinite_scroll: {
        page: page.value,
        per_page: 20,
      },
    };

    const response = await backend.collection(`${path}/collection`, params);

    count.value = response.data.count;
    if (hasNextPage.value) page.value += 1;

    const opts = response.data.options.map(el => ({ label: el.title, value: el.id, is_default: el.is_default }));
    // Sort by index and remove "index" field since we can reorder list
    list.value = _.unionBy(list.value, opts, "value");
  } catch (error) {
    await handleError(error);
  } finally {
    loading.value = false;
  }
};

const triggerIsDefault = async (id, is_default) => {
  try {
    const response = await backend.patch(`${path}/${id}/update_default`, { is_default: !is_default });

    list.value = list.value.map(el => ({ ...el, is_default: false }));

    const elIndex = list.value.findIndex(el => el.value === id);

    list.value[elIndex]["is_default"] = !is_default;
  } catch (e) {
    await handleError(e);
  }
};

const selectFilter = id => {
  resetValue();
  emit("selectFilter", id);
};

const resetValue = () => {
  page.value = 1;
  count.value = 0;
  list.value = [];
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/checkbox";

.select-filters {
  &__menu {
    width: 350px;
    overflow-y: hidden;
    border-radius: 8px;
  }

  &__delete {
    rect {
      fill: var(--dynamic-issues-select-filters-label-color) !important;
    }
  }
}
</style>

<style scoped lang="scss">
.select-filters {
  cursor: pointer;

  &__pin,
  &__delete {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__title {
    cursor: pointer;
    color: var(--dynamic-issues-select-filters-label-color);
    word-break: break-word;
  }

  &__delete {
    margin-left: auto;
    opacity: 0;
    transition: opacity 0.1s ease;
  }

  &--loading {
    cursor: wait;
  }

  &--active {
    cursor: pointer;
  }

  &__label {
    padding-left: 15px;
    width: 100%;
    color: var(--dynamic-issues-select-filters-label-color);
    word-break: break-word;
  }

  &__icon {
    padding-right: 10px;
  }

  &__list {
    overflow-y: scroll;
    max-height: 200px;
    padding: 20px 0;

    &__item_wrapper {
      padding: 0 0 0 5px;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 10px;
      &:hover {
        .select-filters__delete {
          opacity: 1;
        }
      }
    }
  }
}
</style>
