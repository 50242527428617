import Vue from "vue";

Vue.mixin({
  methods: {
    checkFloatCondition(c, current_value, r) {
      let result = r;
      let parent = this.currentForm[c.condition_field]["field"];

      if (!["", undefined, null].includes(parent)) {
        current_value = parseFloat(current_value);
        parent = parseFloat(parent);

        switch (c.expect_result) {
          case ">":
            if (current_value > parent) {
              this.valid_error_message = c.message;
              result = true
            } else {
              this.valid_error_message = null;
              result = false
            }
            break;
          case ">=":
            if (current_value >= parent) {
              this.valid_error_message = c.message;
              result = true
            } else {
              this.valid_error_message = null;
              result = false
            }
            break;
          case "<":
            if (current_value < parent) {
              this.valid_error_message = c.message;
              result = true
            } else {
              this.valid_error_message = null;
              result = false
            }
            break;
          case "<=":
            if (current_value < parent) {
              this.valid_error_message = c.message;
              result = true
            } else {
              this.valid_error_message = null;
              result = false
            }
            break;
          default:
            this.valid_error_message = null;
            result = false
        }
      }

      return result
    }
  }
});
