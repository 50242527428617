export default {
  light: {
    grid_border_color: "#E8E9EB",
    fore_color: "#B5BBC9",
    grid_background_color: "#FFFFFF",
    legend_hidden_color: "#EEEEEE",
    tooltip_background: "#FFFFFF",
    tooltip_color: "#0D1F3C",
  },

  dark: {
    grid_border_color: "#454545",
    fore_color: "#8A9099",
    grid_background_color: "#1C1C25",
    legend_hidden_color: "#444444",
    tooltip_background: "#1C1C25",
    tooltip_color: "#FFFFFF",
  },
};
