<template lang="pug">
.login-component
  video(autoplay, muted, loop)
    source(type="video/mp4", :src="require('../../../assets/videos/amelia-background-video.mp4')")

  .login-background

  .spinner-container.form-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  .q-pa-md.row.items-start.justify-center(v-show="!loading")
    .sales-form-card
      q-card-section.sales-form.justify-center
        q-form(@submit="submitForm")
          .sales-form-label
            span ФИО сотрудника:
          .sales-form-input
            q-input(
              filled,
              :label-slot="false",
              v-model="form.full_name",
              lazy-rules,
              no-error-icon,
              :rules="[val => (val && val.length > 0) || login_locales['fields_rules.not_empty']]"
            )

          .sales-form-label
            span Контактный номер телефона:
          .sales-form-input
            q-input(
              filled,
              :label-slot="false",
              v-model="form.phone",
              mask="+7 (###) ### - ####",
              lazy-rules,
              no-error-icon,
              :rules="[val => (val && val.length > 0) || login_locales['fields_rules.not_empty']]"
            )

          .sales-form-label
            span E-mail:
          .sales-form-input
            q-input(
              filled,
              :label-slot="false",
              v-model="form.email",
              lazy-rules,
              no-error-icon,
              :rules="[val => (val && val.length > 0) || login_locales['fields_rules.not_empty']]"
            )

          .sales-form-label
            span ИНН организации:
          .sales-form-input
            q-input(
              filled,
              :label-slot="false",
              v-model="form.inn",
              lazy-rules,
              no-error-icon,
              :rules="[val => (val && val.length > 0) || login_locales['fields_rules.not_empty']]"
            )

          .sales-form-label
            span Выберите продукт:
          .products-input
            q-option-group(:options="products_options", type="checkbox", v-model="form.products")

          .sales-form-label
            span Сумма сделки:
          .sales-form-input
            q-input(
              filled,
              :label-slot="false",
              v-model="form.sum",
              lazy-rules,
              no-error-icon,
              :rules="[val => (val && val.length > 0) || login_locales['fields_rules.not_empty']]"
            )

          .button-group.row.justify-center
            q-btn.sales-form-submit(flat, no-caps, label="Отправить", type="submit")
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      path: "",
      products_options: [
        { label: "Амелия", value: "Амелия" },
        { label: "Умный объект", value: "Умный объект" },
        { label: "Комплексная уборка", value: "Комплексная уборка" },
        {
          label: "Обеззараживание воздуха методом фотокатализа",
          value: "Обеззараживание воздуха методом фотокатализа",
        },
      ],

      form: {
        full_name: null,
        phone: null,
        email: null,
        inn: null,
        products: [],
        sum: null,
      },
    };
  },

  created() {
    this.loading = false;
    this.$emit("set-loading", false);
  },

  methods: {
    submitForm(params = {}) {
      params["sales_form"] = this.form;

      this.$backend
        .create(this.$store.state.paths["sales_form"], null, params)
        .then(({ data }) => {
          if (data) {
            this.$nextTick(() => {
              this.loading = false;
              this.resetForm();
              this.$q.notify("Ваша заявка успешно отправлена.");
            });
          }
        })
        .catch(error => {
          this.$nextTick(() => {
            this.loading = false;
            // this.resetForm()
            this.$q.notify(this.notifies.error_ask_admin);
          });
          console.log(error);
        });
    },

    resetForm() {
      this.form = {
        full_name: null,
        phone: null,
        email: null,
        inn: null,
        products: [],
        sum: null,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/login.scss";

.sales-form-card {
  width: 400px;
  height: 90vh;
  overflow-y: auto;
  z-index: 1;
  border-radius: 23px;
}

.sales-form-card::-webkit-scrollbar {
  display: none;
}

.sales-form {
  margin-top: 50px;

  .sales-form-label {
    padding-bottom: 4px;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #e8e9eb;
    }
  }

  .sales-form-input {
    margin-bottom: 25px;
    border: 1px solid #e8e9eb;
    border-radius: 13px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      font-size: 14px;
      -webkit-text-fill-color: #ffffff !important;
      -webkit-box-shadow: 0 0 0 0 #fff inset !important;
      -webkit-background-clip: text !important;
    }

    .q-field__control-container {
      //height: 30px;
      margin-top: 4px;
    }

    input {
      background: transparent !important;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #fff;
    }

    .q-field__inner {
      height: 38px;
    }

    .q-field--filled .q-field__control {
      background: transparent;
    }

    .q-field--filled.q-field--focused .q-field__control:before {
      background: transparent;
    }

    .q-field--filled.q-field--highlighted .q-field__control:before {
      background: initial !important;
    }

    .q-field__bottom {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 0.07px;
      color: #df5060;
      padding-left: 0px !important;
      padding-top: 5px !important;
    }
  }

  .q-checkbox {
    padding: 0;

    .q-checkbox__inner {
      margin-left: -8px;
      font-size: 30px;
    }

    .q-checkbox__inner--truthy {
      color: #5b7aff;
    }

    .q-checkbox__inner--falsy {
      color: #5b7aff;
    }

    .q-checkbox__label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #fff;
    }
  }

  .products-input {
    margin-left: 5px;
    margin-bottom: 20px;
  }

  .button-group {
    width: 100%;

    .sales-form-submit {
      margin-top: 25px;
      border-radius: 13px;
      background-color: #5b7aff;
      color: #fff;
      width: 100%;
      height: 43px;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
}
</style>
