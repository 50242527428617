import icon from "../../../../assets/icons/map/controls/search.svg";

// Styles: ../../../../assets/styles/map/controls/search.scss

// const HTML = `
//   <div class='map-search'>
//     <img src='${icon}' class='map-search__icon' />
//     <input class='map-search__input' placeholder="${ map_locales['search_placeholder'] }" />
//   </div>
// `;

function getHtml(map_locales) {
  return `
    <div class='map-search'>
      <img src='${icon}' class='map-search__icon' />
      <input class='map-search__input' placeholder="${map_locales["search_placeholder"]}" />
    </div>
  `;
}

/*
 * Creates
 */
class Search {
  constructor(api, map, handleInput, map_locales) {
    let HTML = getHtml(map_locales);
    this.control = new api.Control(map.getWrapee(), HTML, { position: "topRight" });
    const input = this.control.getContainer().querySelector("input");
    input.oninput = e => handleInput(e.target.value);
  }
}

export default Search;
