<template lang="pug">
.index-page.dashboard
  .trouble-shooting-container(v-if="current_branch")
    .trouble-shooting-message(style="padding-left: 10px; padding-right: 10px")
      span {{ current_branch }}

  .spinner-container.grid-spinner(v-if="!loading && loading_data")
    q-spinner(color="primary", size="3em")

  div(v-if="!loading")
    common-header(
      v-show="!loading_data",
      @reload-data="reloadData",
      :parentData="{ data: dashboard_data, grid: grid, item: item, hide_filters: true, hide_buttons: true, actions: header_actions }"
    )

  .quantities-carousel-block(v-if="!loading")
    div(v-show="!loading_data")
      quantities-carousel(
        ref="quantities-carousel",
        :parentData="{ issues_total_count: dashboard_data.boards.issues_count, issues_count_by_status: dashboard_data.boards.issues_count_by_status }"
      )

  .middle-block(v-if="!loading")
    .middle-left-block(v-if="!loading_data")
      .dashboard-card.customer-satisfaction
        rates-csi-by-day

    .middle-right-block(v-show="!loading_data")
      dashboard-links(:parentData="{ data: dashboard_data.boards.dashboard_links }")

  .footer-block(v-if="!loading")
    .footer-donuts-block(v-if="!loading_data")
      .dashboard-card.chart-card
        donut-chart(:parentData="{ data: dashboard_data.boards.obligations_execution, key: 'obligations_execution' }")
      .dashboard-card.chart-card
        donut-chart(:parentData="{ data: dashboard_data.boards.work_efficiency, key: 'work_efficiency' }")
      .dashboard-card.chart-card
        donut-chart(:parentData="{ data: dashboard_data.boards.rejected_percentage, key: 'rejected_percentage' }")
      .dashboard-card.chart-card
        donut-chart(:parentData="{ data: dashboard_data.boards.reworked_percentage, key: 'reworked_percentage' }")

    .footer-posts-block(v-show="!loading_data")
      .dashboard-card.posts-card
        .dashboard-card-label
          span {{ dashboard_data.boards.posts.title }}
        dashboard-posts(:parentData="{ posts: dashboard_data.boards.posts.data }")

  ui-prompts(v-if="!loading_data", :parentData="{ areas_key: grid }")
</template>

<script>
import commonHeader from "../../shared/helpers/Header";
import quantitiesCarousel from "./quantities/quantitiesCarousel";
import dashboardLinks from "./links/dashboardLinks";
import dashboardPosts from "./posts/dashboardPosts";
import donutChart from "./charts/donutChart";
import apexChart from "./charts/apexChart";
import chartJs from "./charts/chartjsChart";
import uiPrompts from "../../shared/ui_prompts/index.vue";
import ratesCsiByDay from "./boards/ratesCsiByDay.vue";

export default {
  components: {
    commonHeader,
    quantitiesCarousel,
    dashboardLinks,
    dashboardPosts,
    donutChart,
    apexChart,
    chartJs,
    uiPrompts,
    ratesCsiByDay,
  },
  data: function () {
    return {
      grid: "dashboards",
      item: "dashboard",
      path: this.$store.state.paths,
      dashboard_data: {},
      loading: true,
      loading_data: true,
      header_actions: ["project_filter_form"],
      current_branch: "",
    };
  },

  watch: {
    loading(newVal, oldVal) {
      this.$emit("set-loading", this.loading);

      setTimeout(() => {
        this.loading_data = newVal;
      }, 400);
    },
  },

  created() {},

  mounted() {
    this.$root.$on("reload-dashboard-data", () => {
      this.loadDashboardData();
    });
  },

  beforeMount() {
    this.$emit("expand-class", { val: true, class: "dashboard-background" });
    this.loadCurrentBranch();
    this.loadDashboardData();
  },

  methods: {
    reloadData() {
      this.loadDashboardData();
    },

    loadDashboardData(params = {}) {
      if (this.filters) {
        params.filters = this.generateFiltersParams();
      }

      this.$backend
        .index(this.path.dashboard_data, { params: params })
        .then(({ data }) => {
          this.dashboard_data = data;
        })
        .catch(error => {
          if (error.response) {
            this.reLogin(error.response.status);
          }
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadCurrentBranch() {
      this.$backend
        .index(this.path.current_branch)
        .then(({ data }) => {
          this.current_branch = data["current_branch"];
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>
