<template lang="pug">
.expenses__row
  .expenses__row-items(v-if="!loading")
    expenses-row-item(v-for="item in items", :key="item.label", :item="item")
  .expenses__row-loading(v-else)
    q-spinner(color="primary", size="3em")
</template>

<script setup>
import ExpensesRowItem from "./ExpensesRowItem.vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { backend } from "@/api";

import { useEmitter } from "@/services/useEmitter";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { handleError } from "@/services/handleErrors";

import { useStore } from "@/store";

const emitter = useEmitter();

const store = useStore();

const props = defineProps({
  path: { type: String, required: true },
});

const items = ref();
const loading = ref(true);

const getData = async () => {
  const params = generateParams();
  loading.value = true;

  try {
    const { data } = await backend.index(props.path, { params }, { encodeNestedData: true });
    items.value = data;
  } catch (e) {
    await handleError(e);
  } finally {
    loading.value = false;
  }
};

const generateParams = () => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["expenses_dashboard"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  const params = {
    query: localQuery,
    filters: localFilters,
  };

  return params;
};

onMounted(async () => {
  await getData();

  emitter.on("refresh-expenses-dashboard-data", getData);
});

onBeforeUnmount(() => {
  emitter.off("refresh-expenses-dashboard-data");
});
</script>

<style lang="scss">
.expenses {
  &__row-items {
    display: flex;
    align-items: stretch;
    gap: 12px;
  }
  &__row-loading {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
