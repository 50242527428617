import Vue from "vue";

import "../../assets/styles/quasar.styl";
import iconSet from "quasar/icon-set/fontawesome-v5.js";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";

import Quasar, {
  QLayout,
  QExpansionItem,
  QHeader,
  QTime,
  QDrawer,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QInput,
  QDate,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QSpinnerAudio,
  QTable,
  QTh,
  QTr,
  QTd,
  QTabs,
  QTab,
  QTabPanels,
  QTabPanel,
  QRouteTab,
  QDialog,
  ClosePopup,
  QCard,
  QCardSection,
  QCardActions,
  QBtnDropdown,
  QSelect,
  QToggle,
  QForm,
  Notify,
  QCheckbox,
  QSpinner,
  QChip,
  QMenu,
  Dialog,
  QSeparator,
  QPopupProxy,
  QBanner,
  QRating,
  QFile,
  QRadio,
  QOptionGroup,
  QField,
  QColor,
  QStepper,
  QStep,
  QStepperNavigation,
  QSpace,
  QSkeleton,
  QBadge,
  QTooltip,
  QInfiniteScroll,
  QSpinnerDots,
  QScrollArea,
} from "quasar";

Vue.use(Quasar, {
  config: {},
  components: {
    QLayout,
    QExpansionItem,
    QHeader,
    QDrawer,
    QTime,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QInput,
    QDate,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QSpinnerAudio,
    QTable,
    QTh,
    QTr,
    QTd,
    QTabs,
    QTab,
    QTabPanels,
    QTabPanel,
    QRouteTab,
    QDialog,
    QCard,
    QCardSection,
    QCardActions,
    QBtnDropdown,
    QSelect,
    QToggle,
    QForm,
    QCheckbox,
    QSpinner,
    QChip,
    QMenu,
    QSeparator,
    QPopupProxy,
    QBanner,
    QRating,
    QFile,
    QRadio,
    QOptionGroup,
    QField,
    QColor,
    QStepper,
    QStep,
    QStepperNavigation,
    QSpace,
    QSkeleton,
    QBadge,
    QTooltip,
    QInfiniteScroll,
    QSpinnerDots,
    QScrollArea,
  },
  directives: {
    ClosePopup,
    QPopupProxy,
  },
  plugins: {
    Notify,
    Dialog,
  },
  iconSet: iconSet,
});

Notify.setDefaults({
  position: "top",
  // timeout: 2500,
  // textColor: 'white',
  // actions: [{ icon: 'close', color: 'white' }]
});
