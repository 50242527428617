<template lang="pug">
div(style="box-shadow: initial")
  .files-area-body(v-if="filesExist(files)")
    .existing-file(v-for="file in files")
      .form-image(v-if="isImage(file)")
        a.file-link(v-if="file", :href="file.url", target="_blank", :link_title="file.file_name")
          img.file-image(:src="file.url")
          span.caption {{ cutFileName(file.file_name) }}

      .form-image(v-else)
        a.file-link(v-if="file", :href="file.url", target="_blank", :link_title="file.file_name")
          inline-svg.file-document(:src="require('../../../assets/icons/document.svg')")
          span.caption {{ cutFileName(file.file_name) }}
  .files-area-body(v-else)
    span -
</template>

<script>
export default {
  components: {},

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      files: this.parentData.files,
    };
  },

  computed: {},

  methods: {
    closeFilesModal() {
      this.$emit("close-files-modal");
    },

    isImage(value) {
      if (value) {
        return ["image/jpeg", "image/png"].includes(value.content_type);
      } else {
        return false;
      }
    },

    filesExist(files) {
      return files.length > 0;
    },

    cutFileName(name) {
      return name.length > 16 ? name.substring(0, 14) + "..." : name;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/fields/file";
</style>
