<template lang="pug">
div
  .form-field-label
    span {{ `${label}` }}
    span.form-field-require-tag(v-show="required")
      | *

  .form-field.form-input(:class="invalidClass")
    q-input(
      filled,
      no-error-icon,
      v-model="value",
      @input="changeValue",
      :placeholder="placeholder",
      :label-slot="false",
      autocomplete="off"
    )
      q-icon.cancel-string-field(name="cancel", v-if="value && !readonly", @click.stop="resetValue")

  .valid-error-message(v-if="invalid") {{ errorMessage }}
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

const props = defineProps({
  label: { type: String, default: "", required: true },
  required: { type: Boolean, default: false, required: false },
  initialValue: { type: String || null, default: null, required: false },
  placeholder: { type: String, default: "", required: false },
  readonly: { type: Boolean, default: false, required: false },
  /**
   * Флаг отвечающий за валидность поля. Включает подсветку с ошибкой и текстом errorMessage
   */
  invalid: { type: Boolean, default: false, required: false },
  /**
   * Текст отображаемый при невалидном поле
   */
  errorMessage: { type: String, default: "", required: false },
});

const emit = defineEmits(["change", "reset"]);

const value = ref(props.initialValue);

const invalidClass = computed(() => (props.invalid ? "valid-error" : ""));

const changeValue = () => {
  emit("change", { value: value.value });
};

const resetValue = () => {
  value.value = null;
  emit("reset");
  changeValue();
};
</script>

<script>
export default {
  name: "StringField",
};
</script>
