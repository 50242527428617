<template lang="pug">
.equipment-tech-map
  .tech-map-card
    .main-block
      qr-image(:url="qrObject.url")

      .actions-block
        .tech-map-title
          .title
            span {{ data.title }}
          .sub-title
            span {{ data.sub_title }}

    .specification-block
      .block-title
        span {{ titles.specification }}

      div
        table.specification-table
          tbody
            tr(v-for="item in getData('specification')")
              td(v-for="key in Object.keys(item)") {{ item[key].length > 0 ? item[key] : "-" }}

    .components-block
      .block-title
        span {{ titles.components }}

      table#print-components-table.tech-map-tab-table.components-table
        thead
          tr
            th(v-for="column in data['columns']['components']", :key="column.name")
              span.column-header-title {{ column.label }}
        tbody
          tr(v-for="item in getData('components')")
            td(
              v-for="key in Object.keys(item)",
              :class="{ 'properties-td': key === 'properties' }",
              :style="{ padding: key === 'properties' ? '0' : '10px' }"
            )
              table.properties-table(v-if="key === 'properties'")
                tr(v-for="sub_item in item[key]")
                  td(v-for="sub_key in Object.keys(sub_item)")
                    span {{ sub_item[sub_key].length > 0 ? sub_item[sub_key] : "-" }}

              span(v-else) {{ item[key].length > 0 ? item[key] : "-" }}

    .replaced-components-block
      .block-title
        span {{ titles.replaced_components }}

      table#print-replaced-components-table.tech-map-tab-table.replaced-components-table
        thead
          tr
            th(v-for="column in data['columns']['replaced_components']", :key="column.name")
              span.column-header-title {{ column.label }}
        tbody
          tr(v-for="item in getData('replaced_components')")
            td(
              v-for="key in Object.keys(item)",
              :class="{ 'properties-td': key === 'properties' }",
              :style="{ padding: key === 'properties' ? '0' : '10px' }"
            )
              table.properties-table(v-if="key === 'properties'")
                tr(v-for="sub_item in item[key]")
                  td(v-for="sub_key in Object.keys(sub_item)")
                    span {{ sub_item[sub_key].length > 0 ? sub_item[sub_key] : "-" }}

              span(v-else) {{ item[key].length > 0 ? item[key] : "-" }}

    .issues-block(v-if="getData('issues').length > 0")
      .block-title
        span {{ titles.issues }}

      table.tech-map-tab-table.issues-table
        thead
          tr
            th(v-for="column in data['columns']['issues']", :key="column.name")
              span.column-header-title {{ column.label }}
        tbody
          tr(v-for="issue_data in getData('issues')")
            td(v-for="td in issue_data", :class="td.key", :style="td.style")
              span {{ td.value }}
</template>

<script>
import qrImage from "../../../../../../shared/print/qrImage";

export default {
  components: {
    qrImage,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },

  data: function () {
    return {
      data: this.parentData.data,
      titles: this.parentData.titles,
      row: this.parentData.row,
    };
  },

  computed: {
    qrObject() {
      return this.data.qr;
    },
  },

  methods: {
    getData(key) {
      if (key) {
        if (key === "issues") {
          return this.row[key];
        } else {
          return this.row["technological_map"][key];
        }
      }
    },
  },
};
</script>
