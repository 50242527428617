<template lang="pug">
.notification-rule-grid-filters.row
  .grid-filters.col-6(v-show="!loading")
    component(
      v-for="filter in data.filters",
      :key="filter.name",
      :ref="filter.name",
      :is="getFilterComponent(getFilterDataByName(filter.name))",
      @filters-loaded="filtersLoaded",
      @load-options="loadSelectFilterOptions",
      :parentData="{ grid: grid, data: [getFilterDataByName(filter.name)], options_data: options_data }"
    )
</template>

<script>
import filters from "../../shared/filters";

export default {
  components: {
    filters,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      path: this.parentData.path,
      loading_filters: [],
      loading: true,
      options_data: {},
    };
  },

  computed: {
    data() {
      return this.parentData.data.filters_data;
    },
  },

  created() {},

  mounted() {},

  methods: {
    getFilterComponent(key) {
      if (key) {
        return filters[key.type];
      }
    },

    getFilterDataByName(filter_name) {
      return this.data.filters.find(f => f.name === filter_name);
    },

    setOptionsData(data, filter_name) {
      this.$refs[filter_name][0].setOptionsData(data);
    },

    setLoading(val, filter_name) {
      this.$refs[filter_name][0].setLoading(val);
    },

    filtersLoaded(filter) {
      if (this.loading_filters.length === 0) {
        this.loading_filters = this.data.filters
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }
      this.loading_filters = this.loading_filters.filter(f => f.sort().toString() !== filter.sort().toString());

      if (this.loading_filters.length === 0) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
