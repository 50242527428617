<template lang="pug">
.dashboard-card.measuring-last-readings
  .dashboard-card-label.noselect(v-show="currentGrid && currentGrid.data.length > 0")
    span {{ (this.currentGrid && this.currentGrid.title) || "" }}
  .grid
    grid(
      ref="measuring_last_readings",
      @get-collection="getCollection",
      :parentData="{ grid: grid, grid_key: grid_key, sort_by: 'created_at' }"
    )
</template>

<script>
export default {
  components: {
    Grid: () => import("../../../../shared/grid/Grid"),
  },
  data: function () {
    return {
      gridData: {
        grid: "measuring_last_readings",
        grid_key: "statistic_grid_data",
        // default_class: 'simple-table',
        // spinner_size: '2em',
        // without_thead: true,
        // without_row_title: true
      },
    };
  },

  computed: {
    grid() {
      return this.gridData.grid;
    },
    grid_key() {
      return this.gridData.grid_key;
    },
  },

  created() {
    this.$store.commit("initialGridsState", { grid: this.grid, attr: {} });
  },

  methods: {
    reloadData(params) {
      let result_params = this.currentGrid.pagination;
      result_params.page = 1;
      result_params.query = "";
      result_params.filters = params.filters;
      this.getCollection(result_params);
    },

    getCollection(params) {
      if (this.$route.query.building_id) {
        if (!params.filters["building_id"]) {
          params.filters["building_id"] = this.$route.query.building_id;
        }

        history.replaceState({}, null, `${this.$route.path}?building_id=${params.filters["building_id"]}`);
      }

      this.gridCollection(this.$store.state.paths[this.grid], this.grid, this.grid_key, params)
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.$refs[this.grid].setError(false);
        })
        .finally(() => {
          this.$refs[this.grid].setLoading(false);

          if (this.currentGrid.data.length === 0) {
            this.$refs[this.grid].hideTable(true);
          } else {
            this.$refs[this.grid].hideTable(false);
          }
        });
    },
  },
};
</script>

<style lang="scss"></style>
