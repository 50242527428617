<template lang="pug">
div
  .form-field-label
    span {{ label }}
    span.form-field-require-tag(v-show="req", :title="notifies.required_field")
      | *

  div(:class="[currentFieldIsInvalid ? `${main_class} valid-error` : main_class]")
    .file-drag-drop-area(
      v-if="!filesExist(currentField)",
      :id="`file-field-${name}`",
      ref="fileInput",
      @click="openFileInput"
    )
      img.file-field-icon(:src="require('../../../../assets/icons/form/fields/file_field_icon.svg')")
      span.drop-files {{ field_locales.sublabel }}
      q-file(
        v-show="false",
        filled,
        v-model="currentField",
        :multiple="multiple",
        :label="label",
        :for="`file-input-${name}`"
      )

    .form-preview-area.justify-center(v-if="filesExist(currentField)")
      .preview-file(v-for="(file, key) in currentField")
        img.preview-img(
          v-if="['image/jpg', 'image/gif', 'image/jpeg', 'image/png'].includes(file.type)",
          :ref="'preview-img' + parseInt(key)"
        )
        inline-svg.preview-doc(v-else, :src="require('../../../../assets/icons/document.svg')")
        span.caption {{ cutFileName(file.name) }}

    q-icon.cancel-file-field(name="cancel", v-if="currentField", @click.stop="resetField()")

  span.valid-error-message(v-if="currentFieldIsInvalid") {{ valid_error_message }}

  .form-image-area.justify-center(v-if="filesExist(value)")
    existing-files-area(:parentData="{ files: value }")
</template>

<script>
import existingFilesArea from "../../helpers/existingFilesArea";

export default {
  components: {
    existingFilesArea,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      method: this.parentData.method,
      method_limit: this.parentData.method_limit,
      grid: this.parentData.grid,
      multiple: this.parentData.data[0].multiple || false,
      value: this.parentData.data[0].value,
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      available_format: this.parentData.data[0].available_format,
      depend_from: this.parentData.data[0].depend_from || {},
      // required: this.parentData.data[0].require,
      req: this.parentData.data[0].require,
      valid_error_message: null,
      main_class: "form-field form-file",

      watch: this.parentData.data[0].watch || {},
      image_url: "",

      image: false,

      dragAndDropCapable: false,
      files: [],
      uploadPercentage: 0,
    };
  },

  computed: {
    required: {
      get() {
        return this.req && this.files?.length === 0 && this.value?.length === 0;
      },
      set(value) {
        this.req = value;
      },
    },

    currentField: {
      get() {
        let form_field = this.currentForm[this.name];
        if (form_field) {
          if (form_field["field"]) {
            return form_field["field"].map(field => field["label"]);
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      },
      set(value) {
        this.checkAvailableFormat(value);
        if (this.required && this.currentField) {
          this.$store.commit("resetFormFieldValue", {
            grid_name: this.grid,
            field: "invalid_fields",
            value: this.name,
          });
        }
      },
    },

    fieldDependency() {
      return this.checkDependency();
    },

    field_locales() {
      return this.locales.file_field[this.current_locale];
    },
  },

  watch: {
    fieldDependency(newVal, oldVal) {},
  },

  created() {
    this.resetField();
    this.watchSimpleFieldParents();
  },

  beforeMount() {},

  mounted() {
    let input = document.getElementById(`file-field-${this.name}`);
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach(
        function (evt) {
          input.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false,
          );
        }.bind(this),
      );
      input.addEventListener(
        "drop",
        function (e) {
          let files = this.multiple ? e.dataTransfer.files : [e.dataTransfer.files[0]];
          files = Object.values(files);
          this.checkAvailableFormat(files);
        }.bind(this),
      );
    }
  },

  methods: {
    setField(val) {
      // this.value = val
    },

    setRequire(val) {
      this.required = val;
      let result = {};
      if (val) {
        // предотвращаем стирание для возврата файла при смене сервиса заявки. Нет необходимости стирать этот файл при срабатывании функции
        // this.resetField();
      } else {
        let result = {};
        result["invalid"] = false;
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });

        this.$store.commit("resetFormFieldValue", {
          grid_name: this.grid,
          field: "invalid_fields",
          value: this.name,
        });
      }
    },

    checkAvailableFormat(value) {
      if (this.available_format && this.available_format.types && this.available_format.types.length > 0) {
        if (this.multiple) {
          value.forEach(f => {
            if (!this.available_format.types.includes(f.type)) {
              let msg = this.notifies.invalid_file_extension + this.available_format.names.join(", ");
              this.$q.notify(msg);
            }
          });
        } else {
          let val = value[0] || value;
          if (!this.available_format.types.includes(val.type)) {
            let msg = this.notifies.invalid_file_extension + this.available_format.names.join(", ");
            this.$q.notify(msg);
          } else {
            this.getFile(val);
          }
        }

        if (this.multiple) {
          let val = value.filter(f => {
            return this.available_format.types.includes(f.type);
          });
          this.getFile(val);
        }
      } else {
        this.getFile(value);
      }
    },

    cutFileName(name) {
      return name.length > 16 ? name.substring(0, 14) + "..." : name;
    },

    filesExist(array) {
      return array && array.filter(el => el).length > 0;
    },

    openFileInput() {
      document.getElementById(`file-input-${this.name}`).click();
    },

    determineDragAndDropCapable() {
      let div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },

    getImagePreviews() {
      if (this.currentField && this.currentField.length > 0) {
        this.currentField.forEach((file, i) => {
          if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
            let reader = new FileReader();
            reader.addEventListener(
              "load",
              function () {
                this.$refs["preview-img" + parseInt(i)][0].src = reader.result;
              }.bind(this),
              false,
            );
            reader.readAsDataURL(file);
            // } else {
            //   this.$nextTick(function(){
            //     this.$refs['preview' + parseInt( i )][0].src = require('assets/icons/document.svg')
            //   });
          }
        });
      }
    },

    showImage(url) {
      this.image_url = url;
      this.image = true;
    },

    isImage(value) {
      if (value) {
        return ["image/jpeg", "image/png"].includes(value.content_type);
      } else {
        return false;
      }
    },

    getFile(value) {
      let files = [];
      let result = {};
      let arr = [value].flat();

      arr.forEach(file => {
        let val = {
          label: file,
          value: { name: file.name, file: file, type: file.type },
        };

        files = files.concat([val]);

        result["field"] = files;
        result["invalid"] = this.invalid(files);
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
      });

      setTimeout(() => {
        this.getImagePreviews();
      }, 500);
    },

    resetField() {
      let result = {};
      result["invalid"] = this.invalid();
      this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
    },

    invalid(val = undefined) {
      if (this.required) {
        if (val && val.length > 0) {
          this.valid_error_message = null;
          return false;
        } else {
          this.valid_error_message = this.notifies.not_empty;
          return true;
        }
      } else {
        this.valid_error_message = null;
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/file";

.form-file {
  margin-bottom: 20px !important;
  & ~ .valid-error-message {
    margin-top: -20px !important;
  }
}
</style>
