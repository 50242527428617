<template lang="pug">
.grid-filters-row
  .more-filters(v-if="additional_filters.length > 0", :id="`${grid.split('_').join('-')}-more-filters`")
    .more-filters-wrapper(@click="openFiltersForm()")
      inline-svg.more-filters-icon(:src="require('../../../assets/icons/filters/more_filters.svg')")
      span.more-filters-label {{ header["filters_form"]["form_header"] + "(" + additional_filters.length + ")" }}

  .reset-filters
    inline-svg.reset-filters-icon(
      @click="resetFilters()",
      :src="require('../../../assets/icons/filters/reset_filters.svg')"
    )

  .reset-filters
    inline-svg.reset-last-filter-icon(
      v-if="lastFilter",
      @click="resetLastFilter()",
      :src="require('../../../assets/icons/back.svg')"
    )

  q-dialog(ref="filters-modal", v-model="filters_modal")
    grid-filters-form(
      ref="grid-filters-form",
      @load-options="loadSelectFilterOptionsForm",
      @submit-filters-form="submitFiltersForm",
      @close-filters-form="closeFiltersForm",
      :parentData="{ data: data, header: header, grid: grid, path: path, options_data: options_data }"
    )
</template>

<script>
import filters from "../filters";
import gridFiltersForm from "./gridFiltersForm";
import _ from "lodash";
import { useEmitter } from "@/services/useEmitter";

export default {
  components: {
    filters,
    gridFiltersForm,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      path: this.parentData.path,
      // data: this.parentData.data,
      back_filters: this.parentData.back_filters || true,
      additional_filters: this.parentData.data.filters.filter(
        f => !["search_all", "selected_date_label"].includes(f.type),
      ),

      options_data: {},
      options: [],

      filters_modal: false,
      emitter: useEmitter(),
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },

    header() {
      return this.parentData.header;
    },

    lastFilter() {
      const unresettableFilterNames = Object.keys(this.$store.state.unresettable_filters);

      if (unresettableFilterNames.length > 0) {
        return undefined;
      }

      let filters = Object.keys(this.filters).reduce((result, key) => {
        if (this.filters[key]) {
          let val = this.filters[key].value || this.filters[key];
          if (val) {
            result[key] = this.filters[key];
          }
        }
        return result;
      }, {});

      let filters_keys = Object.keys(filters);
      let filter_name = filters_keys[filters_keys.length - 1];

      if (filter_name && filters_keys.length > 0) {
        let attr = {};
        attr["name"] = filter_name;
        attr["value"] = this.filters[filter_name].value;
        return attr;
      } else {
        return undefined;
      }
    },
  },

  methods: {
    resetLastFilter() {
      this.$root.$emit("reset-last-filter", this.lastFilter.name);
      this.emitter.emit("reset-last-filter", this.lastFilter.name);
    },

    updateFilter(filter) {
      if (filter && filter.type) {
        let key = "update_" + filter.type + "_filter";
        this[key](filter);
      }
    },

    openFiltersForm() {
      this.filters_modal = true;
    },

    submitFiltersForm() {
      this.filters_modal = false;
      this.$root.$emit("filters-form-accepted", true);
      this.emitter.emit("filters-form-accepted", true);
    },

    closeFiltersForm() {
      this.filters_modal = false;
      this.$root.$emit("filters-form-accepted", false);
      this.emitter.emit("filters-form-accepted", false);
    },

    resetFilters() {
      this.$root.$emit("filters-form-reset");
      this.emitter.emit("filters-form-reset");
    },

    getFilterComponent(key) {
      return filters[key];
    },

    getFilterDataByName(filter_name) {
      return this.data.filters.find(f => f.name === filter_name);
    },

    update_search_all_filter(filter) {
      if (filter.value) {
        // this.$store.commit('updateFilter', {grid_name: this.grid, filter: filter.name, value: filter.value})
        this.$store.commit("updateQuery", { grid_name: this.grid, value: filter.value });
      }
    },

    update_date_filter(filter) {
      if (filter.value) {
        this.$store.commit("updateFilter", { grid_name: this.grid, filter: filter.name, value: filter.value });
      }
    },

    update_select_filter(filter) {
      let params = {};
      let filter_data = this.data.filters.find(f => f.name === filter.name);

      if (filter_data.options_params) {
        if (filter_data.options_params["infinite_scroll"]) {
          filter_data.options_params["infinite_scroll"] = {
            page: 1,
            per_page: 20,
          };
        }
        params["params"] = filter_data.options_params;
        params["filter_name"] = filter.name;
      }

      this.loadSelectFilterOptions(params, filter.value);
      this.loadChildrenSelectFilterOptions(filter);
    },

    loadSelectFilterOptionsForm(data) {
      let filter_data = this.data.filters.find(f => f.name === data.filter_name);

      if (filter_data.watch && filter_data.watch["parent"]) {
        let parent = this.currentFilters[filter_data.watch["parent"]];

        if (parent && parent.value) {
          let attr = Object.keys(filter_data.options_params).reduce((result, key) => {
            result[key] = filter_data.options_params[key];
            return result;
          }, {});

          attr[filter_data.watch["parent"]] = parent.value;

          this.loadSelectFilterOptions({ params: attr, filter_name: filter_data.name });
        } else {
          this.loadSelectFilterOptions(data);
        }
      } else {
        this.loadSelectFilterOptions(data);
      }
    },

    setOptionsData(data, filter_name) {},

    setLoading(val, filter_name) {},

    filtersLoaded(filter) {},
  },
};
</script>

<style scoped lang="scss">
.grid-filters-row {
  display: flex;
}
</style>
