<template lang="pug">
.coefficient-row
  select-field.coefficient-item(
    required,
    :initial-value="value.type",
    :options-path="typeField.options_path",
    :options-params="typeField.options_params",
    @change="onChange('type', $event)",
    @mounted="onSelectMounted(typeField.name)",
    @initial-load="onSelectInitialLoad(typeField.name)"
  )
    template(v-slot:label)
      span(:class="labelsClass") {{ typeFieldLabel }}

  float-field.coefficient-item(
    v-if="value.type == 'number'",
    required,
    :label="localesData.labels.number",
    :initial-value="value.value",
    @change="onChange('number', $event)"
  )

  select-field.coefficient-item(
    v-else,
    multiple,
    :required="statusesField.require",
    :label="statusesField.label",
    :initial-value="value.value",
    :options-path="statusesField.options_path",
    :options-params="statusesFieldOptionParams",
    @change="onChange('statuses', $event)",
    @mounted="onSelectMounted(statusesField.name)",
    @initial-load="onSelectInitialLoad(statusesField.name)"
  )
</template>

<script setup>
import { computed } from "vue";
import floatField from "@/components/shared/general_components/fields/floatField.vue";
import selectField from "@/components/shared/general_components/fields/selectField.vue";

const props = defineProps({
  value: { type: Object, default: () => {}, required: true }, // Value of current coefficint. Contains type: (type of coefficient) and value:
  coefficentFields: { type: Array, default: () => [], required: true }, // Fields of coefficent fields: coefficient_type and coefficient_statuses
  index: { type: Number, required: true }, // Index of current coefficient in lise
  localesData: { type: Object, default: () => {}, required: true }, // Locales
  simpleMetric: { type: Boolean, required: false, default: false }, // Is current metric simple? True when simple, false when formula
  highlight: { type: Boolean, required: false, default: false }, // Do we need to highlight coefficient with color?
});

const emit = defineEmits(["change", "init", "select-mounted", "select-initial-load"]);

const typeField = computed(() => props.coefficentFields.find(field => field.name == "coefficient_type"));
const statusesField = computed(() => props.coefficentFields.find(field => field.name == "coefficient_statuses"));
const statusesFieldOptionParams = computed(() => statusesField.value.options_params || {});
const typeFieldLabel = computed(() =>
  props.simpleMetric ? typeField.value.label : `${props.localesData.labels.coefficient} ${props.index}`,
);
const labelsClass = computed(() => (props.highlight ? `coefficient${props.index}` : ""));

const onSelectMounted = name => {
  emit("select-mounted", name);
};

const onSelectInitialLoad = name => {
  emit("select-initial-load", name);
};

const onChange = (fieldType, args) => {
  emit("change", { index: props.index, fieldType, args });
};
</script>

<script>
export default {
  name: "ActionsMetricsCoefficient",
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/styles/metrics/coefficients.scss";

.coefficient-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 15px;
}

.coefficient-item {
  width: 50%;
}
</style>
