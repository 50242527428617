import { formatISO } from "date-fns";

export const formatDateAsISO = value => {
  // we need to ensure value is in ISO format "2023-06-21T15:21:00+03:00"
  const checkFormat = value.split("T");

  if (!(checkFormat.length > 1)) {
    const dateTimeArray = value.split(" ");
    const dateArray = dateTimeArray[0].split(".");

    const day = dateArray[0];
    const month = dateArray[1];
    let year = dateArray[2];

    if (dateTimeArray.length > 1) {
      year = year.split(",")[0];
      const timeArray = dateTimeArray[1].split(":");
      const hour = timeArray[0];
      const minute = timeArray[1];

      value = formatISO(new Date(year, month - 1, day, hour, minute, 0));
    } else {
      value = formatISO(new Date(year, month - 1, day));
    }
  } else {
    // переопределение даты с utc +0 в utc +таймзона пользователя,
    // нужно для работы чекинов и других бэковых штук, где нужна таймзона пользователя,
    // работает, когда с бэка приходит значение по умолчанию в формате utc
    const dateWithSeconds = new Date(value);
    const dateWithoutSeconds = new Date(
      dateWithSeconds.getFullYear(),
      dateWithSeconds.getMonth(),
      dateWithSeconds.getDate(),
      dateWithSeconds.getHours(),
      dateWithSeconds.getMinutes(),
    );

    value = formatISO(new Date(dateWithoutSeconds));
  }

  return value;
};
