import Vue from "vue";

Vue.mixin({
  computed: {
    actionsByCheckBox() {
      return this.$store.state.grid[this.grid].actions_by_checkbox.actions;
    },
    actionsByCheckBoxRows() {
      return this.$store.state.grid[this.grid].actions_by_checkbox.by_row;
    },
    actionsByCheckBoxRowsCount() {
      return this.$store.state.grid[this.grid].actions_by_checkbox.by_row.length;
    },
  },
});
