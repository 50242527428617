<template lang="pug">
.chart-wrapper
  highcharts(ref="high-chart", :options="options({ data: data, theme: getTheme(), options: chart_options })")

  .chart-paging(v-if="categories && !chart.scrollable")
    inline-svg.chart-paging-icon.left.noselect(
      @click="chartNavigation('back')",
      :src="require(`../../../../assets/icons/highcharts/arrow-left.svg`)"
    )
    inline-svg.chart-paging-icon.right.noselect(
      @click="chartNavigation('next')",
      :src="require(`../../../../assets/icons/highcharts/arrow-right.svg`)"
    )
  .chart-zoom(v-if="chart.zoom")
    .zoom-wrapper(v-for="zoom in zooms")
      inline-svg(
        :class="`chart-paging-icon ${zoom}`",
        @click="chartNavigation(zoom)",
        :src="require(`../../../../assets/icons/highcharts/${zoom}.svg`)"
      )
</template>

<script>
import { options } from "./highchart/options";
import themes from "./themes";

export default {
  components: {
    options,
    themes,
  },

  props: {
    grid: {
      type: String,
      default: "",
    },
    grid_key: {
      type: String,
      default: "",
    },
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    chart: {
      type: Object,
      default: () => {},
    },
    step_width: {
      type: Number,
      default: 0,
    },
    begin_extr: {
      type: Object,
      default: () => {},
    },
    xaxis: {
      type: Object,
      default: () => {},
    },
    yaxis: {
      type: Object,
      default: () => {},
    },
    legend: {
      type: Object,
      default: () => {},
    },
    tooltip: {
      type: Object,
      default: () => {},
    },
    plot_options: {
      type: Object,
      default: () => {},
    },
    chart_type: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grab_position: "",
      zooms: ["minus", "plus", "reset"],
      options,
    };
  },

  computed: {
    data() {
      return {
        series: this.series,
        categories: this.categories,
      };
    },

    step() {
      return this.stepWidth;
    },

    chart_options() {
      return {
        chart: this.chart,
        begin_extr: this.begin_extr,
        xaxis: this.xaxis,
        yaxis: this.yaxis,
        legend: this.legend,
        tooltip: this.tooltip,
        plot_options: this.plot_options,
        chart_events: this.chart_events,
        type: (this.chart_type && this.chart_type.type) || "",
      };
    },
  },

  watch: {
    begin_extr(newVal, oldVal) {
      let chart = this.$refs["high-chart"].chart;
      chart.xAxis[0].setExtremes(newVal.start, newVal.end);
    },
  },

  created() {},

  mounted() {
    let chart = this.$refs["high-chart"].chart;

    if (this.chart.grab) {
      chart.container.addEventListener("mousedown", e => {
        this.grab_position = e.x;
        chart.container.style.cursor = "grab";
      });

      chart.container.addEventListener("mouseup", e => {
        if (this.grab_position < e.x) {
          this.chartNavigation("back", true);
        } else if (this.grab_position > e.x) {
          this.chartNavigation("next", true);
        }
        this.grab_position = "";
        // chart.container.style.cursor = 'initial'
      });
    }
  },

  beforeDestroy() {},

  methods: {
    chartNavigation(event, grab = false) {
      let chart = this.$refs["high-chart"].chart;

      if (chart) {
        this.$emit("set-chart-area", {
          chart: chart,
          event: event,
          grab: grab,
        });
      }
    },

    getTheme() {
      return themes[this.theme];
    },
  },
};
</script>

<style lang="scss">
.highcharts-scrolling {
  margin-left: 10px;
  margin-right: 10px;
}

.highcharts-credits {
  display: none;
}

.chart-paging {
  position: relative;
}

.chart-paging-icon {
  cursor: pointer;
  position: absolute;
  height: 15px;
  width: 15px;
  margin: initial !important;

  path {
    fill: #d3d3d3 !important;
  }

  g:nth-child(1) > g > path {
    fill: #d3d3d3 !important;
  }
}

.chart-paging-icon.left {
  left: 20px;
  margin-top: 5px;
}
.chart-paging-icon.right {
  right: 20px;
  margin-top: 5px;
}
.chart-paging-icon:hover {
  path {
    fill: #5b7aff !important;
  }
  g:nth-child(1) > g > path {
    fill: #5b7aff !important;
  }
}

.tooltip-header {
  color: #fff !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}

.tooltip-point-name {
  /*color: #fff !important;*/
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;

  padding: 5px;
  white-space: normal;
  width: 80%;
}

.tooltip-point-value {
  color: #fff !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  padding: 5px;
  width: 20%;
}

.tooltip-chart {
  display: flex;
  width: 100%;
  height: fit-content;
}

.chart-zoom {
  /*position: relative;*/
  width: 100%;
  text-align: center;

  display: flex;
  justify-content: center;

  .zoom-wrapper {
    position: relative;
    width: 15px;
    height: 15px;
    margin: 5px;
  }

  .minus {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0;
  }

  .plus {
    position: absolute;
    width: inherit;
    height: inherit;
    left: 0;
  }

  .reset {
    position: absolute;
    width: inherit;
    height: inherit;
    left: 0;
  }
}
</style>
