import { Notify } from "quasar";
import { useReLogin } from "@/services/useAuth";
import { useRouter } from "@/routing/router";
import { notifies } from "@/services/useLocales";

export const handleError = async e => {
  let message = "Неизвестная ошибка. Обратитесь к администратору";

  console.error(e);

  if (e?.response?.data?.errors.length && typeof e.response.data.errors !== "string") {
    message = e.response.data.errors[0];
  } else if (typeof e?.response?.data?.errors === "string") {
    message = e.response.data.errors;
  }

  if (e?.response?.status === 403) {
    const router = useRouter();

    message = notifies.value.access_error;
    Notify.create({ badgeStyle: "display: none;", message });

    router.push("/");
  }

  if (e?.response?.status !== 401) {
    Notify.create({ badgeStyle: "display: none;", message });
  }

  await useReLogin(e?.response?.status);

  console.error(message);
};
