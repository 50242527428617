<template lang="pug">
q-dialog#activity-form(:value="isActivitiesFormShownLocal", seamless, position="right", @hide="hideActivitiesDialog")
  q-card.workflow-form__card
    .spinner-container.form-spinner-container(v-if="loading")
      q-spinner(color="primary", size="3em")
    template(v-else)
      .row
        .col-10
          span.text-h6.text-bold.workflow-form__card__title {{ workflowConstructorLocales.activities_managment }}
        .col-2.workflow-form__card__close-icon
          q-btn(flat, round, icon="close", @click="hideActivitiesDialog")
      .workflow-form__card__scroll
        .scroll-placeholder(v-if="!activities?.length") 
          i {{ workflowConstructorLocales.empty_list }}
        .row(v-else)
          .col-12.list-element(v-for="activity in activities", :key="activity.id")
            .workflow-form__card__activity-wrapper(@click="openCreate(activity)")
              .activity-name {{ activity.title }}
              .icon-delete
                inline-svg(:src="require('../../../../assets/icons/trash.svg')", @click.stop="openDelete(activity)")

        CreateActivityForm(
          v-if="isCreateActivityFormShown",
          :activity="currentActivity",
          :workflowId="workflowId",
          :isCreateActivityFormShown="isCreateActivityFormShown",
          :statusId="parseInt(status?.id)",
          @createActivity="createActivity",
          @updateActivity="updateActivity",
          @hideDialog="isCreateActivityFormShown = false"
        )

        DeleteActivityForm(
          v-if="isDeleteActivityFormShown",
          :id="currentActivity ? parseInt(currentActivity?.id) : null",
          :isDeleteActivityFormShown="isDeleteActivityFormShown",
          @deleteActivity="deleteActivity",
          @hideDialog="isDeleteActivityFormShown = false"
        )
      .row
        .col-6.workflow-form__card__button
          q-btn.workflow-form__card__button--close(flat, no-caps, @click="hideActivitiesDialog") {{ workflowConstructorLocales.cancel }}
        .col-6.workflow-form__card__button
          q-btn.workflow-form__card__button--accept.workflow-form__card__button--create(
            flat,
            no-caps,
            icon="add",
            @click="openCreate"
          ) {{ workflowConstructorLocales.create_activity }}
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import backend from "@/api";
import { handleError } from "@/services/handleErrors";
import { workflowConstructorLocales } from "@/services/useLocales";

import CreateActivityForm from "./CreateActivityForm";
import DeleteActivityForm from "./DeleteActivityForm";

const props = defineProps({
  title: { type: String, default: "" },
  status: { type: Object, default: () => {} },
  workflowId: { type: Number, default: null },
  isActivitiesFormShown: { type: Boolean, default: false },
});

const emit = defineEmits(["hideDialog"]);

const loading = ref(true);
const activities = ref(props.status.activities);
const isCreateActivityFormShown = ref(false);
const isDeleteActivityFormShown = ref(false);

const currentActivity = ref(null);

const isActivitiesFormShownLocal = computed(() => props.isActivitiesFormShown && !!props.status);

const hideActivitiesDialog = () => {
  currentActivity.value = null;
  emit("hideDialog");
};

const openCreate = activity => {
  if (activity?.id) currentActivity.value = activity;
  else currentActivity.value = null;
  isCreateActivityFormShown.value = true;
};

const openDelete = activity => {
  if (activity?.id) currentActivity.value = activity;
  else currentActivity.value = null;
  isDeleteActivityFormShown.value = true;
};

const hideCreate = () => {
  currentActivity.value = null;
  isCreateActivityFormShown.value = false;
};

const hideDelete = () => {
  currentActivity.value = null;
  isDeleteActivityFormShown.value = false;
};

const createActivity = activity => {
  activities.value.push(activity);
  emit("createActivity", { statusId: props.status.id, activity });
};

const updateActivity = activity => {
  const currentIndex = activities.value.findIndex(el => el.id === activity.id);
  if (currentIndex !== -1) {
    activities.value[currentIndex] = activity;
    emit("updateActivity", { statusId: props.status.id, activity });
  }
};

const deleteActivity = id => {
  activities.value = activities.value.filter(el => parseInt(el.id) !== parseInt(id));
  emit("deleteActivity", { id: id, statusId: props.status.id });
};

onMounted(async () => {
  loading.value = false;
});
</script>

<style lang="scss">
#activity-form {
  .workflow-form__card {
    &__scroll {
      .row {
        gap: 10px;
      }
      .scroll-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--header-title-color);
      }
    }

    .list-element {
      padding: 0 12px;
    }

    &__activity {
      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        padding: 15px 15px;

        border: 1px solid grey;
        border-radius: 8px;

        cursor: pointer;

        transition: background 0.2s ease;
        word-break: break-word;

        .activity-name {
          color: var(--header-title-color);
          word-wrap: break-word;
        }

        .icon-delete {
          display: flex;
          align-items: center;

          min-width: 24px;
          min-height: 24px;

          opacity: 0;

          transition: opacity 0.2s ease;

          rect {
            fill: var(--header-title-color);
          }
        }

        &:hover {
          background: var(--activity-list-elem-item-background);

          .icon-delete {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
