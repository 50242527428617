<template lang="pug">
.form-field-radio
  .form-field-radio-label.form-field-label
    span {{ label }}
    span.form-field-require-tag(v-show="required", :title="notifies.required_field")
      | *
    q-icon.cancel-select-field(name="cancel", v-if="checkIfCanCancel", @click.stop="resetField()")

  .radio-row(:class="[currentFieldIsInvalid ? `${main_class} valid-error` : main_class]")
    q-radio(
      :value="currentField",
      :val="true",
      :label="locales.situational_center[current_locale].yes",
      @input="setField"
    )
    q-radio(
      :value="currentField",
      :val="false",
      :label="locales.situational_center[current_locale].no",
      @input="setField"
    )

  //span(v-if="currentFieldIsInvalid" class="valid-error-message") {{valid_error_message}}
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      method: this.parentData.method,
      method_limit: this.parentData.method_limit,
      grid: this.parentData.grid,
      value: this.parentData.data[0].value,
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      required: this.parentData.data[0].require,
      depend_from: this.parentData.data[0].depend_from || {},
      valid_error_message: null,
      main_class: "form-radio",
    };
  },

  computed: {
    currentField: {
      get() {
        let form_field = this.currentForm[this.name];
        if (form_field) {
          return form_field["field"];
        } else {
          return null;
        }
      },
      set(value) {
        let result = {};
        result["field"] = value;
        result["invalid"] = this.invalid(value);
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
        if (!result["invalid"]) {
          this.$store.commit("resetFormFieldValue", {
            grid_name: this.grid,
            field: "invalid_fields",
            value: this.name,
          });
        }
      },
    },

    fieldDependency() {
      return this.checkDependency();
    },

    checkIfCanCancel() {
      return (this.currentField === false || this.currentField === true) && !this.required;
    },
  },

  watch: {
    fieldDependency(newVal, oldVal) {},
  },

  created() {},
  beforeMount() {
    this.setField(this.value);
  },

  methods: {
    setField(val) {
      this.currentField = val;
    },

    resetField() {
      this.currentField = null;
    },

    invalid(val = undefined) {
      if (this.required) {
        if (val === true || val === false) {
          this.valid_error_message = null;
          return false;
        } else {
          this.valid_error_message = this.notifies.not_empty;
          return true;
        }
      } else {
        this.valid_error_message = null;
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/radio";
.form-field-radio {
  .radio-row {
    display: flex;
    gap: 10px;
  }
}
.form-field-radio-label.form-field-label {
  .cancel-select-field {
    display: inline;
    margin-left: 5px;
  }
}
</style>
