<template lang="pug">
q-dialog(:value="isCreatePageDialogVisible", persistent, @hide="closeForm")
  .col-8.checklist-form__wrapper.checklist-add-field__wrapper
    .checklist-new-form.justify-center
      q-card.checklist-form__card
        q-toolbar.q-px-lg.row-bottom-border
          q-icon.q-pr-md.checklist-form__toolbar__icon(name="description", size="24px")
          span.checklist-form__toolbar__title {{ isEditPageDialogVisible ? checklistLocales.edit_page : checklistLocales.add_page }}
          q-space
          q-btn(flat, round, icon="close", color="grey", @click="closeForm()")
        q-card-section
          .row.checklist-form__position-relative
            .col-6
              label
                span.text-bold {{ checklistLocales.title }}
                  q-input.q-pt-sm(
                    v-model="page.name",
                    outlined,
                    :rules="[val => val.length > 0 || checklistLocales.required_field]",
                    color="indigo-12",
                    :placeholder="checklistLocales.enter_page_title"
                  )
            //- div(class="col-6 checklist-form__position-absolute")
            //-     label
            //-         span(class="text-bold") Инструкция
            //-             q-input(class="q-pt-sm"
            //-                     v-model="page.description"
            //-                     outlined
            //-                     autogrow
            //-                     color="indigo-12"
            //-                     rows="1"
            //-                     placeholder="Что могло бы помочь в выполнении задания?"
              )
          .row
            .col-12
              .add-checklist-item(@click="createNewField")
                .text-center
                  q-icon(name="add")
                  span.q-ml-md {{ checklistLocales.to_add_field }}
          .row(v-if="page.fields && page.fields.length && isSelectFieldsMenuVisible")
            .checklist-form__add-checklist__btn-group.col-12.justify-end
              .checklist-form__add-checklist__btn-group.justify-end(v-if="isSelectFieldsMenuActive")
                span.text {{ checklistLocales.were_selected_fields }}
                  span.text-inner.q-ml-sm {{ selected.length }}
                    q-btn.q-ml-sm.clickable-icon(
                      round,
                      flat,
                      size="xs",
                      icon="close",
                      color="grey",
                      @click="changeSelectedStatus()"
                    )
                span.text.selected(@click="selectAllFields()") {{ checklistLocales.select_all }}
                q-btn.more(icon="more_horiz", outline, color="indigo-12")
                  q-menu#select-pages-fields
                    q-list(style="min-width: 100px")
                      q-item(clickable, v-close-popup, @click="deleteField")
                        q-item-section(style="color: var(--modal-form-card-color)") {{ checklistLocales.delete }}
                      q-item(clickable, v-close-popup, @click="selected.length === 1 ? openEditPopup() : false")
                        q-item-section(style="color: var(--modal-form-card-color)") {{ checklistLocales.edit }}
              .checklist-form__add-checklist__btn-group(v-else)
                span.text.selected(@click="isSelectFieldsMenuActive = !isSelectFieldsMenuActive") {{ checklistLocales.to_select_fields }}
          #drop-items.row(v-if="page.fields && page.fields.length")
            .col-12.checklist-field(
              v-for="(field, index) in page.fields.filter(elem => !elem._destroy)",
              :key="field.id"
            )
              q-checkbox(
                color="indigo-12",
                :value="checkIfSelected(field.id)",
                v-if="isSelectFieldsMenuActive",
                @input="selectField(field.id)"
              )
              span.checklist-field__title {{ field.name }}
              span.checklist-field__type {{ field.type.label }}
              .btn-up-down.q-ml-sm
                q-btn(icon="arrow_drop_up", flat, round, size="sm", @click="correctArr(field, 'up')", v-if="index > 0")
                q-btn(
                  icon="arrow_drop_down",
                  flat,
                  round,
                  size="sm",
                  @click="correctArr(field, 'down')",
                  v-if="index < page.fields.length - 1"
                )

          .row.justify-center.btn-grp.btn-position-absolute
            q-btn(outline, color="indigo-12", @click="closeForm()") {{ checklistLocales.cancel }}
            q-btn(
              color="indigo-12 q-ml-sm",
              @click="isEditPageDialogVisible ? editChecklistPage() : addChecklistPage()",
              :disabled="page.fields.length === 0 || !page.fields || !page.name || !isSelectFieldsMenuVisible"
            ) {{ isEditPageDialogVisible ? checklistLocales.edit : checklistLocales.add }}
  addChecklistFieldDialog(
    :checklistLocales="checklistLocales",
    :isCreateFieldDialogVisible.sync="isCreateFieldDialogVisible",
    :isEditFieldDialogVisible.sync="isEditFieldDialogVisible",
    :editField="editField.id ? editField : undefined",
    :allowFiles="allowFiles",
    @on-new-field-created="onNewFieldCreated($event)",
    @on-field-edit="onFieldEdit($event)"
  )
</template>

<script>
import addChecklistFieldDialog from "./addChecklistFieldDialog.vue";

import { v4 } from "uuid";

export default {
  components: {
    addChecklistFieldDialog: addChecklistFieldDialog,
  },

  props: {
    isCreatePageDialogVisible: Boolean,
    isEditPageDialogVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    editPage: {
      type: Object,
      required: false,
      default: () => {},
    },
    allowFiles: {
      type: Boolean,
      required: true,
    },
    checklistLocales: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: function () {
    return {
      selected: [],
      isSelectFieldsMenuActive: false,
      isCreateFieldDialogVisible: false,
      isEditFieldDialogVisible: false,
      isSelectFieldsMenuVisible: true,
      editField: {},
      page: {
        id: v4(),
        name: "",
        fields: [],
      },
    };
  },

  watch: {
    editPage: function () {
      if (this.isEditPageDialogVisible) this.page = this.editPage;
    },
    page: {
      deep: true,
      handler() {
        this.isSelectFieldsMenuVisible = this.page.fields.filter(elem => elem._destroy !== true).length !== 0;
      },
    },
    isCreatePageDialogVisible: function () {
      if (!this.isCreatePageDialogVisible)
        this.page = {
          id: v4(),
          name: "",
          fields: [],
        };
      else if (this.isEditPageDialogVisible) this.page = this.editPage;
    },
    isEditPageDialogVisible: function () {
      if (this.isEditPageDialogVisible) this.page = this.editPage;
      else
        this.page = {
          id: v4(),
          name: "",
          fields: [],
        };
    },
  },

  methods: {
    closeForm() {
      this.$emit("update:isCreatePageDialogVisible", false);
      this.$emit("update:isEditPageDialogVisible", false);
    },

    editChecklistPage() {
      this.$emit("on-edit-checklist-page", this.page);
      this.closeForm();
    },

    addChecklistPage() {
      this.$emit("on-new-page-created", this.page);
      this.closeForm();
    },

    createNewField() {
      this.isCreateFieldDialogVisible = true;
    },

    onNewFieldCreated(event) {
      this.isCreateFieldDialogVisible = false;
      event._new = true;
      this.page.fields.push(event);
    },

    onFieldEdit(event) {
      this.isCreateFieldDialogVisible = false;
      this.isEditFieldDialogVisible = false;
      this.$set(
        this.page.fields,
        this.page.fields.findIndex(elem => elem.id === event.id),
        event,
      );
      this.editField = {};
    },

    changeSelectedStatus() {
      this.isSelectFieldsMenuActive = false;
      this.selected = [];
    },

    checkIfSelected(id) {
      return this.selected.includes(id);
    },

    openEditPopup() {
      this.editField = this.page.fields[this.page.fields.findIndex(elem => this.selected[0] === elem.id)];
      this.isEditFieldDialogVisible = true;
      this.isCreateFieldDialogVisible = true;
    },

    selectAllFields() {
      if (this.selected.length !== this.page.fields.length) {
        this.selected = [];
        this.page.fields.forEach(elem => (!elem._destroy ? this.selected.push(elem.id) : false));
      } else {
        this.selected = [];
      }
    },

    selectField(id) {
      if (this.checkIfSelected(id)) {
        const index = this.selected.findIndex(elem => elem === id);

        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      } else {
        if (this.page.fields.filter(elem => !elem._destroy).findIndex(elem => elem.id === id) !== -1)
          this.selected.push(id);
      }
    },

    deleteField() {
      this.page.fields = this.page.fields.map(elem => {
        elem._destroy = this.selected.includes(elem.id);
        return elem;
      });
      if (this.page.fields.filter(elem => elem._destroy !== true).length === 0) this.isSelectFieldsMenuActive = false;
      this.selected = [];
    },

    correctArr(element, direction) {
      let _arr = this.page.fields;
      const index = this.page.fields.findIndex(elem => elem.id === element.id);
      let first_el;
      let second_el;
      if (direction === "up") {
        first_el = _arr[index - 1];
        second_el = _arr[index];
        this.$set(this.page.fields, index - 1, second_el);
        this.$set(this.page.fields, index, first_el);
      } else {
        first_el = _arr[index + 1];
        second_el = _arr[index];
        this.$set(this.page.fields, index + 1, second_el);
        this.$set(this.page.fields, index, first_el);
      }
    },
  },
};
</script>

<style lang="scss">
.checklist-add-field {
  &__wrapper {
    .q-dialog__inner {
      padding-top: 40px;
    }

    .btn-grp .q-btn {
      padding: 5px 30px;
      text-transform: none;
      border-radius: 8px;
    }

    .q-select--without-chips {
      .q-field__control-container {
        flex-grow: 10;
      }
    }

    .add-another-item {
      color: #5b7aff;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }

      &:active {
        opacity: 0.5;
      }
    }
    &.checklist-form {
      &__wrapper {
        box-shadow: none !important;
      }
    }
    .checklist-form {
      &__card {
        border-radius: 15px;
        box-shadow: none;
      }

      &__position-relative {
        margin-top: 30px !important;
      }
    }

    .checklist-new-form {
      padding-top: 20px;
    }

    .row-bottom-border {
      border-bottom: 1px solid lightgrey;
    }
  }
}
</style>
