<template lang="pug">
.show-card-info.more-details-btn
  .row-info-field(@click="changeShowDetails()")
    .row-more-details
      inline-svg.more-details-icon(v-if="show_details", :src="require('../../../assets/icons/show/arrow_up_blue.svg')")
      inline-svg.more-details-icon(
        v-if="!show_details",
        :src="require('../../../assets/icons/show/arrow_down_blue.svg')"
      )
      span.more-details-label {{ label }}
</template>

<script>
export default {
  components: {},

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      block: this.parentData.block,
      show_details: this.parentData.show_details,
    };
  },

  computed: {
    label() {
      return this.parentData.label;
    },
  },

  methods: {
    changeShowDetails() {
      this.show_details = !this.show_details;
      this.$emit("change-show-details", this.show_details);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/show/details-btn";
</style>
