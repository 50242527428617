<template lang="pug">
div
  div(v-for="block in data")
    div(v-if="!block.details_btn")
      div(v-show="block.hidden ? show_details : true", :class="['show-card-info ' + block.class]")
        .row-info-parts(v-for="part in block.parts")
          .spacer(v-if="block.spacer")
          .row-info-field(v-for="field in part", :style="block.style")
            span.row-label {{ field.label }}
            span.row-value(v-if="field.type === 'description'", v-html="row[field.value]")
            .form-field.form-multiselect(v-else-if="field.type === 'select'")
              multiselect(
                :value="field.value",
                track-by="id",
                label="label",
                :multiple="field.multiple",
                placeholder="",
                :show-labels="false",
                :options="field.options",
                :id="field.name",
                :disabled="true"
              )
                span(slot="noOptions")
                  i {{ notifies.no_options_list }}
            span.row-value(v-else-if="field.type === 'file'")
              //inline-svg(v-if="row[field.value]" :src="require('assets/icons/grid/clip.svg')" @click="openFilesModal(row[column.name])" class="files-collection-icon")
              existing-files-area(:parentData="{ files: row[field.value] }")
            span.row-value(v-else) {{ handleRow(row, field) || "-" }}

    details-btn(
      v-if="block.details_btn",
      @change-show-details="changeShowDetails",
      :parentData="{ block: block, show_details: show_details, label: block.details_btn_label }"
    )
</template>

<script>
import detailsBtn from "./details_btn";
import existingFilesArea from "../helpers/existingFilesArea";
import multiselect from "vue-multiselect";
import { confirmModalLocales } from "@/services/useLocales";

export default {
  components: {
    multiselect,
    detailsBtn,
    existingFilesArea,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      // row: this.parentData.row,
      data: this.parentData.data,
      show_details: this.parentData.show_details,
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },
  },

  methods: {
    // refreshRow(row) {
    //   this.row = row
    // },

    handleRow(row, field) {
      let result;
      if (field.type === "date" && row[field.value]) {
        result = this.handleDateRow(row, field.value, field.format);

        // } else if (field.type === 'description') {
        //   result = this.handleDescriptionTd(row[field.value])
      } else if (field.type === "datetime" && row[field.value]) {
        result = this.handleDateTimeRow(row, field.value, field.format);
      } else if (field.type === "boolean") {
        if (typeof row[field.value] === "string") {
          result = row[field.value];
        } else {
          if (row[field.value] === null) result = null;
          else if (row[field.value]) result = this.locales.confirm_modal[this.current_locale].submit;
          else result = this.locales.confirm_modal[this.current_locale].cancel;
        }
      } else {
        result = row[field.value];
      }
      return result;
    },

    changeShowDetails(val) {
      this.show_details = val;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/show/blocks";
</style>
