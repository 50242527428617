<template lang="pug">
div
</template>

<script>
export default {
  beforeMount() {
    this.$emit("set-loading", false);
    this.$emit("expand-class", { val: false, class: "" });
  },
  methods: {},
};
</script>

<style lang="scss"></style>
