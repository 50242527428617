class EntityMarkerMap {
  constructor() {
    this.storage = {};
  }

  add(entity, marker) {
    this.storage[entity.getEntityKey()] = marker;
  }

  pop(entity) {
    const key = entity.getEntityKey();
    const marker = this.storage[key];
    if (!marker) {
      console.error(`EntityMarkerMap#remove: ${key} was not in storage`);
    }
    delete this.storage[key];
    return marker;
  }

  fetch(entity) {
    const key = entity.getEntityKey();
    const marker = this.storage[key];
    if (!marker) {
      throw `EntityMarkerMap#fetch: ${key} is not in storage`;
    }
    return marker;
  }

  contains(entity) {
    return !!this.storage[entity.getEntityKey()];
  }
}

export default EntityMarkerMap;
