<template lang="pug">
.form-step-two.form-step
  .form-step-two-text.title {{ schedulerLocales["step2"] }}
    span {{ schedulerLocales["object"] }}
  .form-step-two-selects
    .form-step-two-selects-item
      select-field(
        :full-options-path="store.state.paths['building']",
        :readonly="readonly",
        :multiple="true",
        :initialValue="buildings",
        :optionsParams="selectParams",
        :watch="watch",
        :showCancel="false",
        :canPaste="true",
        :label="schedulerLocales['building']",
        @change="updateBuilding"
      )
</template>

<script setup>
import { computed, ref, toRefs } from "vue";
import i18n from "@/plugins/vue-i18n";
import selectField from "../../../../shared/general_components/fields/selectField";
import { useStore } from "@/store";
import { currentLocale } from "@/services/useLocales";

const props = defineProps({
  buildings: { type: Array, default: () => [] },
  readonly: { type: Boolean, default: () => true },
  watch: { type: Object, default: () => null },
  selectParams: { type: Object, default: () => null },
});
const emit = defineEmits(["update-building"]);
const store = useStore();

const schedulerLocales = computed(() => i18n["messages"][currentLocale.value]["scheduler"]);

const { buildings } = toRefs(props);
const { readonly } = toRefs(props);
const { watch } = toRefs(props);
const { selectParams } = toRefs(props);

const updateBuilding = val => {
  emit("update-building", val);
};
</script>
