<template lang="pug">
.column.relative_position_works_search
  q-input.search-all-filter-field(
    filled,
    debounce="500",
    v-model="searchFieldValue",
    :label="searchFieldLabel",
    @input="searchAndSortWorks()"
  )
    q-icon.cancel-search-all-filter(name="cancel", v-if="searchFieldValue", @click.stop="resetForm()")
  .equipment-tech-map
    .tech-map-card
      .tabs-block
        table#components-table.tech-map-tab-table.components-table.stores
          thead
            tr
              th(@click="changeSortParams('id')")
                span.column-header-title {{ tabData[0].titles.id }}
                  q-icon(:name="iconName()", v-if="activeSort === 'id'")
              th(@click="changeSortParams('work_title')")
                span.column-header-title {{ tabData[0].titles.work_title }}
                  q-icon(:name="iconName()", v-if="activeSort === 'work_title'")
              th(@click="changeSortParams('work_external_id')")
                span.column-header-title {{ tabData[0].titles.work_external_id }}
                  q-icon(:name="iconName()", v-if="activeSort === 'work_external_id'")
              th(@click="changeSortParams('cost')")
                span.column-header-title {{ tabData[0].titles.cost }}
                  q-icon(:name="iconName()", v-if="activeSort === 'cost'")
          tbody
            template(v-if="tabData[0].data.length")
              tr(
                v-if="editable",
                v-for="(item, i) in editable_data",
                :key="'editable' + item.id",
                :style="{ cursor: 'pointer' }",
                :class="{ 'active-components-row': checkable && checkable.index === i }",
                @click="setCheckable(item, i)"
              )
                td(v-for="key in Object.keys(item)", :key="'data' + key + item.id")
                  techMapInput(
                    v-if="isCountOrCost(key)",
                    :parentData="{ tech_map_key: techMapKey, grid: grid, index: i, value: item[key], value_key: key }"
                  )
                  template(v-else) {{ item[key] }}
              tr(v-if="!editable", v-for="item in tabData[0].data", :key="'work' + item.id")
                td {{ item.id }}
                td {{ item.work_title }}
                td {{ item.work_external_id }}
                td {{ item.cost }}
            template(v-else)
              tr
                td.text-center(colspan="4") {{ notifies.data_not_found }}
</template>

<script>
import techMapInput from "./helpers/input";
import handleButtons from "./helpers/buttons.vue";

export default {
  components: {
    techMapInput,
    handleButtons,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      rowId: this.parentData.row_id,
      tabData: this.parentData.data,
      checkable: undefined,
      techMapKey: "components",
      searchFieldValue: "",
      activeSort: "id",
      activeDirection: "down",
    };
  },

  computed: {
    editable() {
      return this.parentData.editable;
    },

    data() {
      let data = this.parentData.data;
      return data ? data : [];
    },

    buttons() {
      return this.parentData.buttons;
    },

    searchFieldLabel() {
      return this.current_locale === "en" ? "Search" : "Поиск";
    },

    editable_data: {
      get() {
        let grid = this.$store.state.grid[this.grid];
        if (grid) {
          return grid["works_list"][this.techMapKey];
        } else {
          return undefined;
        }
      },
      set(value) {
        this.$store.commit("addWorkingGroupWorks", {
          grid_name: this.grid,
          tech_map_key: this.techMapKey,
          attr: value[0].data,
        });
      },
    },
  },

  watch: {
    editable(newVal, oldVal) {
      if (!newVal) {
        this.checkable = undefined;
      }
    },

    data(newVal, oldVal) {
      this.editable_data = newVal;
    },
  },

  methods: {
    onClickFn(button) {
      this[button.onclick]();
    },

    resetForm() {
      this.searchFieldValue = "";
      this.searchAndSortWorks();
    },

    iconName() {
      return this.activeDirection === "up" ? "expand_less" : "expand_more";
    },

    isCountOrCost(key) {
      return key === "count" || key === "cost";
    },

    changeSortParams(val) {
      if (val === this.activeSort) {
        if (this.activeDirection === "down") {
          this.activeDirection = "up";
        } else {
          this.activeDirection = "down";
        }
      } else {
        this.activeSort = val;
        this.activeDirection = "down";
      }

      this.searchAndSortWorks();
    },

    searchAndSortWorks() {
      this.$emit("search-or-sort", {
        sort: this.activeSort,
        sort_direction: this.activeDirection,
        search: this.searchFieldValue,
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/show/ppr/equipment/main";
.relative_position_works_search {
  .search-all-filter-field {
    position: relative;
    top: -55px;
    margin-bottom: 40px;
    width: 50%;
    .equipment-tech-map {
      margin-top: 15px;
    }
  }
}
</style>
