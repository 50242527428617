<template lang="pug">
index-page(
  @set-loading="setLoading",
  :parentData="{ grid: grid, path: path, grid_key: gridKey, report_class: reportClass, report_namespace: reportNamespace }"
)
</template>

<script setup>
import { computed, onBeforeMount } from "vue";
import { useStore } from "@/store";
import IndexPage from "../../shared/indexPage";

const store = useStore();

const emit = defineEmits(["set-loading", "expand-class"]);

const grid = "cleaning_tariffs";
const reportClass = "tariff";
const reportNamespace = "cleaning";
const gridKey = "data";

const path = computed(() => store.state.paths["cleaning_tariff"]);

const setLoading = val => {
  emit("set-loading", val);
};

onBeforeMount(async () => {
  emit("expand-class", { val: false, class: "" });
});
</script>
