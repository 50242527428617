<template lang="pug">
.form-step-one.form-step
  .form-step-one-text.title {{ schedulerLocales["step1"] }}
  .form-step-input
    input-title(:title="schedulerLocales['schedulerName']", :required="true")
    q-input.form-step-one-name(:value="name", @input="updateName", filled, no-error-icon, autocomplete="off")
      q-icon.cancel-string-field(name="cancel", v-if="name", @click.stop="resetNameField()")
  .form-step-input
    select-field(
      :full-options-path="store.state.paths['facility']",
      :readonly="false",
      :multiple="false",
      :initialValue="facility ? facility.id : null",
      :optionsParams="{}",
      :showCancel="false",
      :label="schedulerLocales['facility']",
      required,
      @change="updateFacility"
    )
</template>

<script setup>
import { computed, ref, toRefs } from "vue";
import i18n from "@/plugins/vue-i18n";
import selectField from "../../../../shared/general_components/fields/selectField";
import { useStore } from "@/store";
import { currentLocale } from "@/services/useLocales";
import inputTitle from "./inputTitle.vue";

const props = defineProps({
  facility: { type: Object, default: () => null },
  name: { type: String, default: () => "" },
});
const emit = defineEmits(["update-facility", "update-name"]);
const store = useStore();

const schedulerLocales = computed(() => i18n["messages"][currentLocale.value]["scheduler"]);

const { facility } = toRefs(props);
const { name } = toRefs(props);

const updateFacility = val => {
  emit("update-facility", val);
};

const updateName = val => {
  emit("update-name", val);
};

const resetNameField = () => {
  emit("update-name", "");
};
</script>
