<template lang="pug">
.donut-chart-wrapper
  .donut-chart-title
    span {{ data.title }}

  .donut-chart-area
    svg.donut(width="100%", height="100%", viewBox="0 0 42 42")
      defs
        linearGradient#gradient(x1="50%", y1="100%", x2="100%", y2="50%")
          stop(offset="0%", stop-color="#FA7167")
          stop(offset="50%", stop-color="#7283F4")
          stop(offset="100%", stop-color="#5B7AFF")

      circle.donut-ring(cx="21", cy="21", r="15.91549430918954")
      circle.donut-bottom(cx="21", cy="21", r="15.91549430918954")
      circle.donut-chart(v-show="showDonut()", :style="cssVars", cx="21", cy="21", r="15.91549430918954")

      g.chart-text
        text#chart-number.chart-number(x="50%", y="50%") {{ data.value }}
        text#chart-label.chart-label(x="50%", y="50%") {{ label }}
</template>

<script>
export default {
  components: {},

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      // data: this.parentData.data,
      key: this.parentData.key,
      stroke_value: "0 100", // 0% chart value css is (stroke-dasharray: '0 100';), 100% chart value css is (stroke-dasharray: '70 30';)
      endings: ["ов", "", "а", "а", "а", "ов", "ов", "ов", "ов", "ов", "ов"],
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },

    label() {
      if (this.data && this.data.value !== null) {
        let label = this.data.chart_label;

        if (label === "процент") {
          let num = parseInt(this.data.value.toString().slice(-1));
          label = label + this.endings[num];
        }
        return label;
      } else {
        return undefined;
      }
    },

    cssVars() {
      return {
        "--stroke_value": this.stroke_value,
      };
    },
  },

  watch: {
    data(newVal, oldVal) {
      if (newVal.value !== oldVal.value) {
        setTimeout(() => {
          this.getChartValue();
        }, 500);
      }
    },
  },

  created() {
    setTimeout(() => {
      this.getChartValue();
    }, 500);
  },

  methods: {
    showDonut() {
      if (["rejected_percentage", "reworked_percentage"].includes(this.key)) {
        return this.data.value <= 99.5;
      } else {
        return this.data.value >= 0.5;
      }
    },

    getChartValue() {
      let first_val = (70 / 100) * parseInt(this.data.value);

      if (["rejected_percentage", "reworked_percentage"].includes(this.key)) {
        first_val = 70 - first_val; //reverse chart
      }

      let second_val = 100 - first_val;

      this.stroke_value = first_val + " " + second_val;
    },
  },
};
</script>

<style>
/*@import '../../../../assets/styles/dashboard/charts/donut_chart.scss';*/
</style>
