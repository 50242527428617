<template lang="pug">
.floors
  .card-label
    span {{ data.title }}
  .floors-wrapper
    .telemetry-floors-swiper-area(v-show="floors.length > 0")
      .main-swiper
        .floor-badge.swiper-slide(
          :id="`floor-badge-${floor.id}`",
          v-for="floor in floors",
          :key="floor.id",
          @click="onClickFloor(floor)"
        )
          span {{ floor.short_title }}
    grid-hidden-msg(v-show="!floors.length > 0", ref="floors-hidden-msg", :parentData="{ hide_table: tableHidden }")
</template>

<script>
import { Swiper } from "vue-awesome-swiper";
import gridHiddenMsg from "../../../shared/grid/gridHiddenMsg";

export default {
  components: {
    Swiper,
    gridHiddenMsg,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      building: this.parentData.building,
      tableHidden: false,
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },

    floors() {
      return this.parentData.floors;
    },

    floor() {
      return this.parentData.floor;
    },
  },

  methods: {
    hideTable(val) {
      this.tableHidden = val;
      if (this.$refs["floors-hidden-msg"]) {
        this.$refs["floors-hidden-msg"].hideTable(val);
      }
    },

    onClickFloor(floor) {
      if (floor) {
        let attr = {
          item: "floor",
          new_item: floor,
        };
        this.activeBadge(attr);
        this.$emit("set-floor", floor);
        this.$emit("load-rooms", { floor_id: floor.id });
        this.$emit("load-sensors", { floor_id: floor.id });
      }
    },
  },
};
</script>

<style lang="scss">
.floors {
  height: fit-content;
  border-bottom: 1px dashed #8d8d8d30;

  .floors-wrapper {
    min-height: 90px;
    height: fit-content;

    .hidden-tabs-message {
      height: 75px;

      span {
        padding-top: initial;
      }
    }

    .main-swiper {
      height: 45px;
      display: flex;
      flex-direction: row;
      overflow: auto;
      gap: 10px;

      .swiper-pagination-bullet {
        background: #007aff;
      }
    }
  }

  .telemetry-floors-swiper-area {
    width: 100%;
    padding: 20px 5px 0 5px;
  }

  .swiper-slide {
    width: fit-content !important;
  }

  .floor-badge {
    height: fit-content;
    padding: 5px;
    border-radius: 15px;
    background: var(--telemetry-floor-badge-background);
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;

      white-space: nowrap;

      color: var(--dashboard-label-color);
    }
  }

  .active-badge {
    background: #5b7aff !important;

    span {
      color: #ffffff !important;
    }
  }
}
</style>
