<template lang="pug">
div
  .entity-view__label {{ map_locales["full_name"] }}:
  .entity-view__value {{ entity.getFullName() }}

  div(v-if="entity.getPhone()")
    .entity-view__label {{ map_locales["phone"] }}:
    .entity-view__value {{ entity.getPhone() }}

  Tabs(@click="activeTab = $event", :active="activeTab", :items="tabItems")
  PillList(v-if="activeTab == 'services'", :items="serviceListItems")
  PillList(v-if="activeTab == 'workGroups'", :items="workGroupListItems")
  IssuesTable(v-if="activeTab == 'issues'", :issues="issues")
</template>

<script>
import IssuesTable from "../EntityView/IssuesTable";
import PillList from "../EntityView/PillList";
import Tabs from "../EntityView/Tabs";

export default {
  components: {
    IssuesTable,
    PillList,
    Tabs,
  },
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      issues: [],
      services: [],
      workGroups: [],

      activeTab: "services",
      // tabItems: [
      //   { key: 'services', title: this.map_locales['tab_title_services'] },
      //   { key: 'issues', title: this.map_locales['tab_title_issues'] },
      //   { key: 'workGroups', title: this.map_locales['tab_title_work_groups'] },
      // ],
    };
  },

  computed: {
    serviceListItems() {
      return this.services.map(s => ({ key: s.id, title: s.title }));
    },
    workGroupListItems() {
      return this.workGroups.map(g => ({ key: g.id, title: g.title }));
    },

    tabItems() {
      return [
        { key: "services", title: this.map_locales["tab_title_services"] },
        { key: "issues", title: this.map_locales["tab_title_issues"] },
        { key: "workGroups", title: this.map_locales["tab_title_work_groups"] },
      ];
    },
  },

  mounted() {
    this.loadDetails().then(response => {
      this.issues = response.data.issues;
      this.workGroups = response.data.work_groups;
      this.services = response.data.services;
    });
  },

  methods: {
    loadDetails() {
      return this.$backend.index(`/api/v3/map/workers/${this.entity.getId()}`);
    },
  },
};
</script>

<style lang="scss"></style>
