<template lang="pug">
q-card#form.modal-form-card
  q-card-section
    form-header(
      ref="formHeaderRef",
      @close-form="closeForm",
      :parentData="{ grid: grid, title: headerTitle, title_icon: 'download_header.svg' }"
    )

  .spinner-container.modal-spinner(v-if="showLoader")
    q-spinner(color="primary", size="3em") 

  q-card-section(v-show="!showLoader")
    q-form
      .col-12(v-for="field in data.fields", :key="field.name", style="padding: 5px")
        component(
          :is="getComponent(field)",
          :label="field.label",
          :full-options-path="getStoreOptionsPath(field)",
          :optionsParams="field.options_params",
          :watch="getWatchData(field, form)",
          @change="onChange($event, field)",
          @load="onLoad(field)"
        )

      .col-12(v-if="list.length > 0") 
        qr-list(v-show="false", ref="printRef", :list="list", @mounted="openPrint")

  q-card-actions.button-group.row.justify-center(v-if="!showLoader")
    q-btn.modal-form-cancel(flat, no-caps, :label="data.form_buttons.cancel", @click="closeForm()")
    q-btn.modal-form-submit(flat, no-caps, :label="data.form_buttons.submit", @click="submitForm()")
</template>

<script setup>
import { ref, computed, watchEffect, onMounted } from "vue";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { useStore } from "@/store";
import { getWatchData } from "@/services/useWatchParent";
import { buildUrlParams } from "@/services/urlParams";
import { Notify } from "quasar";
import { notifies } from "@/services/useLocales";
import { openTabAndPrintContent } from "@/services/print";
import formHeader from "../../shared/forms/formHeader";
import filters from "@/components/shared/general_components/fields/index";
import qrList from "@/components/shared/print/qrList.vue";
import compiledPrintQrCss from "!raw-loader!sass-loader!../../../assets/styles/printQr/qr.scss";

const Types = {
  "/api/v3/rooms": "rooms",
  "/api/v3/measuring/meters": "measuring_meters",
  "/api/v3/ppr/equipments": "ppr_equipments",
};

const props = defineProps({
  parentData: { type: Object, default: () => {}, required: true },
});

const emit = defineEmits(["close-export"]);

const store = useStore();

const formHeaderRef = ref(null);
const printRef = ref(null);
const loading = ref(true);
const submitCompleted = ref(false);
const fieldsLoadings = ref({});
const data = ref({});
const form = ref({});
const list = ref([]);

const grid = computed(() => props.parentData.grid);
const headerTitle = computed(() => props.parentData.formAttr.headerTitle);
const gridPath = computed(() => props.parentData.path);
const showLoader = computed(() => loading.value || Object.values(fieldsLoadings.value).some(state => state === true));
const currentType = computed(() => Types[gridPath.value]);

watchEffect(() => {
  formHeaderRef.value?.setLoading(showLoader.value); // Update header on loading change
});

const closeForm = () => {
  emit("close");
};

const getStoreOptionsPath = field => {
  const optionsPath = field.options_path;

  return store.state.paths[optionsPath];
};

const loadFormData = async () => {
  try {
    const response = await backend.index(`${gridPath.value}/print_qr_list_form_data`);
    data.value = response.data;

    const fields = data.value.fields;
    form.value = fields.map(field => field.name).reduce((a, v) => ({ ...a, [v]: null }), {}); // Set form default values
    fieldsLoadings.value = fields
      .filter(field => field.type == "select")
      .map(field => field.name)
      .reduce((a, v) => ({ ...a, [v]: true }), {}); // Set selects as loadings
  } catch (error) {
    await handleError(error);
  } finally {
    loading.value = false;
  }
};

const submitForm = async () => {
  try {
    loading.value = true;

    const params = buildUrlParams({ type: currentType.value, filters: form.value });
    const response = await backend.index(`/api/v3/qr_list?${params}`);
    submitCompleted.value = true;
    list.value = response.data;

    if (list.value.length == 0) {
      const message = notifies.value.data_not_found;
      Notify.create({ badgeStyle: "display: none;", message });
    }
  } catch (error) {
    await handleError(error);
  } finally {
    loading.value = false;
  }
};

const getComponent = field => {
  if (field) {
    return filters[field.type];
  }
};

const getData = field => {
  return data.value.fields.find(f => f === field);
};

const onChange = (args, field) => {
  form.value = { ...form.value, [field.name]: args.value };
};

const onLoad = field => {
  if (fieldsLoadings.value[field.name] === false) {
    return;
  }

  fieldsLoadings.value = { ...fieldsLoadings.value, [field.name]: false };
};

const openPrint = () => {
  openTabAndPrintContent({
    html: printRef.value.$el.innerHTML,
    css: compiledPrintQrCss,
    closeModalFunction: closeForm,
  });
};

onMounted(async () => {
  await loadFormData();
});
</script>
