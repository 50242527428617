<template lang="pug">
#covid-page.index-page
  Header(:parentData="{ data: { title: 'Статистика Covid-19' }, hide_filters: true }")
</template>

<script>
import Header from "../../shared/helpers/Header";

export default {
  components: {
    Header,
  },
  data: function () {
    return {
      dashboard_data: {},
      loading: true,
    };
  },

  watch: {
    loading(newVal, oldVal) {
      this.$emit("set-loading", this.loading);
    },
  },

  beforeMount() {
    this.$emit("expand-class", { val: true, class: "dashboard-background" });
    this.loadDashboardData();
  },

  methods: {
    loadDashboardData() {
      this.$backend
        .index(this.$store.state.paths.dashboard_data, { params: { boards: ["illness_statistic"] } })
        .then(response => {
          this.dashboard_data = response.data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 800);
        });
    },
  },
};
</script>

<style lang="scss"></style>
