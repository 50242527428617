<template lang="pug">
div
  .form-field-label
    span {{ `${label}` }}
    span.form-field-require-tag(v-show="required")
      | *

  .form-field.form-input
    q-input(
      filled,
      no-error-icon,
      v-model="value",
      type="number",
      @input="changeValue",
      :placeholder="placeholder",
      :label-slot="false",
      autocomplete="off",
      :step="step"
    )
      q-icon.cancel-string-field(name="cancel", v-if="value && !readonly", @click.stop="resetValue")
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  label: { type: String, default: "", required: true },
  required: { type: Boolean, default: false, required: false },
  initialValue: { type: [String, Number], default: null, required: false },
  placeholder: { type: String, default: "", required: false },
  readonly: { type: Boolean, default: false, required: false },
  /**
   * Проп для возможности дробных значений. По умолчанию такой возможности нет
   */
  step: { type: String, default: "", required: false },
});

const emit = defineEmits(["change", "reset"]);

const value = ref(`${props.initialValue}`);

const changeValue = () => {
  emit("change", { value: value.value });
};

const resetValue = () => {
  value.value = null;
  emit("reset");
  changeValue();
};
</script>

<script>
export default {
  name: "FloatField",
};
</script>
