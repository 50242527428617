import { useStore } from "@/store";

// To create hidden grid please make sure to add grid name to store/index.js (grids key)
const createHiddenGrid = gridName => {
  const store = useStore();

  store.commit("initialGridsState", { grid: gridName });
  store.commit("initialGrid", { grid_name: gridName, grid_key: "data", sort_by: "id", descending: true });
};

export { createHiddenGrid };
