<template lang="pug">
.col-12.col-lg-6
  .form-field-label
    span {{ field.title }}
    span.form-field-require-tag(v-show="field.required", :title="field.title")
      | *

  .form-field.form-date(:id="field.id || field.uuid")
    datetime(
      :ref="'elem' + (field.id || field.uuid)",
      type="date",
      v-model="value",
      :phrases="phrases",
      value-zone="Europe/Moscow",
      :disabled="readonly",
      @input="editField",
      :input-id="field.id ? field.id.toString() : field.uuid.toString()"
    )
      template(slot="before")
        inline-svg.datetime-icon(:src="require(`../../../assets/icons/calendar.svg`)")

    q-icon.cancel-datetime-field(name="cancel", v-if="value && !readonly", @click.stop="resetField()")
</template>

<script>
import { Datetime } from "vue-datetime";

export default {
  components: {
    Datetime,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean || undefined,
      required: false,
      default: false,
    },
    initialValue: {
      type: Array,
      required: false,
      default: () => [{ value: "" }],
    },
  },
  data() {
    return {
      value: this.$props.initialValue[0].value,
      phrases: {},
      name: this.field.id || this.field.uuid,
    };
  },
  created() {
    if (this.readonly) {
      this.value = this.field._data.value && this.field._data.value.length > 0 ? this.field._data.value[0].value : "";
    }
  },

  beforeMount() {
    this.setDateFieldLocale();
    this.setDateFieldPhrases();
  },
  methods: {
    resetField() {
      this.value = "";
      this.$emit("reset-field", { id: this.field.id });
    },
    editField(val) {
      if (!this.readonly) this.$emit("edit-field", { id: this.field.id, value: [{ value: val, title: "" }] });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/fields/date";

.form-date {
  position: relative;
}
</style>
