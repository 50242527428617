<template lang="pug">
div
  table
    tr(
      v-for="(chunkedItems, index) in items",
      :style="trStyle(index)",
      :key="`chunked-list-${chunkedItemsKey(chunkedItems)}`"
    )
      th(v-if="chunkedItems[0]") 
        qr(:title="getTitle(chunkedItems[0])", :texts="getTexts(chunkedItems[0])", :url="chunkedItems[0].url")
      th(v-if="chunkedItems[1]")
        qr(:title="getTitle(chunkedItems[1])", :texts="getTexts(chunkedItems[1])", :url="chunkedItems[1].url")
</template>

<script setup>
import { computed, onMounted } from "vue";
import qr from "./qr";
import _ from "lodash";

const emit = defineEmits(["mounted"]);

const props = defineProps({
  list: { type: Array, default: () => [], required: true },
});

const items = computed(() => _.chunk(props.list, 2));

const trStyle = index => {
  const trueIndex = index + 1;

  return trueIndex % 3 == 0 ? { "page-break-after": "always" } : {};
};

const chunkedItemsKey = items => {
  const ids = items.map(item => item.id);
  const key = ids.join("-");

  return key;
};

const getTitle = item => {
  return item.form.header;
};

const getTexts = item => {
  return item.form.fields;
};

onMounted(() => {
  emit("mounted");
});
</script>

<style lang="scss">
@import "../../../assets/styles/printQr/qr.scss"; // This style has separate import and injected in new tab
</style>
