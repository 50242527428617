<template lang="pug">
prompt(
  v-if="prompt",
  :ref="prompt.class",
  @customize-prompt="customizePrompt",
  @close-modal="closeModal",
  :parentData="{ prompt: prompt }"
)
</template>

<script>
import Prompt from "../../../../prompt";

export default {
  components: {
    Prompt,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    prompt() {
      return this.parentData.prompt;
    },
  },

  methods: {
    customizePrompt(parent_el, arrow, card) {
      arrow.style.top = `${parent_el.offsetTop + 55}px`;
      arrow.style.left = `${parent_el.offsetWidth / 2 + parent_el.offsetLeft - arrow.offsetWidth / 2}px`;
      card.style.top = `${parent_el.offsetTop + 60}px`;
      card.style.left = `${parent_el.offsetWidth / 2 + parent_el.offsetLeft - card.offsetWidth / 2}px`;

      document.getElementById("ui-prompt-img").style.width = `${card.offsetWidth - 40}px`;

      this.$refs[this.prompt.class].showPrompt();
    },

    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss">
.modal-form-card.ui-prompt-card.export-button {
  width: 234px !important;
}
</style>
