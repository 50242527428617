<template lang="pug">
.grid-row-item
  .grid-row-item__file-count(v-if="showIssueFilesCount") 
    inline-svg.grid-row-item__clip-icon(:src="require('@/assets/icons/dynamic-issues/index/clip.svg')") 
    .grid-row-item__file-count__value {{ displayValue() }}

  .grid-row-item__file-count(v-else-if="showDynamicFilesCount") 
    inline-svg.grid-row-item__clip-icon(:src="require('@/assets/icons/dynamic-issues/index/clip.svg')") 
    .grid-row-item__file-count__value {{ dynamicFileCount }}

  .grid-row-item__status(v-else-if="props.column.type === 'issue_state'", :style="{ background: issue.status_color }") {{ displayValue() }}

  div(v-else) {{ displayValue() }}
    .td-title(:id="`td-title-${issue.id}-${column.name}`")
      .title-msg
      .title-arrow
</template>

<script setup>
import { computed, nextTick } from "vue";
import { currentLocale } from "@/services/useLocales";
import { handleDateRow, handleDescriptionTd } from "@/services/reformatStrings";
import i18n from "@/plugins/vue-i18n";

const props = defineProps({
  issue: { type: Object, required: true }, // Current issue
  column: { type: Object, required: true }, // Current column
});

const value = computed(() => props.issue[props.column.name]);
const dynamicFileCount = computed(() => (Array.isArray(value.value) ? value.value.length : 0));
const showIssueFilesCount = computed(() => props.column.name === "file_count" && value.value > 0);
const showDynamicFilesCount = computed(() => props.column.type === "attachments" && dynamicFileCount.value > 0);

const displayValue = () => {
  let result = value.value;
  const textLimit = 45;

  if (props.column.type === "boolean") {
    if (value.value === null) {
      result = null;
    } else if (typeof value.value === "boolean") {
      result = value.value ? i18n["messages"][currentLocale.value]["yes"] : i18n["messages"][currentLocale.value]["no"];
    } else if (typeof value.value === "string") {
      result = value.value;
    }
  } else if (props.column.type === "description") {
    result = handleDescriptionTd(value.value);

    if (result && result.length > textLimit) {
      addTitleEvent(result);
      result = result.substring(0, textLimit) + "...";
    }
  } else if (["datetime", "date"].includes(props.column.type)) {
    result = handleDateRow(props.issue, props.column.name, props.column.format);
  }

  if (result && result.length > textLimit) {
    addTitleEvent(result);
    result = result.substring(0, textLimit) + "...";
  }

  return result;
};

const addTitleEvent = title => {
  nextTick(() => {
    const tdEl = document.getElementById(`td-${props.issue.id}-${props.column.name}`);
    const titleEl = document.getElementById(`td-title-${props.issue.id}-${props.column.name}`);

    tdEl.addEventListener("mouseover", () => {
      showTitle(tdEl, titleEl, title);
    });

    tdEl.addEventListener("mouseleave", () => {
      hideTitle(titleEl);
    });
  });
};

const showTitle = (tdEl, titleEl, title) => {
  const msg = titleEl.querySelector(".title-msg");

  if (msg.children.length === 0) {
    const span = document.createElement("span");
    span.textContent = title;
    msg.appendChild(span);
  }

  const maxSymbolsLength = 100;

  // для сообщений больше 100 символов устанавливаем повышенную ширину всплывающего окна
  if (title.length >= maxSymbolsLength) {
    msg.style.width = "450px";
    titleEl.style.width = "450px";
  }

  titleEl.style.visibility = "visible";
  titleEl.style.opacity = "1";
  titleEl.style.top = tdEl.getBoundingClientRect().top - titleEl.clientHeight + "px";
  // мы устанавливаем абсолютную позицию для всплывающего окна так, чтобы оно находилось центрировано относительно ячейки

  const leftPosition =
    tdEl.getBoundingClientRect().left -
    (titleEl.getBoundingClientRect().width - tdEl.getBoundingClientRect().width) / 2;

  titleEl.style.left = leftPosition < 0 ? "0px" : `${leftPosition}px`;

  // устанавливаем относительную позицию для стрелочки так, чтобы она была по центру всплывающего окна
  const titleArrow = titleEl.querySelector(".title-arrow");

  titleArrow.style.left =
    (titleEl.getBoundingClientRect().width - titleArrow.getBoundingClientRect().width / 2) / 2 + "px";
};

const hideTitle = titleEl => {
  titleEl.style.opacity = "0";
  titleEl.style.visibility = "hidden";
  const msg = titleEl.querySelector(".title-msg");

  while (msg.hasChildNodes()) {
    msg.removeChild(msg.firstChild);
  }
};
</script>

<script>
export default {
  name: "GridRowItem",
};
</script>

<style lang="scss">
.grid-row-item {
  display: flex;
  justify-content: center;

  &__clip-icon {
    height: 22px;
    cursor: pointer;

    path {
      fill: var(--files-collection-icon-fill);
    }
  }

  &__file-count {
    display: flex;
    align-items: center;

    &__value {
      padding-left: 5px;
    }
  }

  &__status {
    border-radius: 17px;
    padding: 5px 11px 7px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    align-items: center;
    letter-spacing: -0.08px;
    margin-left: 15px;
    width: fit-content;
    color: white;
  }
}
</style>
