import { computed } from "vue";
import { useStore } from "@/store";
import { useReLogin } from "@/services/useAuth";
import backend from "@/api";

export const theme = computed(() => {
  const store = useStore();

  return store.state.theme.value;
});

export const setTheme = async val => {
  const store = useStore();

  try {
    const response = backend.index(store.state.paths["user"] + "/current_theme", { params: {} }).data;

    let theme = val || response;
    store.commit("changeTheme", theme);

    document.documentElement.setAttribute("theme", theme);
  } catch (e) {
    console.log(e);
    if (e.response) await useReLogin();
  }
};
