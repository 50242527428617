<template lang="pug">
.issue-center__custom
  columns(
    ref="columnComponent",
    :displayRules="displayRules",
    @openShow="openShow",
    @openShowColumn="openShowColumn",
    @deleteColumn="deleteColumn"
  )
  grid-modals(
    ref="customFiltersModal",
    :parentData="{ grid, grid_key: '', path: '/api/v3/situational_center/issue_filters', transition_key: 'issue_filter', row: {} }",
    @refresh-table="refreshTable"
  )
</template>

<script setup>
import { ref, onMounted } from "vue";

import backend from "@/api";
import { useStore } from "@/store";
import { handleError } from "@/services/handleErrors";

import { Notify } from "quasar";

import GridModals from "@/components/shared/grid/gridModals";
import Columns from "./columns";

const props = defineProps({
  displayRules: { type: Array, default: () => [], required: true },
});

const emit = defineEmits(["openShow"]);

const store = useStore();

const customFiltersModal = ref();
const columnComponent = ref();

const grid = ref("issue_center_filters");

store.commit("initialGridsState", { grid: grid.value, attr: {} });

const openShow = event => {
  emit("openShow", event);
};

const createNewColumn = () => {
  customFiltersModal.value.openForm({ form_type: "add" });
};

const refreshTable = e => {
  if (e.action === "update") {
    columnComponent.value.onColumnUpdated();
  }
  if (e.action === "create") {
    columnComponent.value.onColumnUpdated();
  }
};

const openShowColumn = async event => {
  if (event) {
    try {
      customFiltersModal.value.openForm({ form_type: "edit", row: { id: event }, grid: grid.value });
    } catch (e) {
      await handleError(e);
    }
  }
};

const deleteColumn = async event => {
  if (event) {
    try {
      const response = await backend.destroy("api/v3/situational_center/issue_filters", event);

      columnComponent.value.onColumnUpdated();

      Notify.create(response.data.message);
    } catch (e) {
      await handleError(e);
    }
  }
};

defineExpose({ createNewColumn });
</script>
