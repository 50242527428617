<template lang="pug">
.show-card-actions
  grid-actions(
    v-for="(handler, i) in handlers",
    :key="handler.name",
    ref="grid-actions",
    @refresh-table="refreshTable",
    @open-form="openForm",
    :parentData="gridActionsParentData(i + 1, handler)"
  )

  .button-wrapper(v-for="(button, i) in buttons", :key="button.name", :style="button.style.wrapper")
    q-btn.show-card-action(flat, no-caps, @click="onClickButton(button)", :style="button.style.button")
      template(v-if="button.icon && button.icon.length > 0", slot="default")
        inline-svg(
          :class="[button.name + '-icon']",
          :style="button.style.icon",
          :src="require(`../../../assets/icons/show/actions/${button.icon}`)"
        )
      template(v-if="button.label && button.label.length > 0", slot="default")
        span.action-label {{ button.label }}
</template>

<script>
import gridActions from "../grid/gridActions";

export default {
  components: {
    gridActions,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      transition_key: this.parentData.transition_key,
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      additional_transition_params: this.parentData.additional_transition_params || {},
    };
  },

  computed: {
    row() {
      return this.parentData?.row;
    },
    handlers() {
      return this.parentData.data?.handlers;
    },
    activities() {
      return this.parentData.data?.activities;
    },
    buttons() {
      return this.parentData.data?.buttons;
    },
  },

  methods: {
    gridActionsParentData(index, handler) {
      const params = {
        index,
        actions: this.getHandleActions(handler.name),
        row: this.row,
        grid: this.grid,
        grid_key: this.grid_key,
        path: this.path,
        transition_key: this.transition_key,
        btn_label: handler.label,
        default_class: `grid-actions ${handler.name}`,
        actions_icon_path: `show/actions/${handler.icon}`,
      };

      // "handle" name occurs only elements of transiosion
      if (handler.name === "handle") {
        params["additional_transition_params"] = this.additional_transition_params;
        params["additional_activities_params"] = this.getHandleActions("activities");

        if (this.activities?.length && !Object.keys(params["additional_activities_params"])?.length) {
          params["additional_activities_params"] = { activities: this.activities };
        }
      }

      return params;
    },

    onClickButton(button) {
      const onclick = button.onclick;

      this.$emit("click", button.name);

      if (onclick) {
        this[onclick.fn_name](onclick.attr);
      }
    },

    refreshTable(data) {
      this.$emit("refresh-table", data);
    },

    openForm(data) {
      this.$emit("open-form", data);
    },

    getHandleActions(action, result = {}) {
      if (this.grid === "issue_center" || this.grid === "dynamic_issues") {
        // remade issues actions storing
        // set issues actions by issue_center, not by services)

        result = Object.keys(this.$store.state.grid[this.grid].actions)
          // removing current action
          .filter(key => key === action)
          // adding actions from store using "key" to result
          .reduce((r, key) => {
            r[key] = this.$store.state.grid[this.grid].actions[key];
            return r;
          }, {});

        if (result.handle) {
          // filtering handle from result, el.key should be included into available_transitions of this row
          result.handle = result.handle.filter(el => this.row.available_transitions.includes(el.key));
        }
      } else {
        result = Object.keys(this.currentGrid.actions)
          .filter(key => key === action)
          .reduce((r, key) => {
            r[key] = this.currentGrid.actions[key];
            return r;
          }, {});
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/show/actions";
</style>
