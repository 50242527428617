<template lang="pug">
.dashboard-card.meter-readings-chart-card
  .spinner-container.grid-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  div(v-if="!loading && !common_spending_filter_data_loading")
    .common-spending-title
      .dashboard-card-label.noselect
        span {{ title }}
      .common-spending-filters
        inline-svg.more-filters-icon(
          @click="openFiltersForm()",
          :src="require('../../../../../../assets/icons/filters/more_filters.svg')"
        )
      .reset-filters
        inline-svg.reset-filters-icon(
          @click="filtersFormReset()",
          :src="require('../../../../../../assets/icons/filters/reset_filters.svg')"
        )

      q-dialog(v-model="modal")
        filters-form(
          ref="common-spending-filters-form",
          @submit-filters-form="submitFiltersForm",
          @close-filters-form="closeFiltersForm",
          @filters-form-reset="filtersFormReset",
          :parentData="{ grid: chart_grid, data: forms['common_spending_filter_data'] }"
        )

    high-chart(
      v-if="chart.length > 0",
      ref="high-chart",
      @set-chart-area="setChartArea",
      :grid="grid",
      :grid_key="grid_key",
      :chart="{ height: chart_height, margin_top: 20, zoom: false, grab: true, scrollable: false }",
      :series="series",
      :categories="categories",
      :step_width="step",
      :xaxis="xAxis",
      :yaxis="yAxis",
      :legend="{ enabled: true }",
      :tooltip="tooltip",
      :begin_extr="{ start: begin_extr_start, end: begin_extr_end }",
      :plot_options="plot_options",
      :chart_type="{ type: 'areaspline' }"
    )

  grid-hidden-msg(:ref="`${chart_name}-hidden-msg`", :parentData="{ hide_table: hide_table }")
</template>

<script>
import highChart from "../../../../../shared/helpers/charts/highChart";
import gridHiddenMsg from "../../../../../shared/grid/gridHiddenMsg";
import FiltersForm from "./FiltersForm";

export default {
  // props: {
  //   parentData: Object
  // },

  components: {
    highChart,
    gridHiddenMsg,
    FiltersForm,
  },
  data: function () {
    return {
      modal: false,
      path: this.$store.state.paths.measuring_statistic,
      grid: "measuring_statistics",
      grid_key: "statistic_grid_data",
      chart_grid: "common_spending_statistic",
      chart_name: "common_spending_statistic",
      data: {},
      // title: this.parentData.title,
      title: "Тест",
      loading: true,
      common_spending_filter_data_loading: true,
      forms: {
        common_spending_filter_data: [],
      },
      hide_table: false,
      step: 11,
      chart_height: 300,
      tooltip: {
        headerFormat: "",
        pointFormat: this.pointFormat(),
        footerFormat: "",
        shared: false,
        useHTML: true,
        className: "tooltip-chart",
        backgroundColor: "#5B7AFF",
        borderWidth: 0,
        borderRadius: 12,
        padding: 15,
        hideDelay: 500,
        followTouchMove: false,
        followPointer: true,
        style: {
          pointerEvents: "auto",
        },
      },
      xAxis: {
        reversed: false,
      },
      yAxis: {
        min: null,
        grid_line_color: "transparent",
      },
      plot_options: {
        fill_opacity: 0.4,
        sticky_tracking: false,
        areaspline: {
          marker: {
            enabled: false,
          },
        },
        point: {
          events: {
            mouseOver: function (event) {
              if (this.series.chart.tooltip.label) {
                this.series.chart.tooltip.label.show();
              }
            },
          },
        },
      },
      // extr_start: this.parentData.extr_start || null,
      // extr_end: this.parentData.extr_end || null,
      // is_modal: this.parentData.is_modal
    };
  },

  computed: {
    chart() {
      return (this.data && this.data.data) || [];
    },

    chart_filters() {
      let grid = this.$store.state.grid[this.chart_grid];
      if (grid) {
        return grid["filters"];
      } else {
        return undefined;
      }
    },

    series() {
      return (
        (this.data.data &&
          this.data.data.map(v => {
            return {
              name: v.name,
              data: v.data.map(val => {
                return {
                  y: val.value,
                  color: v.color,
                  month: val.month,
                  year: val.year,
                  unit: v.measure_unit,
                };
              }),
              color: v.color,
            };
          })) ||
        []
      );
    },

    categories() {
      return (this.data.data && this.data.data.map(val => val.data.map(val => val.month))[0]) || [];
    },

    step_width() {
      return this.categories && this.categories.length < this.data.step_width
        ? this.categories.length
        : this.data.step_width;
    },

    begin_extr_start() {
      return !this.categories || this.categories.length <= this.data.step_width
        ? this.step_width
        : this.categories.length - this.step_width;
    },

    begin_extr_end() {
      return !this.categories || this.categories.length <= 1 ? 0 : this.categories.length;
    },
  },

  created() {
    this.$store.commit("initialGridsState", { grid: this.chart_grid, initial_fields: {} });
    Object.keys(this.forms).forEach(data_key => {
      this.loadFormData(data_key);
    });
    this.loadChartData();
  },

  methods: {
    filtersFormReset() {
      this.$store.commit("resetAllFilters", { grid_name: this.chart_grid, filters: Object.keys(this.chart_filters) });
      this.loadChartData();
    },

    submitFiltersForm(attr) {
      this.modal = false;
      this.loadChartData();
    },

    closeFiltersForm() {
      this.modal = false;
    },

    openFiltersForm() {
      this.modal = true;
    },

    pointFormat() {
      return (
        "<ul>" +
        '<li class="indication-chart-tooltip-row date">{point.month} {point.year}:</li>' +
        '<li class="indication-chart-tooltip-row name">{series.name}</li>' +
        '<li class="indication-chart-tooltip-row value">{point.y} {point.unit}</li>' +
        "</ul>"
      );
    },

    hideTable(val) {
      this.hide_table = val;
      if (this.$refs[`${this.chart_name}-hidden-msg`]) {
        this.$refs[`${this.chart_name}-hidden-msg`].hideTable(val);
      }
    },

    setChartArea(attr) {
      let extr = attr.chart.xAxis[0].getExtremes();

      if (attr.event === "back") {
        if (extr.min > extr.dataMin) {
          attr.chart.xAxis[0].setExtremes(extr.min - this.step_width, extr.max - this.step_width);
        }
      } else if (attr.event === "next") {
        if (extr.max < extr.dataMax) {
          attr.chart.xAxis[0].setExtremes(extr.min + this.step_width, extr.max + this.step_width);
        }
      }
    },

    loadFormData(key) {
      let path = this.path + "/" + key;

      this.$backend
        .index(path, { params: {} })
        .then(({ data }) => {
          this.forms[key] = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this[key + "_loading"] = false;
        });
    },

    loadChartData(params = {}) {
      params.filters = (this.filters && this.generateFiltersParams()) || {};

      let chart_filters_params = this.generateFiltersParams(this.chart_filters);
      Object.keys(chart_filters_params).forEach(key => {
        params.filters[key] = chart_filters_params[key];
      });

      if (this.$route.query.building_id) {
        if (!params.filters["building_id"]) {
          params.filters["building_id"] = this.$route.query.building_id;
        }

        history.replaceState({}, null, `${this.$route.path}?building_id=${params.filters["building_id"]}`);
      }

      params["chart_name"] = this.chart_name;

      this.$backend
        .collection(`${this.path}/common_spending_chart_data`, params)
        .then(({ data }) => {
          this.data = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.loading = false;

          if (this.chart.length > 0) {
            this.hideTable(false);
          } else {
            this.hideTable(true);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.meter-readings-chart-card {
  .hidden-tabs-message {
    height: 320px !important;

    span {
      padding-top: initial;
    }
  }

  .spinner-container.grid-spinner {
    height: 320px !important;
  }

  .indication-chart-tooltip-row.date {
    font-weight: 400 !important;
    font-size: 11px !important;
  }

  .indication-chart-tooltip-row.name {
    font-weight: 600 !important;
    font-size: 13px !important;
    margin-top: 10px;
  }

  .indication-chart-tooltip-row.value {
    font-weight: 400 !important;
    font-size: 14px !important;
    margin-top: 5px;
  }

  .common-spending-title {
    display: flex;

    .dashboard-card-label {
      flex: 1;
    }

    .more-filters-icon {
      margin: 10px;
      cursor: pointer;
    }
    .reset-filters-icon {
      padding: 2px;
      margin: 5px;
      cursor: pointer;
    }
  }
}
</style>
