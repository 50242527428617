<template lang="pug">
.quantity-card(@click="openIssuesByState()")
  quantity-bg(:code="stringQuantityCode")
  span.quantity_title {{ quantity.title }}
  span.quantity_value {{ quantity.value }}
</template>

<script>
import quantityBg from "./quantityBg";

export default {
  name: "IssueQuantity",

  components: {
    quantityBg,
  },

  props: {
    quantity: {
      type: Object,
      default: () => {},
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    stringQuantityCode() {
      return this.quantity.code.toString();
    },
  },

  created() {},

  methods: {
    openIssuesByState(params = {}) {
      if (!this.disable) {
        params["attr"] = {
          filters: {
            status_id: [
              {
                label: this.quantity.title,
                value: this.quantity.code,
              },
            ],
          },
        };

        if (this.current_user.additional_services_ids.length > 0) {
          params["service_id"] = this.current_user.additional_services_ids[0];
        }

        let route = this.$store.state.issues_archive_states.includes(this.quantity.code) ? "archive" : "issues";

        this.$router.push({
          name: route,
          params: params,
        });
      }
    },
  },
};
</script>
