<template lang="pug">
.index-page
  common-header(
    v-if="!filter_data_loading",
    @reload-data="reloadData",
    :parentData="{ path: path, grid: grid, data: forms['filter_data'], hide_filters: true, hide_buttons: true }"
  )

  notification-rule-grid-filters(
    v-if="!filter_data_loading",
    :parentData="{ path: path, grid: grid, data: forms['filter_data'] }"
  )

  .notification-rule-grid(v-if="!filter_data_loading")
    notification-rule-grid(
      ref="notification-rule-grid",
      :parentData="{ filters_data: forms['filter_data'], grid: grid, mutation: 'updateGridData', path: path, grid_key: 'data' }"
    )
</template>

<script>
import commonHeader from "../../shared/helpers/Header";
import notificationRuleGridFilters from "./notificationRuleGridFilters";
import notificationRuleGrid from "./notificationRuleGrid";

export default {
  components: {
    commonHeader,
    notificationRuleGridFilters,
    notificationRuleGrid,
  },
  data: function () {
    return {
      grid: "notification_rules",
      path: this.$store.state.paths["notification_rule"],
      filter_data_loading: true,
      forms: {
        filter_data: [],
      },
    };
  },

  computed: {},

  watch: {
    allDataLoaded(newVal, oldVal) {
      if (newVal) {
        this.$emit("set-loading", false);
      }
    },
  },

  created() {
    let initial_fields = this.$store.state.initial_grid_fields;
    this.$store.commit("initialGridsState", { grid: this.grid, initial_fields: initial_fields });
    Object.keys(this.forms).forEach(data_key => {
      this.loadFormData(data_key);
    });
  },

  beforeMount() {
    this.$emit("expand-class", { val: false, class: "" });
  },

  methods: {
    reloadData() {
      Object.keys(this.forms).forEach(data_key => {
        this.loadFormData(data_key);
      });
      let grid = this.$refs["notification-rule-grid"];
      if (grid) {
        grid.reloadData();
      }
    },

    filtersFormAccepted(val) {
      this.$refs["grid"].filtersFormAccepted(val);
    },

    loadFormData(key) {
      let path = this.path + "/" + key;

      this.$backend
        .index(path, { params: {} })
        .then(({ data }) => {
          this.forms[key] = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this[key + "_loading"] = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/index-page/notification-rules/panel";
</style>
