<template lang="pug">
.dashboard-link 
  .dashboard-card.link-card
    .link-card-icon-container
      .link-card-icon
        inline-svg(:src="require(`../../../../assets/icons/dashboard/links/${options.icon}`)")
    .link-card-title
      span {{ options.title }}
    .link-card-button
      q-btn(
        v-if="!showDropdown",
        flat,
        no-caps,
        @click="onClickFn(options.button.onclick, options.button.attr)",
        :disable="options.button.disable"
      )
        template(slot="default")
          span {{ options.button.label }}
      q-btn-dropdown(
        v-else,
        flat,
        no-caps,
        :label="options.button.label",
        content-class="dashboard-link__dropdown-scroll"
      )
        q-list(v-for="item in dropdownOptions", :key="item.label")
          q-item(clickable, v-close-popup, @click="onClickFn(options.button.onclick, { value: item.value })")
            q-item-section
              q-item-label.dropdown__label {{ item.label }}
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "@/store";
import { useRouter } from "@/routing/router";

const props = defineProps({
  options: { type: Object, default: () => {} },
});

const store = useStore();
const router = useRouter();

const showDropdown = computed(() => props.options.button.dropdown);
const dropdownOptions = computed(() => props.options.button.attr.facilities || []);

const onClickFn = (fn, attrs) => {
  if (fn) {
    const params = attrs || {};
    handlerFunctions.value[fn](params);
  }
};

const handlerFunctions = computed(() => ({
  addIssue,
  openIssues,
  openFacilityDashboard,
}));

// Called fron fn
const addIssue = () => {
  const params = { add_issue: true, attr: {} };
  sendQuery(params);
};

// Called fron fn
const openIssues = () => {
  sendQuery();
};

// Called fron fn/dropdown
const openFacilityDashboard = params => {
  // User with single facility => { facilities: [{ value: 1, label: "name" }, ...] }
  // User with many facilities has dropdown enabled => { value: { value: 1, label: "name" } }
  const singleFacilityParams = params.facilities?.[0] || params;
  router.push({ name: "Dashboard", params: { facility_id: singleFacilityParams.value, type: "facility" } });
};

const sendQuery = (params = {}) => {
  const additionalServicesIds = store.state.account.current_user.additional_services_ids;

  if (additionalServicesIds.length > 0) {
    params["service_id"] = additionalServicesIds[0];
  }

  router.push({ name: "issues", params });
};
</script>

<style lang="scss">
.dashboard-link__dropdown-scroll {
  overflow-y: auto; // override global .scroll to disable empty space and revert to quasar defaults
}

.dropdown__label {
  color: var(--dashboard-label-color);
}

.dashboard-link {
  .link-card {
    &:hover {
      .link-card-icon {
        transform: scale(1.1);

        svg {
          transform: scale(1.1);
        }
      }
    }

    position: relative;
    padding: 0;
    height: 152px;
    width: 260px;
    margin-left: 30px;
    margin-right: 0;
    margin-bottom: 0;

    .link-card-icon-container {
      display: flex;
      justify-content: center;

      .link-card-icon {
        position: absolute;
        top: 20px;
        width: 46px;
        height: 46px;
        background-color: var(--link-card-icon-background);
        border-radius: 50%;
        display: flex;
        align-items: center;

        svg {
          margin: 0 auto;
          height: 22px;
        }
      }
    }

    .link-card-title {
      position: absolute;
      top: 75px;
      width: 100%;
      text-align: center;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: var(--dashboard-label-color);
      }
    }

    .link-card-button {
      position: absolute;
      top: 105px;
      width: 100%;
      text-align: center;

      button {
        height: 25px;
        border-radius: 25px;
        background-color: var(--link-card-button-background);
        color: #fff;
        padding-left: 20px;
        padding-right: 20px;

        .q-btn__wrapper {
          min-height: initial !important;
        }

        .q-btn__content {
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
