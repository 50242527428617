<template lang="pug">
div(v-if="loaded")
  .notification-checkbox(v-for="type in types", :key="type.id")
    q-checkbox(dark, v-model="type.value", :label="type.id", @input="updateNotificationRule")
</template>

<script setup>
import { onBeforeMount, ref, computed } from "vue";
import { handleError } from "@/services/handleErrors";
import backend from "@/api";

const props = defineProps({
  parentData: { type: Object, default: () => {} },
});

const data = ref({});
const types = ref([]);
const loaded = ref(false);

const path = computed(() => props.parentData.path);
const grid = computed(() => props.parentData.grid);
const event = computed(() => props.parentData.event);
const role = computed(() => props.parentData.role);
const statusId = computed(() => props.parentData.statusId);
const serviceId = computed(() => props.parentData.serviceId.value);

const updateNotificationRule = async val => {
  const notificationRule = {
    event: event.value,
    role: role.value,
  };
  if (!data.value) {
    notificationRule["service_id"] = serviceId.value;
    notificationRule["status_id"] = statusId.value;
  } else {
    notificationRule["id"] = data.value.id;
  }
  types.value.map(type => {
    notificationRule[type.id] = type.value;
  });
  await changeNotificationRule(notificationRule);
};

const changeNotificationRule = async notificationRule => {
  try {
    const res = await backend.put(`${path.value}/change`, { notification_rule: notificationRule });
    data.value = res.data;
  } catch (error) {
    await handleError(error);
  }
};

onBeforeMount(async () => {
  data.value = props.parentData.data;
  types.value = props.parentData.types.map(type => {
    return { id: type, value: data.value ? data.value[type] : false };
  });
  loaded.value = true;
});
</script>

<style lang="scss"></style>
