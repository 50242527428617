export const options = (data, locale, theme) => ({
  grid: {
    borderColor: theme.grid_border_color,
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    strokeDashArray: 7,
  },
  stroke: {
    show: true,
    curve: "smooth",
    lineCap: "butt",
    colors: ["#FFFFFF"],
    width: 3,
    dashArray: 0,
  },
  chart: {
    toolbar: {
      show: false,
    },
    foreColor: theme.fore_color,
    defaultLocale: locale || "ru",
    locales: [
      {
        name: "ru",
        options: {
          months: [
            "январь",
            "февраль",
            "март",
            "апрель",
            "май",
            "июнь",
            "июль",
            "август",
            "сентябрь",
            "октябрь",
            "ноябрь",
            "декабрь",
          ],
          shortMonths: [
            "янв.",
            "февр.",
            "марта",
            "апр.",
            "мая",
            "июня",
            "июля",
            "авг.",
            "сент.",
            "окт.",
            "нояб.",
            "дек.",
          ],
          days: ["воскресение", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"],
          shortDays: ["вскр", "пнд", "вт", "ср", "чт", "пт", "суб"],
        },
      },
      {
        name: "en",
        options: {
          months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        },
      },
    ],
    id: "vuechart-example",
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: "datetime",
    categories: data.map(val => val.finished_at),
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    labels: {
      formatter: val => (val > 0 ? `${val}%` : val),
    },
  },
  tooltip: {
    theme: "dark",
  },
});
