// Return timezone offset from UTC+0 (as positive number)
// returns string (for example "3")
const currentTimezoneOffset = value => {
  const currentDate = new Date();
  const currentTimezoneOffset = currentDate.getTimezoneOffset() / 60;

  return `${-currentTimezoneOffset}`;
};

export { currentTimezoneOffset };
