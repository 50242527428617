<template lang="pug">
div
  .select-fields.form-field.form-multiselect.filter(@click.stop="toggleFields", :class="selectFieldsClass")
    .row.items-center.select-fields__label {{ text }}
    .row.items-center.select-fields__icon
      inline-svg(v-if="showing", :src="require(`@/assets/icons/dynamic-issues/index/arrow-up.svg`)")
      inline-svg(v-else, :src="require(`@/assets/icons/dynamic-issues/index/arrow-down.svg`)")

  q-menu(
    content-class="select-fields__menu",
    v-model="showing",
    transition-show="jump-down",
    transition-hide="jump-up"
  )
    div
      q-list.select-fields__list
        q-item.select-fields__list__item_wrapper
          .select-fields__empty
          checkbox(:label="currentLocales.select_all", :value="selectAll", @input="val => changeAllValues(val)")
        draggable.list-group(:list="list", ghost-class="select-fields__ghost", handle=".select-fields__handle")
          q-item.select-fields__list__item_wrapper(v-for="item in list", :key="item.name")
            .select-fields__list__item
              .select-fields__handle(v-if="allowDrag(item)")
                inline-svg(:src="require('../../../../assets/icons/dynamic-issues/index/more-horizontal.svg')")
              .select-fields__empty(v-else) 
              checkbox(
                :label="item.label",
                :value="item.value",
                :disable="item.disabled",
                @input="val => changeValue(item, val)"
              )
      .select-fields__buttons
        button(type="secondary", :label="currentLocales.cancel", :disable="loading", @click="hideFields")
        button(type="primary", :label="currentLocales.submit", :disable="loading", @click="submit")
</template>

<script setup>
import { ref, computed } from "vue";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { dynamicIssuesLocales } from "@/services/useLocales";
import Checkbox from "@/components/shared/general_components/inputs/Checkbox.vue";
import Button from "@/components/shared/general_components/inputs/Button.vue";
import draggable from "vuedraggable";

const props = defineProps({
  facilityId: { type: Number, required: true },
});

const emit = defineEmits(["submit"]);

const loading = ref(false);
const showing = ref(false);
const list = ref([]);
const selectAll = ref(false);

const selectFieldsClass = computed(() => ({
  "select-fields--active": showing.value,
  "select-fields--loading": loading.value,
}));

const currentLocales = computed(() => dynamicIssuesLocales.value.select_fields);
const text = computed(() => (loading.value ? currentLocales.value.loading : currentLocales.value.title));

const toggleFields = async () => {
  if (loading.value == true) {
    return;
  }

  if (showing.value == true && loading.value == false) {
    showing.value = false;
    return;
  }

  await loadFields();
  showing.value = true;
};

const hideFields = () => {
  showing.value = false;
};

const loadFields = async () => {
  try {
    loading.value = true;
    const response = await backend.index("api/v3/dynamic/issues/card", {
      params: { facility_id: props.facilityId },
    });

    // Sort by index and remove "index" field since we can reorder list
    list.value = response.data
      .sort((a, b) => a.index - b.index)
      .map(item => ({ label: item.label, name: item.name, value: item.value, disabled: item.disabled }));

    // Select all value based on items selection
    const allItemsSelected = list.value.every(item => item.value === true);
    selectAll.value = allItemsSelected;
  } catch (error) {
    await handleError(error);
  } finally {
    loading.value = false;
  }
};

const submit = async () => {
  const fieldsList = list.value.map((item, index) => ({ [item.name]: { index, value: item.value } }));
  const fields = Object.assign(...fieldsList);

  try {
    await backend.patch("api/v3/dynamic/issues/card", { facility_id: props.facilityId, issue_card: fields });
    emit("submit");
    hideFields();
  } catch (error) {
    await handleError(error);
  }
};

const changeAllValues = val => {
  selectAll.value = val;

  list.value = list.value.map(item => {
    const value = item.disabled ? item.value : val; // disabled value is not touched

    return { ...item, value };
  });
};

const changeValue = (updatedItem, val) => {
  list.value = list.value.map(item => {
    if (item.name == updatedItem.name) {
      return { ...item, value: val };
    } else {
      return item;
    }
  });
};

const allowDrag = item => {
  return item.value && !item.disabled;
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/checkbox";

.select-fields__menu {
  min-width: 350px;
  overflow-y: hidden;
  border-radius: 8px;
}
</style>

<style scoped lang="scss">
$handle-size: 24px;

// Draggable classes
.select-fields__handle {
  cursor: move;
  width: $handle-size;
  height: $handle-size;
}
.select-fields__empty {
  width: $handle-size;
  height: $handle-size;
}
.select-fields__ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.select-fields {
  cursor: pointer;

  &--loading {
    cursor: wait;
  }

  &--active {
    cursor: pointer;
  }

  &__label {
    padding-left: 15px;
    width: 100%;
    color: var(--dynamic-issues-select-fields-label-color);
  }

  &__icon {
    padding-right: 10px;
  }

  &__list {
    overflow-y: scroll;
    max-height: 470px;
    padding: 20px 0;

    &__ghost {
      opacity: 0.5;
      background: #c8ebfb;
    }

    &__item_wrapper {
      padding: 0 0 0 5px;
    }

    &__item {
      display: flex;
      align-items: center;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}
</style>
