<template lang="pug">
.grid-button-group
  span(v-for="button in buttons")
    q-btn(
      v-if="button.dropdown",
      flat,
      no-caps,
      :style="button.style",
      :id="`${button.name || ''}-${grid.split('_').join('-')}-button`"
    )
      template(v-if="button.icon", slot="default")
        inline-svg.grid-btn-icon-left(:src="require(`../../../assets/icons/header/${button.icon}`)")
      template(v-if="button.label", slot="default")
        span {{ button.label }}
      template(slot="default")
        inline-svg.arrow-down-actions-icon(:src="require(`../../../assets/icons/grid/dropdown.svg`)")

      grid-button-group-menu(
        ref="grid-actions-menu",
        @get-handler="getHandler",
        :parentData="{ grid: grid, grid_key: grid_key, links: button.dropdown }"
      )

    q-btn(
      v-else,
      flat,
      no-caps,
      :style="button.style",
      @click="getHandler(button)",
      :id="`${button.name || ''}-${grid.split('_').join('-')}-button`"
    )
      template(v-if="button.icon", slot="default")
        inline-svg.grid-btn-icon-left(:src="require(`../../../assets/icons/header/${button.icon}`)")
      template(v-if="button.label", slot="default")
        span {{ button.label }}
</template>

<script>
import { useEmitter } from "@/services/useEmitter";
import gridButtonGroupMenu from "./gridButtonGroupMenu";

export default {
  components: {
    gridButtonGroupMenu,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      emitter: useEmitter(),
    };
  },

  computed: {
    buttons() {
      return this.parentData.buttons;
    },
  },

  methods: {
    getHandler(item) {
      return this[item.onclick](item);
    },

    addNewItem() {
      this.$root.$emit("add-new-item");
      this.emitter.emit("add-new-item"); // for composition api
    },

    openExportForm(item) {
      this.$root.$emit("open-export-form", item);
      this.emitter.emit("open-export-form", item); // for composition api
    },

    openImportForm(item) {
      this.$root.$emit("open-import-form", item);
    },

    openVerifyCheckinsForm(item) {
      this.$root.$emit("verify-checkins-form", item);
    },

    openPrintQRListForm(item) {
      this.emitter.emit("print-qr-list-form", item);
    },
  },
};
</script>
