<template lang="pug">
.boolean-select-filter.form-field.form-multiselect.filter(:class="filterClass")
  .boolean-select-filter__label(v-if="showSelectionLabel") {{ label }}
  multiselect.multiple-select-filter-field(
    v-model="currentFilter",
    closeOnSelect,
    track-by="value",
    label="label",
    :disabled="disabled",
    :placeholder="label",
    :show-labels="false",
    :options="options",
    :hideSelected="true",
    open-direction="bottom"
  )
    span(slot="noResult")
      i {{ notifies.no_search_result }}

  q-icon.cancel-select-filter(name="cancel", v-show="isResettable", @click.stop="resetFilter()")
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "@/store";
import { currentLocale } from "@/services/useLocales";
import Multiselect from "vue-multiselect";
import i18n from "@/plugins/vue-i18n";
import "vue-multiselect/dist/vue-multiselect.min.css";

const props = defineProps({
  parentData: { type: Object, default: () => {} },
  disabled: { type: Boolean, default: false },
  selectionLabel: { type: Boolean, default: false },
});

const store = useStore();

const currentFilters = computed(() => store.state.grid[grid.value]["filters"]);
const options = computed(() => {
  return [
    { label: i18n["messages"][currentLocale.value].yes, value: true },
    { label: i18n["messages"][currentLocale.value].no, value: false },
  ];
});
const grid = computed(() => props.parentData.grid);
const value = computed(() => props.parentData.data[0].value);
const name = computed(() => props.parentData.data[0].name);
const reset = computed(() => {
  const resetValue = props.parentData.data[0].reset;
  return resetValue === undefined ? true : resetValue;
});
const label = computed(() => props.parentData.data[0].label);

const isResettable = computed(() => {
  if (!currentFilter.value) {
    // if default/value is not set
    return false;
  }

  return reset.value;
});
const currentFilter = computed({
  get() {
    const storeValue = currentFilters.value[name.value];

    if (storeValue === undefined) {
      return false;
    }

    if (storeValue) {
      const truthyOption = options.value.find(obj => obj.value === true);
      return truthyOption;
    } else {
      const falseyOption = options.value.find(obj => obj.value === false);
      return falseyOption;
    }
  },
  set(obj) {
    store.commit("updateFilter", { grid_name: grid.value, filter: name.value, value: obj.value });
  },
});
const showSelectionLabel = computed(() => (props.selectionLabel && currentFilter.value ? true : false));

const filterClass = computed(() => ({
  "boolean-select-filter--selection-label": showSelectionLabel.value,
}));

const resetFilter = () => {
  store.commit("resetFilter", { grid_name: grid.value, filter: name.value });
};

const setValueFromLocal = () => {
  const localFilters = store.state.grid[grid.value].filters;

  if (!localFilters || !localFilters[name.value]) {
    if (value.value !== null) {
      store.commit("updateFilter", { grid_name: grid.value, filter: name.value, value: value.value });
    } else {
      resetFilter();
    }
  }
};

onMounted(() => {
  setValueFromLocal();
});
</script>

<style scoped lang="scss">
@import "../../../assets/styles/filters/select";

.boolean-select-filter {
  cursor: pointer;

  &--selection-label {
    position: relative;

    .boolean-select-filter__label {
      position: absolute;
      left: 15px;
      font-size: 11px;
      color: var(--field-input-color);
    }

    :deep(.multiselect__tags) {
      position: relative;
      top: 5px;
    }
  }
}
</style>
