<template lang="pug">
.floor-plan-dot-prompt-content
  .floor-plan-dot-prompt-content-item(v-for="(value, key) in shortInfo", :key="`${key} ${value}`")
    | {{ key }}
    p {{ value ? value : "—" }}
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  promptData: { type: Object, default: () => {} },
});

const shortInfo = computed(() => props.promptData.dot.short_info);
const dot = computed(() => props.promptData.dot);
const floor_plan_id = computed(() => props.promptData.floor_plan_id);
</script>

<style lang="scss">
@import "../../../../assets/styles/floor-plan/main";
</style>
