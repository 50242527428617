<template lang="pug">
div
  .form-field-label
    span {{ field.label }}
    span.form-field-require-tag(v-show="required", :title="notifies.required_field")
      | *

  .form-field.form-input
    q-input(filled, no-error-icon, :value="workflowInputFieldValue", autocomplete="off", @input="setField")
      q-icon.cancel-string-field(name="cancel", v-if="workflowInputFieldValue", @click.stop="resetInputField()")
</template>

<script setup>
import { ref } from "vue";
import { notifies } from "@/services/useLocales";

const props = defineProps({
  field: { type: Object, default: () => {} },
  required: { type: Boolean, default: false },
  object: { type: Object, default: () => {} },
});

const emit = defineEmits(["fieldChange"]);

const workflowInputFieldValue = ref(props.object[props.field.name]);

const setField = val => {
  workflowInputFieldValue.value = val;
  emit("fieldChange", { name: props.field.name, value: val });

  if (!val && props.field.require) {
    emit("disableField", props.field.name);
  } else {
    emit("enableField", props.field.name);
  }
};

const resetInputField = () => {
  workflowInputFieldValue.value = "";
  emit("fieldChange", { name: props.field.name, value: "" });

  if (props.field.require) {
    emit("disableField", props.field.name);
  } else {
    emit("enableField", props.field.name);
  }
};
</script>

<style scoped lang="scss">
.form-field-label {
  position: relative;
}
</style>
