<template lang="pug">
table.tech-map-tab-table
  thead
    tr
      th
        span.column-header-title Обозн.
      th
        span.column-header-title Наим.
      th
        span.column-header-title Техн. Хар-ки
      th
        span.column-header-title Комментарий
  tbody
    tr(v-for="item in components")
      td {{ item.code || "–" }}
      td {{ item.name || "–" }}
      td.properties-td
        table.properties-table
          tbody
            tr(v-for="prop in item.properties")
              td {{ prop.name || "–" }}
              td {{ prop.value || "–" }}
      td {{ item.comment || "–" }}
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      components: this.parentData.tabs,
    };
  },

  mounted() {},
};
</script>
