<template lang="pug">
.index-page.measuring-statistic.dashboard
  //div(class="trouble-shooting-container")
    div(class="trouble-shooting-message" style="padding-left: 10px; padding-right: 10px;")
      span {{trouble_shooting_message}}

  .spinner-container.grid-spinner(v-if="!loading && loading_data")
    q-spinner(color="primary", size="3em")

  div(v-if="!loading")
    common-header(
      v-show="!loading_data",
      @reload-data="reloadData",
      :parentData="{ data: dashboard_data, grid: grid, item: item, hide_filters: true, hide_buttons: true, actions: header_actions }"
    )

  .meter-type-charts-carousel-block(v-if="!loading")
    div(v-show="!loading_data")
      component(
        ref="meter-type-charts-carousel",
        :is="getBoardComponent('meter_types_statistic')",
        :parentData="{ grid: grid, grid_key: grid_key }"
      )

  .middle-block(v-if="!loading")
    .middle-left-block(v-if="!loading_data")
      component(ref="meter-readings-chart", :is="getBoardComponent('common_spending_statistic')")

    .middle-right-block(v-show="!loading_data")
      component(ref="measuring_soon_verifyings", :is="getBoardComponent('measuring_soon_verifyings')")

  .footer-block(v-if="!loading")
    component(
      v-show="!loading_data",
      ref="measuring_last_readings",
      :is="getBoardComponent('measuring_last_readings')"
    )
</template>

<script>
import commonHeader from "../../../shared/helpers/Header";
import boards from "./boards";

export default {
  components: {
    commonHeader,
    boards,
  },
  data: function () {
    return {
      grid: "measuring_statistics",
      grid_key: "statistic_grid_data",
      item: "measuring_statistic",
      path: this.$store.state.paths,
      dashboard_data: {},
      loading: true,
      loading_data: true,
      header_actions: ["project_filter_form"],

      trouble_shooting_message: "Статистика временно отключена, приносим свои извинения.",
    };
  },

  watch: {
    loading(newVal, oldVal) {
      this.$emit("set-loading", this.loading);

      setTimeout(() => {
        this.loading_data = newVal;
      }, 500);
    },
  },

  created() {
    this.$root.$on("reload-measuring-last-readings", () => {
      if (this.$refs["measuring_last_readings"]) {
        this.$refs["measuring_last_readings"].reloadData();
      }
    });
  },

  mounted() {
    this.$root.$on("reload-measuring_statistic-data", () => {
      this.reloadData();
    });
  },

  beforeMount() {
    this.$emit("expand-class", { val: true, class: "dashboard-background" });
    this.loadDashboardData();
  },

  methods: {
    getBoardComponent(board) {
      return boards[board];
    },

    reloadData(params = {}) {
      this.loadDashboardData();

      if (this.filters) {
        params.filters = this.generateFiltersParams();
      }

      setTimeout(() => {
        if (this.$refs["meter-type-charts-carousel"]) {
          this.$refs["meter-type-charts-carousel"].reloadData();
        }
        if (this.$refs["meter-readings-chart"]) {
          this.$refs["meter-readings-chart"].loadChartData();
        }
        if (this.$refs.measuring_soon_verifyings) {
          this.$refs.measuring_soon_verifyings.reloadData(params);
        }
        if (this.$refs.measuring_last_readings) {
          this.$refs.measuring_last_readings.reloadData(params);
        }
      }, 300);
    },

    loadDashboardData(params = {}) {
      params.filters = (this.filters && this.generateFiltersParams()) || {};

      if (this.$route.query.building_id) {
        if (!params.filters["building_id"]) {
          params.filters["building_id"] = this.$route.query.building_id;
        }

        history.replaceState({}, null, `${this.$route.path}?building_id=${params.filters["building_id"]}`);
      }

      this.$backend
        .index(`${this.path.measuring_statistic}/data`, { params: params })
        .then(({ data }) => {
          this.dashboard_data = data;
        })
        .catch(error => {
          if (error.response) {
            this.reLogin(error.response.status);
          }
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/measuring/statistic/main";
</style>
