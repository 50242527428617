<template lang="pug">
.equipment-tech-map
  .spinner-container.form-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  .tech-map-card(v-if="!loading")
    .main-block
      div
        .qr-preview
          qr-image(:url="qrObject.url")
        qr(
          v-show="false",
          ref="printRef",
          :title="qrObject.form.header",
          :texts="qrObject.form.fields",
          :url="qrObject.url"
        ) 

      .actions-block
        .tech-map-title
          .title
            span {{ data.title }}
          .sub-title
            span {{ data.sub_title }}

        .spacer

        .show-card-actions
          div(v-for="button in buttons", :class="'button-wrapper ' + button.name", :style="button.style.wrapper")
            q-btn.show-card-action(flat, no-caps, @click="onActionClick(button)", :style="button.style.button")
              template(v-if="button.icon && button.icon.length > 0", slot="default")
                inline-svg(
                  :class="[button.name + '-icon']",
                  :style="button.style.icon",
                  :src="require(`../../../../../../assets/icons/ppr/equipment/show/actions/${button.icon}`)"
                )
              template(v-if="button.label && button.label.length > 0", slot="default")
                span.action-label {{ button.label }}

    .techmap-info-blocks
      .spinner-container.form-spinner(v-if="loading_blocks")
        q-spinner(color="primary", size="3em")

      .specification-block(v-if="!loading_blocks")
        .specification-title
          span {{ data.specification }}
        specification(
          :parentData="{ buttons: data['edit_tech_map_buttons'], data: row['technological_map']['specification'], editable: editable, grid: grid, row_id: row_id }"
        )

      .equipment-show-tabs.tabs-block(v-if="!loading_blocks")
        q-tabs(:value="tab.name", align="left")
          q-tab(
            v-for="tab in data.tabs",
            :key="tab.name",
            v-if="tabCondition(tab.name)",
            @click="addCurrentTab(tab)",
            :name="tab.name",
            :label-slot="false"
          )
            template(v-if="tab.label", slot="default")
              span {{ tab.label }}

        q-tab-panels.equipment-show-tabs-panels(:value="tab.name")
          q-tab-panel(v-for="tab in data.tabs", v-if="tabCondition(tab.name)", :key="tab.name", :name="tab.name")
            component(
              :is="getTabPanel(tab.name)",
              :parentData="{ buttons: data['edit_tech_map_buttons'], data: getData(tab.name), tab_data: tab.data, editable: editable, grid: grid, row_id: row_id }"
            )

    #print-equipment-tech-map(v-if="!loading", v-show="false")
      component(
        :is="getShowComponent('print_equipment_tech_map')",
        :parentData="{ titles: data.titles, data: data.print_equipment_tech_map, row: row }"
      )

    .button-group.row.justify-center(v-if="editable")
      q-btn.tech-map-form-submit(
        flat,
        no-caps,
        :label="data['edit_tech_map_buttons']['submit']['label']",
        @click="onSubmit()"
      )
</template>

<script>
import { openTabAndPrintContent } from "@/services/print";
import specification from "./blocks/specification";
import tabPanels from "./blocks/tabs/tabs_panels";
import qrImage from "../../../../../shared/print/qrImage";
import qr from "../../../../../shared/print/qr";
import compiledPrintQrCss from "!raw-loader!sass-loader!../../../../../../assets/styles/printQr/qr.scss";

export default {
  components: {
    specification,
    tabPanels,
    qrImage,
    qr,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      item: "ppr_equipment",
      row_id: this.parentData.row_id,
      tab_name: this.parentData.tab_name,
      row: {},
      data: [],
      editable: false,
      loading: true,
      loading_blocks: false,
      tab: "",
    };
  },

  computed: {
    buttons() {
      return this.data.actions;
    },

    tech_map_data() {
      let grid = this.$store.state.grid[this.grid];
      if (grid) {
        return grid["tech_map"];
      } else {
        return undefined;
      }
    },

    qrObject() {
      return this.data.qr;
    },
  },

  watch: {
    tech_map_data(newVal, oldVal) {},
  },

  created() {
    this.$store.commit("initialEquipmentTechMap", { grid_name: this.grid });
    this.loadData();
  },

  methods: {
    tabCondition(tab_name) {
      return tab_name !== "issues" || (tab_name === "issues" && this.getData(tab_name).length > 0);
    },

    getData(tab_name) {
      if (tab_name) {
        if (tab_name === "issues") {
          return this.row[tab_name];
        } else {
          return this.row["technological_map"][tab_name];
        }
      }
    },

    onSubmit(params = {}) {
      params[this.item] = {
        technological_map: JSON.stringify(this.tech_map_data),
      };

      this.loading = true;

      this.$backend
        .update(this.path, this.row_id, params)
        .then(({ data }) => {
          this.row = data;
          this.editable = false;
          this.loading = false;

          this.loadData();

          this.$q.notify(this.notifies.success_updated_entry);
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.loading = false;
          this.$q.notify(this.notifies.error_ask_admin);
        });
    },

    getTabPanel(key) {
      if (key) {
        return tabPanels[key];
      }
    },

    addCurrentTab(tab) {
      this.tab = tab;
    },

    onActionClick(button) {
      if (button.name != "print_qr") {
        this.onClickFn(button);
        return;
      }

      openTabAndPrintContent({ html: this.$refs.printRef.$el.innerHTML, css: compiledPrintQrCss });
    },

    onClickFn(button) {
      if (button.onclick) {
        if (button.onclick.child) {
          this.buttons.find(b => b.name === button.name).style.wrapper.display = "none";
          this.buttons.find(b => b.name === button.onclick.child).style.wrapper.display = "initial";
        } else if (button.onclick.parent) {
          this.buttons.find(b => b.name === button.name).style.wrapper.display = "none";
          this.buttons.find(b => b.name === button.onclick.parent).style.wrapper.display = "initial";
        }

        if (button.onclick.fn_name) {
          this[button.onclick.fn_name](button.onclick.attr);
        }
      }
    },

    setEditable(attr) {
      this.editable = attr.value;
      if (!attr.value) {
        this.loading_blocks = true;
        this.loadData();
      }
    },

    loadData(params = {}) {
      this.$backend
        .show(this.path, this.row_id, { params: params })
        .then(({ data }) => {
          this.row = data["common"]["data"];
          this.data = data["common"]["tabs"].find(tab => tab.name === this.tab_name)["data"];
          this.tab = this.data.tabs[0];

          this.$nextTick(() => {
            let keys = Object.keys(this.row.technological_map);
            if (keys.length > 0) {
              keys.forEach(key => {
                // >> Temporary solution, shall to remove it later, the reason is old type of tech_map hash
                if (key === "specification") {
                  if (this.row["technological_map"][key].map(s => s.required).length > 0) {
                    this.row["technological_map"][key].map(s => delete s.required);
                  }
                  if (this.row["technological_map"][key].filter(el => el.name).length > 0) {
                    this.row["technological_map"][key] = this.rewriteKeysTempLogic(this.row["technological_map"][key]);
                  }
                } else {
                  this.row["technological_map"][key].forEach(key_el => {
                    if (key_el["properties"].filter(el => el.name).length > 0) {
                      key_el["properties"] = this.rewriteKeysTempLogic(key_el["properties"]);
                    }
                  });
                }
                // <<

                this.$store.commit("addEquipmentTechMap", {
                  grid_name: this.grid,
                  tech_map_key: key,
                  attr: this.row["technological_map"][key],
                });
              });
            }
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.loading = false;
          this.loading_blocks = false;
        });
    },

    rewriteKeysTempLogic(obj) {
      return obj.map(el => {
        return Object.keys(el).reduce((r, key) => {
          if (key === "name") {
            r["key"] = el[key];
          } else {
            r[key] = el[key];
          }
          return r;
        }, {});
      });
    },
  },
};
</script>

<style lang="scss"></style>
