import ExpensesRow from "./ExpensesRow";
import ExpensesBarChart from "./ExpensesBarChart";
import ExpensesPieChart from "./ExpensesPieChart";
import ExpensesTimelineChart from "./ExpensesTimeline";

export default {
  expensesRow: ExpensesRow,
  expensesBarChart: ExpensesBarChart,
  expensesPieChart: ExpensesPieChart,
  expensesTimelineChart: ExpensesTimelineChart,
};
