import { render, staticRenderFns } from "./ratesCsi.vue?vue&type=template&id=8774afe0&scoped=true&lang=pug&"
import script from "./ratesCsi.vue?vue&type=script&setup=true&lang=js&"
export * from "./ratesCsi.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ratesCsi.vue?vue&type=style&index=0&id=8774afe0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8774afe0",
  null
  
)

export default component.exports