import { render, staticRenderFns } from "./SelectionTabs.vue?vue&type=template&id=47d8377d&scoped=true&lang=pug&"
import script from "./SelectionTabs.vue?vue&type=script&setup=true&lang=js&"
export * from "./SelectionTabs.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./SelectionTabs.vue?vue&type=style&index=0&id=47d8377d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d8377d",
  null
  
)

export default component.exports