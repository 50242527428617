<template lang="pug">
.entity-view-pill-list
  .entity-view-pill-list__pill(v-for="item in items") {{ item.title }}
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.entity-view-pill-list {
  &__pill {
    $space-between: 10px;
    $horizontal-padding: 20px;
    $vertical-padding: 9px;
    display: inline-block;
    margin-right: $space-between;
    margin-bottom: $space-between;
    padding-top: $vertical-padding;
    padding-bottom: $vertical-padding;
    padding-left: $horizontal-padding;
    padding-right: $horizontal-padding;

    background-color: var(--simple-table-tr-background);
    color: var(--simple-table-tr-color);
    border-radius: 8px;
  }
}
</style>
