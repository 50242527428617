import dashboardNavbarIssues from "./pages/dashboards/navbar/issues";
import dashboardNavbarSettings from "./pages/dashboards/navbar/settings";
import dashboardFiltersProjects from "./pages/dashboards/filters/projects";

import issuesActionsIssues from "./pages/issues/actions/issues";
import issuesTabsIssues from "./pages/issues/tabs/issues";
import issuesButtonsAddIssue from "./pages/issues/buttons/add";
import issuesGridCheckboxes from "./pages/issues/grid/checkboxes";

import addIssueFormsNew from "./pages/add_issue/forms/new";

import addServiceFormsNew from "./pages/add_service/forms/new";

import editUserFormsNew from "./pages/edit_user/forms/edit";

import pprEquipmentFormsShow from "./pages/show_ppr_equipment/forms/show";

import pprCalendarFiltersAll from "./pages/ppr_calendar/filters/all";

import indexPageActionsDropdown from "./pages/shared/index_page/actions/dropdown";
import indexPageAddButton from "./pages/shared/index_page/buttons/add";
import indexPageExportButton from "./pages/shared/index_page/buttons/export";
import indexPageImportButton from "./pages/shared/index_page/buttons/import";

let index_page_attr = {
  actions: {
    dropdown: indexPageActionsDropdown,
  },
  buttons: {
    add: indexPageAddButton,
    export: indexPageExportButton,
  },
};

export default {
  dashboards: {
    navbar: {
      issues: dashboardNavbarIssues,
      settings: dashboardNavbarSettings,
    },
    filters: {
      projects: dashboardFiltersProjects,
    },
  },

  issues: {
    actions: {
      issues: issuesActionsIssues,
    },
    grid: {
      checkboxes: issuesGridCheckboxes,
    },
    tabs: {
      issues: issuesTabsIssues,
    },
    buttons: {
      add: issuesButtonsAddIssue,
    },
  },

  add_issue: {
    forms: {
      new: addIssueFormsNew,
    },
  },

  add_service: {
    forms: {
      new: addServiceFormsNew,
    },
  },

  edit_user: {
    forms: {
      edit: editUserFormsNew,
    },
  },

  show_ppr_equipment: {
    forms: {
      show: pprEquipmentFormsShow,
    },
  },

  ppr_calendar: {
    filters: {
      all: pprCalendarFiltersAll,
    },
  },
  ppr_group_systems: Object.assign(index_page_attr),
  ppr_systems: Object.assign(index_page_attr),
  ppr_works: Object.assign(index_page_attr),
  ppr_equipments: Object.assign(index_page_attr),
  facilities: Object.assign(index_page_attr),
  companies: Object.assign(index_page_attr),
  buildings: Object.assign(index_page_attr),
  floors: Object.assign(index_page_attr),
  room_types: Object.assign(index_page_attr),
  rooms: Object.assign(index_page_attr),
  services: Object.assign(index_page_attr),
  work_categories: Object.assign(index_page_attr),

  users: {
    actions: {
      dropdown: indexPageActionsDropdown,
    },
    buttons: {
      add: indexPageAddButton,
      export: indexPageExportButton,
      import: indexPageImportButton,
    },
  },
};
