<template lang="pug">
.controls
  .controls__tabs-control(v-if="getTab('preset')")
    q-btn(
      flat,
      rounded,
      unelevated,
      no-caps,
      :label="issueCenterLocales.day",
      @click="changeMode('timeline')",
      :class="{ 'controls__tabs-control--selected': mode === 'timeline' }"
    )
    q-btn(
      flat,
      rounded,
      unelevated,
      no-caps,
      :label="issueCenterLocales.week",
      @click="changeMode('dateline')",
      :class="{ 'controls__tabs-control--selected': mode === 'dateline' }"
    )
    q-btn(
      flat,
      rounded,
      unelevated,
      no-caps,
      :label="issueCenterLocales.month",
      @click="changeMode('calendar')",
      :class="{ 'controls__tabs-control--selected': mode === 'calendar' }"
    )

  .controls__time-control(v-if="getTab('preset')")
    .controls__time-control__left-arrow
      q-btn(icon="chevron_left", round, flat, unelevated, size="14px", padding="xs", @click="changeTime('-')")
    .controls__time-control__label {{ getTimeControlLabel() }}
    .controls__time-control__right-arrow
      q-btn(icon="chevron_right", round, flat, unelevated, size="14px", padding="xs", @click="changeTime('+')")

  .controls__buttons(v-if="getTab('custom')")
    q-btn.controls__buttons__add-column(
      icon="add",
      @click="createNewColumn",
      :label="issueCenterLocales.add_column",
      unelevated,
      no-caps,
      rounded
    )

  .controls__actions-control
    q-btn.controls__actions-control__map-control(unelevated, no-caps, @click="navigateToMap", v-if="isMapButtonShown")
      inline-svg(:src="require('../../../assets/icons/navbar/nav-map.svg')")

    q-btn-dropdown.controls__actions-control__issue-params(
      unelevated,
      outline,
      dropdown-icon="expand_more",
      :label="issueCenterLocales.display_issues",
      no-caps
    )
      q-list.issue-center__controls__actions-control__issue-params__list(v-if="displayRules.length")
        q-item(v-for="rule in displayRules", :key="rule.name")
          q-checkbox(:label="rule.label", :value="rule.value", @input="val => changeDisplayRules(rule.name, val)")

    q-btn.controls__actions-control__create-issue(unelevated, no-caps, @click="openCreate", v-if="mayCreateIssue")
      template(slot="default")
        inline-svg.grid-btn-icon-left(:src="require(`../../../assets/icons/header/add.svg`)")
      template(slot="default")
        span {{ issueCenterLocales.create_issue }}
</template>

<script setup>
import { onMounted, ref } from "vue";
import backend from "@/api";
import { issueCenterLocales } from "@/services/useLocales";

import { handleError } from "@/services/handleErrors";
import { useRouter } from "@/routing/router";
import { currentLocale } from "@/services/useLocales";

import { format } from "date-fns";

import i18n from "@/plugins/vue-i18n";

const router = useRouter();

const emit = defineEmits(["changeHours", "changeDisplayRules", "openCreate", "createNewColumn"]);

const props = defineProps({
  /* Дата первого часа, информация по которому будет запрошена с бэка */
  firstHour: { type: Date, default: undefined, required: false },
  /* Дата последнего часа, информация по которому будет запрошена с бэка */
  lastHour: { type: Date, default: undefined, required: false },
  /* Дата первого дня, информация по которому будет запрошена с бэка */
  firstDate: { type: Date, default: undefined, required: true },
  /* Дата последнего дня, информация по которому будет запрошена с бэка */
  lastDate: { type: Date, default: undefined, required: true },
  /* Начальная дата календаря */
  initialCalendarDate: { type: Date, default: undefined, required: true },
  /* Имя таба, который отображается в данный момент */
  tab: { type: String, default: undefined, required: false },
  /* Тип переключения даты (по часам, по дням, по месяцам) */
  mode: { type: String, default: undefined, required: false },
  /* Правила показа полей в заявке - массив полей. Пример ['facility_id' ...] */
  displayRules: { type: Array, default: () => [], required: true },
  /* Можно ли показывать кнопку создания заявки */
  mayCreateIssue: { type: Boolean, default: false, required: false },
});

const isMapButtonShown = ref(false);

const getHoursStringWithZeroes = hours => {
  return hours < 10 ? `0${hours}:00` : `${hours}:00`;
};

const changeTime = val => {
  if (props.mode === "timeline") emit("changeHours", val);
  else if (props.mode === "dateline") emit("changeDates", val);
  else if (props.mode === "calendar") emit("changeCalendarDates", val);
};

const changeDisplayRules = (name, val) => {
  emit("changeDisplayRules", { name: name, val: val });
};

const navigateToMap = () => {
  router.push({ name: "map" });
};

const openCreate = () => {
  emit("openCreate");
};

const createNewColumn = () => {
  emit("createNewColumn");
};

const changeMode = newMode => {
  emit("changeMode", newMode);
};

const getMonth = month => {
  return i18n["messages"][currentLocale.value]["date"]["months"][month];
};

const getTimeControlLabel = () => {
  if (props.mode === "timeline")
    return `${format(props.firstHour, "dd.MM.yyyy, HH:mm")} - ${format(props.lastHour, "dd.MM.yyyy, HH:mm")}`;
  else if (props.mode === "dateline")
    return `${format(props.firstDate, "dd.MM.yyyy")} - ${format(props.lastDate, "dd.MM.yyyy")}`;
  else if (props.mode === "calendar")
    return `${getMonth(props.initialCalendarDate.getMonth())}, ${format(props.initialCalendarDate, "yyyy")}`;
};

const getTab = name => {
  if (props.tab === name) return true;
};

onMounted(async () => {
  try {
    const resp = await backend.index("api/v3/situational_center/issues/show_map");

    isMapButtonShown.value = resp.data;
  } catch (e) {
    await handleError(e);
  }
});
</script>

<style lang="scss">
.issue-center {
  &__controls__actions-control {
    &__issue-params {
      &__list {
        padding: 10px 0;

        .q-item {
          padding-top: 0;
          padding-bottom: 0;
        }

        .q-checkbox__inner {
          &--falsy {
            .q-checkbox__bg {
              color: var(--header-title-color);
            }
          }

          &--truthy {
            .q-checkbox__bg {
              color: #3e62ff;
            }
          }
        }

        .q-checkbox__label {
          color: var(--header-title-color);
        }
      }
    }
  }

  .controls {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__tabs-control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .q-btn {
        background-color: var(--index-grid-table-tr-background);
        color: var(--header-title-color);
        padding: 0px 15px;
      }

      &--selected.q-btn {
        background: #3e62ff;
        color: white;
      }
    }

    &__time-control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      color: var(--header-title-color);

      &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      &__left-arrow .q-btn,
      &__right-arrow .q-btn {
        background-color: var(--index-grid-table-tr-background);
        color: var(--header-title-color);
      }
    }

    &__buttons {
      &__add-column {
        width: fit-content;
        padding: 0 10px;
        color: #3e62ff;

        .q-icon {
          background: #3e62ff;
          border-radius: 50%;
          color: white;
        }
      }
    }

    &__actions-control {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      &__map-control {
        background: #3e62ff;
        padding: 8px 10px !important;
        border-radius: 8px;

        .q-btn__content {
          height: 20px;
        }

        svg {
          height: 20px;
        }
      }

      button {
        border-radius: 8px;
        height: 36px;
      }

      &__issue-params {
        .q-btn__wrapper {
          color: #3e62ff;
          padding: 0 10px 0 20px !important;

          &:before {
            border: 1px solid #3e62ff;
            color: #3e62ff;
          }
        }

        .q-btn-dropdown__arrow {
          font-size: 18px !important;
        }
      }

      &__create-issue {
        .q-btn__wrapper {
          color: #fff;
          padding: 0 20px !important;

          &:before {
            background: #3e62ff;
            color: #3e62ff;
          }
        }
      }
    }
  }
}
</style>
