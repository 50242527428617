<template lang="pug">
div
  q-card-section.checklist-logs
    .checklist-logs-title
      span {{ data["checklist_title"] }}

    .checklist-logs-title
      span {{ data["building_title"] }}

    .checklist-logs-table
      table(cellspacing="0", cellpadding="0")
        tr(v-for="row in default_rows")
          td.title-row
            span {{ row["title"] }}
          td.user-row
            span
          td.date-row
            span {{ data[row["key"]] }}
          td.value-row
            span

        tr(v-for="row in data['form_params']")
          td.title-row
            span {{ row["title"] }}
          td.user-row
            span {{ row["user"] }}
          td.date-row
            span {{ row["created_at"] }}
          td.value-row
            table
              tr.log-card-param(v-for="param in row['values']")
                td.label
                  span {{ param["label"] }}
                td.value
                  span {{ param["value"] }}

    .checklist-logs-title
      span {{ data["result_time"] }}
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      data: this.parentData.data,
      default_rows: [
        {
          title: "Открыт",
          key: "started_at",
        },
        {
          title: "Завершен",
          key: "finished_at",
        },
      ],
    };
  },
};
</script>
