<template lang="pug">
q-expansion-item.expansion-list__item(
  :class="`expansion-list__item--level-${level}`",
  :group="`level-${level}`",
  expand-icon="none",
  v-model="isActive",
  @input="toggleExpansionItem"
)
  template(v-slot:header)
    .expansion-list__item__header__number
      b {{ header.fields[0].label }}
      span {{ header.fields[0].value }}
    .expansion-list__item__header
      .expansion-list__item__header__item(
        v-for="headerItem in header.fields",
        :key="`${headerItem.label} ${headerItem.value}`",
        v-if="!isNumber(headerItem)"
      )
        div
          span {{ headerItem.label }}
          b(v-if="headerItem.type !== 'attachments'") {{ getFieldValue(headerItem) }}
          b(v-else-if="headerItem.value === []") -
          b(v-else)
            a.field-value-attachment(
              v-for="attachment in headerItem.value",
              :key="`${attachment.file_name} ${attachment.file_size}`",
              target="_blank",
              :href="attachment.url"
            ) {{ attachment.file_name }}
      .expansion-list__item__header__edit-icons(v-if="icons")
        q-btn.expansion-list__item__header__edit-icon(
          v-for="field in icons.fields",
          :key="`${field.label} ${field.value}`",
          :id="field.id",
          :icon="iconName(field.params.icon)",
          flat,
          round,
          size="md",
          dense,
          @click.stop="openEditForm(field)"
        )
      q-icon.expansion-list__item__header__expand-icon(:name="expandIcon")
  q-card.expansion-list__item__content(v-if="hasContent")
    .row.align-items-center.justify-between(v-if="hasLeftContent")
      .col-12
        .row.expansion-list__item__content__rows
          .col-3.no-padding(
            v-for="field in data.fields",
            :key="`${field.label} ${field.value}`",
            :class="canShowField(field) ? '' : 'hidden'"
          )
            .q-pr-md
              template(v-if="isFloorPlanVisible(field)")
                .expansion-list__item__content__field-lable
                  span {{ field.label }}
                .expansion-list__item__content__plan-preview-image(
                  :style="getExpansionItemImageStyle(field)",
                  @click="triggerIsFloorPlanShown",
                  v-if="field.value"
                )
                img.broken-img(:src="getImageSrc(field.value)", v-else)
                q-dialog(v-model="isFloorPlanShown")
                  q-card.expansion-list__item__content__plan
                    .row.justify-end
                      q-icon.expansion-list__item__content__plan__close-icon(
                        name="close",
                        @click="triggerIsFloorPlanShown"
                      )
                    floorPlan.expansion-list__item__content__plan__floor-plan(
                      :parentData="{ building_id: topParentId, item_id: element.id, data: [{ label: field.label, name: field.label }] }"
                    )
              template(v-else)
                b.expansion-list__item__content__field-lable {{ field.label }}
                .expansion-list__item__content__field-value(v-if="field.type === 'attachments'")
                  a.expansion-list__item__content__field-value-attachment(
                    v-for="attachment in field.value",
                    :key="`${attachment.file_name} ${attachment.file_size}`",
                    target="_blank",
                    :href="attachment.url"
                  ) {{ attachment.file_name }}
                .expansion-list__item__content__field-value(
                  :class="getUnderlineClass(field)",
                  v-else-if="field.type === 'open_form'",
                  @click.stop="openEditForm(field)"
                ) {{ getComponentFieldValue(field) }}
                .expansion-list__item__content__field-value(v-else-if="field.type === 'list'")
                  .expansion-list__item__content__field-value__list-item(v-for="listItem in field.value") {{ listItem }}
                .expansion-list__item__content__field-value(v-else) {{ getFieldValue(field) }}
    template(v-else)
      .row.expansion-list__item__content__rows
        .col-3.q-pa-sm.no-padding(
          v-for="field in data.fields",
          :key="`${field.label} ${field.value}`",
          :class="canShowField(field) ? '' : 'hidden'"
        )
          .q-pr-md
            template(v-if="isFloorPlanVisible(field)")
              .expansion-list__item__content__field-lable
                span {{ field.label }}
              .expansion-list__item__content__plan-preview-image(
                :style="getExpansionItemImageStyle(field)",
                @click="triggerIsFloorPlanShown",
                v-if="field.value"
              )
              img.broken-img(:src="getImageSrc(field.value)", v-else)
              q-dialog(v-model="isFloorPlanShown")
                q-card.expansion-list__item__content__plan
                  .row.justify-end
                    q-icon.expansion-list__item__content__plan__close-icon(
                      name="close",
                      @click="triggerIsFloorPlanShown"
                    )
                  floorPlan.expansion-list__item__content__plan__floor-plan(
                    :parentData="{ building_id: topParentId, item_id: element.id, data: [{ label: field.label, name: field.label }] }"
                  )
            template(v-else)
              b.expansion-list__item__content__field-lable {{ field.label }}
              .expansion-list__item__content__field-value(v-if="field.type === 'attachments'")
                a.expansion-list__item__content__field-value-attachment(
                  v-for="attachment in field.value",
                  :key="`${attachment.file_name} ${attachment.file_size}`",
                  target="_blank",
                  :href="attachment.url"
                ) {{ attachment.file_name }}
              .expansion-list__item__content__field-value(
                :class="getUnderlineClass(field)",
                v-else-if="field.type === 'open_form'",
                @click.stop="openEditForm(field)"
              ) {{ getComponentFieldValue(field) }}
              .expansion-list__item__content__field-value(v-else-if="field.type === 'list'")
                .expansion-list__item__content__field-value__list-item(v-for="listItem in field.value") {{ listItem }}
              .expansion-list__item__content__field-value(v-else) {{ getFieldValue(field) }}
  .expansion-list__item__additional-content(v-if="hasAdditionalContent")
    slot(name="additional-content")
  .expansion-wrapper(v-if="needTabs()")
    q-tabs(v-model="currentTab", dense, align="justify", narrow-indicator)
      q-tab(v-for="tab in items", :key="tab.label", :name="tab.label", :label="tab.label")
    q-tab-panels(v-model="currentTab", animated)
      q-tab-panel(v-for="tab in items", :key="tab.label", :name="tab.label")
        template(v-for="(elem, i) in tab.items")
          expansion-list-item(
            :element="elem",
            :level="level + 1",
            :horizontalFilters="horizontalFilters",
            :ref="el => (expansionItemRefs[i] = el)",
            :showEditButton="showEditButton",
            :hideEmptyValues="props.hideEmptyValues",
            @openEditForm="emitOpenEditForm"
          )
  .expansion-wrapper(v-else)
    template(v-for="(item, i) in items")
      expansion-list-item(
        :element="item",
        :level="level + 1",
        :horizontalFilters="horizontalFilters",
        :ref="el => (expansionItemRefs[i] = el)",
        :showEditButton="showEditButton",
        :hideEmptyValues="props.hideEmptyValues",
        @openEditForm="emitOpenEditForm"
      )
</template>

<script setup>
import { ref, computed, useSlots, inject } from "vue";
import { handleError } from "@/services/handleErrors";
import { backend } from "@/api";
import { handleDateRow } from "@/services/reformatStrings";

import FloorPlan from "@/components/shared/forms/fields/plan_field";
import ExpansionListItem from "@/components/shared/general_components/expansionList/ExpansionListItem";

const props = defineProps({
  element: { type: Object, default: () => {} },
  level: { type: Number, default: 1 },
  horizontalFilters: { type: Object, default: () => {} },
  showEditButton: { type: Boolean, default: false },
  hideEmptyValues: { type: Boolean, default: false },
});

const emit = defineEmits();
const slots = useSlots();

const blankFieldValue = "-";

const items = ref([]);
const data = ref([]);
const currentTab = ref("");

const expansionItemRefs = ref([]);

const firstTimeToggle = ref(false);
const isActive = ref(false);
const isFloorPlanShown = ref(false);
const topParentId = inject("topParentId");

const expandIcon = computed(() => (isActive.value ? "expand_less" : "expand_more"));

const header = computed(() => props.element.blocks.find(item => item.type === "header"));
const icons = computed(() => props.element.blocks.find(item => item.type === "icon"));

const hasContent = computed(() => data?.value?.length !== 0);
const hasLeftContent = computed(() => !!slots["left-content"]);
const hasAdditionalContent = computed(() => !!slots["additional-content"]);

const needTabs = () => {
  return items.value && currentTab.value && items.value.length > 1;
};

const toggleExpansionItem = async val => {
  if (!firstTimeToggle.value && val === true) {
    data.value = await loadContent();
    items.value = await loadChildren();
    firstTimeToggle.value = true;
  }
  if (val === "toggleOnRoot") {
    expansionItemRefs.value.forEach(el => {
      if (el) el.toggleExpansionItem("toggleOnRoot");
    });
    firstTimeToggle.value = false;
    isActive.value = false;
    return;
  }
  if (val === false) {
    expansionItemRefs.value.forEach(el => {
      if (el) el.toggleClose();
    });
  }
  isActive.value = val;
};

const toggleClose = () => {
  isActive.value = false;
  expansionItemRefs.value.forEach(el => {
    el.toggleClose();
  });
};

const loadContent = async () => {
  try {
    const dataPath = `/api/v3/tech_passport/objects/${props.element.id}?template_id=${
      props.element.template_id
    }&filters=${JSON.stringify(props.horizontalFilters)}`;

    const response = await backend.index(dataPath);
    const data = response.data.blocks.find(item => item.type === "description");

    if (response.length !== 0) {
      emit("data-received");
      return data;
    } else {
      return [];
    }
  } catch (error) {
    await handleError(error);
  }
};

const loadChildren = async () => {
  try {
    const itemsPath = `/api/v3/tech_passport/objects?parent_id=${props.element.id}&parent_template_id=${
      props.element.template_id
    }&filters=${JSON.stringify(props.horizontalFilters)}`;

    const response = (await backend.index(itemsPath)).data;

    if (response.length !== 0) {
      emit("child-items-received");
      if (response.length === 1) {
        return response[0].items;
      }
      if (response.length > 1 && response.some(item => item.items.length > 0)) {
        currentTab.value = response[0].type;
      }
      return response;
    }
  } catch (error) {
    await handleError(error);
  }
};

const triggerIsFloorPlanShown = () => {
  isFloorPlanShown.value = !isFloorPlanShown.value;
};

const getImageSrc = value => {
  if (value) {
    return process.env.VUE_APP_BACKEND_URL + value;
  } else {
    return require("/src/assets/icons/object_pass/image_not_found.svg");
  }
};

const getExpansionItemImageStyle = field => {
  return "background: url(" + getImageSrc(field.value) + ");";
};

const isFloorPlanVisible = field => {
  return field?.type && field.type === "floor_plan";
};

const isNumber = field => {
  return field.label === `№`;
};

const getFieldValue = field => {
  if (field.type === "date") {
    return handleDateRow(field, "value");
  }
  if (field.value === 0 && field.measure_unit) {
    return `${field.value} ${field.measure_unit}`;
  } else if (field.value === 0 && !field.measure_unit) {
    return `${field.value}`;
  }
  if (field.measure_unit) {
    return field.value ? `${field.value} ${field.measure_unit}` : blankFieldValue;
  }
  return field.value || blankFieldValue;
};

const getComponentFieldValue = field => {
  return field.value ? field.value : blankFieldValue;
};

const getUnderlineClass = field => {
  return getComponentFieldValue(field) === blankFieldValue ? "" : "underline";
};

const canShowField = field => {
  if (!props.hideEmptyValues || isFloorPlanVisible(field) || field.type === "attachments") {
    return true;
  }
  if (getFieldValue(field) === blankFieldValue) {
    return false;
  }
  if (field.type === "open_form" && getComponentFieldValue(field) === blankFieldValue) {
    return false;
  }
  return true;
};

const openEditForm = field => {
  if (field.type === "open_form") {
    var params = {
      id: field.params.id,
      grid: field.params.form.replace("/", "_"),
      path: field.params.form,
      report_class: field.params.form.replace("/", "_").replace(/s$/, ""),
    };
    emit("openEditForm", params);
  }
};

const emitOpenEditForm = val => {
  emit("openEditForm", val);
};

const iconName = code => {
  if (code === "edit") {
    return "o_edit";
  } else if (code === "tech_map") {
    return "o_construction";
  }
  return "";
};

defineExpose({ toggleExpansionItem, toggleClose });
</script>

<style lang="scss">
.expansion-list {
  &__item {
    padding-top: 20px;
    .broken-img {
      margin-left: -3px;
    }
    &__header,
    &__content,
    &__additional-content,
    &__content__plan {
      border-radius: 20px !important;
      color: var(--expansion-list-text-color);
      background: var(--expansion-list-background);
    }
    &__content,
    &__additional-content {
      margin-top: -30px;
      padding: 46px 16px 16px 16px;
      border: 2px solid var(--expansion-list-background);
      background-color: var(--expansion-list-description-background);
    }
    &__header {
      width: 90%;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(4, 1fr);
      padding: 8px 16px;
      padding-right: 85px;
      & + .q-item__section--side {
        display: none;
      }
      &__expand-icon {
        font-size: 25px;
        position: absolute;
        right: 15px;
      }
      &__edit-icons {
        right: 45px;
        font-size: 20px;
        top: 5px;
        position: absolute;
      }
      &__item {
        b {
          display: block;
        }
        a {
          color: #3d4c63;
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }

      &__number {
        color: var(--expansion-list-text-color);
        text-align: center;
        padding: 8px 24px;
        span {
          margin-left: 5px;
        }
      }

      &__image {
        color: var(--expansion-list-text-color);
        text-align: center;
        padding: 8px 0 0 20px;
        span {
          margin-left: 5px;
        }

        img {
          height: 50px;
        }
      }
    }
    &__content {
      &__plan {
        padding: 15px;
        width: 80%;
        &-preview-image {
          width: 50%;
          height: 50px !important;
          background-size: contain !important;
          background-position: center center !important;
          background-repeat: no-repeat !important;
          display: block;
          border: 1px solid var(--expansion-list-accent-color);
          border-radius: 10px;
          cursor: pointer;
        }
        &__close-icon {
          font-size: 20px !important;
          position: relative;
          z-index: 1;
          cursor: pointer;
        }
        &__floor-plan {
          margin-top: -23px;
          .floor-plan-thumb-buttons {
            display: none !important;
          }
        }
      }
      &__rows {
        row-gap: 16px;

        &.hidden {
          display: none;
        }
      }
      &__field-lable {
        color: var(--expansion-list-accent-color);
        font-weight: 400;
      }
      &__field-value {
        a {
          color: #3d4c63;
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
        &.underline {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    // стиль применяется для класса level-#{$i}, где i номер уровня экспансии. Начиная от 2 и до 4
    @for $i from 2 through 4 {
      .expansion-list__item--level-#{$i} {
        padding-left: 30px;
      }
    }
  }
  .not-accent {
    color: var(--expansion-list-not-accent-color);
  }
  .q-expansion-item__container {
    & > div:first-child {
      background: var(--expansion-list-background);
      padding: 0;
      border-radius: 20px;
      z-index: 1;
    }
  }
  .no-padding {
    padding: 0 !important;
  }

  .q-expansion-item {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 40px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid #5b7aff;
      background-color: var(--expansion-list-background);
    }

    &.q-expansion-item--expanded {
      &::after {
        background-color: #5b7aff;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 5px;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(#5b7aff 100%, rgba(255, 255, 255, 0) 0%);
      background-position-x: left;
      background-position-y: bottom 25px;
      background-size: 2px 15px;
      background-repeat: repeat-y;
    }

    &:first-child {
      &::before {
        top: 40px;
      }
    }

    &:last-child {
      &::before {
        height: 40px;
      }
    }

    &.expansion-list__item--level-1,
    &:only-child {
      &::after {
        display: none;
      }
      &::before {
        display: none;
      }
    }
  }

  .q-expansion-item--expanded ~ .q-expansion-item {
    &::before {
      background-image: linear-gradient(#5b7aff 50%, rgba(255, 255, 255, 0) 0%);
    }
  }
}
</style>
