import { render, staticRenderFns } from "./sensor_indicators.vue?vue&type=template&id=4b57a600&scoped=true&lang=pug&"
import script from "./sensor_indicators.vue?vue&type=script&setup=true&lang=js&"
export * from "./sensor_indicators.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./sensor_indicators.vue?vue&type=style&index=0&id=4b57a600&prod&lang=scss&"
import style1 from "./sensor_indicators.vue?vue&type=style&index=1&id=4b57a600&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b57a600",
  null
  
)

export default component.exports