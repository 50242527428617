import AbstractEntity from "./AbstractEntity";

class Worker extends AbstractEntity {
  getType() {
    return "worker";
  }

  getFullName() {
    return this.data.full_name;
  }

  getPhone() {
    return this.data.phone;
  }

  getEmail() {
    return this.data.email;
  }

  getIssuesNumber() {
    return this.data.issues.length;
  }

  getServiceList() {
    return this.data.services.map(s => s.title);
  }

  getWorkGroupList() {
    return this.data.work_groups.map(g => g.title);
  }
}

export default Worker;
