<template lang="pug">
.expenses__row-item
  .expenses__row-item__header
    .expenses__row-item__header-background(:style="`background-color: ${item.icon_background}`")
      img(:src="getSvg(item.icon)")
    .expenses__row-item__header-title {{ item.label }}
  .expenses__row-item__content
    .expenses__row-item__content-item(v-for="el in item.data", :key="el.label + el.value")
      .expenses__row-item__content-data {{ el.value }}
      .expenses__row-item__content-label {{ el.label }}
</template>

<script setup>
import { getOriginPath } from "@/services/getOriginPath";

const props = defineProps({
  item: { type: Object, required: true },
});

const getSvg = path => {
  let main = getOriginPath();
  if (main.slice(-1) === "/") {
    main = main.slice(0, -1);
  }
  return main + path;
};
</script>

<style lang="scss" scoped>
.expenses__row-item {
  padding: 12px;

  background-color: var(--expenses-content-background-color);
  border-radius: 24px;

  display: flex;
  flex-grow: 1;
  align-items: center;

  &__header {
    display: flex;
    align-items: center;

    &-background {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      border-radius: 16px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    &-title {
      color: #5b7aff;
      font-weight: 600;
      font-size: 16px;
      margin-left: 12px;
    }
  }

  &__content {
    display: flex;
    margin-left: 16px;

    &-item {
      display: flex;
      flex-direction: column;
      position: relative;
      height: fit-content;

      &:not(:last-child) {
        margin-right: 18px;
        &::after {
          content: "";
          display: block;
          width: 2px;
          height: 100%;
          background-color: var(--expenses-search-button-border-color);
          position: absolute;
          right: -8px;
        }
      }
    }

    &-data {
      color: var(--expenses-main-text-color);
      font-weight: 500;
      font-size: 15px;
    }
    &-label {
      color: var(--expenses-row-subtext-color);
      font-weight: 600;
      opacity: 0.72;
      font-size: 13px;
    }
  }
}
</style>
