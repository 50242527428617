<template lang="pug">
.expenses-dashboard-lists
  .expenses-dashboard-loading(v-if="loading")
    q-spinner(color="primary", size="3em")
  .expenses-dashboard-list(v-if="tabs && !loading")
    q-tabs(v-model="currentTab", align="left")
      q-tab(v-for="tab in tabs", :name="tab.name", :label="tab.label", :key="tab.name")

    q-tab-panels(v-model="currentTab", :keep-alive="true")
      q-tab-panel(v-for="tab in tabs", :name="tab.name", :key="tab.name")
        expansion-tab(:tabData="tab", :grid="grid")
</template>

<script setup>
import { ref, computed, onBeforeMount, onBeforeUnmount } from "vue";
import { backend } from "@/api";
import { useEmitter } from "@/services/useEmitter";
import { handleError } from "@/services/handleErrors";

import ExpansionTab from "./ExpansionTab.vue";

const props = defineProps({
  grid: {
    type: String,
    required: true,
  },
});

const emitter = useEmitter();

const tabs = ref();
const currentTab = ref();
const loading = ref(true);

const getTabs = async () => {
  try {
    loading.value = true;
    const { data } = await backend.index("/api/v3/expenses_dashboard/tabs", {});
    tabs.value = data;
    currentTab.value = data.find(tab => tab.default).name;
  } catch (error) {
    handleError(error);
  } finally {
    loading.value = false;
  }
};

onBeforeMount(async () => {
  await getTabs();

  // emitter.on("refresh-expenses-dashboard-data", getTabs);
});

onBeforeUnmount(() => {
  // emitter.off("refresh-expenses-dashboard-data");
});
</script>

<style lang="scss">
.expenses-dashboard-list {
  margin-top: 20px;
}

.expenses-dashboard-loading {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
