<template lang="pug">
q-dialog#activity-create-form(
  :value="isCreateActivityFormShownLocal",
  seamless,
  position="right",
  @hide="hideActivityDialog"
)
  q-card.workflow-form__card
    .spinner-container.form-spinner-container(v-if="loading")
      q-spinner(color="primary", size="3em")
    template(v-else)
      .row
        .col-10
          span.text-h6.text-bold.workflow-form__card__title {{ getFormCardTitle }}
        .col-2.workflow-form__card__close-icon
          q-btn(flat, round, icon="close", @click="hideActivityDialog")
      .workflow-form__card__scroll
        .row
          .col-12
            .workflow-form__field(v-for="(field, i) in form?.fields", :key="field.id")
              component(
                :is="getFieldComponent(field.type)",
                :field="field",
                :object="activity?.id ? activity : {}",
                :ref="el => { fieldsRefs[i] = el; }",
                :required="field.require",
                @fieldChange="onFieldChange",
                @disableField="disableField",
                @enableField="enableField"
              )

            WorkflowActivitiesValidationFields(
              :activity="activity",
              :workflowId="workflowId",
              @activityValidationChanged="onActivityValidationChanged"
            )

      .row
        .col-6.workflow-form__card__button
          q-btn.workflow-form__card__button--close(flat, no-caps, @click="hideActivityDialog") {{ workflowConstructorLocales.cancel }}
        .col-6.workflow-form__card__button
          q-btn.workflow-form__card__button--accept(
            flat,
            no-caps,
            :disable="!!disabledFields.length",
            @click="updateActivity",
            v-if="activity?.id"
          ) {{ workflowConstructorLocales.save }}

          q-btn.workflow-form__card__button--accept(
            flat,
            no-caps,
            :disable="!!disabledFields.length",
            @click="createActivity",
            v-else
          ) {{ workflowConstructorLocales.save }}
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import WorkflowInputField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowInputField";
import WorkflowFloatField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowFloatField";
import WorkflowSelectField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowSelectField";
import WorkflowCheckboxField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowCheckboxField";
import WorkflowActivitiesValidationFields from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowActivitiesValidationFields";
import ColorPicker from "@/components/shared/general_components/ColorPicker";

import { handleError } from "@/services/handleErrors";
import backend from "@/api";
import { workflowConstructorLocales } from "@/services/useLocales";

const props = defineProps({
  activity: { type: Object, default: () => {} },
  isCreateActivityFormShown: { type: Boolean, default: false },
  workflowId: { type: Number, default: null },
  statusId: { type: Number, default: null },
});

const emit = defineEmits(["updateTransition", "hideDialog"]);

const form = ref();
const currentActivity = ref({ ...props.activity } || {});
const validation = ref([]);
const disabledFields = ref([]);

const fieldsRefs = ref([]);

const loading = ref(true);

const isCreateActivityFormShownLocal = computed(() => {
  return props.isCreateActivityFormShown && !!props.statusId;
});

const disableField = event => {
  if (!disabledFields.value.includes(event)) {
    disabledFields.value.push(event);
  }
};

const enableField = event => {
  const fieldIndex = disabledFields.value.findIndex(field => field === event);
  if (fieldIndex !== -1) {
    disabledFields.value.splice(fieldIndex, 1);
  }
};

const onFieldChange = event => {
  currentActivity.value[event.name] = event.value;
};

const getFieldComponent = type => {
  if (type === "string") {
    return WorkflowInputField;
  } else if (type === "float") {
    return WorkflowFloatField;
  } else if (type === "select") {
    return WorkflowSelectField;
  } else if (type === "boolean") {
    return WorkflowCheckboxField;
  }
};

const hideActivityDialog = () => {
  currentActivity.value = {};
  emit("hideDialog");
};

const createActivity = async () => {
  try {
    const response = await backend.create(`api/v3/statuses/${props.statusId}/activities`, null, {
      activity: { ...currentActivity.value, validations_attributes: validation.value },
    });
    emit("createActivity", response.data);
    emit("hideDialog");
  } catch (e) {
    await handleError(e);
  }
};

const updateActivity = async () => {
  try {
    const req = currentActivity.value;
    delete req.validations;

    const response = await backend.update("api/v3/activities", props.activity.id, {
      activity: { ...req, validations_attributes: validation.value },
    });
    emit("updateActivity", response.data);
    emit("hideDialog");
  } catch (e) {
    await handleError(e);
  }
};

const onActivityValidationChanged = val => {
  validation.value = val;
};

const getFields = async () => {
  try {
    form.value = (await backend.index(`api/v3/statuses/${props.statusId}/activities/form`)).data;
  } catch (e) {
    await handleError(e);
  }
};

const getFormCardTitle = computed(() => {
  if (props.activity?.id) return `${form.value?.form_header?.edit} №${props.activity?.id}`;
  else return `${form.value?.form_header?.add}`;
});

onMounted(async () => {
  await getFields();

  form.value.fields.forEach(el => {
    if (el.require && !currentActivity.value[el.name]) disableField(el.name);
  });

  loading.value = false;
});
</script>
