export default {
  light: {
    grid_border_color: "#E8E9EB",
    fore_color: "#B5BBC9",
  },

  dark: {
    grid_border_color: "#454545",
    fore_color: "#8A9099",
  },
};
