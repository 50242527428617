import Vue from "vue";

Vue.mixin({
  computed: {
    login_locales() {
      return this.locales.login[this.current_locale];
    },
  },

  methods: {
    reLoginRun(msg = undefined) {
      this.$q.notify({
        badgeStyle: "display: none",
        message: msg || this.notifies.session_has_over,
      });
      this.$store.commit("logOut");

      if (this.$route.path === "/login") {
        setTimeout(() => this.$emit("set-loading", false), 500);
      } else {
        this.$router.replace({ name: "Login" });
      }
    },

    reLogin(status, msg = undefined) {
      if (status && status === 401) {
        this.reLoginRun(msg);
      }
    },
  },
});
