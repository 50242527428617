<template lang="pug">
div
  .form-field-label
    span {{ label }}
    span.form-field-require-tag(v-if="softRequired()", :title="notifies.required_field")
      | {{ soft_required.sublabels.map(label => label).join(" ") }}
    span.form-field-require-tag(v-show="required", :title="notifies.required_field")
      | *

  div(:class="[currentFieldIsInvalid ? `${main_class} valid-error` : main_class]")
    q-input.float-field(
      filled,
      no-error-icon,
      v-model="currentField",
      @input="val => onInput(val)",
      type="number",
      :min="min_val",
      :max="max_val",
      :step="step_val",
      :readonly="readonly",
      :for="name",
      :label="placeholder ? placeholder : ''",
      :class="{ 'with-label': !!placeholder }"
    )
      q-icon.cancel-string-field(name="cancel", v-if="resetCurrentFieldActive", @click.stop="resetField()")

  .valid-error-message(v-if="currentFieldIsInvalid") {{ currentErrorMessage }}
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      method: this.parentData.method,
      method_limit: this.parentData.method_limit,
      grid: this.parentData.grid,
      value: this.parentData.data[0].value,
      label: this.parentData.data[0].label,
      placeholder: this.parentData.data[0].placeholder,
      soft_required: this.parentData.data[0].soft_required || {},
      name: this.parentData.data[0].name,
      depend_from: this.parentData.data[0].depend_from || {},
      req: this.parentData.data[0].require,
      watch: this.parentData.data[0].watch || {},
      valid_error_message: null,
      main_class: "form-field form-input",

      min_val: this.parentData.data[0].min_val || this.parentData.data[0].allow_below_zero ? null : 0,
      max_val: this.parentData.data[0].max_val || null,
      step_val: this.parentData.data[0].step_val || "any",

      readonly: this.parentData.data[0].readonly || false,
    };
  },

  computed: {
    required: {
      get() {
        return this.req;
      },
      set(value) {
        this.req = value;
      },
    },

    resetCurrentFieldActive() {
      return (this.currentField || this.currentField === 0) && !this.readonly;
    },

    currentField: {
      get() {
        let form_field = this.currentForm[this.name];
        if (form_field) {
          return form_field["field"];
        } else {
          return undefined;
        }
      },
      set(value) {
        let result = {};
        if (!["", undefined, null].includes(value)) {
          result["field"] = value;
          result["invalid"] = this.invalid(value);
        } else {
          result["invalid"] = this.invalid();
        }
        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });
        if (!result["invalid"]) {
          this.$store.commit("resetFormFieldValue", {
            grid_name: this.grid,
            field: "invalid_fields",
            value: this.name,
          });
        }
      },
    },

    fieldDependency() {
      return this.checkDependency();
    },

    // Errors messages we set from backend
    customErrorMessage() {
      const invalidFieldsErrors = this.currentForm.invalid_fields_errors;

      if (!invalidFieldsErrors) {
        return "";
      }

      return invalidFieldsErrors[this.name];
    },

    // Order of fields is important: valid_error_message must be first to preserve required errors
    currentErrorMessage() {
      return this.valid_error_message || this.customErrorMessage;
    },
  },

  watch: {
    fieldDependency(newVal, oldVal) {},
  },

  created() {
    this.watchSimpleFieldParents();
  },

  beforeMount() {
    this.$store.commit("createFormField", { grid_name: this.grid, field: this.name });
    this.setField(this.value);
  },

  methods: {
    softRequired() {
      return this.soft_required && this.soft_required.sublabels && this.soft_required.sublabels.length > 0;
    },

    setField(val) {
      this.currentField = val ? val.toString().replace(/[,]/g, ".") : val;
    },

    setRequire(val) {
      this.required = val;
      let result = {};
      result["field"] = this.currentField;
      result["invalid"] = this.invalid(this.currentField);
      this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });

      this.$store.commit("resetFormFieldValue", {
        grid_name: this.grid,
        field: "invalid_fields",
        value: this.name,
      });
    },

    onInput(val) {
      val = val.replace(/[,]/g, ".");
      this.$nextTick(() => {
        this.currentField = val;
      });
    },

    resetField() {
      this.currentField = "";
    },

    invalid(val = undefined) {
      let result;

      if (!["", undefined, null].includes(val)) {
        this.valid_error_message = null;
        result = false;
      }

      if (this.required) {
        if (!["", undefined, null].includes(val)) {
          this.valid_error_message = null;
          result = false;
        } else {
          this.valid_error_message = this.notifies.not_empty;
          result = true;
        }

        // } else if (this.max_val && this.max_val < val) {
        //   this.valid_error_message = this.notifies.value_greater_than_allowed
        //   return true
      } else {
        this.valid_error_message = null;
        result = false;
      }

      if (this.watch && this.watch["conditions"] && this.watch["conditions"].length > 0) {
        this.watch["conditions"].forEach(c => {
          if (!["", undefined, null].includes(val)) {
            result = this.checkFloatCondition(c, val, result);
          }
        });
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../assets/styles/forms/fields/string";
@import "../../../../assets/styles/forms/fields/float";

.valid-error-message {
  position: static;
}
</style>
