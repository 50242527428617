<template lang="pug">
div
  .login-title.login-reset-password-title.row.justify-center
    span {{ loginLocales["password_recovery_form.title"] }}

  q-form(@submit="onSubmit", @reset="onCancel")
    .recovery-form.login-field
      .login-field-label
        span {{ loginLocales["password_recovery_form.fields.login.label"] }}

      .login-field-input
        q-input(
          filled,
          :label-slot="false",
          ref="loginInput",
          v-model="login",
          lazy-rules,
          no-error-icon,
          for="login-input",
          :rules="[val => (val && val.length > 0) || loginLocales['fields_rules.not_empty'], val => checkLogin(val) || loginLocales['fields_rules.wrong_email_format']]"
        )

    .recovery-form.button-group.row.justify-center
      q-btn.login-submit(flat, no-caps, :label="loginLocales['password_recovery_form.buttons.submit']", type="submit")
      q-btn.login-cancel(flat, no-caps, :label="loginLocales['password_recovery_form.buttons.cancel']", type="reset")
</template>

<script setup>
import { ref } from "vue";
import { Notify } from "quasar";
import { useStore } from "@/store";
import { loginLocales } from "@/services/useLocales";
import backend from "@/api";

const emit = defineEmits(["setLoading", "passwordRecovery"]);
const store = useStore();

const login = ref(null);
const loginInput = ref();

const checkLogin = email => {
  const regex = /[^@\s]+@[^@\s]+/;
  return regex.test(String(email).toLowerCase());
};

const onCancel = () => {
  emit("passwordRecovery", false);
};

const sendOnRecovery = async params => {
  try {
    await backend.recovery_password(store.state.paths["reset_password"], params);

    emit("passwordRecovery", false);

    Notify.create({ message: loginLocales.value["password_recovery_form.notifies.sent"] });
  } catch (e) {
    console.log(e);
    Notify.create({ message: loginLocales.value["password_recovery_form.notifies.error"] });
  }
};

const onSubmit = async () => {
  if (loginInput.value) {
    loginInput.value.validate();

    if (!loginInput.value.hasError) {
      const params = {
        user: {
          email: login.value,
        },
      };

      await sendOnRecovery(params);
    }
  }
};
</script>

<style lang="scss"></style>
