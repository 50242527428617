<template lang="pug">
.bottom
  .label {{ `${map_locales["plan"]} (${(floor && floor.short_title) || ""})` }}
  .floor-plan
    floorMap(v-if="!loading && floor", :parentData="{ floor_id: floor.id, building_id: building.id, editable: false }")
</template>

<script>
import floorMap from "../../../shared/floorMap.vue";

export default {
  components: {
    floorMap,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      building: this.parentData.building,
      floors: this.parentData.floors,
      floor: this.parentData.floor,
      loading: true,
    };
  },

  watch: {
    parentData: function (newVal, oldVal) {
      // watch it
      this.floor = newVal.floor;
    },
  },

  created() {
    if (this.floor.id) {
      this.loading = false;
      return;
    }
    this.floor = this.floors[0];
    this.loading = false;
  },

  methods: {
    onClickFloor(floor) {
      if (floor) {
        let attr = {
          item: "floor",
          new_item: floor,
          id: "floor-plan",
        };
        this.activeBadge(attr);
        this.floor = floor;
      }
    },
  },
};
</script>

<style lang="scss">
.bottom {
  height: fit-content;
  /*width: 636px;*/

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    color: var(--dashboard-label-color);
  }

  .floor-plan {
    margin-top: 10px;
    position: relative;
    height: 375px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--dashboard-card-background);
    border-radius: 20px;

    img {
      object-fit: contain;
      max-height: 365px;
      width: 100%;
    }

    .floors::-webkit-scrollbar-track {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .floors {
      overflow-y: auto;
      position: absolute;
      top: 10px;
      width: fit-content;
      min-width: 50px;
      max-width: 150px;
      height: fit-content;
      max-height: 270px;
      background: #fff;
      border-radius: 15px;
      right: 15px;
      box-shadow: 0px 4px 15px rgb(0 0 0 / 16%);

      .floors-list {
        text-align: center !important;

        .floor-link {
          padding: 10px;

          .active-badge {
            background: #5b7aff;

            span {
              color: #ffffff !important;
            }
          }

          li {
            padding: 10px;
            cursor: pointer;
            align-items: center;
            user-select: none;
            list-style-type: none;
            border-radius: 10px;

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 16px;
              letter-spacing: 0.2px;

              white-space: normal;

              color: #000000;
            }
          }

          li:hover {
            background: #f1f1f1;
            border-radius: 10px;
            span {
              color: #000000;
            }
          }
        }
      }
    }
  }
}
</style>
