/**
 * Returns unit with right word for this number
 *
 * @param {Number} num      Number
 * @param {Object} cases    Word options { nom: 'час', gen: 'часа', plu: 'часов' }
 * @param {String} locale   Current locale: "ru" or "en"
 * @return {String}
 */
const unitsSpelling = (num, cases, locale) => {
  if (locale == "ru") {
    return russianUnitsSpelling(num, cases);
  } else {
    return englishUnitsSpelling(num, cases);
  }
};

/**
 * Returns unit with right word for number in enlish locale
 *
 * @param {Number} num      Number
 * @param {Object} cases    Word options { nom: 'day', gen: '', plu: 'days' }
 * @return {String}
 */
const englishUnitsSpelling = (num, cases) => {
  if (num == 1) {
    return cases.nom;
  } else {
    return cases.plu;
  }
};

/**
 * Returns unit with right word for number in russian locale
 *
 * @param {Number} num      Number
 * @param {Object} cases    Word options { nom: 'час', gen: 'часа', plu: 'часов' }
 * @return {String}
 */
const russianUnitsSpelling = (num, cases) => {
  num = Math.abs(num);

  var word = "";

  if (num.toString().indexOf(".") > -1) {
    return cases.gen;
  } else {
    const word =
      num % 10 == 1 && num % 100 != 11
        ? cases.nom
        : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
        ? cases.gen
        : cases.plu;

    return word;
  }
};

export { unitsSpelling };
