<template lang="pug">
q-dialog#transition-change-form(
  :value="isTransitionChangeFormShown",
  seamless,
  position="right",
  @hide="hideTransitionDialog"
)
  q-card.workflow-form__card
    .spinner-container.form-spinner-container(v-if="loading")
      q-spinner(color="primary", size="3em")
    .row
      .col-10
        span.text-h6.text-bold.workflow-form__card__title {{ getFormCardTitle }}
      .col-2.workflow-form__card__close-icon
        q-btn(flat, round, icon="close", @click="hideTransitionDialog")
    .workflow-form__card__scroll
      .row
        .col-12
          .workflow-form__field(v-for="(field, i) in form?.fields", :key="field.id")
            component(
              :is="getFieldComponent(field.type)",
              :field="field",
              :object="transition",
              :ref="el => { fieldsRefs[i] = el; }",
              @fieldChange="onFieldChange",
              @disableField="disableField",
              @enableField="enableField"
            )
          WorkflowValidationFields(
            :transition="transition",
            :workflowId="workflowId",
            @transitionValidationChanged="onTransitionValidationChanged"
          )
          .workflow-form__field
            ColorPicker(:color="color", @update:color="e => (color = e)", :label="workflowConstructorLocales.color")

    .row
      .col-6.workflow-form__card__button
        q-btn.workflow-form__card__button--close(flat, no-caps, @click="hideTransitionDialog") {{ workflowConstructorLocales.cancel }}
      .col-6.workflow-form__card__button
        q-btn.workflow-form__card__button--accept(
          flat,
          no-caps,
          :disable="!!disabledFields.length",
          @click="updateTransition"
        ) {{ workflowConstructorLocales.save }}
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import WorkflowInputField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowInputField";
import WorkflowFloatField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowFloatField";
import WorkflowSelectField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowSelectField";
import WorkflowCheckboxField from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowCheckboxField";
import WorkflowValidationFields from "@/components/view/workflows/workflowForms/workflowFormsFields/WorkflowTransitionValidationFields";
import ColorPicker from "@/components/shared/general_components/ColorPicker";

import { handleError } from "@/services/handleErrors";
import backend from "@/api";
import { workflowConstructorLocales } from "@/services/useLocales";

const props = defineProps({
  transition: { type: Object, default: () => {} },
  isTransitionChangeFormShown: { type: Boolean, default: false },
  workflowId: { type: Number, default: null },
});

const emit = defineEmits(["updateTransition", "hideDialog"]);

const form = ref();
const currentTransition = ref({});
const validation = ref([]);
const color = ref(props.transition?.template?.color || "#c181ff");
const disabledFields = ref([]);

const fieldsRefs = ref([]);

const loading = ref(true);

const disableField = event => {
  if (!disabledFields.value.includes(event)) {
    disabledFields.value.push(event);
  }
};

const enableField = event => {
  const fieldIndex = disabledFields.value.findIndex(field => field === event);
  if (fieldIndex !== -1) {
    disabledFields.value.splice(fieldIndex, 1);
  }
};

const onFieldChange = event => {
  currentTransition.value[event.name] = event.value;
};

const getFieldComponent = type => {
  if (type === "string") {
    return WorkflowInputField;
  } else if (type === "float") {
    return WorkflowFloatField;
  } else if (type === "select") {
    return WorkflowSelectField;
  } else if (type === "boolean") {
    return WorkflowCheckboxField;
  }
};

const hideTransitionDialog = () => {
  currentTransition.value = {};
  emit("hideDialog");
};

const updateTransition = async () => {
  if (checkIfColorEquals()) {
    currentTransition.value.template = { color: color.value };
  }

  emit("updateTransition", {
    id: props.transition.id,
    transition: Object.keys(currentTransition.value).length ? currentTransition.value : null,
    transitionFormFieldAttributes: validation.value?.validationAttributes?.length
      ? validation.value?.validationAttributes
      : null,
  });
};

const onTransitionValidationChanged = val => {
  validation.value = val;
};

const getFields = async () => {
  try {
    form.value = (await backend.index(`api/v3/statuses/${props.transition.old_status.id}/transitions/form`)).data;
  } catch (e) {
    await handleError(e);
  }
};

const checkIfColorEquals = () =>
  // we need to control weather data is changed or not
  // in this function we firstly check color changing and color presenting
  // secondly we ensure if first check fails, that template neither presented nor has inside itself color value
  (props.transition.template &&
    props.transition.template.color &&
    color.value &&
    props.transition.template.color !== color.value) ||
  (!props.transition.template && color.value) ||
  (props.transition.template && !props.transition.template.color && color.value);

const getFormCardTitle = computed(() => {
  return `${form.value?.form_header?.edit} №${props.transition?.id}`;
});

onMounted(async () => {
  await getFields();
  loading.value = false;
});
</script>
