<template lang="pug">
#show-form.issue-show
  .show-card
    q-card-section
      form-header(
        v-if="show_data.common",
        ref="form-header",
        @close-form="closeShow",
        :parentData="{ grid: grid, title: show_data.common.form_header.title + ' № ' + row_id, title_icon: 'show_header.svg' }"
      )

    .spinner-container.form-spinner(v-if="loading")
      q-spinner(color="primary", size="3em")

    div(v-if="!loading")
      q-card-section
        component(
          ref="actions",
          v-if="show_data.common['actions']",
          :is="getShowComponent('actions')",
          @refresh-table="refreshTable",
          @open-form="openForm",
          :parentData="{ row: row, grid: grid, grid_key: grid_key, path: path, transition_key: transition_key, data: show_data.common['actions'] }"
        )

      q-card-section(v-if="row && row.checklist_id")
        checklist(ref="checklist", :parentData="{ row: row, grid: grid }")

      q-card-section
        component(
          ref="blocks",
          v-if="show_data.common['blocks']",
          :is="getShowComponent('blocks')",
          :parentData="{ row: row, show_details: false, data: show_data.common['blocks'] }"
        )

      q-card-section
        component(
          ref="tabs",
          v-if="show_data.common['tabs']",
          :is="getShowComponent('tabs')",
          :parentData="{ data: show_data.common['tabs'] }"
        )

      q-card-section
        component(
          ref="percentage",
          v-if="show_data.common['percentage']",
          :is="getShowComponent('percentage')",
          :parentData="{ row: row, data: show_data.common['percentage'] }"
        )

      q-card-section
        component(
          ref="form_data",
          v-if="show_data.common['form_data']",
          :is="getShowComponent('form_data')",
          @load-show-data="loadShowData",
          @refresh-table="refreshTable",
          @set-oading="setLoading",
          :parentData="{ item: item, row: row, grid: grid, path: path, data: show_data.common['form_data'] }"
        )

    #print-issue-card(v-if="!loading", v-show="false")
      component(
        ref="print_card",
        :is="getShowComponent('print_card')",
        :parentData="{ row: row, data: show_data.common }"
      )

    #print-completion-certificate(v-if="!loading", v-show="false")
      component(
        :is="getShowComponent('completion_certificate')",
        :parentData="{ data: { ...show_data.common['completion_certificate'], issue_id: row.id } }"
      )
</template>

<script>
import { useEmitter } from "@/services/useEmitter";
import formHeader from "../../shared/forms/formHeader";
import showComponents from "../../shared/show_card_components/index";
import checklist from "../checklists/item";

export default {
  components: {
    formHeader,
    showComponents,
    checklist,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      row_id: this.parentData.row.id,
      row: {},
      item: "issue",
      transition_key: this.parentData.transition_key,
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      show_data: [],
      loading: true,
      emitter: useEmitter(),
    };
  },

  computed: {},

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"]?.setLoading(newVal);
      }
    },
  },

  created() {
    this.$emit("row-is-active", true);
    setTimeout(() => {
      this.loadShowData();
    }, 300);

    this.$root.$on("load-show-data", row => {
      if (this.row_id && row && this.row_id === row.id) {
        this.loadShowData();
      }
    });
  },

  mounted() {},

  beforeMount() {
    this.emitter.on("submited-form", () => {
      this.refreshHistoryOrComments();
    });
  },

  beforeDestroy() {
    this.row_id = "";
    this.emitter.off("submited-form");
  },

  methods: {
    setLoading(val) {
      this.loading = val;
    },

    refreshHistoryOrComments() {
      if (this.$refs.tabs.tab.name === "comments") {
        this.$refs.tabs.refreshGrid("comments");
      } else {
        this.$refs.tabs.refreshGrid("history");
      }
    },

    refreshTable(data) {
      this.refreshRow(data);
      this.$emit("refresh-table", data);

      this.refreshHistoryOrComments();

      if (data.action === "destroy") {
        this.$emit("close-show", this.row);
      }
    },

    refreshRow(data) {
      this.row = data.attr.data || data.attr;
    },

    openForm(data) {
      this.$emit("open-form", data);
    },

    closeShow(row = this.row) {
      this.$emit("close-show", row);
    },

    loadShowData(params = {}) {
      this.$backend
        .show(this.path, this.row_id, { params: params })
        .then(({ data }) => {
          this.show_data = data;

          this.row = this.show_data.common["data"];
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/show/issue/main";
</style>
