import Vue from "vue";
import showComponents from "../../components/shared/show_card_components/index";

Vue.mixin({
  components: {
    showComponents,
  },
  computed: {},

  methods: {
    getShowComponent(key) {
      return showComponents[key];
    },
  },
});
