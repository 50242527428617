<template lang="pug">
div
  #form.techpassport-templates-form.new-edit-form
    .form-card
      q-card-section
        div
          .form-card-title
            inline-svg.form-card-title-icon(
              v-if="titleIconPath",
              :src="require(`../../../assets/icons/form/header/${titleIconPath}`)"
            )
            span {{ templateLocales.title }}
            inline-svg.form-card-close-icon(
              v-if="!loading",
              :src="require('../../../assets/icons/form/header/close.svg')",
              @click="closeForm(formData)"
            )

          .form-card-title-spacer

      .spinner-container.form-spinner(v-if="loading")
        q-spinner(color="primary", size="3em")

      q-card-section(v-if="!loading")
        templates-item(
          v-for="(data, index) in formData",
          :key="data.id",
          :data="data",
          :fieldTypes="getFieldTypeOptions(index)",
          :level="0",
          :hideCatalogTemplates="hideCatalogTemplates"
        )
</template>

<script setup>
import Multiselect from "vue-multiselect";
import multiselectField from "./templates/multiselectField";
import templatesItem from "./templates/templatesItem";
import "vue-multiselect/dist/vue-multiselect.min.css";

import { ref, onMounted, computed } from "vue";
import { templateLocales } from "@/services/useLocales";
import { Notify } from "quasar";

import { notifies } from "@/services/useLocales";

import { handleError } from "@/services/handleErrors";
import backend from "@/api";

const props = defineProps({
  parentData: {
    type: Object,
    default() {
      return {};
    },
  },
});

const emit = defineEmits(["close-form"]);

const titleIconPath = "add_header.svg";
const facilitiesId = computed(() => props.parentData.row.id);

const forms = ref([]);

const namespace = ref(props.parentData.callback_params.key.split("/")[0]);
const type = ref(props.parentData.callback_params.key.split("/")[1]);
const hideCatalogTemplates = ref(false);

const formData = ref([]);
const fieldTypes = ref([]);
const loading = ref(true);
let typeOptions = [];

const closeForm = data => {
  emit("close-form", data);
};

const getFieldTypeOptions = index => {
  const template = fieldTypes.value[index];

  if (!template) {
    console.error("No template was found");
    return;
  }

  return template.types.options;
};

const getFieldTypes = async () => {
  try {
    for (let i = 0; i < forms.value.length; i++) {
      const template = forms.value[i];

      const response = await backend.index(`/api/v3/tech_passport/field_types/collection?template_id=${template.id}`);

      fieldTypes.value.push({ id: template.id, types: response.data });
    }
  } catch (e) {
    await handleError(e);
  }
};

const getTemplates = async () => {
  if (props.parentData.callback_params.key === "template_id") {
    forms.value = [{ id: props.parentData.row.template_id }];
    hideCatalogTemplates.value = true;
    return;
  }
  try {
    const response = await backend.index(
      `/api/v3/tech_passport/templates?facility_id=${facilitiesId.value}&namespace=${namespace.value}&type=${type.value}`,
    );
    forms.value = response.data;
  } catch (e) {
    await handleError(e);
  }
};

const getFields = async () => {
  try {
    for (let i = 0; i < forms.value.length; i++) {
      const template = forms.value[i];

      const { data } = await backend.index(`/api/v3/tech_passport/templates/${template.id}/edit`);

      if (!data.fields.length) {
        const field = {
          template_id: template.id,
          external_id: null,
          need_external_id: true,
          name: null,
          description: null,
          type: null,
          params: {
            measure_unit: "",
            dropdown_options: [],
            metrics: {},
          },
          put_to_header: false,
        };

        data.catalog_templates = data.catalog_templates.map(item => {
          item.active = false;
          return item;
        });

        data.fields.push(field);
      }

      formData.value.push(data);
    }
  } catch (e) {
    await handleError(e);
  }
};

onMounted(async () => {
  await getTemplates();
  await getFields();
  await getFieldTypes();

  loading.value = false;
});
</script>

<style lang="scss">
@import "../../../assets/styles/forms/templates-form";
</style>
