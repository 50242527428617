<template lang="pug">
.checkbox-filter
  div(:class="main_class")
    q-checkbox(dark, v-model="currentFilter", :disable="disabled", :label="label")
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      grid: this.parentData.grid,
      path: this.parentData.path,
      disabled: this.parentData.data[0].disabled,
      watch: this.parentData.data[0].watch || {},
      main_class: "form-checkbox",
    };
  },

  computed: {
    currentFilter: {
      get() {
        return this.currentFilters[this.name] || false;
      },
      set(value) {
        this.$store.commit("updateFilter", { grid_name: this.grid, filter: this.name, value: value });
      },
    },
  },

  created() {},

  beforeMount() {
    // this.setField(false)
  },

  methods: {
    setField(val) {
      this.currentFilter = val;
    },
  },
};
</script>

<style scoped lang="scss">
// @import "../../../assets/styles/forms/fields/checkbox";

.checkbox-filter {
  margin-top: 5px;
  margin-bottom: 5px;

  :deep(.q-checkbox__inner--truthy) {
    color: var(--checkbox-checked);
  }

  :deep(.q-checkbox__inner--falsy) {
    color: var(--checkbox-unchecked);
  }

  :deep(.q-checkbox__label) {
    color: var(--field-input-color);
    flex: 1;
    padding: 5px;
  }
}
</style>
