<template lang="pug">
.floor-plan-legend
  .floor-plan-legend-text(v-if="editable") {{ locales["floor_plan"][current_locale]["create_object"] }}
  .floor-plan-legend-icons(v-if="icons")
    .floor-plan-legend-icons-item(v-for="(value, key) in icons", :key="`${key} ${value}`")
      inline-svg.floor-plan-legend-icon(:src="value")
      span.floor-plan-legend-icons-item-sep —
      span.floor-plan-legend-icons-item-text {{ getName(key) }}
</template>

<script setup>
import { ref, computed } from "vue";
import { floorPlanLocales } from "@/services/useLocales";

const props = defineProps({
  legendData: { type: Object, default: () => {} },
});

const editable = computed(() => props.legendData.editable);
const types = computed(() => props.legendData.types);
const icons = computed(() => props.legendData.icons);

const getName = type => {
  if (type === "equipments") {
    return floorPlanLocales.value["equipment"];
  }
  if (type === "measuring_meters") {
    return floorPlanLocales.value["meter"];
  }
  if (type === "sensors") {
    return floorPlanLocales.value["sensors"];
  }
  if (type === "rooms") {
    return floorPlanLocales.value["rooms"];
  }
  if (type === "users") {
    return floorPlanLocales.value["users"];
  }
};
</script>

<style lang="scss">
@import "../../../../assets/styles/floor-plan/main";
</style>
