<template lang="pug">
div
  .workflow-fields__field
    .form-field-label
      span {{ selectAttributesLocale }}
    .form-field.form-multiselect
      Multiselect(
        :value="statusFields",
        :track-by="'id'",
        :label="'title'",
        :options="statusFieldsOptions",
        placeholder="",
        :multiple="true",
        :hideSelected="true",
        :show-labels="false",
        open-direction="bottom",
        :allow-empty="true",
        :close-on-select="false",
        @select="selectBasicField",
        @remove="removeBasicField"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}
        span(slot="noOptions")
          i {{ notifies.no_options_list }}

  .workflow-fields__field
    .form-field-label
      span {{ workflowConstructorLocales.select_required_attrs }}
    .form-field.form-multiselect
      Multiselect(
        :value="statusRequiredFields",
        :track-by="'id'",
        :label="'title'",
        :options="statusFields",
        placeholder="",
        :multiple="true",
        :hideSelected="true",
        :show-labels="false",
        open-direction="bottom",
        :allow-empty="true",
        :close-on-select="false",
        @select="selectRequiredField",
        @remove="removeRequiredField"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}
        span(slot="noOptions")
          i {{ notifies.no_options_list }}
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

import Multiselect from "vue-multiselect";
import backend from "@/api";

import { workflowConstructorLocales, currentLocale } from "@/services/useLocales";
import { handleError } from "@/services/handleErrors";

import i18n from "@/plugins/vue-i18n";

const props = defineProps({
  status: { type: Object, default: () => {} },
  workflowId: { type: Number, default: null },
});

const emit = defineEmits(["statusValidationChanged"]);

const statusFields = ref([]);
const statusRequiredFields = ref([]);

const statusFieldsOptions = ref([]);

const requestFields = ref([]);

const selectAttributesLocale = computed(() => {
  return i18n["messages"][currentLocale.value]["selectAttributes"];
});

const getStatusValidationsFromProps = () => {
  if (!props.status.validations) {
    statusFields.value = [];
    statusRequiredFields.value = [];
  }

  let array = props.status.validations.map(elem => {
    return {
      id: elem.attribute,
      title: elem.attribute_title,
      validation_id: elem.id,
      type: elem.type,
    };
  });

  statusFields.value = array;
  statusRequiredFields.value = array.filter(el => el.type === "required");
};

const getStatusValidationFieldsOptions = async () => {
  try {
    statusFieldsOptions.value = (
      await backend.collection("api/v3/workflow_validations/collection", { workflow_id: props.workflowId })
    ).data.options;
  } catch (e) {
    await handleError(e);
  }
};

const emitStatusFieldsUpdated = () => {
  const req = {
    validationAttributes: requestFields.value,
  };

  emit("statusFieldsChanged", req);
};

const selectBasicField = event => {
  statusFields.value.push(event);

  const requestIndex = requestFields.value.findIndex(el => el.attribute === event.id);

  if (requestIndex === -1 && !event.validation_id)
    requestFields.value.push({
      entity_type: "Status",
      entity_id: props.status.id,
      attribute: event.id,
      type: null,
    });

  emitStatusFieldsUpdated();
};

const selectRequiredField = event => {
  statusRequiredFields.value.push(event);

  const requestIndex = requestFields.value.findIndex(el => el.attribute === event.id);

  if (requestIndex !== -1) {
    requestFields.value[requestIndex] = {
      entity_type: "Status",
      entity_id: props.status.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      type: "required",
    };
  } else {
    requestFields.value.push({
      entity_type: "Status",
      entity_id: props.status.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      type: "required",
    });
  }

  emitStatusFieldsUpdated();
};

const removeBasicField = event => {
  const index = statusFields.value.findIndex(el => el.id === event.id);

  if (index !== -1) {
    statusFields.value.splice(index, 1);
  }

  const reqIndex = statusRequiredFields.value.findIndex(el => el.id === event.id);

  if (reqIndex !== -1) {
    statusRequiredFields.value.splice(reqIndex, 1);
  }

  const requestIndex = requestFields.value.findIndex(el => el.attribute === event.id);

  if (requestIndex !== -1 && !event.validation_id) {
    requestFields.value.splice(requestIndex, 1);
  } else {
    requestFields.value.push({
      entity_type: "Status",
      entity_id: props.status.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      _destroy: true,
    });
  }

  emitStatusFieldsUpdated();
};

const removeRequiredField = event => {
  const index = statusRequiredFields.value.findIndex(el => el.id === event.id);

  if (index !== -1) {
    statusRequiredFields.value.splice(index, 1);
  }

  const requestIndex = requestFields.value.findIndex(el => el.attribute === event.id);

  if (requestIndex !== -1) {
    requestFields.value[requestIndex] = {
      entity_type: "Status",
      entity_id: props.status.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      type: null,
    };
  } else {
    requestFields.value.push({
      entity_type: "Status",
      entity_id: props.status.id,
      id: event.validation_id ? event.validation_id : null,
      attribute: event.id,
      type: null,
    });
  }

  emitStatusFieldsUpdated();
};

onMounted(async () => {
  await getStatusValidationFieldsOptions();

  getStatusValidationsFromProps();
});
</script>
