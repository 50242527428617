<template lang="pug">
div
  .middle-sub-block.top
    div(v-for="options in data[0]")
      dashboard-link(:options="options")

  .middle-sub-block
    .div(v-for="options in data[1]")
      dashboard-link(:options="options")
</template>

<script setup>
import { defineProps, computed } from "vue";
import dashboardLink from "./dashboardLink.vue";

const props = defineProps({
  parentData: { type: Object, default: () => {} },
});

const data = computed(() => props.parentData.data);
</script>
