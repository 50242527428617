<template lang="pug">
.template-form-item
  .template-form-title(v-if="props.showTitle") {{ data.name }}
  q-form(@submit.prevent.stop="submitForm()")
    div(v-for="(field, index) in data.fields", :key="field.id || 'local' + index")
      .template-form-field(v-if="!field._destroy")
        .field-item.field-item-checkbox
          span.field-item-label {{ templateLocales.put_to_header }}
          q-checkbox.form-checkbox(v-model="field.put_to_header")
        .field-item.field-item-id
          span.field-item-label {{ templateLocales.id }}
          q-input(
            :class="`field-input`",
            filled,
            no-error-icon,
            v-model="field.external_id",
            :label-slot="false",
            autocomplete="off"
          )
            q-icon.cancel-field.template-cancel(
              name="cancel",
              v-if="field.external_id",
              @click.stop="resetField(index, 'external_id')"
            )
        .field-item.field-item-name
          span.field-item-label {{ templateLocales.field_title }}
            span *
          q-input.field-input(
            filled,
            no-error-icon,
            v-model="field.name",
            :label-slot="false",
            autocomplete="off",
            :rules="fieldNameRule",
            lazy-rules
          )
            q-icon.cancel-field.template-cancel(
              name="cancel",
              v-if="field.name",
              @click.stop="resetField(index, 'name')"
            )
        .field-item.field-item-type
          span.field-item-label {{ templateLocales.field_type }}
            span *
          multiselectField(
            :multiselectClass="getMultiselectClasses(field)",
            :multiselectOptions="fieldTypes",
            :multiselectValue="getFieldType(field.type)",
            :templateId="data.id",
            :formType="field.type",
            :dropdownOptions="field.params.dropdown_options",
            :metricsValues="field.params.metrics",
            :referenceValue="parseInt(field.params.template_id)",
            @resetMetrics="resetMetrics(index)",
            @setSelectValue="val => { setSelectValue(index, val); }",
            @resetField="val => { resetField(index, val); }",
            @addDropdownVariable="addDropdownVariable(index)",
            @removeMultiselectItem="val => { removeMultiselectItem(index, val); }",
            @setMetricsValue="val => { setMetricsValue(index, val); }",
            @setReferencesValue="val => { setReferencesValue(index, val); }",
            @setMetricsChildValue="val => { setMetricsChildValue(index, val); }"
          )

        div(:class="getMeasureClasses(field)")
          span.field-item-label {{ templateLocales.measure_unit }}
          q-input.field-input(
            filled,
            no-error-icon,
            v-model="field.params.measure_unit",
            :label-slot="false",
            autocomplete="off",
            :disable="disabledInput(field)"
          )
            q-icon.cancel-field.template-cancel(
              name="cancel",
              v-if="field.params.measure_unit",
              @click.stop="resetMeasureField(index)"
            )

        inline-svg.field-item-remove(
          :src="require(`../../../../assets/icons/object_pass/templates/remove_field.svg`)",
          @click="removeOldField(index)"
        )
    .add-new-field(@click="addNewField") 
      inline-svg.add-new-field-icon(:src="require(`../../../../assets/icons/object_pass/templates/add_field.svg`)")
      .add-new-field-text {{ templateLocales.add_attribute }}

    .button-group.row.justify-center
      q-btn.new-edit-form-submit(flat, no-caps, :label="templateLocales.submit", type="submit")

    .catalog-templates.catalog-templates-field(
      :style="{ paddingLeft: `${level * 15}px` }",
      v-if="canShowCatalogTemplate"
    ) 
      .catalog-templates-field-item(
        v-for="template in data.catalog_templates",
        :key="template.name + template.id",
        :class="template.active && 'active'"
      )
        .catalog-templates-field-item-name.template-form-title(
          :class="template.active && 'active'",
          @click="openCatalogTemplate(template)"
        ) {{ template.name }}
        .catalog-templates-field-item-fields(v-if="template.active")
          templates-item(
            v-if="catalogTemplateData",
            :data="catalogTemplateData",
            :fieldTypes="fieldTypes",
            :level="level + 1",
            :showTitle="false"
          )
</template>

<script setup>
import Multiselect from "vue-multiselect";
import multiselectField from "./multiselectField";
import "vue-multiselect/dist/vue-multiselect.min.css";
import templatesItem from "./templatesItem";

import { ref, computed, defineProps, defineEmits } from "vue";
import { templateLocales } from "@/services/useLocales";
import { Notify } from "quasar";

import { notifies } from "@/services/useLocales";

import { handleError } from "@/services/handleErrors";
import backend from "@/api";

const props = defineProps({
  data: { type: Object, default: () => {} },
  fieldTypes: { type: Array, default: () => [] },
  level: { type: Number, default: () => 0 },
  showTitle: { type: Boolean, default: () => true },
  hideCatalogTemplates: { type: Boolean, default: () => false },
});

const data = computed(() => props.data);
const level = computed(() => props.level);
const fieldNameRule = computed(() => [val => (val && val.length > 0) || notifies.value.not_empty]);
const canShowCatalogTemplate = computed(() => !props.hideCatalogTemplates && data.value.catalog_templates.length > 0);

const fieldTypes = ref(props.fieldTypes);
const currentCatalogTemplate = ref(null);
const catalogTemplateData = ref(null);

const emit = defineEmits(["close-form"]);

const resetField = (index, key) => {
  data.value.fields[index][key] = null;
};

const getMultiselectClasses = field => {
  const classes = ["form-field", "form-multiselect"];
  if (field.type) {
    classes.push("filled");
  }
  // else {
  //   classes.push("invalid");
  // }
  return classes.join(" ");
};

const getMeasureClasses = field => {
  const classes = ["field-item", "field-item-measure"];
  if (field.type === "date") {
    classes.push("disabled-field");
  }
  return classes.join(" ");
};

const resetMeasureField = index => {
  data.value.fields[index]["params"]["measure_unit"] = "";
};

const resetMetrics = index => {
  data.value.fields[index].params["metrics"] = {};
};

const setSelectValue = (index, val) => {
  data.value.fields[index]["type"] = val.id;
  data.value.fields[index]["params"]["metrics"] = {};
  if (val.id === "date") {
    resetMeasureField(index);
  }
};

const setMetricsValue = (index, id) => {
  data.value.fields[index]["params"]["metrics"]["calculated_field"] = id;
};

const setReferencesValue = (index, id) => {
  data.value.fields[index]["params"]["template_id"] = id;
};

const setMetricsChildValue = (index, id) => {
  data.value.fields[index]["params"]["metrics"]["calculated_child"] = id;
};

const addDropdownVariable = index => {
  data.value.fields[index]["params"]["dropdown_options"].push({ name: "", external_id: "" });
};

const removeMultiselectItem = (index, indexSelect) => {
  data.value.fields[index]["params"]["dropdown_options"].splice([indexSelect], 1);
};

const getFieldType = fieldType => {
  const response = fieldTypes.value.find(el => el.id === fieldType);

  return response || {};
};

const disabledInput = field => {
  return field.type === "date";
};

const removeOldField = index => {
  if (data.value.fields[index].id) {
    const prevField = data.value.fields[index];
    data.value.fields.splice(index, 1, { ...prevField, _destroy: true });
  } else {
    data.value.fields.splice(index, 1);
  }
};

const addNewField = () => {
  const field = {
    template_id: data.value.id,
    external_id: null,
    need_external_id: true,
    name: null,
    description: null,
    type: null,
    params: {
      measure_unit: "",
      dropdown_options: [],
      metrics: {},
    },
    put_to_header: false,
  };

  data.value.fields.push(field);
};

const submitForm = async () => {
  const params = {
    fields_attributes: [],
  };

  let invalid = false;
  let dropdownEmpty = false;

  for (let i = 0; i < data.value.fields.length; i++) {
    const currentField = data.value.fields[i];
    const typesArray = fieldTypes.value;
    const currentType = typesArray.find(el => el.id === currentField.type);
    const fieldParams = currentField["params"];

    if (!currentField["name"] || currentField["name"].trim() == "") {
      invalid = true;
    }
    if (!currentField["type"]) {
      invalid = true;
    }
    if (currentField["type"] === "dropdown" && currentField.params["dropdown_options"].length < 1) {
      dropdownEmpty = true;
    }

    if (currentType.params_type === "metric") {
      if (
        !fieldParams["metrics"]["calculated_field"] ||
        !fieldParams["metrics"]["calculated_child"] ||
        (currentType.params_type === "reference" && !fieldParams["template_id"])
      ) {
        Notify.create(templateLocales.value.invalid_metrics);
        return;
      }
    }
  }
  if (invalid) {
    Notify.create(templateLocales.value.invalid);
    return;
  }

  if (dropdownEmpty) {
    Notify.create(templateLocales.value.dropdown_empty);
    return;
  }

  params.fields_attributes = serializeData(data.value.fields);

  try {
    const response = await backend.update(`/api/v3/tech_passport/templates`, data.value.id, params);
    data.value.fields = response.data.fields;
    Notify.create(templateLocales.value.success);
  } catch (err) {
    if (err.message === "Request failed with status code 422") {
      Notify.create(templateLocales.value.duplicate_fields_prohibitted);
    }
  }
};

const serializeData = data => {
  const newData = [];
  data.forEach((item, index) => {
    newData.push({
      name: item.name,
      type: item.type,
      external_id: item.external_id,
      put_to_header: item.put_to_header,
      params: item.params,
    });
    if (item.id) {
      newData[index]["id"] = item.id;
    }
    if (item._destroy) {
      newData[index]["_destroy"] = item._destroy;
    }
  });
  return newData;
};

const openCatalogTemplate = async template => {
  data.value.catalog_templates = data.value.catalog_templates.map(item => {
    if (item.id !== template.id) {
      item.active = false;
    } else {
      item.active = !item.active;
    }
    return item;
  });
  try {
    const resp = await backend.index(`/api/v3/tech_passport/templates/${template.id}/edit`);
    const catalogData = resp.data;

    if (!catalogData.fields.length) {
      const field = {
        template_id: template.id,
        external_id: null,
        need_external_id: true,
        name: null,
        description: null,
        type: null,
        params: {
          measure_unit: "",
          dropdown_options: [],
          metrics: {},
        },
        put_to_header: false,
      };

      catalogData.fields.push(field);
    }

    catalogTemplateData.value = catalogData;
  } catch (err) {
    await handleError(err);
  }
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/templates-form";
</style>
