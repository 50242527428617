import meter_types_statistic from "./meter_type_statistic/MeterTypeChartsCarousel";
import common_spending_statistic from "./meter_readings_statistic/MeterReadingsChart";
import measuring_soon_verifyings from "./measuring_soon_verifyings";
import measuring_last_readings from "./measuring_last_readings";

export default {
  meter_types_statistic: meter_types_statistic,
  common_spending_statistic: common_spending_statistic,
  measuring_soon_verifyings: measuring_soon_verifyings,
  measuring_last_readings: measuring_last_readings,
};
