<template lang="pug">
.select-dyn-numb-filter.form-field.form-multiselect(:class="filterClass")
  .select-dyn-numb-filter__label(v-if="showSelectionLabel") {{ defaultLabel }}
  .select-dyn-numb-filter__value {{ label }}
  q-icon.select-dyn-numb-filter__cancel(name="cancel", v-if="isAllResetable", @click.stop="resetAllFilters") 

  q-menu(v-model="showingMenu", @before-show="onMenuShow")
    .menu__container
      .menu__inputs
        .menu__input.form-field.form-input(v-for="(filter, index) in filters", :key="filter.name", :class="inputClass")
          q-input(
            :value="inputValue(filter)",
            filled,
            no-error-icon,
            type="number",
            :placeholder="placeholder(filter)",
            :label-slot="false",
            autocomplete="off",
            @input="changeInputValue($event, filter)"
          )
          q-icon(:class="cancelClass(filter)", name="cancel", @click="resetInputValue(filter)")
      .menu__errors(v-if="!valid") {{ dataLocales.validations.dynamic_numbers_from_cant_be_more_then_to }}
      .menu__buttons
        button(type="primary", :label="dataLocales.apply", @click="saveValues")
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "@/store";
import Multiselect from "vue-multiselect";
import Button from "@/components/shared/general_components/inputs/Button.vue";

const props = defineProps({
  parentData: { type: Object, default: () => {} },
  disabled: { type: Boolean, default: false },
  dataLocales: { type: Object, default: () => {} },
  selectionLabel: { type: Boolean, default: false },
});

const emit = defineEmits([
  /* 
  На ресет значения поля, отправляет объект фильтра 
  */
  "resetFilter",
]);

const store = useStore();

const showingMenu = ref(false);
const valid = ref(true);
const fromVal = ref({ val: "", filter: null });
const toVal = ref({ val: "", filter: null });

const currentFilters = computed(() => store.state.grid[grid.value]["filters"]);
const firstFilter = computed(() => filters.value[0]);
const secondFilter = computed(() => filters.value[1]);
const firstFilterValue = computed(() => currentFilters.value[firstFilter.value.name]);
const secondFilterValue = computed(() => currentFilters.value[secondFilter.value.name]);
const grid = computed(() => props.parentData.grid);
const defaultLabel = computed(() => props.parentData.data[0].label);
const filters = computed(() => props.parentData.data[0].items);

const label = computed(() => {
  // default label or selected values:
  // * ... - 123
  // * 123 - ...
  // * 123 - 456
  if (firstFilterValue.value || secondFilterValue.value) {
    if (firstFilterValue.value && secondFilterValue.value) {
      return `${firstFilterValue.value} - ${secondFilterValue.value}`;
    } else if (!firstFilterValue.value && secondFilterValue.value) {
      return `... - ${secondFilterValue.value}`;
    } else if (firstFilterValue.value && !secondFilterValue.value) {
      return `${firstFilterValue.value} - ...`;
    } else {
      return "...";
    }
  } else {
    return defaultLabel.value;
  }
});
const isAllResetable = computed(() => label.value !== defaultLabel.value);
const showSelectionLabel = computed(() => props.selectionLabel && isAllResetable.value);

const filterClass = computed(() => ({
  "select-dyn-numb-filter--selection-label": showSelectionLabel.value,
}));
const inputClass = computed(() => ({ "menu__input--error": !valid.value }));

const cancelClass = filter => {
  const currentValue = filter.name.includes("from") ? fromVal.value.val : toVal.value.val;

  return {
    "menu__input__cancel--visible": currentValue.length,
    "menu__input__cancel--hidden": !currentValue.length,
  };
};

// Initialize from and to values from store to be able to show in inputs inside modal
const onMenuShow = () => {
  valid.value = true; // reset state

  // Detect where filter "from" is: in the begining or end
  if (firstFilter.value.name.includes("from")) {
    fromVal.value = { val: firstFilterValue.value || "", filter: firstFilter.value };
    toVal.value = { val: secondFilterValue.value || "", filter: secondFilter.value };
  } else {
    fromVal.value = { val: secondFilterValue.value || "", filter: secondFilter.value };
    toVal.value = { val: firstFilterValue.value || "", filter: firstFilter.value };
  }
};

const isResettable = filter => {
  let value = null;

  if (filter.name.includes("from")) {
    value = fromVal.value.val;
  } else {
    value = toVal.value.val;
  }

  return value.length > 0;
};

const placeholder = filter => {
  const matchedTexts = filter.name.match(/[a-z]+\b/g);
  const name = matchedTexts[matchedTexts.length - 1];
  const translatedName = props.dataLocales[name];

  return translatedName;
};

const inputValue = filter => {
  if (filter.name.includes("from")) {
    return fromVal.value.val;
  } else {
    return toVal.value.val;
  }
};

const changeInputValue = (value, filter) => {
  const data = { val: value, filter };

  if (filter.name.includes("from")) {
    fromVal.value = data;
  } else {
    toVal.value = data;
  }

  // Remove validation error to match behavior with other inputs
  valid.value = true;
};

const resetInputValue = filter => {
  const data = { val: "", filter };

  if (filter.name.includes("from")) {
    fromVal.value = data;
  } else {
    toVal.value = data;
  }
};

const changeFilterValue = (value, filter) => {
  if (value === null || value === undefined) {
    resetFilter(filter);
  } else if (value.length == 0) {
    resetFilter(filter);
  } else {
    setFilter(filter, value);
  }
};

const setFilter = (filter, value) => {
  store.commit("updateFilter", { grid_name: grid.value, filter: filter.name, value });
};

const resetFilter = filter => {
  emit("resetFilter", filter);
  store.commit("resetFilter", { grid_name: grid.value, filter: filter.name });
};

const resetAllFilters = () => {
  filters.value.forEach(filter => resetFilter(filter));
};

const applyFilters = () => {
  changeFilterValue(fromVal.value.val, fromVal.value.filter);
  changeFilterValue(toVal.value.val, toVal.value.filter);
};

const validateValues = () => {
  // Skip if one of inputs is blank
  if (fromVal.value.val.length == 0 || toVal.value.val.length == 0) {
    return true;
  }

  const from = Number(fromVal.value.val);
  const to = Number(toVal.value.val);

  if (from > to) {
    return false;
  }

  return true;
};

const saveValues = () => {
  valid.value = validateValues();

  if (valid.value) {
    applyFilters();
    showingMenu.value = false;
  }
};
</script>

<style scoped lang="scss">
.select-dyn-numb-filter {
  display: flex;
  margin-bottom: 0;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;

  &--selection-label {
    position: relative;

    .select-dyn-numb-filter__label {
      position: absolute;
      top: 0;
      left: 15px;
      font-size: 11px;
      color: var(--field-input-color);
    }

    .select-dyn-numb-filter__value {
      padding-top: 13px;
    }
  }

  &__value {
    padding-left: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--select-dynamic-numbers-filter-label-color);
  }

  &__cancel {
    padding-right: 17px;
    color: var(--field-cancel-icon-color);
  }
}

.menu__container {
  .menu__inputs {
    display: flex;
    padding: 16px 16px 0 16px;
    gap: 10px;

    .menu__input {
      display: flex;
      align-items: center;
      margin-bottom: 3px; // to show error message closer to the inputs
      padding-right: 16px;
      padding-bottom: 1px; // fix to not cut part of bottom border

      &--error {
        border: 1px solid red !important;
      }

      &__cancel--visible {
        visibility: visible;
        color: var(--field-cancel-icon-color);
      }

      &__cancel--hidden {
        visibility: hidden;
      }
    }
  }

  .menu__errors {
    display: flex;
    color: red;
    font-size: 12px;
    padding-left: 20px;
    padding-right: 16px;
  }

  .menu__buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    margin-top: 10px;
  }
}
</style>
