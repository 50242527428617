<template lang="pug">
div(:class="className") {{ translation }}
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      default: "",
    },
    translation: {
      type: String,
      default: "",
    },
  },

  computed: {
    className() {
      return `issue-state ${this.state}-badge`;
    },
  },
};
</script>

<style lang="scss">
// @import('../../../../assets/styles/state_badges.scss')
</style>
