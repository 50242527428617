<template lang="pug">
div
  div(v-show="locales")
    .login-title.row.justify-center
      span {{ loginLocales["login_form.title"] }}

    q-form(@submit="onSubmit")
      .login-form.login-field
        .login-field-label
          span {{ loginLocales["login_form.fields.login.label"] }}

        .login-field-input
          q-input(
            filled,
            :label-slot="false",
            ref="loginInput",
            v-model="login",
            lazy-rules,
            no-error-icon,
            for="login-input",
            :rules="[val => (val && val.length > 0) || loginLocales['fields_rules.not_empty'], val => checkLogin(val) || loginLocales['fields_rules.wrong_email_format']]"
          )

      .login-form.password-field
        .login-field-label
          span {{ loginLocales["login_form.fields.password.label"] }}

        .login-field-input
          q-input(
            filled,
            :label-slot="false",
            ref="passwordInput",
            v-model="password",
            :type="hiddenPass ? 'password' : 'text'",
            lazy-rules,
            no-error-icon,
            for="password-input",
            :rules="[val => (val && val.length >= 4) || loginLocales['fields_rules.not_less_4'], val => !checkForCyrillicSymbols(val) || loginLocales['fields_rules.not_cyrillic']]"
          )
            .show-password-icon(@click="hiddenPass = !hiddenPass")
              inline-svg(:src="require(`../../../assets/icons/login/${hiddenPass ? 'closed-eye' : 'opened-eye'}.svg`)")

      .login-form-under-fields-area.row
        q-checkbox.col-6(dark, v-model="rememberMe", :label="loginLocales['login_form.fields.remember_me.label']")
        span.col-6.remind-password-link(@click="openPasswordRecovery()") {{ loginLocales["login_form.remind_password"] }}

      .login-form.button-group.row.justify-center
        q-btn.login-submit(flat, no-caps, :label="loginLocales['login_form.buttons.submit']", type="submit")

    .login-form-personal-data-agreement.row.justify-center
      span {{ loginLocales["login_form.agreement"] }}
      a.link(v-if="privacyPolicyLink", :href="privacyPolicyLink", target="_blank") {{ loginLocales["login_form.agreement_link"] }}
      span.link(v-else) {{ loginLocales["login_form.agreement_link"] }}

    .login-form-login-width-ad.column.justify-center
      .login-form-login-width-ad-icon(@click="openAdForm") {{ authWithAdText }}

    .login-form-download-app(v-if="haveMpLinks")
      .login-form-download-app-msg
        span {{ loginLocales["login_form.download_app_msg"] }}

      .login-form-download-app-icons
        a.link(v-if="appStoreLink", :href="appStoreLink", target="_blank")
          inline-svg(:src="require(`../../../assets/icons/login/app-store.svg`)")
        a.link(v-if="googlePlayLink", :href="googlePlayLink", target="_blank")
          inline-svg(:src="require(`../../../assets/icons/login/google-play.svg`)")
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { useSignIn } from "@/services/useAuth";
import { loginLocales } from "@/services/useLocales";
import i18n from "@/plugins/vue-i18n";
import { currentLocale } from "@/services/useLocales";

const emit = defineEmits(["setLoading", "passwordRecovery", "openAdForm"]);

const login = ref(null);
const password = ref(null);
const rememberMe = ref(false);
const hiddenPass = ref(true);

const loginInput = ref(null);
const passwordInput = ref(null);

const privacyPolicyLink = ref(process.env.VUE_APP_PRIVACY_POLICY_LINK || "");
const appStoreLink = ref(process.env.VUE_APP_APP_STORE_LINK || "");
const googlePlayLink = ref(process.env.VUE_APP_GOOGLE_PLAY_LINK || "");

const haveMpLinks = computed(() => appStoreLink.value || googlePlayLink.value);
const authWithAdText = computed(() => i18n["messages"][currentLocale.value].ad);

const openPasswordRecovery = () => {
  emit("passwordRecovery", true);
};

const openAdForm = () => {
  emit("openAdForm", true);
};

const checkLogin = email => {
  const regex = /[^@\s]+@[^@\s]+/;
  return regex.test(String(email).toLowerCase());
};

const checkForCyrillicSymbols = password => {
  const regex = /[а-яА-Я]/;
  return regex.test(String(password));
};

const onSubmit = async () => {
  if (loginInput.value && passwordInput.value) {
    loginInput.value.validate();
    passwordInput.value.validate();

    if (!loginInput.value.hasError && !passwordInput.value.hasError) {
      const params = {
        user: {
          email: login.value.toLowerCase().trim(),
          password: password.value,
        },
      };

      await useSignIn(params, { emit });
    }
  }
};

onMounted(() => {
  emit("setLoading", false);
});
</script>

<style lang="scss"></style>
