<template lang="pug">
prompt(
  v-if="prompt",
  :ref="prompt.class",
  @customize-prompt="customizePrompt",
  @close-modal="closeModal",
  :parentData="{ prompt: prompt }"
)
</template>

<script>
import Prompt from "../../../prompt";

export default {
  components: {
    Prompt,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    prompt() {
      return this.parentData.prompt;
    },
  },

  methods: {
    customizePrompt(parent_el, arrow, card) {
      arrow.style.top = `${parent_el.offsetHeight / 2 + parent_el.offsetTop}px`;
      card.style.top = `${parent_el.offsetTop}px`;
      card.style.left = `${parent_el.offsetLeft - 5}px`;

      this.$refs[this.prompt.class].showPrompt();
    },

    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss">
.modal-form-card.ui-prompt-card.dashboards-navbar-issues {
  width: 300px !important;
}
</style>
