<template lang="pug">
div
  .show-percentage
    span.show-percentage-label {{ data.label }}
    span.show-percentage-value {{ row.percentage_completion + "%" }}
  progress-bar(size="medium", bar-color="#5B7AFF", :val="row.percentage_completion")
</template>

<script>
import ProgressBar from "vue-simple-progress";

export default {
  components: {
    ProgressBar,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      // row: this.parentData.row
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },

    data() {
      return this.parentData.data;
    },
  },

  methods: {
    // refreshRow(row) {
    //   this.row = row
    // }
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/show/percentage";
</style>
