<template lang="pug">
index-page(
  @set-loading="setLoading",
  :parentData="{ grid, path, grid_key, report_class, report_namespace, transition_key, headerFiltersGroupClass: 'col-4 no-padding' }"
)
</template>

<script>
import indexPage from "../../../shared/indexPage";

export default {
  components: {
    indexPage,
  },
  data: function () {
    return {
      grid: "measuring_meters",
      report_class: "meter",
      report_namespace: "measuring",
      path: this.$store.state.paths["measuring_meter"],
      grid_key: "data",
      transition_key: "measuring_meter",
    };
  },

  beforeMount() {
    // this.$emit('set-loading', false)
    this.$emit("expand-class", { val: false, class: "" });
  },

  methods: {
    setLoading(val) {
      this.$emit("set-loading", val);
    },
  },
};
</script>

<style lang="scss"></style>
