import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const theme = {
  state: {
    value: null,
  },

  mutations: {
    changeTheme(state, theme) {
      state.value = theme;
    },
  },

  actions: {},
};
