<template lang="pug">
div
  table.specification-table
    tbody
      tr(
        v-if="editable",
        v-for="(item, i) in editable_data",
        :style="{ cursor: 'pointer' }",
        :class="{ 'active-specification-row': checkable && checkable.index === i }",
        @click="setCheckable(item, i)"
      )
        td(v-for="key in Object.keys(item)")
          tech-map-input(
            :parentData="{ tech_map_key: tech_map_key, grid: grid, index: i, value: item[key], value_key: key }"
          )

      tr(v-if="!editable", v-for="item in data")
        td(v-for="key in Object.keys(item)") {{ item[key].length > 0 ? item[key] : "-" }}

  handle-buttons(
    v-if="editable",
    @on-click-fn="onClickFn",
    :parentData="{ buttons: buttons['rows_handle'], checkable: checkable }"
  )
</template>

<script>
import techMapInput from "./helpers/input";
import handleButtons from "./helpers/buttons.vue";

export default {
  components: {
    techMapInput,
    handleButtons,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      row_id: this.parentData.row_id,
      checkable: undefined,
      tech_map_key: "specification",
    };
  },

  computed: {
    editable() {
      return this.parentData.editable;
    },

    data() {
      let data = this.parentData.data;
      return data ? data : [];
    },

    buttons() {
      return this.parentData.buttons;
    },
  },

  watch: {
    editable(newVal, oldVal) {
      if (!newVal) {
        this.checkable = undefined;
      }
    },

    data(newVal, oldVal) {
      this.editable_data = newVal;
    },
  },

  created() {},

  methods: {
    onClickFn(button) {
      this[button.onclick]();
    },

    addItem() {
      let empty_specification = { key: "", value: "" };
      this.editable_data.push(empty_specification);
      let index = this.editable_data.length - 1;
      this.setCheckable(this.editable_data[index], index);
    },

    duplicateItem() {
      let new_specification = {
        key: this.checkable.row.key,
        value: this.checkable.row.value,
      };
      this.editable_data.push(new_specification);
      let index = this.editable_data.length - 1;
      this.setCheckable(this.editable_data[index], index);
    },

    deleteItem() {
      if (this.editable_data.length > 1) {
        this.editable_data = this.editable_data.filter((el, i) => i !== this.checkable.index);
      } else {
        let empty_specification = { key: "", value: "" };
        this.editable_data = [empty_specification];
      }
      this.checkable = undefined;
    },
  },
};
</script>

<style lang="scss"></style>
