import _ from "lodash";
import qs from "qs";
import { deepMapKeysSnakeCase } from "./caseConvert";

const buildUrlParams = obj => {
  const snakeCaseObject = deepMapKeysSnakeCase(obj);
  const params = qs.stringify(snakeCaseObject);

  return params;
};

export { buildUrlParams };
