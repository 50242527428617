<template lang="pug">
.calendar__container
  .calendar
    template(v-if="!loading")
      .calendar__column(
        v-for="(dates, index) in columns",
        :key="dates.items[0].date.toString()",
        :ref="el => (columnRefs[index] = el)",
        :class="{ 'calendar__column--weekend': isWeekend(dates.items[0].date) }"
      )
        .calendar__column__header
          .calendar__column__header__time {{ getDayOfWeek(dates.items[0].date.getDay()) }}
            .calendar__column__header__time__count(
              :class="{ 'calendar__column__header__time__count--empty': getCountLabel(dates.count) === 0 }"
            ) {{ getCountLabel(dates.count) }}

        .calendar__column__content
          .calendar__row(
            v-for="item in dates.items",
            :key="item.date.toString()",
            :class="{ 'calendar__row--another-month': checkMonth(item.date) }",
            @click="openDate(item.date)"
          )
            .calendar__day
              .calendar__day__header
                .calendar__day__header__day(
                  :class="{ 'calendar__day__header__day--active': isCurrentDate(item.date) }"
                ) {{ getDateLabel(item) }}
                .calendar__day__header__month {{ getMonthLabel(item) }}

              span.calendar__day__count(:class="{ 'calendar__day__count--empty': item.count === 0 }") {{ item.count }}

    template(v-else)
      .calendar__column(v-for="(day, index) in [0, 1, 2, 3, 4, 5, 6]", :key="day.toString()")
        .calendar__column__header
          .calendar__column__header__time
            q-skeleton(type="text", width="100px")

        .calendar__column__content
          .calendar__row(v-for="issue in [0, 1, 2, 3, 4, 5]", :key="issue")
            .calendar__day
              .calendar__day__header
                .calendar__day__header__day
                  q-skeleton(type="text", width="30px")
                .calendar__day__header__month
                  q-skeleton(type="text", width="30px")

              q-skeleton.calendar__day__count--skeleton(type="circle", width="22px", height="22px")

  .footer
    span {{ `${issueCenterLocales.now} ${currentTime}` }}
</template>

<script setup>
import backend from "@/api";
import { issueCenterLocales } from "@/services/useLocales";
import { useEmitter } from "@/services/useEmitter";

import { onMounted, onBeforeUnmount, ref, computed, nextTick } from "vue";
import { handleError } from "@/services/handleErrors";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";

import { currentLocale } from "@/services/useLocales";

import i18n from "@/plugins/vue-i18n";

import { toDate, format, formatISO, add, sub } from "date-fns";

const emitter = useEmitter();

const store = useStore();

const columnRefs = ref([]);

const columns = ref();

const currentHour = ref();

const loading = ref(true);

const props = defineProps({
  firstCalendarDate: { type: Date, default: undefined, required: true },
  lastCalendarDate: { type: Date, default: undefined, required: true },
  initialCalendarDate: { type: Date, default: undefined, required: false },
  mode: { type: String, default: undefined, required: false },
});

const emit = defineEmits(["set-loading"]);

const initializeColumns = () => {
  const result = {
    Mon: { count: 0, items: [] },
    Tue: { count: 0, items: [] },
    Wed: { count: 0, items: [] },
    Thu: { count: 0, items: [] },
    Fri: { count: 0, items: [] },
    Sat: { count: 0, items: [] },
    Sun: { count: 0, items: [] },
  };

  let lastUsedDate = props.firstCalendarDate;

  while (lastUsedDate - props.lastCalendarDate !== 0) {
    const prefix = checkDayOfWeek(lastUsedDate.getDay());

    result[prefix].items.push({ date: lastUsedDate, count: 0 });

    lastUsedDate = add(lastUsedDate, { days: 1 });
  }

  result["Sun"].items.push({ date: lastUsedDate, count: 0 });

  columns.value = result;
};

const isCurrentDate = date => {
  const currentDate = getCurrentDate();

  return (
    date.getDate() === currentDate.getDate() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear()
  );
};

const isWeekend = day => {
  return day.getDay() === 0 || day.getDay() === 6;
};

const getMonth = month => {
  return i18n["messages"][currentLocale.value]["date"]["monthsShort"][month];
};

const getDayOfWeek = day => {
  return i18n["messages"][currentLocale.value]["date"]["daysShort"][day];
};

const checkMonth = date => {
  return (
    props.initialCalendarDate.getMonth() !== date.getMonth() ||
    props.initialCalendarDate.getFullYear() !== date.getFullYear()
  );
};

const getRequestParams = timeParam => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["issue_center"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  localFilters["type"] = "MaintenanceIssue";
  localFilters["finish_date_plane#from"] = `${getISODate(props.firstCalendarDate)}`;
  localFilters["finish_date_plane#to"] = `${getISODate(sub(props.lastCalendarDate, { seconds: 1 }))}`;

  return {
    json: JSON.stringify({
      type: "day_issue_count",
      table: {
        ignore_pagination: true,
        except_filters: {},
        query: localQuery,
        filters: localFilters,
      },
    }),
  };
};

const getIssues = async timeParam => {
  try {
    const params = getRequestParams(timeParam);

    const response = await backend.index("api/v3/situational_center/issues", { params });

    for (let item in response.data.data) {
      const unformattedDate = new Date(item);
      const formattedDate = new Date(
        unformattedDate.getFullYear(),
        unformattedDate.getMonth(),
        unformattedDate.getDate(),
      );
      const prefix = checkDayOfWeek(formattedDate.getDay());

      const itemIndex = columns.value[prefix].items.findIndex(el => el.date - formattedDate === 0);

      columns.value[prefix].count += response.data.data[item];
      columns.value[prefix].items[itemIndex].count = response.data.data[item];
    }
  } catch (e) {
    await handleError(e);
  }
};

const getCurrentDate = () => {
  return toDate(new Date());
};

const getISODate = timeParam => {
  return formatISO(
    new Date(
      timeParam.getFullYear(),
      timeParam.getMonth(),
      timeParam.getDate(),
      timeParam.getHours(),
      timeParam.getMinutes(),
      timeParam.getSeconds(),
    ),
  );
};

const getCountLabel = count => {
  return count || 0;
};

const isDateFields = rule => {
  return rule.name === "finish_date_plane" || rule.name === "created_at" || rule.name === "updated_at";
};

const getCurrentTime = () => {
  return format(getCurrentDate(), "dd.MM.yyyy");
};

const getDateLabel = day => {
  return format(day.date, "dd");
};

const getMonthLabel = day => {
  return getMonth(day.date.getMonth());
};

const currentTime = ref(getCurrentTime());

const changeCalendarDates = async prefix => {
  let newDate = new Date(props.initialCalendarDate.getFullYear(), props.initialCalendarDate.getMonth(), 1);

  if (prefix === "+") {
    newDate = add(newDate, { months: 1 });
  } else {
    newDate = sub(newDate, { months: 1 });
  }

  changeInitialDates(newDate);
  nextTick(async () => {
    loading.value = true;

    initializeColumns();
    await getIssues();

    loading.value = false;
  });
};

const getFormattedDate = date => {
  if (!date) return;
  // We need to ensure date is formatted as ISO string.
  const dataArray = date.split("T");

  if (dataArray.length > 1) {
    const dateInstance = new Date(date);

    return format(dateInstance, "dd.MM.yyyy, HH:mm");
  }
};

const openDate = date => {
  const currentTime = getCurrentDate();
  const newDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    currentTime.getHours(),
    currentTime.getMinutes(),
  );

  emit("openDate", newDate);
};

const changeInitialDates = (date = getCurrentDate()) => {
  const initialDate = new Date(date.getFullYear(), date.getMonth(), 1);

  const firstDate = sub(initialDate, { days: initialDate.getDay() === 0 ? 6 : initialDate.getDay() - 1 });
  const lastDate = add(firstDate, { days: 41 });

  emit("changeCalendarDates", {
    initialDate: date,
    firstDate: firstDate,
    lastDate: lastDate,
  });
};

const onIssueArchived = event => {
  const issueDate = new Date(event.finish_date_plane);
  const data = new Date(issueDate.getFullYear(), issueDate.getMonth(), issueDate.getDate());

  // Get prefix (day of week).
  const prefix = checkDayOfWeek(data.getDay());

  columns.value[prefix].count -= 1;

  // Get index of presented date.
  const itemIndex = columns.value[prefix].items.findIndex(el => el.date - data === 0);
  if (itemIndex !== -1) columns.value[prefix].items[itemIndex].count -= 1;
};

const onIssueUpdated = event => {
  const issueOldDate = new Date(event.old_finish_date_plane);
  const issueNewDate = new Date(event.new_finish_date_plane);

  // Dackend sends 2 dates for issue - previous and next, so we can find old date and new date in our data structure.
  // Our keys in data structure are dates, so we can get data using date.
  const issueOldDateFormatted = new Date(issueOldDate.getFullYear(), issueOldDate.getMonth(), issueOldDate.getDate());
  const issueNewDateFormatted = new Date(issueNewDate.getFullYear(), issueNewDate.getMonth(), issueNewDate.getDate());

  // Get prefix (day of week).
  const oldPrefix = checkDayOfWeek(issueOldDateFormatted.getDay());

  columns.value[oldPrefix].count -= 1;

  // Get index of presented date.
  const oldItemIndex = columns.value[oldPrefix].items.findIndex(el => el.date - issueOldDateFormatted === 0);
  if (oldItemIndex !== -1) columns.value[oldPrefix].items[oldItemIndex].count -= 1;

  // Get prefix (day of week).
  const newPrefix = checkDayOfWeek(issueNewDateFormatted.getDay());

  columns.value[newPrefix].count += 1;

  // Get index of presented date.
  const newItemIndex = columns.value[newPrefix].items.findIndex(el => el.date - issueNewDateFormatted === 0);
  if (newItemIndex !== -1) columns.value[newPrefix].items[newItemIndex].count += 1;
};

const onIssueCreated = event => {
  const issueDate = new Date(event.finish_date_plane);
  const data = new Date(issueDate.getFullYear(), issueDate.getMonth(), issueDate.getDate());

  // Get prefix (day of week).
  const prefix = checkDayOfWeek(data.getDay());

  columns.value[prefix].count += 1;

  // Get index of presented date.
  const itemIndex = columns.value[prefix].items.findIndex(el => el.date - data === 0);
  if (itemIndex !== -1) columns.value[prefix].items[itemIndex].count += 1;
};

const checkDayOfWeek = day => {
  // We need to specify keys as nonNumeric, cause day in Date is example of array [1, 2, 3, 4, 5, 6, 0].
  // Using numeric keys will lead to layout change (sunday will become first day of week).
  const daysByNumbers = { 0: "Sun", 1: "Mon", 2: "Tue", 3: "Wed", 4: "Thu", 5: "Fri", 6: "Sat" };

  return daysByNumbers[day];
};

const initializeData = async () => {
  if (props.mode === "calendar") {
    loading.value = true;
    columns.value = [];

    initializeColumns();

    await getIssues();
    loading.value = false;
  }
};

onMounted(async () => {
  await initializeData();
  emitter.on("issue-center-calendar-refresh-data", initializeData);
  emitter.on("createdNewIssue", e => onIssueCreated(e));
  emitter.on("updatedCalendarIssue", e => onIssueUpdated(e));
  emitter.on("archivedIssue", e => onIssueArchived(e));
});

onBeforeUnmount(() => {
  emitter.off("issue-center-calendar-refresh-data");
  emitter.off("createdNewIssue");
  emitter.off("updatedCalendarIssue");
  emitter.off("archivedIssue");
});

defineExpose({ changeCalendarDates });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/issue_center.scss";

.calendar {
  grid-template-columns: repeat(7, calc(100% / 7));

  &__column {
    padding-bottom: 0 !important;
    &__content {
      margin-top: 0 !important;
      height: calc(100% - 40px);
      border-top: 0.5px solid var(--issue-center-border-color);
    }
  }

  &__row {
    cursor: pointer;
    border-top: 0.5px solid var(--issue-center-border-color);
    border-bottom: 0.5px solid var(--issue-center-border-color);
    height: calc(100% / 6);
    padding: 10px;

    &--another-month {
      background-color: var(--issue-center-active-time-color);
    }
  }

  &__day {
    position: relative;
    height: 100%;

    &__header {
      display: flex;
      justify-content: space-between;

      &__day {
        color: var(--header-title-color);
        &--active {
          color: #3e62ff;
        }
      }

      &__month {
        color: grey;
      }
    }

    &__count {
      position: absolute;
      background: #3e62ff;
      border-radius: 20px;
      padding: 2px 6px;
      color: white;
      font-size: 12px;
      display: inline-block;
      min-width: 22px;
      text-align: center;
      bottom: 0;
      left: 0;

      &--skeleton {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &--empty {
        background: var(--issue-center-calendar-inactive-count-color);
        border-radius: 20px;
        padding: 2px 6px;
        color: grey;
        font-size: 12px;
        display: inline-block;
        min-width: 22px;
        text-align: center;
      }
    }
  }
}
</style>
