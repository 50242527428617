<template lang="pug">
div
  .form-card-title
    inline-svg.form-card-title-icon(
      v-if="title_icon",
      :src="require(`../../../assets/icons/form/header/${title_icon}`)"
    )
    span {{ title }}
    inline-svg.form-card-close-icon(
      v-if="!loading",
      :src="require('../../../assets/icons/form/header/close.svg')",
      @click="closeForm()"
    )

  .form-card-title-spacer
</template>

<script>
export default {
  components: {},

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      title_icon: this.parentData.title_icon,
      loading: true,
    };
  },

  computed: {
    title() {
      return this.parentData.title;
    },
  },

  methods: {
    closeForm() {
      if (this.grid) {
        this.resetForm();
      }
      this.$emit("close-form", {});
    },

    setLoading(val) {
      this.loading = val;
    },
  },
};
</script>

<style lang="scss"></style>
