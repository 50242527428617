<template lang="pug">
.workflow-fields__field
  q-checkbox(:value="workflowCheckboxFieldValue", :label="field.label", @input="setField")
</template>

<script setup>
import { ref, onMounted } from "vue";

const props = defineProps({
  field: { type: Object, default: () => {} },
  object: { type: Object, default: () => {} },
});

const emit = defineEmits(["fieldChange"]);

const workflowCheckboxFieldValue = ref(props.object[props.field.name]);

const setField = val => {
  workflowCheckboxFieldValue.value = val;
  emit("fieldChange", { name: props.field.name, value: val });

  if (props.field.name === "started") {
    emit("changeStarted", workflowCheckboxFieldValue.value);
  }
};

onMounted(() => {
  if (props.field.name === "started") {
    emit("changeStarted", workflowCheckboxFieldValue.value);
  }
});
</script>
