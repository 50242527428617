<template lang="pug">
.floor-dot-menu
  .floor-dot-menu-buttons
    q-btn(v-if="canBack", dense, flat, @click="backToType")
      inline-svg(fill="red", :src="require(`../../../../assets/icons/arrow-left.svg`)", alt="icon")
    q-btn(dense, flat, icon="close", @click="canselCreate")
  #nav-link-search-input-dot.form-field.form-input.sublinks-menu-search
    q-input(
      filled,
      no-error-icon,
      autocomplete="off",
      v-model="searchMsg",
      :placeholder="floorPlanLocales.search",
      :label-slot="false",
      for="nav-link-search-input-dot"
    )
      q-icon.cancel-string-field(name="cancel", v-if="searchMsg", @click.stop="resetSearchMsg()")
  ul.floor-dot-menu-select(v-if="typeGetting", v-show="!loading")
    div(v-for="(value, key) in types", :key="value.id")
      li.nav-link(v-if="value", @click="getType(getTypeText(key))") 
        | {{ floorPlanLocales[key] }}
  ul.floor-dot-menu-select(v-else, v-show="!loading")
    li.nav-link(v-for="item in searchDotList", :key="item.id", @click="getCollection(nextDotType, item.id)") 
      | {{ item.title }}
</template>

<script setup>
import { ref, computed } from "vue";
import backend from "@/api";
import { floorPlanLocales } from "@/services/useLocales";
import { handleError } from "@/services/handleErrors";

const props = defineProps({
  parentData: { type: Object, default: () => {} },
});

const emit = defineEmits(["setDot", "hide"]);

const loading = ref(false);
const currentDotList = ref([]);
const searchDotList = ref([]);
const nextDotType = ref("");
const prevDotType = ref("");
const dot = ref(props.parentData.dot);
const search = ref("");
const typeGetting = ref(true);
const canBack = ref(false);

const floorId = computed(() => props.parentData.floor_id);
const floor_plan_id = computed(() => props.parentData.floor_plan_id);
const building_id = computed(() => props.parentData.building_id);
const types = computed(() => props.parentData.types);
const searchMsg = computed({
  get() {
    return search.value;
  },
  set(value) {
    search.value = value;

    if (search.value !== "") {
      searchDotList.value = currentDotList.value.filter(link => {
        return link.title.toLowerCase().includes(search.value.toLowerCase());
      });
    } else {
      searchDotList.value = currentDotList.value;
    }
  },
});

const resetSearchMsg = () => {
  searchMsg = "";
};

const getTypeText = msg => {
  if (msg === "Оборудование") return "equipments";
  if (msg === "Счетчики") return "measuring_meters";
  if (msg === "Датчики") return "sensors";
  return msg;
};

const getCollection = async (type, id) => {
  if (type) {
    getOptions(type, id);
    return;
  }
  dot.value.value = id;
  const plan_points = {
    floor_plan_id: floor_plan_id.value,
    device_id: dot.value.value,
    x: dot.value.x,
    y: dot.value.y,
    type_device: dot.value.type,
  };
  if (dot.value.type === "rooms") {
    plan_points.figure_type = "rectangle";
    plan_points.rotate = 0;
    plan_points.border_width = 2;
    plan_points.color = "#0ec262";
    plan_points.width = 3;
    plan_points.height = 3;
  }
  try {
    const { data } = await backend.collection("api/v3/plan_points", { plan_point: plan_points });
    dot.value = {
      value: data.device_id,
      type: data.type_device,
      x: data.x,
      y: data.y,
      id: data.id,
      width: data.width,
      height: data.height,
      short_info: data.short_info,
      icon_url: data.icon_url,
      figure_type: data.figure_type,
      rotate: data.rotate,
      border_width: data.border_width,
      color: data.color,
    };
    emit("setDot", dot.value);
  } catch (e) {
    await handleError(e);
  } finally {
    currentDotList.value = [];
    searchDotList.value = currentDotList.value;
    prevDotType.value = "";
    nextDotType.value = "";
    loading.value = false;
    return;
  }
};

const getOptions = async (type, id) => {
  const dotType = dot.value.type;
  const apiPath = getApiPath(dotType, type, id);
  if (apiPath) {
    try {
      let params = {};
      if (dotType === "sensors") {
        params["building_id"] = building_id.value;
      }
      const { data } = await backend.index(apiPath, params);
      canBack.value = true;
      currentDotList.value = data.options;
      searchDotList.value = currentDotList.value;
      let nextType;
      let prevType;
      if (type === "group_systems") {
        nextType = "systems";
        prevType = "group_system";
      }
      if (type === "systems") {
        nextType = "equipments";
        prevType = "system";
      }
      if (type === "meter_types") {
        nextType = "meters";
        prevType = "meter_types";
      }
      nextDotType.value = nextType;
      prevDotType.value = prevType;
      loading.value = false;
    } catch (e) {
      await handleError(e);
    }
  }
};

const getApiPath = (dotType, type, id) => {
  let path;
  if (dotType === "equipments") {
    path = `api/v3/ppr/${type}/collection${prevDotType.value ? "?ppr_" + prevDotType.value + "_id=" + id : ""}`;
  } else if (dotType === "meters") {
    path = `api/v3/measuring/${type}/collection?measuring_meter_type_id=${id}`;
  } else {
    path = `api/v3/${type}/collection?floor_id=${floorId.value}`;
  }
  return path;
};

const getType = type => {
  typeGetting.value = false;
  switch (type.toLowerCase()) {
    case "equipments":
      dot.value.type = "equipments";
      getCollection("group_systems");
      break;
    case "measuring_meters":
      dot.value.type = "meters";
      getCollection("meter_types");
      break;
    case "sensors":
      dot.value.type = "sensors";
      getCollection("sensors");
      break;
    case "users":
      dot.value.type = "users";
      getCollection("users");
      break;
    case "rooms":
      dot.value.type = "rooms";
      getCollection("rooms");
      break;
    default:
      break;
  }
};

const canselCreate = () => {
  emit("hide");
};

const backToType = () => {
  canBack.value = false;
  typeGetting.value = true;
  currentDotList.value = [];
  searchDotList.value = currentDotList.value;
  prevDotType.value = "";
  nextDotType.value = "";
};
</script>

<style lang="scss">
@import "../../../../assets/styles/floor-plan/main";
</style>
