<template lang="pug">
q-input.tech-map-input(
  filled,
  :value="value",
  :placeholder="value || '-'",
  :label-slot="false",
  @input="onChange($event)"
)
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      index: this.parentData.index,
      valueKey: this.parentData.value_key,

      valueKeyParent: this.parentData.value_key_parent,
      subIndex: this.parentData.sub_index,

      techMapKey: this.parentData.tech_map_key,
    };
  },

  computed: {
    value() {
      return this.parentData.value;
    },
  },

  methods: {
    onChange(val) {
      let attr = {
        index: this.index,
        value_key: this.valueKey,
        value: val,
      };

      if (this.valueKeyParent) {
        attr["value_key_parent"] = this.valueKeyParent;
        attr["sub_index"] = this.subIndex;
      }

      this.$store.commit("updateWorksList", {
        grid_name: this.grid,
        tech_map_key: this.techMapKey,
        attr: attr,
      });
    },
  },
};
</script>

<style lang="scss">
.tech-map-input {
  .q-field__control {
    padding: 0;
    background: transparent;
    height: fit-content;

    .q-field__control-container {
      input {
        padding: 0;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #5b7aff;
      }
    }
  }
}
</style>
