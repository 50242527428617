<template lang="pug">
div
  .form-field-label
    span {{ label }}

  .form-description-area
    span(v-html="description_area_content")
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      method: this.parentData.method,
      method_limit: this.parentData.method_limit,
      grid: this.parentData.grid,
      name: this.parentData.data[0].name,
      value: this.parentData.data[0].value,
      label: this.parentData.data[0].label,
      watch: this.parentData.data[0].watch || {},

      description_area: false,
      description_area_content: "",
    };
  },

  computed: {},

  created() {
    if (this.value) {
      this.description_area = true;
      this.description_area_content = this.value;
    }

    this.$emit("show-field", { name: this.name, val: this.description_area });
    this.watchSimpleFieldParents();
  },

  beforeMount() {},

  methods: {
    setField(val) {},

    loadValue(params, parent_params) {
      if (Object.keys(parent_params).length > 0) {
        this.$backend
          .index(this.$store.state.paths[parent_params["path"]] + parent_params["action"], { params: params })
          .then(({ data }) => {
            let value = data[parent_params["result_key"]];

            if (value && (value !== "" || value !== undefined)) {
              this.description_area = true;
              this.description_area_content = value;
            } else {
              this.description_area = false;
              this.description_area_content = "";
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.error = true;
          })
          .finally(() => {
            this.$emit("show-field", { name: this.name, val: this.description_area });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/info";
</style>
