<template lang="pug">
.col-12.col-lg-6
  .form-field-label
    span {{ field.title }}
    span.form-field-require-tag(v-show="field.required", :title="field.title")
      | *

  .d-flex.radio-buttons
    q-option-group.q-mt-sm(
      style="display: block; margin-left: 0; min-width: unset; paddingg-bottom: unset",
      :options="options",
      v-model="value",
      :disable="readonly",
      color="indigo-12"
    )
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean || undefined,
      required: false,
      default: false,
    },
    initialValue: {
      type: Array,
      required: false,
      default: () => [{ value: null }],
    },
  },
  data() {
    return {
      options: this.field.options.map(elem => ({ label: elem.title, value: elem.id })),
      value: this.$props.initialValue[0].value ? parseInt(this.$props.initialValue[0].value) : null,
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (!this.readonly) this.editField(this.value);
      },
    },
  },
  created() {
    if (this.readonly) {
      this.options = this.field.options.map(elem => ({
        label: elem.values[0].title,
        value: parseInt(elem.values[0]._data.value),
      }));
      this.value =
        this.field._data.value && this.field._data.value.length > 0 ? parseInt(this.field._data.value[0].value) : "";
    }
  },
  methods: {
    resetField() {
      this.value = "";
      this.$emit("reset-field", { id: this.field.id });
    },
    editField(val) {
      if (!this.readonly)
        this.$emit("edit-field", { id: this.field.id, value: [{ value: val.toString(), title: "" }] });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/fields/checkbox";
.checklist-element-form .radio-buttons .q-radio {
  min-width: unset !important;
  padding-bottom: unset !important;
  color: var(--modal-form-card-color);
  .q-radio__bg {
    color: var(--modal-form-card-color);
  }
}
</style>
