import backend from "../api/index";
import { useStore } from "@/store";

const useLoadDefaultLocale = async (params = {}) => {
  const store = useStore();
  try {
    const response = await backend.index(store.state.paths["default_locale"], { params: params });

    store.commit("setDefaultLocale", response.data);
  } catch (e) {
    console.log(e);
  }
};

const useLoadDefaultLocalesData = async (params = {}) => {
  const store = useStore();
  try {
    const response = await backend.index(store.state.paths["default_locales"], { params: params });

    if (response.data && Object.keys(response.data).length > 0) {
      store.commit("addLocales", response.data);
    }
  } catch (e) {
    console.log(e);
  }
};

export { useLoadDefaultLocalesData, useLoadDefaultLocale };
