<template lang="pug">
.dashboard-posts
  .posts-area
    div(v-for="post in posts", :key="post.id")
      .post(@click="openModal(post)")
        .post-img
          img(
            :src="post && post.image_url ? post.image_url : require('../../../../assets/icons/dashboard/posts/missing_post.svg')"
          )
        .post-info
          span.post-title {{ post.title }}
          span.post-content {{ handleDescriptionTd(post.short_content) }}
        .post-date
          span {{ post.created_at }}

      .posts-spacer

  q-dialog(v-model="modal")
    post-modal(@close-modal="closeModal", :post="post")
</template>

<script setup>
import { defineProps, ref, computed } from "vue";
import postModal from "./postModal";

const props = defineProps({
  parentData: { type: Object, default: () => {} },
});

const post = ref({});
const modal = ref(false);

const posts = computed(() => props.parentData.posts);

const openModal = postVal => {
  post.value = postVal;
  modal.value = true;
};

const closeModal = () => {
  modal.value = false;
  post.value = {};
};
</script>
