import store from "../../store/index.js";

let grids = store.state.grids;
let ppr_grids = store.state.ppr.grids;
let measuring_grids = store.state.measuring.grids;

let grid_routes = grids
  .filter(grid => !store.state.additional_grids.includes(grid))
  .map(grid => {
    let result = {};
    result["name"] = grid;
    result["path"] = "/" + grid;
    result["component"] = require("../../components/view/" + grid + "/index").default;

    return result;
  });

let archive = {
  name: "archive",
  path: "/issues/archive",
  component: require("../../components/view/issues/archive").default,
};

let ppr = ppr_grids
  .filter(grid => !store.state.additional_grids.includes(grid))
  .map(grid => {
    let result = {};
    result["name"] = "ppr_" + grid;
    result["path"] = "/ppr/" + grid;
    result["component"] = require("../../components/view/ppr/" + grid + "/index").default;
    return result;
  });

let ppr_archive = {
  name: "ppr_archive",
  path: "/ppr/issues/archive",
  component: require("../../components/view/ppr/issues/archive").default,
};

let measuring = measuring_grids
  .filter(grid => !store.state.additional_grids.includes(grid))
  .map(grid => {
    let result = {};
    result["name"] = "measuring_" + grid;
    result["path"] = "/measuring/" + grid;
    result["component"] = require("../../components/view/measuring/" + grid + "/index").default;

    return result;
  });

let routes = grid_routes.concat([archive, ppr_archive], ppr, measuring);

export default routes;
