export const getWatchData = (field, form) => {
  const parentFieldName = field.watch?.parent;
  const parentFieldValue = form[parentFieldName];
  const watchData = { name: parentFieldName, value: parentFieldValue };

  return watchData;
};

export const getDynamicWatch = (name, value) => {
  const watchData = { name: name, value: value };
  return watchData;
};
