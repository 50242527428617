import { format, getYear, parseISO } from "date-fns";
import ruLocale from "date-fns/locale/ru";

export const options = (chartType, data, locale, theme) => {
  if (chartType == "csi-line") {
    return csiLineOptions(data, locale, theme);
  } else if (chartType === "issues-cc-bar") {
    // issues creates counts bar, must match styles with csi-line
    return issuesCCBarOptions(data, locale, theme);
  } else {
    throw `Apex options are not defined for chart type ${chartType}, please add a method for it`;
  }
};

const csiLineOptions = (data, locale, theme) => ({
  grid: {
    borderColor: theme.grid_border_color,
    padding: {
      right: 40, // Fix bug with cutting label
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    strokeDashArray: 7,
  },
  stroke: {
    show: true,
    curve: "smooth",
    lineCap: "butt",
    colors: ["#FFFFFF"],
    width: 3,
    dashArray: 0,
  },
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
    foreColor: theme.fore_color,
    defaultLocale: locale || "ru",
    locales: apexLocales(),
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: "category", // datetime is not compatiable with tickPlacement: 'between'
    tickPlacement: "between",
    tickAmount: 15, // to avoid too many labels which are very difficult to read on large range
    categories: data.map(val => val.date_at),
    tooltip: {
      enabled: false,
    },
    labels: {
      rotate: 0,
      formatter: function (val, _timestamp) {
        return xAxisFormatter(locale, data, val);
      },
    },
  },
  yaxis: {
    labels: {
      minWidth: 30,
      formatter: val => (val > 0 ? `${val}%` : val),
    },
    max: 100,
    min: 0, // fix bug with 5e-324% when no data provided
    tickAmount: 5,
  },
  tooltip: {
    theme: "dark",
  },
});

// Issues creates counts bar
const issuesCCBarOptions = (data, locale, theme) => ({
  grid: {
    padding: {
      right: 40, // Fix bug with cutting label
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    strokeDashArray: 7,
  },
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
    defaultLocale: locale || "ru",
    locales: apexLocales(),
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: "category", // match type with label to use same labels format as we try to match them as much as we can
    tickAmount: 15, // to avoid too many labels which are very difficult to read on large range
    categories: data.map(val => val.date_at),
    tooltip: {
      enabled: false,
    },
    labels: {
      rotate: 0,
      formatter: function (val, _timestamp) {
        return xAxisFormatter(locale, data, val);
      },
    },
  },
  yaxis: {
    labels: {
      minWidth: 30,
    },
  },
  tooltip: {
    theme: "dark",
  },
  fill: {
    type: "gradient",
    gradient: {
      type: "vertical",
      colorStops: [
        {
          offset: 0,
          color: "#B137EB",
          opacity: 1,
        },
        {
          offset: 90,
          color: "#FF948C",
          opacity: 1,
        },
      ],
    },
  },
});

const apexLocales = () => {
  return [
    {
      name: "ru",
      options: {
        months: [
          "январь",
          "февраль",
          "март",
          "апрель",
          "май",
          "июнь",
          "июль",
          "август",
          "сентябрь",
          "октябрь",
          "ноябрь",
          "декабрь",
        ],
        shortMonths: [
          "янв.",
          "февр.",
          "марта",
          "апр.",
          "мая",
          "июня",
          "июля",
          "авг.",
          "сент.",
          "окт.",
          "нояб.",
          "дек.",
        ],
        days: ["воскресение", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"],
        shortDays: ["вскр", "пнд", "вт", "ср", "чт", "пт", "суб"],
      },
    },
    {
      name: "en",
      options: {
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      },
    },
  ];
};

// This function is called for each x axis element
//
// Set different formats for axis labels based on range (stored in data argument as array of values):
//
// * day and month - if current range is withing a year
// * day, month and year - if current range is large then a year
const xAxisFormatter = (locale, data, val) => {
  let parseFormat = "dd MMM";
  const firstItemDate = data[0]?.date_at;

  if (firstItemDate) {
    const currentYear = new Date().getFullYear();
    const firsXAxisYear = getYear(parseISO(firstItemDate));

    if (firsXAxisYear < currentYear) {
      parseFormat = "dd MMM ''yy";
    }
  }

  const options = dateFnsOptions(locale);
  const dayWithMonth = format(parseISO(val), parseFormat, options);

  return dayWithMonth;
};

const dateFnsOptions = locale => {
  if (locale == "ru") {
    return { locale: ruLocale };
  } else {
    return {};
  }
};
