import _ from "lodash";
import qs from "qs";
import { deepMapKeys } from "./deepMap";

const deepMapKeysCamelCase = obj => deepMapKeys(_.mapKeys)(obj, (val, key) => _.camelCase(key));
const deepMapKeysSnakeCase = obj => deepMapKeys(_.mapKeys)(obj, (val, key) => _.snakeCase(key));
const mapKeysCamelCase = obj => _.mapKeys(obj, (_val, key) => _.camelCase(key));
const mapKeysSnakeCase = obj => _.mapKeys(obj, (_val, key) => _.snakeCase(key));

export { deepMapKeysCamelCase, deepMapKeysSnakeCase, mapKeysCamelCase, mapKeysSnakeCase };
