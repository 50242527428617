<template lang="pug">
q-dialog(:value="isCreateFieldDialogVisible", persistent, @hide="closeForm")
  .col-8.checklist-form__wrapper.checklist-add-field__wrapper
    .checklist-new-form.justify-center
      q-card.checklist-form__card
        q-toolbar.q-px-lg.row-bottom-border
          q-icon.q-pr-md.checklist-form__toolbar__icon(name="description", size="24px")
          span.checklist-form__toolbar__title {{ isEditFieldDialogVisible ? checklistLocales.edit_field : checklistLocales.add_field }}
          q-space
          q-btn(flat, round, icon="close", color="grey", @click="closeForm()")
        q-card-section
          .row.checklist-form__position-relative
            .col-6
              label
                span.text-bold {{ checklistLocales.title }}
                q-input.q-pt-sm(
                  v-model="field.name",
                  outlined,
                  :rules="[val => val.length > 0 || checklistLocales.required_field]",
                  color="indigo-12",
                  :placeholder="checklistLocales.enter_field_title"
                )
            .col-6.checklist-form__position-absolute
              label
                span.text-bold {{ checklistLocales.details }}
                q-input.q-pt-sm(
                  v-model="field.description",
                  outlined,
                  autogrow,
                  color="indigo-12",
                  rows="1",
                  :placeholder="checklistLocales.what_can_help"
                )
          .row
            .col-6
              q-option-group.q-mt-sm(
                :options="fieldIsRequiredOptions",
                v-model="field.isRequired",
                color="indigo-12",
                type="toggle"
              )
          .row
            .col-6
              label
                span.text-bold {{ checklistLocales.field_type }}
                q-select.q-pt-sm(
                  :options="fieldTypeOptions",
                  v-model="field.type",
                  outlined,
                  :rules="[val => val.value != 0 || checklistLocales.required_field]",
                  color="indigo-12",
                  :default="checklistLocales.select_field_type",
                  dropdown-icon="expand_more",
                  popup-content-style="color: var(--modal-form-card-color)"
                )
            .col-6(v-if="checkFieldIsAddItemAllowed()")
              label.q-pb-sm
                span.text-bold {{ labelText }}
                div(style="overflow: auto; max-height: 240px")
                  div(v-for="(option, index) in field.options", style="display: flex; flex-direction: row")
                    q-input.q-pt-sm.special-fields(
                      :value="getFieldValue(option)",
                      @input="val => changeFieldOption(val, option)",
                      outlined,
                      autogrow,
                      :rules="[val => val.length > 0 || checklistLocales.required_field]",
                      color="indigo-12",
                      rows="1",
                      :bottom-slots="false",
                      :placeholder="checklistLocales.subfield_title"
                    )
                    .btn-up-down.q-ml-sm.q-pt-sm
                      q-btn(
                        icon="arrow_drop_up",
                        flat,
                        round,
                        size="sm",
                        @click="correctArr(option, 'up')",
                        v-if="index > 0"
                      )
                      q-btn(
                        icon="arrow_drop_down",
                        flat,
                        round,
                        size="sm",
                        @click="correctArr(option, 'down')",
                        v-if="index < field.options.length - 1"
                      )
              .q-mt-sm
                span.add-another-item(@click="addAnotherOption") {{ checklistLocales.another_option }}
          .row.justify-center.btn-grp.btn-position-absolute
            q-btn(outline, color="indigo-12", @click="closeForm") {{ checklistLocales.cancel }}
            q-btn(
              color="indigo-12 q-ml-sm",
              @click="isEditFieldDialogVisible ? editChecklistField() : addChecklistField()",
              :disabled="disabled",
              type="submit"
            ) {{ isEditFieldDialogVisible ? checklistLocales.edit : checklistLocales.add }}
</template>

<script>
import { v4 } from "uuid";

export default {
  props: {
    isCreateFieldDialogVisible: Boolean,
    isEditFieldDialogVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    editField: {
      type: Object,
      required: false,
      default: () => {},
    },
    allowFiles: {
      type: Boolean,
      required: true,
    },
    checklistLocales: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: function () {
    return {
      fieldIsRequiredOptions: [{ label: this.checklistLocales.required_subfield, value: 1 }],
      fieldTypeOptions: [],
      fieldTypeLabelsOptions: [],
      allowedToExpand: [],
      field:
        this.$props.editField && this.$props.editField.id
          ? this.$props.editField
          : {
              id: v4(),
              name: "",
              description: "",
              isRequired: [],
              type: { label: this.checklistLocales.select_field_type, value: 0 },
              options: [],
            },
      disabled: true,
    };
  },

  computed: {
    labelText() {
      return this.fieldTypeLabelsOptions.filter(elem => this.field.type.value === elem.value).length
        ? this.fieldTypeLabelsOptions.filter(elem => this.field.type.value === elem.value)[0].label || ""
        : "";
    },
  },

  watch: {
    editField: function () {
      this.field = this.editField;
    },
    field: {
      deep: true,
      handler() {
        this.field.type.label === this.checklistLocales.select_field_type ||
        !this.field.name ||
        (this.field.options.length > 0 && this.field.options.filter(elem => elem.value === "").length !== 0)
          ? (this.disabled = true)
          : (this.disabled = false);
      },
    },
    isCreateFieldDialogVisible: function () {
      if (!this.isCreateFieldDialogVisible)
        this.field = {
          id: v4(),
          name: "",
          description: "",
          isRequired: [],
          type: { label: this.checklistLocales.select_field_type, value: 0 },
          options: [{ id: v4(), value: "" }],
        };
      else if (this.isEditFieldDialogVisible) this.field = this.editField;
    },
    isEditFieldDialogVisible: function () {
      if (this.isEditFieldDialogVisible) this.field = this.editField;
      else
        this.field = {
          id: v4(),
          name: "",
          description: "",
          isRequired: [],
          type: { label: this.checklistLocales.select_field_type, value: 0 },
          options: [{ id: v4(), value: "" }],
        };
    },
  },

  created() {
    this.getFieldOptions();
    // eslint-disable-next-line vue/no-mutating-props
    this.checklistLocales = this.locales.checklist[this.current_locale];
  },

  beforeDestroy() {
    this.field = {
      id: v4(),
      name: "",
      description: "",
      isRequired: [],
      type: { label: this.checklistLocales.select_field_type, value: 0 },
      options: [{ id: v4(), value: "" }],
    };
  },

  methods: {
    async getFieldOptions() {
      const newFieldOptions = await this.$backend.index("api/v3/checklist_element_types/collection");

      newFieldOptions.data.options.forEach(elem => {
        if (!this.allowFiles && elem.code === "file") {
          return;
        }
        this.fieldTypeOptions.push({ label: elem.title, value: elem.id });
        this.fieldTypeLabelsOptions.push({ label: elem.add_item_title, value: elem.id });
        if (elem.is_add_item_allowed) {
          this.allowedToExpand.push(elem.id);
        }
      });
    },

    closeForm() {
      this.$emit("update:isCreateFieldDialogVisible", false);
      this.$emit("update:isEditFieldDialogVisible", false);
    },

    changeFieldOption(val, option) {
      const index = this.field.options.findIndex(elem => elem.id === option.id);
      if (index !== -1) {
        this.field.options[index].value = val;
      }
    },

    getFieldValue(option) {
      const index = this.field.options.findIndex(elem => elem.id === option.id);
      if (index !== -1) {
        return this.field.options[index].value;
      }
    },

    checkFieldIsAddItemAllowed() {
      if (this.editField && this.editField.options.length && this.isEditFieldDialogVisible) {
        return true;
      }
      const res = this.field.type && this.allowedToExpand.includes(this.field.type.value);
      if (res && this.field.options.length === 0) {
        this.field.options.push({ id: v4(), value: "", _new: true });
      }
      return res;
    },

    addAnotherOption() {
      this.field.options.push({ id: v4(), value: "", _new: true });
    },

    addChecklistField() {
      this.$emit("on-new-field-created", this.field);
      this.closeForm();
    },

    editChecklistField() {
      this.$emit("on-field-edit", this.field);
      this.closeForm();
    },

    correctArr(element, direction) {
      let _arr = this.field.options;
      const index = this.field.options.findIndex(elem => elem.id === element.id);
      let first_el;
      let second_el;

      if (direction === "up") {
        first_el = _arr[index - 1];
        second_el = _arr[index];
        this.$set(this.field.options, index - 1, second_el);
        this.$set(this.field.options, index, first_el);
      } else {
        first_el = _arr[index + 1];
        second_el = _arr[index];
        this.$set(this.field.options, index + 1, second_el);
        this.$set(this.field.options, index, first_el);
      }
    },
  },
};
</script>

<style lang="scss">
.checklist-add-field {
  &__wrapper {
    .q-dialog__inner {
      padding-top: 40px;
    }

    .special-fields {
      .q-field__bottom {
        display: none !important;
      }
    }

    .btn-grp .q-btn {
      padding: 5px 30px;
      text-transform: none;
      border-radius: 8px;
    }

    .q-select--without-chips {
      .q-field__control-container {
        flex-grow: 10;
      }
    }

    .add-another-item {
      color: #5b7aff;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }

      &:active {
        opacity: 0.5;
      }
    }
    &.checklist-form {
      &__wrapper {
        box-shadow: none !important;
      }
    }
    .checklist-form {
      &__card {
        border-radius: 15px;
        box-shadow: none;
      }

      &__position-relative {
        margin-top: 30px !important;
      }
    }

    .checklist-new-form {
      padding-top: 20px;
    }

    .row-bottom-border {
      border-bottom: 1px solid lightgrey;
    }
  }
}
</style>
