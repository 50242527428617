import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const issues_tabs = {
  state: {
    tabs_order: null,
  },

  mutations: {
    updateIssuesTabsOrder(state, val) {
      state.tabs_order = val;
    },

    resetIssuesTabsOrder(state) {
      state.tabs_order = null;
    },
  },
};
