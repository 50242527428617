<template lang="pug">
index-page(
  @set-loading="setLoading",
  :parentData="{ grid, path, grid_key, report_class, headerSearchClass: 'col-11 no-padding', headerFiltersContainerClass: 'col-1' }"
)
</template>

<script>
import indexPage from "../../shared/indexPage";

export default {
  components: {
    indexPage,
  },
  data: function () {
    return {
      grid: "documents",
      report_class: "document",
      path: this.$store.state.paths["document"],
      grid_key: "data",
    };
  },

  beforeMount() {
    this.$emit("expand-class", { val: false, class: "" });
  },

  methods: {
    setLoading(val) {
      this.$emit("set-loading", val);
    },
  },
};
</script>

<style lang="scss"></style>
