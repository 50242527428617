<template lang="pug">
q-dialog(:value="isDeleteActivityFormShown", @hide="closeForm")
  q-card.activity-delete-form__card
    .row.justify-between
      .activity-delete-form__title
        span.activity-save-form__title__text {{ `${workflowConstructorLocales?.destroy_activity} №${id}?` }}

    .activity-delete-form__buttons-group
      button(type="secondary", :label="workflowConstructorLocales.cancel", @click="closeForm")
      button(type="primary", :label="workflowConstructorLocales.destroy", @click="onDelete")
</template>

<script setup>
import { ref } from "vue";
import Button from "@/components/shared/general_components/inputs/Button.vue";
import backend from "@/api";

import { workflowConstructorLocales } from "@/services/useLocales";
import { handleError } from "@/services/handleErrors";

const props = defineProps({
  id: { type: Number, required: true, default: null },
  isDeleteActivityFormShown: { type: Boolean, required: true, default: false },
});

const emit = defineEmits(["hideDialog", "delete"]);

const closeForm = () => {
  emit("hideDialog");
};

const onDelete = async () => {
  try {
    const response = await backend.destroy("api/v3/activities", props.id);
    emit("deleteActivity", props.id);
    emit("hideDialog");
  } catch (e) {
    await handleError(e);
  }
};
</script>

<style lang="scss" scoped>
.activity-delete-form {
  &__card {
    border-radius: 20px !important;
    color: var(--expansion-list-text-color);
    background: var(--expansion-list-background);
    width: 350px;
    padding: 32px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    gap: 10px;
  }

  &__buttons-group {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
