<template lang="pug">
.issue-center__preset 
  timeline(
    v-if="getTemplate('timeline')",
    ref="timelineComponent",
    :firstHour="firstHour",
    :lastHour="lastHour",
    :initialHour="initialHour",
    :displayRules="displayRules",
    :mode="mode",
    @openShow="openShow",
    @changeHours="changeInitialHours"
  )

  dateline(
    v-if="getTemplate('dateline')",
    ref="datelineComponent",
    :firstDate="firstDate",
    :lastDate="lastDate",
    :initialDate="initialDate",
    :displayRules="displayRules",
    :mode="mode",
    @openShow="openShow",
    @openDate="openDate",
    @changeDates="changeInitialDates"
  )

  calendar(
    v-if="getTemplate('calendar')",
    ref="calendarComponent",
    :firstCalendarDate="firstCalendarDate",
    :lastCalendarDate="lastCalendarDate",
    :initialCalendarDate="initialCalendarDate",
    :mode="mode",
    @openDate="openDate",
    @changeCalendarDates="changeInitialCalendarDates"
  )
</template>

<script setup>
import { ref, onMounted } from "vue";

import Timeline from "./tabs/timeline";
import Dateline from "./tabs/dateline";
import Calendar from "./tabs/calendar";

const props = defineProps({
  firstHour: { type: Date, default: undefined, required: false },
  lastHour: { type: Date, default: undefined, required: false },
  initialHour: { type: Date, default: undefined, required: false },
  firstDate: { type: Date, default: undefined, required: true },
  lastDate: { type: Date, default: undefined, required: true },
  initialDate: { type: Date, default: undefined, required: true },
  firstCalendarDate: { type: Date, default: undefined, required: true },
  lastCalendarDate: { type: Date, default: undefined, required: true },
  initialCalendarDate: { type: Date, default: undefined, required: true },
  mode: { type: String, default: undefined, required: false },
  displayRules: { type: Array, default: () => [], required: true },
});

const emit = defineEmits([
  "changeInitialHours",
  "changeInitialDates",
  "changeInitialCalendarDates",
  "openDate",
  "openShow",
]);

const timelineComponent = ref();
const datelineComponent = ref();
const calendarComponent = ref();

const changeHours = async prefix => {
  timelineComponent.value.changeHours(prefix);
};

const changeDates = async prefix => {
  datelineComponent.value.changeDates(prefix);
};

const changeCalendarDates = async prefix => {
  calendarComponent.value.changeCalendarDates(prefix);
};

const changeInitialHours = event => {
  emit("changeInitialHours", event);
};

const changeInitialDates = event => {
  emit("changeInitialDates", event);
};

const changeInitialCalendarDates = event => {
  emit("changeInitialCalendarDates", event);
};

const openDate = event => {
  emit("openDate", event);
};

const openShow = event => {
  emit("openShow", event);
};

const getTemplate = param => {
  if (param === props.mode) return true;
};

defineExpose({ changeHours, changeDates, changeCalendarDates });
</script>
