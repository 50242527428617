<template lang="pug">
q-btn(flat, no-caps, :label="label", :disable="disable", @click="$emit('click', $event)", :class="buttonClass")
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  label: { type: String, required: true }, // label, example: "Cancel"
  type: { type: String, required: true }, // type of button. Available options: "primary", "secondary"
  disable: { type: Boolean, required: false, default: false }, // is button disabled
});

const buttonClass = computed(() => ({
  "general-button": true,
  "general-button--secondary": props.type === "secondary",
  "general-button--primary": props.type === "primary",
}));
</script>

<style scoped lang="scss">
.general-button {
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
}

.general-button--primary {
  background-color: var(--general-components-button-primary-background);
  color: var(--general-components-button-primary-color);
  margin-left: 8px;
}

.general-button--secondary {
  background-color: var(--general-components-button-secondary-background);
  color: var(--general-components-button-secondary-color);
  box-shadow: var(--general-components-button-secondary-box-shadow);
}
</style>
