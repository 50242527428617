<template lang="pug">
.filters-form
  .filters-form__fields(v-show="!loading")
    div(v-for="filter in filterList", :key="filter.name")
      component(
        selectionLabel,
        :ref="el => { filtersRefs[filter.name] = el; }",
        :is="getFilterComponent(filter)",
        :parentData="{ grid, data: [filter], options_data: optionsData, value: filter.value }",
        :dataLocales="currentLocales",
        @load-options="loadFilterOptions",
        @resetFilter="resetFilter"
      )
  .filters-form__fields(v-show="loading")
    div(v-for="filter in filterList", :key="filter.name")
      q-skeleton(height="47px")
  .filters-form__actions
    .filters-form__actions__buttons
      button(type="primary", :label="currentLocales.submit", @click="submit")
      button(type="secondary", :label="filtersSaveButtonText", @click="onFiltersSave")

  filters-save-form(
    :name="filterNameProp",
    :id="filterIdProp",
    :show="showFiltersSaveForm",
    @closeForm="closeFiltersSaveForm",
    @filtersUpdated="filtersUpdated",
    :facilityId="facilityId",
    :selectedFilters="currentFilters"
  )
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "@/store";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { dynamicIssuesLocales } from "@/services/useLocales";
import Filters from "@/components/shared/filters";
import Checkbox from "@/components/shared/general_components/inputs/Checkbox.vue";
import Button from "@/components/shared/general_components/inputs/Button.vue";
import FiltersSaveForm from "./FiltersSaveForm.vue";
import { LocalStorage } from "quasar";

import _ from "lodash";

const props = defineProps({
  /* 
  Данные с бэкенда по форме, содержат поля и основную информацию по строению
  */
  filtersData: { type: Object, default: () => {} },
  /* 
  Имя грида
  */
  grid: { type: String, default: "" },
  /* 
  Идентификатор фасилити
  */
  facilityId: { type: Number, required: true },
  /* 
  Объект с выбранным фильтром, который содержит идентификатор и имя в виде label, value
  */
  selectedFilter: { type: Object || null, required: false, default: null },
});

const emit = defineEmits(["submit", "changeOrCondition", "allSelectsLoaded"]);
const store = useStore();

const filtersRefs = ref({});
const optionsData = ref();
const loadedSelectMapCount = ref({});
const selectLoadOptionsEmited = ref(false);
const showFiltersSaveForm = ref(false);
const loadingFilters = ref(
  props.filtersData.filters.filter(el => el.options_path && !el.common_key).map(el => el.name),
);
const loading = ref(true);
const list = ref(props.filtersData.filters.filter(filter => filter.name != "query"));

const currentFilters = computed(() => store.state.grid[props.grid]["filters"]);

const currentLocales = computed(() => dynamicIssuesLocales.value.filters);

const filtersSaveButtonTitle = computed(() =>
  props.selectedFilter?.label ? currentLocales.value.update_filter : currentLocales.value.save_filter,
);

const totalSelectCount = computed(() => filterList.value.filter(filter => filter.type == "select").length);

const filtersSaveButtonText = computed(() => filtersSaveButtonTitle.value || "");

const filterNameProp = computed(() => props.selectedFilter?.label || "");

const filterIdProp = computed(() => props.selectedFilter?.value.toString());

const filterList = computed(() => {
  const excludedCommonKeys = [];

  // Create new list where we group dynamic float fields into single object.
  // This new item contains all children in 'items' key
  // This is needed to displya float dynamic numbers in a special select component
  const listWithGroupedDynamicFloats = list.value
    .map(filter => {
      const commonKey = filter.common_key;

      // Mark child as removable if it was grouped with a previuos child
      if (excludedCommonKeys.includes(commonKey)) {
        return null;
      }

      // выполнение операций по изменению стора в данном вычисленном свойстве необходимо для повышения стабильности исполнения и для сто процентной уверенности

      if (filter.type == "float" && commonKey) {
        // Group 2 float fields into one
        const items = list.value.filter(f => f.common_key == commonKey);
        excludedCommonKeys.push(commonKey);

        updateStoreFilter(filter);

        items.forEach(el => {
          updateStoreFilter(el);
        });

        return { label: filter.label, type: "select_dynamic_numbers", items };
      } else if (filter.type == "date" && commonKey) {
        const items = list.value.filter(f => f.common_key == commonKey);
        excludedCommonKeys.push(commonKey);

        updateStoreFilter(filter);

        items.forEach(el => {
          updateStoreFilter(el);
        });
        return { label: filter.label, type: "dates_dynamic_filter", items, name: commonKey };
      } else {
        return filter;
      }
    })
    .filter(filter => filter != null); // Remove values we grouped into one

  return listWithGroupedDynamicFloats;
});

const filtersUpdated = id => {
  emit("filtersUpdated", id);
};

const updateStoreFilter = filter => {
  const localFilters = store.state.grid[props.grid].filters;
  if (!localFilters || !localFilters[filter.name]) {
    if (filter.value) {
      store.commit("updateFilter", { grid_name: props.grid, filter: filter.name, value: filter.value });
    } else {
      store.commit("resetFilter", { grid_name: props.grid, filter: filter.name });
    }
  }
};

const resetFilter = filter => {
  list.value = list.value.map(el => {
    if (el.name === filter.name) {
      return { ...el, value: null };
    } else {
      return el;
    }
  });
};

const getFilterComponent = key => {
  if (key) {
    // Override checkbox with custom select to fit design
    const type = key.type == "checkbox" ? "select_boolean" : key.type;

    return Filters[type];
  }
};

const submit = () => {
  emit("submit");
};

const loadFilterOptions = async data => {
  const currentFilter = props.filtersData.filters.find(f => f.name === data.filter_name);

  if (currentFilter.watch && currentFilter.watch["parent"]) {
    const parent = currentFilters.value[currentFilter.watch["parent"]];

    if (parent && parent.value) {
      const attr = Object.keys(currentFilter.options_params).reduce((result, key) => {
        result[key] = currentFilter.options_params[key];
        return result;
      }, {});

      attr[currentFilter.watch["parent"]] = parent.value;

      await loadSelectFilterOptions({ params: attr, filter_name: currentFilter.name });
    } else {
      await loadSelectFilterOptions(data);
    }
  } else {
    await loadSelectFilterOptions(data);
  }
};

const loadSelectFilterOptions = async attr => {
  const filter = props.filtersData.filters.find(f => f.name === attr.filter_name);
  const currentFilter = currentFilters.value[attr.filter_name];
  if (currentFilter) {
    if (filter.multiple) {
      attr.params["default_value"] = currentFilter.map(v => v.value);
    } else {
      attr.params["default_value"] = currentFilter.value || currentFilter;
    }
  } else {
    attr.params["default_value"] = filter.value;
  }

  // Fetch
  try {
    const optionsDataObj = await loadCollection(filter, attr.params);

    if (optionsData.value?.options && optionsDataObj.options) {
      optionsData.value.options = _.unionBy(optionsData.value.options, optionsDataObj.options, "value");
    }

    if (optionsData.value?.opt_object && optionsDataObj.opt_object) {
      optionsData.value.opt_object = {
        count: optionsDataObj.opt_object.count,
        options: _.unionBy(optionsData.value.opt_object.options, optionsDataObj.opt_object.options, "value"),
      };
    }

    if (!optionsData.value) {
      optionsData.value = optionsDataObj;
    }

    const isFilterPresentedInStore = store.state.grid[props.grid].filters[filter.name];

    if (filter.value !== null && !isFilterPresentedInStore) {
      let newVal = null;

      if (!filter.multiple) {
        newVal = optionsDataObj.options.find(opt => opt.value.toString() === filter.value.toString());
      } else {
        newVal = filter.value.map(el => {
          const option = optionsData.value.options.find(opt => opt.value.toString() === el.toString());

          if (!option)
            console.error(
              "Option that is going to filter is not presented in optionsData.options and may possibly be undefined or null",
            );

          return option;
        });
      }

      store.commit("updateFilter", { grid_name: props.grid, filter: filter.name, value: newVal });
    }

    // Set child data
    if (filtersRefs.value && filtersRefs.value[filter.name]) {
      const filterRef = filtersRefs.value[filter.name];

      filterRef.setOptionsData(optionsDataObj);
      filterRef.setLoading(false);
      // condition for composition use ref functions
    } else if (filtersRefs.value && filtersRefs.value[filter.common_key]) {
      const filterRef = filtersRefs.value[filter.common_key];
      filterRef._setupState.setOptionsData(optionsDataObj);
      filterRef._setupState.setLoading(false);
    }

    loadingFilters.value = loadingFilters.value.filter(el => el !== filter.name);

    if (loadingFilters.value.length <= 0) {
      loading.value = false;
    }
  } catch (e) {
    await handleError(e);
  }
};

const loadCollection = async (filter, params) => {
  const filterName = filter.name;
  const optionsPath = filter.options_path;

  if (!optionsPath) {
    throw "Filter does not contain options_path data. Please add code logic for that";
  }

  try {
    const url = `${store.state.paths[optionsPath]}/collection`;
    const response = await backend.collection(url, params);

    const responseData = response.data;
    const selectOptions = responseData.options
      .map(el => {
        return [el["title"] ? el["title"] : el["full_name"], el.id];
      })
      .map(el => {
        return el.reduce((result, val, index, arr) => {
          result["label"] = arr[0];
          result["value"] = arr[1];
          return result;
        }, {});
      });
    const optionsDataObject = { opt_object: responseData, options: selectOptions };

    // Notify parent that show button can be enabled now
    // This event should only happen once
    notifyOnLoadFinish(filterName);

    return optionsDataObject;
  } catch (error) {
    await handleError(error);
  }
};

const notifyOnLoadFinish = filterName => {
  if (!loadedSelectMapCount.value[filterName]) {
    loadedSelectMapCount.value = { ...loadedSelectMapCount.value, [filterName]: true };
  }

  const loadedSelectCount = Object.keys(loadedSelectMapCount.value).length;

  if (!selectLoadOptionsEmited.value && loadedSelectCount == totalSelectCount.value) {
    emit("allSelectsLoaded");
    selectLoadOptionsEmited.value = true;
  }
};

const changeOrCondition = val => {
  emit("changeOrCondition", val);
};

const onFiltersSave = async () => {
  showFiltersSaveForm.value = true;
};

const closeFiltersSaveForm = () => {
  showFiltersSaveForm.value = false;
};
</script>

<script>
export default {
  name: "FiltersForm",
};
</script>

<style scoped lang="scss">
.filters-form {
  background-color: var(--dynamic-issues-filters-form-background);
  border-radius: 16px;

  &__fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 10px;
    padding: 10px;
  }

  &__actions {
    display: flex;
    padding: 10px 2px 10px 2px;

    &__buttons {
      display: flex;
      gap: 10px;
    }

    .actions__options {
      flex: 1;
    }

    &::after {
      flex: 1;
      content: "";
    }
  }
}
</style>
