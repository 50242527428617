<template lang="pug">
.modal-form-card
  q-card-section
    form-header(ref="form-header", @close-form="closeModal", :parentData="{ title: post.created_at }")

  .post-modal-area
    q-card-section.post-modal-img
      img(
        :src="post && post.image_url ? post.image_url : require('../../../../assets/icons/dashboard/posts/missing_post.svg')"
      )

    q-card-section.post-modal-title
      span {{ post.title }}

    q-card-section.post-modal-content
      span.post-title(v-html="post.content")
</template>

<script setup>
import { defineProps, ref, computed } from "vue";
import formHeader from "../../../shared/forms/formHeader";

const props = defineProps({
  post: { type: Object, default: () => {} },
});

const emit = defineEmits(["close-modal"]);

const closeModal = () => {
  emit("close-modal");
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/modal-form";

.post-modal-area {
  padding-left: 15px;
  padding-right: 15px;

  .post-modal-img {
    text-align: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 350px;
    }
  }

  .post-modal-title {
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 21px;
      color: var(--modal-form-card-color);
    }
  }

  .post-modal-content {
    padding-bottom: 50px;

    .post-title {
      h1,
      h2,
      h3,
      h4,
      h5,
      p,
      i,
      em {
        strong {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: var(--modal-form-card-color) !important;
        }

        big,
        span,
        em {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--modal-form-card-color) !important;
        }

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--modal-form-card-color) !important;
      }

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: var(--modal-form-card-color) !important;
    }
  }
}
</style>
