<template lang="pug">
div
  .work-equipments-list
    .work-equipments-list-thead
      .list-item.title
        span {{ work_equipment_locales.work_title }}

      .list-item.is-active
        span {{ work_equipment_locales.is_active }}

      .list-item.weeks
        span {{ work_equipment_locales.weeks }}

      .list-item.delete(v-if="collection && collection[0] && collection[0]['can_delete']")

    .work-equipments-list-tbody(v-for="(item, i) in collection")
      .wrapper
        .list-item.title
          span {{ item.title }}

        .list-item.is-active
          .form-checkbox
            q-checkbox(
              dark,
              :value="item.is_active",
              :label-slot="false",
              @input="onChange(item, 'is_active', $event)"
            )

        .list-item.weeks
          .form-field.form-input
            q-input(
              filled,
              no-error-icon,
              :value="item.weeks",
              :label-slot="false",
              @input="onChange(item, 'weeks', $event)",
              @clear="onChange(item, 'weeks', $event)"
            )

        .list-item.delete(v-if="item && item.can_delete")
          q-icon.delete-work-equipment-icon(name="cancel", @click.stop="onDelete(item)", title="Удалить")

  q-dialog(v-model="confirm_modal")
    confirm-modal(@confirm="confirmResult", :parentData="{ message: confirm_message }")
</template>

<script>
export default {
  components: {
    confirmModal: () => import("../../../shared/helpers/confirmModal"),
  },

  props: {
    collection: {
      type: Array,
      default: () => [],
    },
    path: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      confirm_modal: false,
      confirm_message: "",
      destroy_data: undefined,
    };
  },

  created(attr = {}) {},

  beforeMount() {},

  methods: {
    onDelete(item) {
      this.destroy_data = item;
      this.confirm_message = this.notifies.confirm_destroy_ppr_work;
      this.confirm_modal = true;
    },

    onChange(item, field, e, params = {}, attr = {}) {
      item[field] = e;

      if (field === "weeks") {
        attr[field] = e.replace(/\s/g, "").split(",");
      } else {
        attr[field] = e;
      }

      params["ppr_work_equipment"] = attr;

      this.$backend
        .update(this.path, item.id, params)
        .then(({ data }) => {
          this.$q.notify(this.notifies.success_updated_entry);
        })
        .catch(error => {
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.$q.notify(this.notifies.error_ask_admin);
          console.log(error);
        });
      // .finally(() => {
      //   this.$emit('load-collection')
      // })
    },

    confirmResult(val) {
      if (val) {
        this.$backend
          .destroy(this.path, this.destroy_data.id)
          .then(({ data }) => {
            this.confirm_modal = false;
            this.destroy_data = undefined;
            this.$emit("load-collection");
            this.$q.notify(this.notifies.success_deleted_ppr_work);
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.$q.notify(this.notifies.error_ask_admin);
          });
      } else {
        this.confirm_modal = false;
        this.destroy_data = undefined;
      }
    },
  },
};
</script>

<style lang="scss">
.work-equipments-list {
  background-color: var(--selected-items-form-background);
  color: var(--selected-items-form-color);
  border-radius: 8px !important;
  margin-top: 10px;
  margin-bottom: 20px;

  .work-equipments-list-thead {
    display: flex;
    padding: 5px;

    .list-item {
      padding: 10px;
    }

    .list-item.title {
      flex: 40%;
    }
    .list-item.is-active {
      flex: 20%;
    }
    .list-item.weeks {
      flex: 30%;
    }
    .list-item.delete {
      flex: 10%;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      padding: 10px;
      display: block;
    }
  }

  .work-equipments-list-tbody {
    padding-bottom: 10px;

    .wrapper {
      display: flex;
      padding: 5px;

      .list-item {
        padding: 10px;
      }
      .list-item.title {
        flex: 40%;
      }
      .list-item.is-active {
        flex: 20%;
        text-align: center;
      }
      .list-item.weeks {
        @import "../../../../assets/styles/forms/fields/string";

        flex: 30%;
      }
      .list-item.delete {
        flex: 10%;

        .delete-work-equipment-icon {
          cursor: pointer;
          left: 10px;
          top: 10px;

          color: var(--field-cancel-icon-color);
        }
      }

      .form-input {
        border: initial;
      }

      @import "../../../../assets/styles/forms/main";
      @import "../../../../assets/styles/forms/fields/checkbox";

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        padding: 10px;
        display: block;
      }
    }
  }
}
</style>
