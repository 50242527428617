<template lang="pug">
div
  .form-field-label
    span {{ field.label }}

  .form-field.form-input
    q-input(
      filled,
      no-error-icon,
      :value.number="workflowFloatFieldValue",
      autocomplete="off",
      type="number",
      @input="setField",
      :rules="generateRules"
    )
      q-icon.cancel-string-field(name="cancel", v-if="workflowFloatFieldValue", @click.stop="resetInputField()")
</template>

<script setup>
import { ref, computed } from "vue";
import { workflowConstructorLocales } from "@/services/useLocales";

const props = defineProps({
  field: { type: Object, default: () => {} },
  object: { type: Object, default: () => {} },
});

const emit = defineEmits(["fieldChange", "disableField", "enableField"]);

const workflowFloatFieldValue = ref(props.object[props.field.name]);

const setField = val => {
  workflowFloatFieldValue.value = parseFloat(val);
  emit("fieldChange", { name: props.field.name, value: parseFloat(val) });
};

const resetInputField = () => {
  workflowFloatFieldValue.value = "";
  emit("fieldChange", { name: props.field.name, value: null });
};

const generateRules = computed(() => {
  const rules = [];
  if (props.field.options) {
    if (props.field.options.min !== undefined) {
      rules.push(event => ruleMin(event) || ruleMinError());
    }
    if (props.field.options.max !== undefined) {
      rules.push(event => ruleMax(event) || ruleMaxError());
    }
  }

  return rules;
});

const ruleMin = event => {
  emitFieldEnabled();
  return event >= props.field.options.min;
};

const ruleMax = event => {
  emitFieldEnabled();
  return event <= props.field.options.max;
};

const ruleMinError = () => {
  emitFieldDisabled();
  return `${workflowConstructorLocales.value.should_be_greater} ${props.field.options.min}`;
};

const ruleMaxError = () => {
  emitFieldDisabled();
  return `${workflowConstructorLocales.value.should_be_less} ${props.field.options.max}`;
};

const emitFieldDisabled = () => {
  emit("disableField", props.field.field_id);
};

const emitFieldEnabled = () => {
  emit("enableField", props.field.field_id);
};
</script>
