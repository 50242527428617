function kindFilter(kinds, entities) {
  if (kinds.size == 0) {
    return entities;
  }

  const kindToFilter = {
    workers: isWorker,
    buildings: isBuilding,
    vehicles: isVehicle,
    emergencies: isBuildingWithUrgentIssues,
  };

  const filters = [...kinds].map(k => {
    const f = kindToFilter[k];
    if (!f) {
      console.error(`kindFilter: no filter for kind ${k}`);
      return () => false;
    }

    return f;
  });

  return entities.filter(e => filters.some(f => f(e)));
}

function isWorker(entity) {
  return entity.getType() == "worker";
}

function isBuilding(entity) {
  return entity.getType() == "building";
}

function isVehicle(entity) {
  return entity.getType() == "vehicle";
}

function isBuildingWithUrgentIssues(entity) {
  return isBuilding(entity) && entity.hasUrgentIssues() > 0;
}

export default kindFilter;
