import Vue from "vue";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import borderRadius from "highcharts-border-radius";

borderRadius(Highcharts);

Vue.use(HighchartsVue);

export default HighchartsVue;
