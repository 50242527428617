import acceptTransition from "./default_transition";
import finishTransition from "./default_transition";
import processTransition from "./default_transition";
import closeTransition from "./default_transition";
import changeExecutorsTransition from "./default_transition";
import rejectTransition from "./default_transition";
import returnTransition from "./default_transition";
import checkOnTransition from "./default_transition";
import reopenTransition from "./default_transition";
import reworkTransition from "./default_transition";
import pauseTransition from "./default_transition";
import restoreTransition from "./default_transition";
import addUserTransition from "./default_transition";
import addWorkCategoryTransition from "./default_transition";
import addBuildingTransition from "./default_transition";
import addPprSystem from "./default_transition";
import isArchive from "./default_transition";
import addMeterReading from "./default_transition";
import addMeterVerifying from "./default_transition";

export default {
  accept: acceptTransition,
  finish: finishTransition,
  process: processTransition,
  reject: rejectTransition,
  close: closeTransition,
  return: returnTransition,
  restore: restoreTransition,
  executor_changing: changeExecutorsTransition,
  check_on: checkOnTransition,
  reopen: reopenTransition,
  rework: reworkTransition,
  pause: pauseTransition,
  add_user: addUserTransition,
  add_work_category: addWorkCategoryTransition,
  add_building: addBuildingTransition,
  add_ppr_system: addPprSystem,
  is_archive: isArchive,
  add_meter_reading: addMeterReading,
  add_meter_verifying: addMeterVerifying,
};
