import AbstractEntity from "./AbstractEntity";

class Vehicle extends AbstractEntity {
  getType() {
    return "vehicle";
  }

  getModel() {
    return this.data.model;
  }

  getPlateNumber() {
    return this.data.plate_number;
  }

  getVehicleType() {
    return this.data.additional_data.vehicle_type;
  }

  getDriverName() {
    return this.data.additional_data.driver_name;
  }

  getDriverPhoneNumber() {
    return this.data.additional_data.driver_phone_number;
  }
}

export default Vehicle;
