import selectFilter from "./select_filter";
import queryFilter from "./query_filter";
import dateFilter from "./date_filter";
import checkboxFilter from "./checkbox_filter";
import selectedDateLabel from "./selected_date_label";
import selectBooleanFilter from "./select_boolean_filter";
import selectDynamicNumbersFilter from "./select_dynamic_numbers_filter";
import datesDynamicFilter from "./dates_dynamic_filter";

export default {
  select: selectFilter,
  query: queryFilter,
  date: dateFilter,
  datetime: dateFilter,
  checkbox: checkboxFilter,
  selected_date_label: selectedDateLabel,
  select_boolean: selectBooleanFilter,
  select_dynamic_numbers: selectDynamicNumbersFilter,
  dates_dynamic_filter: datesDynamicFilter,
};
