<template lang="pug">
.actions-metrics-form
  q-card-section(v-show="!loading")
    form-header(
      ref="formHeaderRef",
      @close-form="closeForm",
      :parentData="{ title: formLocales?.title, title_icon: 'edit.svg' }"
    )

  div
    .spinner-container.form-spinner(v-if="loading")
      q-spinner(color="primary", size="3em")

  .metrics-container(v-show="!loading")
    div(v-for="(fieldsItems, index) in fields") 
      action-form-metric(
        :fields="fieldsItems",
        :coefficent-fields="coefficentFields",
        :default-coefficients="defaultCoefficients",
        :metric-index="index",
        :metrics-count="fields.length",
        :localesData="formLocales",
        :deleted="isMetricDeleted(fieldsItems)",
        @change="onChange(index, $event)",
        @delete="onDelete(index, $event)",
        @recover="onRecover",
        @metric-select-mounted="onMetricSelectMounted",
        @metric-select-initial-load="onMetricSelectInit"
      )
    .add-metric-button(@click="addMetric")
      q-btn.add-metric-button--color(round, outline, size="xs", icon="fas fa-plus")
      span.add-metric-button__text {{ formLocales.buttons?.add }}

  q-card-actions.row.justify-center(v-if="!loading")
    q-btn.modal-form-buttons-cancel(flat, no-caps, :label="formLocales.buttons.cancel", @click="closeForm")
    q-btn.modal-form-buttons-submit(
      flat,
      no-caps,
      :label="formLocales.buttons.submit",
      :disable="submiting",
      @click="submitForm"
    )
</template>

<script setup>
import { ref, computed, onMounted, reactive } from "vue";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { deepMapKeysSnakeCase } from "@/services/caseConvert";
import { Notify } from "quasar";
import { notifies } from "@/services/useLocales";
import formHeader from "@/components/shared/forms/formHeader";
import actionFormMetric from "./Metric.vue";

const props = defineProps({
  parentData: { type: Object, default: () => {} }, // Contains: callback_params, grid, row (workflow item)
});

const emit = defineEmits(["close-form"]);

const selectMountedCount = ref(0);
const selectInitCount = ref(0);
const selectInitialFetchingFinished = ref(false);
const loadingForm = ref(true);
const submiting = ref(false);
const deleteMetricIds = ref([]);
const fields = ref([]);
const newFields = ref([]); // example of fields from backend to create new metric
const coefficentFields = ref([]);
const formLocales = ref({});
const formHeaderRef = ref();

const workflowId = computed(() => props.parentData.row.id);
const formTitle = computed(() => formLocales.value?.title || "");
const defaultCoefficients = computed(() => newFields.value.find(field => field.name == "coefficients").value);

const loading = computed(() => {
  if (loadingForm.value) {
    return true;
  }

  // To able to show form when we have 0 metrics and skip loader when add first metric
  if (fields.value.length <= 1) {
    return false;
  }

  return !selectInitialFetchingFinished.value;
});

const loadMetrics = async () => {
  try {
    const response = await backend.index(`/api/v3/workflows/${workflowId.value}/metrics/edit_form`);
    fields.value = response.data.fields;
    newFields.value = response.data.new_fields;
    coefficentFields.value = response.data.coefficient_fields;
    formLocales.value = response.data.form;
  } catch (error) {
    await handleError(error);
  } finally {
    loadingForm.value = false;
    formHeaderRef.value.setLoading(false);
  }
};

const updateMetrics = async () => {
  submiting.value = true;
  const allMetricsList = fields.value.map(fieldsRow => {
    const fieldsRowObject = Object.fromEntries(fieldsRow.map(field => [field.name, field.value]));
    return fieldsRowObject;
  });

  // Remove deleted metrics from attributes to avoid saving them
  const metricsList = allMetricsList.filter(metric => !deleteMetricIds.value.includes(metric.id));

  try {
    await backend.put(`/api/v3/workflows/${workflowId.value}/metrics/update_form`, {
      metrics: {
        attributes: metricsList,
        delete_ids: deleteMetricIds.value,
      },
    });

    showUpdatedNotification(metricsList);
    closeForm();
  } catch (error) {
    await handleError(error);
  } finally {
    submiting.value = false;
  }
};

const showUpdatedNotification = metricsList => {
  if (metricsList.length > 1) {
    Notify.create({ badgeStyle: "display: none;", message: `${notifies.value.success_updated_list}` });
  } else {
    Notify.create({ badgeStyle: "display: none;", message: `${notifies.value.success_updated_entry}` });
  }
};

const isMetricDeleted = fieldRow => {
  const metricId = fieldRow.find(field => field.name === "id").value;
  const deleted = deleteMetricIds.value.includes(metricId);

  return deleted;
};

// Change form
const onChange = (index, newField) => {
  fields.value = updatedFields(index, newField); // sync changes with fields since children use updates internally
};

const onDelete = (index, id) => {
  if (id !== null) {
    deleteMetricIds.value.push(id);
  }
};

const onRecover = id => {
  deleteMetricIds.value = deleteMetricIds.value.filter(existingId => existingId !== id);
};

const onMetricSelectMounted = () => {
  if (selectInitialFetchingFinished.value) {
    return;
  }

  selectMountedCount.value = selectMountedCount.value + 1;
};

const onMetricSelectInit = () => {
  if (selectInitialFetchingFinished.value) {
    return;
  }

  selectInitCount.value = selectInitCount.value + 1;

  if (selectMountedCount.value === selectInitCount.value) {
    selectInitialFetchingFinished.value = true;
  }
};

const updatedFields = (index, newField) => {
  const fieldRow = fields.value[index];
  const newFieldRow = fieldRow.map(field => (field.name == newField.name ? newField : field));
  const newFields = fields.value.map((existingFieldRow, fieldsIndex) =>
    fieldsIndex === index ? newFieldRow : existingFieldRow,
  );

  return newFields;
};

const closeForm = () => {
  emit("close-form", {});
};

const addMetric = () => {
  fields.value.push(newFields.value);
};

const submitForm = async () => {
  await updateMetrics();
};

onMounted(async () => {
  await loadMetrics();
});
</script>

<script>
export default {
  name: "ActionsMetricsForm",
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/styles/forms/modal-form-buttons.scss";

.actions-metrics-form {
  border-radius: 15px 0 0 15px !important;
  background: var(--new-edit-form-background);
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  right: 0;
  top: 0;
  width: 950px;
  min-height: 100%;
  height: 100%;
}

.metrics-container {
  padding-left: 20px;
  padding-right: 20px;
}

.add-metric-button {
  color: var(--modal-form-cancel-color);
  margin-top: 15px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &-icon {
    margin-right: 10px;
  }

  .add-metric-button__text {
    padding-left: 5px;
  }

  .add-metric-button--color {
    color: var(--modal-form-cancel-color);
  }
}
</style>
