export default {
  received(data) {
    const options = {
      message: data.message,
      actions: [
        {
          icon: "close",
          color: "white",
        },
      ],
    };

    // Keep alert visible until it's manually closed
    if (data.persisted) {
      options["timeout"] = 0;
    }

    this.$q.notify(options);
  },
};
