<template lang="pug">
.dates-dynamic-filter.form-field.form-multiselect(:class="filterClass")
  .dates-dynamic-filter__icon
    inline-svg.dates-dynamic-filter__icon(:src="require(`@/assets/icons/calendar.svg`)")
  .dates-dynamic-filter__label(v-if="showSelectionLabel") {{ defaultLabel }}
  .dates-dynamic-filter__value {{ label }}
  q-icon.dates-dynamic-filter__cancel(v-if="isAllResetable", name="cancel", @click.stop="resetAllFilters") 
  q-menu(content-class="dates-dynamic-filter__menu", @before-hide="closeMenu")
    .menu__container
      .menu__inputs(v-if="hasSelect")
        select-filter(ref="select", :parentData="selectFilterProps", @load-options="loadOptions")
      .menu__inputs
        date-filter(:parentData="filterProps(filters[0], filters[1])")
        date-filter(:parentData="filterProps(filters[1], filters[0])")
      .menu__buttons
        button(type="primary", :label="dataLocales.close_text", v-close-popup)
  // for load select with currentFilters
  select-filter(
    v-if="isVisible",
    v-show="false",
    ref="select",
    :parentData="selectFilterProps",
    @load-options="loadOptions"
  )
</template>

<script setup>
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { useStore } from "@/store";
import { format, parseISO } from "date-fns";
import Multiselect from "vue-multiselect";
import Button from "@/components/shared/general_components/inputs/Button.vue";
import dateFilter from "./date_filter.vue";
import selectFilter from "./select_filter.vue";
import { Notify } from "quasar";

const props = defineProps({
  parentData: { type: Object, default: () => {} },
  disabled: { type: Boolean, default: false },
  dataLocales: { type: Object, default: () => {} },
  selectionLabel: { type: Boolean, default: false },
});

const emit = defineEmits([
  /**
   * Срабатывает при сбросе значения поля.
   */
  "resetFilter",
  /**
   * Срабатывает при загрузке опций селекта
   */
  "load-options",
]);

const store = useStore();

const select = ref(null);

const fromVal = ref("");
const toVal = ref("");
const filterNameByIndex = ref({});
const selectForCurrentFilters = ref(false);

const currentFilters = computed(() => store.state.grid[grid.value]["filters"]);

const grid = computed(() => props.parentData.grid);

const defaultLabel = computed(() => props.parentData.data[0].label);

const filters = computed(() => props.parentData.data[0].items);

// Check if items have select
const hasSelect = computed(() => props.parentData.data[0].items.some(item => item.type === "select"));

// Props for selectFilters
const selectFilterProps = computed(() => {
  return { data: [filters.value[2]], grid: grid.value, options_data: {}, value: filters.value[2].value };
});

const label = computed(() => {
  // Default label or selected values: ...-01.02.2024, 01.01.2024-..., 01.01.2024-01.02.2024
  const indexes = Object.keys(filterNameByIndex.value);
  const storeValues = indexes.map(index => {
    const filterName = filterNameByIndex.value[index];
    const filterValue = currentFilters.value[filterName];
    return filterValue;
  });
  const filledValues = storeValues.filter(item => item !== null && item !== undefined);

  if (filledValues.length == 0) {
    return defaultLabel.value;
  }

  // Format dates and link them together
  const output = storeValues
    // Select values always are in an array. We remove selects values from label
    .filter(item => !Array.isArray(item))
    .map(value => {
      if (value === null || value === undefined) {
        return "...";
      } else {
        return format(parseISO(value), "dd.MM.yyyy");
      }
    })
    .join(" - ");

  return output;
});

const isAllResetable = computed(() => label.value !== defaultLabel.value);

const showSelectionLabel = computed(() => props.selectionLabel && isAllResetable.value);

const filterClass = computed(() => ({
  "dates-dynamic-filter--selection-label": showSelectionLabel.value,
}));

const isVisible = computed(() => hasSelect.value && selectForCurrentFilters.value);

const isResettable = filter => {
  const value = currentFilters.value[filter.name];

  return typeof value == "string" && value.length > 0;
};

const placeholder = filter => {
  const matchedTexts = filter.name.match(/[a-z]+\b/g);
  const name = matchedTexts[matchedTexts.length - 1];
  const translatedName = props.dataLocales[name];

  return translatedName;
};

const filterProps = (filter, otherFilter) => {
  const data = { ...filter, label: placeholder(filter) };
  const otherFilterValue = currentFilters.value[otherFilter.name]; // value in vuex or undefined (if not set)

  if (otherFilterValue) {
    const minOrMaxName = otherFilter.name.includes("from") ? "minDate" : "maxDate";
    const limitDate = format(parseISO(otherFilterValue), "dd.MM.yyyy");
    data[minOrMaxName] = limitDate;
  }

  return { data: [data], grid: grid.value, options_data: {} };
};

const loadOptions = options => {
  emit("load-options", options);
};

const setOptionsData = options => {
  select.value.setOptionsData(options);
};

const setLoading = bool => {
  select.value.setLoading(bool);
};

const resetFilter = filter => {
  emit("resetFilter", filter);
  store.commit("resetFilter", { grid_name: grid.value, filter: filter.name });
};

const resetAllFilters = () => {
  filters.value.forEach(filter => resetFilter(filter));
};

// Initialize map to access name by index. This map exists to ensure we display values in correct order
const setFilterNameByIndex = () => {
  const list = filters.value.map((filter, index) => ({ [index]: filter.name }));
  filterNameByIndex.value = Object.assign({}, ...list);
};

// When closing q-menu this function was triggered and looks to see if the date and select fields are filled
const closeMenu = () => {
  if (selectForCurrentFilters.value) {
    const selectFilter = filters.value.find(filter => filter.type == "select");
    const dateFilters = filters.value.filter(filter => filter.type == "date");
    const useSelectFilter = currentFilters.value[selectFilter.name];
    const useDateFilters = [];
    dateFilters.forEach(date => {
      if (currentFilters.value[date.name]) {
        useDateFilters.push(date);
      }
    });

    // If one of this fields is empty trigger resetAllFilters() function and use Notify alert for user with prompt
    if ((useSelectFilter && useDateFilters.length > 0) || (!useSelectFilter && useDateFilters.length == 0)) {
      return;
    } else {
      Notify.create(`${props.dataLocales.validations["select_status_and_date"]}`);
      resetAllFilters();
    }
  }
};

const checkHaveSelect = () => {
  if (filters.value.some(filter => filter.type == "select")) {
    selectForCurrentFilters.value = true;
  }
};

onBeforeMount(() => {
  checkHaveSelect();
  setFilterNameByIndex();
});
</script>

<style scoped lang="scss">
.dates-dynamic-filter {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;

  &__icon {
    margin-left: 5px;
    width: 19px;
    height: 17px;

    :deep(svg) {
      path {
        fill: var(--dates-dynamic-filter-icon-fill) !important;
      }
    }
  }

  &--selection-label {
    position: relative;

    .dates-dynamic-filter__label {
      position: absolute;
      top: 0;
      left: 37px;
      font-size: 11px;
      color: var(--field-input-color);
    }

    .dates-dynamic-filter__value {
      padding-top: 13px;
    }
  }

  &__value {
    padding-left: 13px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--select-dynamic-numbers-filter-label-color);
  }

  &__cancel {
    padding-right: 17px;
    color: var(--field-cancel-icon-color);
  }
}

.menu__container {
  padding: 16px;

  .menu__inputs {
    display: flex;
    gap: 10px;
    width: 455px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .form-multiselect {
      width: 100%;
    }
  }

  .menu__buttons {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
}
</style>
