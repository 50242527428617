import Marker from "./Marker";
import icon from "../../../../assets/icons/map/emergency.svg";

class EmergencyMarker extends Marker {
  constructor(api, map, coordinates) {
    const iconOptions = { icon, size: [39, 70], anchor: [19, 65] };
    super(api, map, coordinates, iconOptions);
  }
}

export default EmergencyMarker;
