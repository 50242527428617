<template lang="pug">
.expansion-item__content-children
  .content-loading(v-if="loading")
    .tab-rows__items-loader
      q-spinner(color="primary", size="3em")
  .content-wrapper(v-else)
    .content-header 
      .content-header__title(
        v-for="(column, index) in contentData.columns",
        :key="column.name",
        :style="{ width: column.width }",
        @click="changeSort(column.name)"
      )
        Transition(name="rotate", mode="out-in")
          inline-svg.tab-rows__sort-icon(
            :src="getSortIcon(column.name)",
            :key="column.name + getSortIcon(column.name)"
          )
        span {{ column.label }}
    .content-items 
      .content-items__row(v-for="row in contentData.items", :key="`main-${row.id}`")
        .content-items__item(
          v-for="(column, index) in contentData.columns",
          :key="`${row.id}-${column.name}`",
          :style="{ width: column.width }"
        ) {{ getValueForKey(row.id, column.name) }}
      .content-items__loading-more(v-if="hasNextPage")
        .tab-rows__items-loader(v-if="loadingMore")
          q-spinner(color="primary", size="3em")
      .tab-rows__observe-visibility(v-observe-visibility="reachedEndOfList")
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import DownArrow from "@/assets/icons/grid/down_arrow.svg";
import UpArrow from "@/assets/icons/grid/up_arrow.svg";

import { backend } from "@/api";
import { useStore } from "@/store";
import { handleError } from "@/services/handleErrors";

const props = defineProps({
  buildingId: { type: Number, required: true },
});

const store = useStore();

const facilityId = store.state.grid["expenses_dashboard"]?.filters.facility_id?.value;

const sort = ref(null);
const contentData = ref({
  items: [],
  columns: [],
});
const loading = ref(true);
const totalCount = ref(0);
const page = ref(1);
const loadingMore = ref(false);
const hasNextPage = computed(() => page.value < totalCount.value / 20);

const loadContentData = async () => {
  try {
    const params = {
      filters: {
        building_id: [props.buildingId],
        facility_id: facilityId,
      },
      pagination: {
        page: page.value,
        perPage: 20,
      },
    };

    if (sort.value) {
      params["sort"] = sort.value;
    }

    const { data } = await backend.index("api/v3/expenses_dashboard/issues", { params }, { encodeNestedData: true });

    totalCount.value = data.count;

    if (page.value === 1) {
      contentData.value.columns = data.columns;
      contentData.value.items = data.data;
    } else {
      contentData.value.items = _.unionBy(contentData.value.items, data.data, "id");
    }

    return true;
  } catch (e) {
    await handleError(e);
  } finally {
    loading.value = false;
    loadingMore.value = false;
  }
};

const changeSort = async sortName => {
  if (sort.value?.sortBy === sortName) {
    sort.value.descending = !sort.value.descending;
  } else {
    sort.value = { sortBy: sortName, descending: true };
  }

  loading.value = true;
  page.value = 1;
  loadContentData();
};
const getSortIcon = name => {
  if (sort.value?.name === name && sort.value?.value === true) {
    return UpArrow;
  } else {
    return DownArrow;
  }
};

const getValueForKey = (id, key) => {
  return contentData.value.items.find(el => el.id === id)[key];
};

const reachedEndOfList = reached => {
  if (reached && !loading.value && hasNextPage.value) {
    page.value++;
    loadingMore.value = true;
    loadContentData();
  }
};

onMounted(() => {
  loadContentData();
});
</script>

<style lang="scss" scoped>
.content-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.content-header {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid var(--expenses-expansion-separator-color);
  padding: 12px 16px;
  position: sticky;
  top: 0;
  background-color: var(--expenses-content-background-color);

  &__title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    cursor: pointer;
    color: var(--expenses-expansion-text-header-color);

    :deep(.tab-rows__sort-icon) {
      width: 20px;
      height: 20px;

      path {
        fill: var(--expenses-expansion-text-header-color);
      }
    }
  }
}

.content-items {
  margin-top: 12px;
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    gap: 20px;
    align-items: center;
    border-radius: 8px;
    padding: 12px 16px;
    transition: background-color 0.2s ease;
    color: var(--expenses-main-text-color);

    &:hover {
      background-color: var(--expenses-expansion-separator-color);
    }
  }

  &__loading-more {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.3s ease;
}

.rotate-enter-from,
.rotate-leave-to {
  transform: rotate(0.5turn);
}
</style>
