<template lang="pug">
.login-component
  video(autoplay, muted, loop)
    source(type="video/mp4", :src="require('../../assets/videos/amelia-background-video.mp4')")

  .login-background

  .spinner-container.form-spinner(v-if="loading || loadingForm")
    q-spinner(color="primary", size="3em")

  .q-pa-md.row.items-start.justify-center(v-if="!loading && !loginLocalesLoading")
    #login-card(v-show="!loadingForm")
      .login-logo.row.justify-center
        inline-svg(:src="require('../../assets/icons/login/login-logo.svg')")

      login-form(
        v-if="useLoginForm()",
        @passwordRecovery="onPasswordRecovery",
        @openAdForm="onSignInWithAd",
        @setLoading="setLoading"
      )

      password-recovery-form(v-if="passwordRecovery", @passwordRecovery="onPasswordRecovery", @setLoading="setLoading")

      password-reset-form(
        v-if="resetPasswordForm",
        :resetPasswordToken="resetPasswordToken",
        @signInWithAd="onSignInWithAd",
        @setLoading="setLoading"
      )

      sign-in-with-ad(v-if="signWithAd", @signInWithAd="onSignInWithAd", @setLoading="setLoading")
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useLoadDefaultLocale, useLoadDefaultLocalesData } from "@/services/useLoadLocales";
import { loginLocales } from "@/services/useLocales";

import LoginForm from "../view/login/LoginForm";
import PasswordRecoveryForm from "../view/login/PasswordRecoveryForm";
import PasswordResetForm from "../view/login/PasswordResetForm";
import SignInWithAd from "../view/login/SignInWithAd";
import { useRoute } from "@/routing/router";
import { Notify } from "quasar";

const emit = defineEmits(["set-loading"]);
const route = useRoute();

const passwordRecovery = ref(false);
const signWithAd = ref(false);

const resetPasswordForm = ref(false);
const resetPasswordToken = ref("");

const loading = ref(false);
const loadingForm = ref(true);
const loginLocalesLoading = ref(true);

const onPasswordRecovery = val => {
  passwordRecovery.value = val;
};

const onPasswordReset = val => {
  if (!val) {
    resetPasswordToken.value = "";
  }
  resetPasswordForm.value = val;
};

const onSignInWithAd = val => {
  signWithAd.value = val;
};

const setLoading = val => {
  emit("set-loading", val);
  loadingForm.value = val;
};

const useLoginForm = () => {
  return !passwordRecovery.value && !resetPasswordForm.value && !signWithAd.value;
};

useLoadDefaultLocale();
useLoadDefaultLocalesData().finally(() => {
  loginLocalesLoading.value = false;
  if (route.query["error"] === "Unauthorized") {
    Notify.create(loginLocales.value["password_recovery_form.notifies.error"]);
  }
});

onMounted(() => {
  if (route.query["reset_password_token"]) {
    resetPasswordToken.value = route.query["reset_password_token"];
    resetPasswordForm.value = true;
  }
});
</script>

<style lang="scss">
@import "../../assets/styles/login.scss";
</style>
