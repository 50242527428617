<template lang="pug">
.workflow-fields__field
  .form-field-label
    span {{ field.label }}
    q-icon.text-h6.workflow-fields__icon-reset.cursor-pointer(
      name="refresh",
      @click="resetSelectField",
      v-if="workflowSelectField?.length > 0"
    )
  .form-field.form-multiselect
    Multiselect(
      :value="workflowSelectField",
      :track-by="'id'",
      :label="'title'",
      :options="workflowSelectFieldOptions",
      placeholder="",
      :multiple="field.multiple",
      :hideSelected="true",
      :show-labels="false",
      open-direction="bottom",
      :allow-empty="true",
      @select="setField",
      @remove="removeField",
      :close-on-select="!props.field.multiple"
    )
      span(slot="noResult")
        i {{ notifies.no_search_result }}

      span(slot="noOptions")
        i {{ notifies.no_options_list }}
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import Multiselect from "vue-multiselect";
import backend from "@/api";
import { handleError } from "@/services/handleErrors";

const props = defineProps({
  field: { type: Object, default: () => {} },
  object: { type: Object, default: () => {} },
});

const emit = defineEmits(["fieldChange"]);

const workflowSelectField = ref([]);
const workflowSelectFieldOptions = ref([]);

const setField = val => {
  if (props.field.multiple) {
    workflowSelectField.value.push(val);
  } else {
    workflowSelectField.value = val;
  }

  emitFieldChange();
};

const removeField = val => {
  const answerIndex = workflowSelectField.value.findIndex(el => el.id === val.id);

  if (answerIndex !== -1) {
    workflowSelectField.value.splice(answerIndex, 1);
  }

  emitFieldChange();
};

const resetSelectField = () => {
  workflowSelectField.value = [];

  emitFieldChange();
};

const emitFieldChange = () => {
  emit("fieldChange", {
    name: props.field.name,
    value: getCurrentValue.value,
  });
};

const generateCollectionLink = computed(() => {
  let link = `api/v3/${props.field.options_path}/collection`;

  Object.keys(props.field.options_params).forEach((elem, index) => {
    index === 0 ? (link += "?") : (link += "&");

    let param = props.field.options_params[elem];

    if (typeof param !== "number" && typeof param !== "string") {
      param = JSON.stringify(param);
    }

    link += `${elem}=${param}`;
  });

  return link;
});

const getSelectOptions = async () => {
  try {
    workflowSelectFieldOptions.value = (await backend.index(generateCollectionLink.value)).data.options;
  } catch (e) {
    await handleError(e);
  }
};

const getCurrentValue = computed(() => {
  if (props.field.multiple) {
    if (workflowSelectField.value.length) {
      return workflowSelectField.value.map(el => {
        return el.id;
      });
    } else {
      return [];
    }
  } else {
    return workflowSelectField.value.id;
  }
});

const getInitialFieldValue = () => {
  if (props.object[props.field.name]) {
    if (props.field.multiple) {
      workflowSelectField.value = props.object[props.field.name]?.length ? generateArrayOfId() : [];
    } else {
      workflowSelectField.value = [findWorkflowOption(props.object[props.field.name])];
    }
  }
};

const generateArrayOfId = () => {
  return props.object[props.field.name].map(el => findWorkflowOption(el));
};

const findWorkflowOption = element => {
  return workflowSelectFieldOptions.value.find(option => {
    return option.id === element;
  });
};

onMounted(async () => {
  await getSelectOptions();
  getInitialFieldValue();
});
</script>
