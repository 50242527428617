<template lang="pug">
.checkbox-field
  .form-field.form-checkbox
    q-checkbox(dark, :value="value", :label="label", @input="changeValue")
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  /* Лейбл чекбокса, строка */
  label: { type: String, default: "", required: true },
  /* Начальное значение поля, булеан или пустое значение, которое отображается на форме как незаполненное поле типа false */
  initialValue: { type: Boolean || null, default: null, required: false },
});

const emit = defineEmits(["change", "reset"]);

const value = ref(props.initialValue);

const changeValue = () => {
  emit("change", { value: !value.value });
  value.value = !value.value;
};

const resetValue = () => {
  value.value = null;
  emit("reset");
  changeValue();
};
</script>

<script>
export default {
  name: "TextField",
};
</script>

<style scoped lang="scss">
.text-field {
  ::v-deep .text-field-area {
    padding-right: 20px;
  }
}
</style>
