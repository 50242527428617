<template lang="pug">
div
  .login-title.login-reset-password-title.row.justify-center
    span {{ loginLocales["ad_form.title"] }}

  q-form(@submit="onSubmit", @reset="onCancel")
    .recovery-form.login-field
      .login-field-label
        span {{ loginLocales["ad_form.fields.email.label"] }}

      .login-field-input
        q-input(
          filled,
          :label-slot="false",
          ref="loginInput",
          v-model="login",
          lazy-rules,
          no-error-icon,
          for="login-input",
          :rules="[val => checkMaxLength(val), val => checkLogin(val)]"
        )

    .recovery-form.button-group.row.justify-center
      q-btn.login-submit(flat, no-caps, :label="loginLocales['password_recovery_form.buttons.submit']", type="submit")
      q-btn.login-cancel(flat, no-caps, :label="loginLocales['password_recovery_form.buttons.cancel']", type="reset")
</template>

<script setup>
import { ref } from "vue";
import { Notify } from "quasar";
import { useStore } from "@/store";
import { loginLocales } from "@/services/useLocales";
import backend from "@/api";
import { handleError } from "@/services/handleErrors";

const emit = defineEmits(["setLoading", "signInWithAd"]);
const store = useStore();

const login = ref(null);
const loginInput = ref();

const checkLogin = email => {
  const regex = /[^@\s]+@[^@\s]+/;
  return regex.test(String(email).toLowerCase()) || loginLocales["fields_rules.wrong_email_format"];
};

const onCancel = () => {
  emit("signInWithAd", false);
};

const checkMaxLength = val => {
  return (val && val.length > 0) || loginLocales["fields_rules.not_empty"];
};

const sendOnRecovery = async params => {
  const id = await getSAMLIdentifyId(params);
  if (id) {
    try {
      const csrf = getCookie("CSRF-TOKEN");
      const res = await backend.auth_with_csrf(`${store.state.paths["saml"]}/${id}`, csrf);
    } catch (e) {
      console.log(e);
    }
  }
};

const getSAMLIdentifyId = async params => {
  try {
    const { data } = await backend.sign_in(`${store.state.paths["saml"]}/login`, params);
    return data.identity_provider_id;
  } catch (error) {
    handleError(error);
    Notify.create({ message: loginLocales.value["password_recovery_form.notifies.error"] });
    return false;
  }
};

const onSubmit = async () => {
  if (!loginInput.value) {
    return;
  }
  loginInput.value.validate();

  if (!loginInput.value.hasError) {
    const params = {
      email: login.value,
    };

    await sendOnRecovery(params);
  }
};
//Шаблонная регулярка для получаения куки по имени. Получаем с помощью нее куку с csrf которая добавляется с бека в запросе функции getSAMLIdentifyId
const getCookie = name => {
  const matches = document.cookie.match(
    new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
</script>

<style lang="scss"></style>
