<template lang="pug">
div
  .entity-view__label {{ map_locales["vehicle_model"] }}:
  .entity-view__value {{ entity.getModel() }}

  .entity-view__label {{ map_locales["vehicle_number"] }}:
  .entity-view__value {{ entity.getPlateNumber() }}

  .entity-view__label {{ map_locales["vehicle_type"] }}:
  .entity-view__value {{ entity.getVehicleType() }}

  .entity-view__label {{ map_locales["driver"] }}:
  .entity-view__value {{ entity.getDriverName() }}

  .entity-view__label {{ map_locales["phone"] }}:
  .entity-view__value {{ entity.getDriverPhoneNumber() }}
</template>

<script>
export default {
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss"></style>
