<template lang="pug">
q-card#form.checklist-form__wrapper(style="background-color: var(--simple-table-background)")
  q-card-section.checklist-form__card-section
    form-header(
      ref="form-header",
      @close-form="closeForm",
      :parentData="{ grid: grid, title: parentData.data.checklist.title, title_icon: 'checklist.svg' }"
    )

    q-form.checklist-element-form.fill-height
      .q-px-md.q-stepper-fill.fill-height
        q-stepper(
          header-class="checklist-form__stepper-header",
          :animated="false",
          v-model="checklistStep",
          ref="stepper",
          color="primary",
          done-color="indigo-12",
          active-color="indigo-12",
          active-icon="circle",
          style="display: flex; flex-direction: column; height: 100%; background-color: var(--simple-table-background)"
        )
          q-step(
            v-for="(page, index) in parentData.data.checklist.pages",
            :key="page.id",
            :name="index",
            :title="page.title",
            icon="edit",
            :done="checklistStep > index"
          )
            .row
              component(
                :ref="field.name",
                v-for="field in page.elements",
                :key="field.id",
                :is="getFieldComponent(field.checklist_element_type_code)",
                :field="field",
                :initialValue="getInitialValue(field)",
                @edit-field="editField($event)",
                @reset-field="resetField($event)"
              )

    .row.justify-center.btn-position-absolute
      q-stepper-navigation
        q-btn(outline, color="indigo-12", @click="checklistStep--", :disable="checklistStep === 0") {{ checklist_locales.previous }}
        q-btn(
          color="indigo-12 q-ml-sm",
          v-if="parentData.data.checklist.pages.length - 1 > checklistStep",
          @click="checklistStep++"
        ) {{ checklist_locales.next }}
        q-btn(color="indigo-12 q-ml-sm", v-else, @click="completeChecklist", :disable="disabled") {{ checklist_locales.close_checklist }}
</template>

<script>
import formHeader from "../../shared/forms/formHeader";
import fill_string_field from "./fill_string_field";
import fill_number_field from "./fill_number_field";
import fill_select_field from "./fill_select_field";
import fill_checkbox_field from "./fill_checkbox_field";
import fill_radio_field from "./fill_radio_field";
import fill_file_field from "./fill_file_field";
import fill_date_field from "./fill_date_field";
import fill_datetime_field from "./fill_datetime_field";
import { v4 } from "uuid";

export default {
  components: {
    formHeader,
    fill_string_field,
    fill_number_field,
    fill_select_field,
    fill_checkbox_field,
    fill_radio_field,
    fill_file_field,
    fill_date_field,
    fill_datetime_field,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,

      form_valid: false,
      checklistStep: 0,
      disabled: null,

      checklist: this.parentData ? this.getChecklistFieldsFromPages(this.parentData) : [],
    };
  },

  computed: {
    checklist_locales() {
      return this.locales.checklist[this.current_locale];
    },

    row() {
      return this.parentData.row;
    },

    data() {
      return this.parentData.data;
    },

    title() {
      return this.data.title;
    },

    header_title() {
      return this.parentData.header_title;
    },

    // buttons() {
    //   return this.parentData.data.header.filters_form.form_buttons
    // }
  },

  created() {},

  mounted() {
    this.isButtonDisabled();
  },

  methods: {
    closeForm(ob) {
      this.$emit("close-form", ob);
    },

    getFieldComponent(key) {
      if (key === "text") return fill_string_field;
      if (key === "select") return fill_select_field;
      if (key === "number") return fill_number_field;
      if (key === "date") return fill_date_field;
      if (key === "datetime") return fill_datetime_field;
      if (key === "checkbox") return fill_checkbox_field;
      if (key === "radio") return fill_radio_field;
      if (key === "file") return fill_file_field;
    },

    getChecklistFieldsFromPages(parentData) {
      let fieldsArray = [];
      parentData.data.checklist.pages.forEach(page =>
        page.elements.forEach(element => fieldsArray.push({ id: element.id, required: element.required })),
      );
      return fieldsArray;
    },

    editField(event) {
      const index = this.checklist.findIndex(el => el.id === event.id);
      this.checklist[index].value = event.value;
      this.isButtonDisabled();
    },

    resetField(event) {
      const index = this.checklist.findIndex(el => el.id === event.id);
      delete this.checklist[index].value;
      this.isButtonDisabled();
    },

    getInitialValue(field) {
      const index = this.checklist.findIndex(el => el.id === field.id);
      if (index !== -1 && this.checklist[index].value) return this.checklist[index].value;
    },

    isButtonDisabled() {
      if (this.checklist) {
        for (let elem of this.checklist) {
          if (elem.required && !elem.value) {
            this.disabled = true;
            return;
          }
        }
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },

    completeChecklist() {
      if (!this.disabled) {
        let checklist_data_id = v4();
        let checklist_data = {
          checklist_data: {
            uuid: checklist_data_id,
            issue_id: this.parentData.row.id,
            checklist_pages: this.parentData.data.checklist.pages.map(page => {
              return {
                uuid: v4(),
                title: page.title,
                parent_uuid: checklist_data_id,
                checklist_elements: page.elements.map(element => {
                  let checklist_element_index = this.checklist.findIndex(elem => elem.id === element.id);
                  let checkValue = this.checklist[checklist_element_index].value;
                  let checkType = typeof checkValue;
                  if (element.checklist_element_type_id == 9) {
                    checkType = "file";
                  }
                  if (checkValue && checkValue.length && checkType !== "string") {
                    checkType = typeof [];
                  }
                  const res = {
                    uuid: v4(),
                    type: element.checklist_element_type_code,
                    title: element.title,
                    order: element.order,
                    _data: {
                      type: element.checklist_element_type_code === "file" ? "file" : "string",
                      value:
                        element.checklist_element_type_code === "file"
                          ? [
                              {
                                value: checkValue ? checkValue[0].value[0] : null,
                                title: checkValue ? checkValue[0].title : null,
                              },
                            ]
                          : checkValue,
                    },
                    checklist_element_options:
                      element.checklist_element_type_code === "checkbox" ||
                      element.checklist_element_type_code === "radio"
                        ? element.options.map(option => {
                            return {
                              type: parseInt(option.title) == option.title ? "number" : "string",
                              values: [
                                {
                                  uuid: v4(),
                                  title: option.title,
                                  order: option.order,
                                  _data: {
                                    type: parseInt(option.title) == option.title ? "number" : "string",
                                    value: option.id,
                                  },
                                },
                              ],
                            };
                          })
                        : null,
                  };
                  element.checklist_element_type_code === "checkbox" || element.checklist_element_type_code === "radio"
                    ? true
                    : delete res.checklist_element_options;
                  return res;
                }),
              };
            }),
          },
        };

        this.$backend
          .create(`/api/v3/issues/${this.parentData.row.id}/checklist_data`, null, checklist_data)
          .then(res => {
            this.$q.notify(this.checklist_locales.all_data_saved);
            this.closeForm({ complete: true, data: { checklist_data: res.data } });
          })
          .catch(() => this.$q.notify(this.notifies.fix_invalid_fields));
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/modal-form";
@import "../../../assets/styles/checklists/form";

.q-stepper-fill {
  display: flex;
  flex-direction: column;
  .q-stepper__content {
    flex-grow: 10000;
  }
  .q-stepper__header--standard-labels .q-stepper__tab {
    justify-content: center !important;
  }
  .q-panel.scroll {
    overflow-y: unset;
  }
}
</style>
