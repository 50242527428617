<template lang="pug">
#calendar-ppr.calendar-ppr
  .spinner-container.grid-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")
  #calendar-ppr-table.calendar-ppr-table(v-show="!loading")
    #first-col-overlapping
    #another-one-col-overlapping
    table
      div
        thead
          tr
            th.first-col
              span {{ getTitleLocale() }}
            th(v-for="week in currentGrid.data")
              span {{ week.number }}
        tbody
          tr
            td.first-col.common-data
              span {{ pprWorkEquipmentLocales.total }}

            td(
              v-for="week in currentGrid.data",
              :class="week.current ? 'another-one-col current-week-col' : 'another-one-col'"
            )
              div
                .week-data
                  span.week-data-hours {{ getTotalHours(week) }}

          tr(v-for="(entity, entityIndex) in entities")
            td.first-col
              span(@click="onClickTitle(entity)") {{ entity.title }}
            td(
              v-for="(week, weekIndex) in entity.weeks",
              :class="week.current ? 'another-one-col current-week-col' : 'another-one-col'"
            )
              div(v-for="(week_data, week_data_i) in week.data")
                .spacer(v-if="week.data && week.data.length > 0 && week_data_i !== 0")

                div(:class="week_data.description ? 'week-data with-icon' : 'week-data'")
                  span.week-data-hours {{ week_data.hours }}

  grid-modals(
    ref="gridModalsRef",
    @reload-data="reloadData",
    :parentData="{ grid: grid, grid_key: gridKey, path: path }"
  )
</template>

<script setup>
import gridModals from "@/components/shared/grid/gridModals";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { computed, ref, onBeforeMount, watch } from "vue";
import { useStore } from "@/store";
import backend from "@/api";
import { handleError } from "@/services/handleErrors";
import { useEmitter } from "@/services/useEmitter";
import { useRouter } from "@/routing/router";
import { pprWorkEquipmentLocales } from "@/services/useLocales";

const store = useStore();
const props = defineProps({
  parentData: { type: Object, default: () => {} },
});
const emit = defineEmits(["get-collection"]);
const emitter = useEmitter();
const router = useRouter();

const grid = computed(() => props.parentData.grid);
const gridKey = computed(() => props.parentData.grid_key);
const path = computed(() => props.parentData.path);
const currentGrid = computed(() => store.state.grid[grid.value][gridKey.value]);
const currentFilters = computed(() => store.state.grid[grid.value].filters);
const loading = ref(true);
const entities = ref([]);
const gridModalsRef = ref(null);
const calendarType = ref("facility");
const nextEntity = ref(false);
const filtersFormAcceptedVal = ref(false);
const facilityId = ref(null);
const buildingId = ref(null);

const setColOverlapping = () => {
  return;
  setTimeout(() => {
    const gridHeight = document.getElementById("grid-data").getBoundingClientRect().height;
    const gridWidth = document.getElementById("grid-data").getBoundingClientRect().width;
    const tableHeight = document.getElementById("calendar-ppr-table").getBoundingClientRect().height;
    const firstColOverlapping = document.getElementById("first-col-overlapping");
    const anotherColOverlapping = document.getElementById("another-one-col-overlapping");

    if (tableHeight < gridHeight) {
      firstColOverlapping.style.height = tableHeight + 20 + "px";
      anotherColOverlapping.style.width = gridWidth - 350 + "px";
    } else {
      firstColOverlapping.style.height = gridHeight + 17 + "px";
      anotherColOverlapping.style.width = gridWidth - 353 + "px";
    }
  }, 5);
};

const setLoading = val => {
  loading.value = val;

  !loading.value && setColOverlapping();
};

const reloadData = () => {};

const getTotalHours = week => {
  let count = null;
  week.events.forEach(item => {
    count += item.hours;
  });
  return count;
};

const onRequest = async () => {
  const params = {
    query: "",
    filters: await generateFiltersParams(currentFilters.value),
  };

  emit("get-collection", params);
};

const getEntities = async () => {
  loading.value = true;
  try {
    const params = {
      infinite_scroll: {
        page: 1,
        per_page: 1000,
      },
    };
    let path = store.state.paths["facility"];
    if (calendarType.value === "building") {
      params.facility_id = facilityId.value;
      path = store.state.paths["building"];
    }
    if (calendarType.value === "equipment") {
      params.building_id = buildingId.value;
      path = store.state.paths["ppr_equipment"];
    }
    const { data } = await backend.collection(`${path}/collection`, params);
    entities.value = [];
    data.options.forEach(option => {
      option["weeks"] = [];
      entities.value.push(option);
    });
  } catch (err) {
    await handleError(err);
  } finally {
    setEntityValues();
  }
};

const setEntityValues = () => {
  currentGrid.value.data.forEach(week => {
    entities.value.forEach(entity => {
      const data = [];
      week.events.forEach(event => {
        if (event.facility_id === entity.id || event.building_id === entity.id || event.equipment_id === entity.id) {
          data.push({
            hours: event.hours,
          });
        }
      });
      entity["weeks"].push({
        data: data,
        week: week.number,
        current: week.current,
      });
    });
  });
  loading.value = false;
};

const openImportForm = attr => {
  gridModalsRef.value.openImportForm(attr);
};

const onClickTitle = entity => {
  const filters = {};
  if (calendarType.value === "facility") {
    filters.facility_id = entity.id;
  } else if (calendarType.value === "building") {
    filters.building_id = entity.id;
  } else if (calendarType.value === "equipment") {
    router.push({ name: "ppr_equipments", query: { show: true, tab: "tech_map", id: entity.id } });
    return;
  }
  emitter.emit("add-filters-from-params", filters);
  nextEntity.value = true;
};

const getFiltersFormAcceptedVal = () => {
  return filtersFormAcceptedVal.value;
};

const filtersFormAccepted = val => {
  filtersFormAcceptedVal.value = val;
};

const getTitleLocale = () => {
  let locale = pprWorkEquipmentLocales.value.facility;
  if (calendarType.value === "building") {
    locale = pprWorkEquipmentLocales.value.building;
  }
  if (calendarType.value === "equipment") {
    locale = pprWorkEquipmentLocales.value.equipment;
  }
  return locale;
};

watch(
  currentFilters,
  newVal => {
    if (newVal && nextEntity.value) {
      nextEntity.value = false;
      filtersFormAcceptedVal.value = true;
    }
  },
  { deep: true },
);

watch(getFiltersFormAcceptedVal, async newVal => {
  if (newVal) {
    loading.value = true;
    if (currentFilters.value.facility_id) {
      facilityId.value = currentFilters.value.facility_id.value;
      calendarType.value = "building";
      if (currentFilters.value.building_id) {
        buildingId.value = currentFilters.value.building_id.value;
        calendarType.value = "equipment";
      }
    } else {
      buildingId.value = null;
      facilityId.value = null;
      calendarType.value = "facility";
    }
    onRequest();
    getEntities();
    filtersFormAcceptedVal.value = false;
  }
});

watch(
  currentGrid,
  async newVal => {
    if (newVal.data) {
      await getEntities();
    }
  },
  { deep: true },
);

onBeforeMount(async () => {
  store.commit("initialGrid", { grid_name: grid.value, grid_key: gridKey.value });
  await onRequest();
});

defineExpose({
  setLoading,
  openImportForm,
  filtersFormAccepted,
});
</script>

<style lang="scss">
@import "../../../../assets/styles/ppr/calendar/main";
</style>
