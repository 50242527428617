<template lang="pug">
div
  apexchart(
    v-if="list && chartHeight",
    ref="apex-chart",
    :height="chartHeight",
    :options="apex.options(type, list, locale, getTheme())",
    :series="apex.series(list, label)"
  )
</template>

<script setup>
import { ref } from "vue";
import { apex } from "./apex/index";
import { theme } from "@/services/useTheme";
import themes from "./themes";

const props = defineProps({
  list: { type: Array, default: () => [], required: true },
  label: { type: String, required: true },
  locale: { type: String, required: true },
  type: { type: String, required: true }, // Chart type. Currently it can be 'csi-line' or 'issues-cc-bar'
  height: { type: String, required: false },
});

const chartHeight = props.height || 250;
const windowWidth = ref("");

const getTheme = () => {
  return themes[theme.value];
};
</script>

<style scoped>
@import "../../../../../../../assets/styles/dashboard/charts/apex_chart.scss";
</style>
