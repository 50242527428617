import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const locales = {
  state: {},

  mutations: {
    addLocales(state, data) {
      Object.keys(data).forEach(key => {
        state[key] = data[key];
      });
    },

    // removeNotifies(state) {
    //   Object.keys(state).forEach(key => {
    //     state[key] = {}
    //   })
    // }
  },

  actions: {},
};
