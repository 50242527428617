<template lang="pug">
.timeline__container
  .timeline
    template(v-if="!loading")
      .timeline__column(
        v-for="(hour, index) in columns",
        :key="hour.toString()",
        :ref="el => (columnRefs[index] = el)"
      )
        .timeline__column__header
          .timeline__column__header__time {{ getDateWithDay(hour) }}
            .timeline__column__header__time__count(
              :class="{ 'timeline__column__header__time__count--empty': getCountLabel(hour) === 0 }"
            ) {{ getCountLabel(hour) }}

        .timeline__column__content
          .timeline__row(v-for="issue in getLayoutIssues(hour)", :key="issue.id")
            .timeline__card(@click="openShow(issue)", :style="getCardColor(issue.status_color)")
              .timeline__card__header
                .timeline__card__header__id №{{ issue.id }}
                .timeline__card__header__status(v-if="stateRule.value", :style="getStatusColor(issue.status_color)") {{ issue.state }}

              .timeline__card__content
                div(v-for="rule in filteredDisplayRules", :key="issue.id + rule.name")
                  b {{ `${rule.label}: ` }}
                  span(v-if="isDateFields(rule)") {{ getFormattedDate(issue[rule.name]) || "-" }}
                  div(v-else-if="rule.name === 'description'", v-html="issue[rule.name] || '-'")
                  span(v-else) {{ issue[rule.name] || "-" }}

    template(v-else)
      .timeline__column(v-for="(hour, index) in [0, 1, 2, 3, 4, 5]", :key="hour.toString()")
        .timeline__column__header
          .timeline__column__header__time
            q-skeleton(type="text", width="100px")

        .timeline__column__content
          .timeline__row(v-for="issue in [0, 1, 2, 3, 4]", :key="issue")
            .timeline__card.timeline__card--skeleton
              .timeline__card__header
                .timeline__card__header__id
                  q-skeleton(type="text", width="20px")

              .timeline__card__content
                q-skeleton(type="text")

  .line(ref="timeline", v-if="!loading")

  .footer
    span {{ `${issueCenterLocales.now} ${currentTime}` }}
</template>

<script setup>
import backend from "@/api";
import { issueCenterLocales } from "@/services/useLocales";
import { useEmitter } from "@/services/useEmitter";

import { onMounted, onBeforeUnmount, ref, computed, nextTick } from "vue";
import { handleError } from "@/services/handleErrors";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";

import { toDate, format, formatISO, add, sub, millisecondsToHours, millisecondsToMinutes } from "date-fns";

const props = defineProps({
  firstHour: { type: Date, default: undefined, required: true },
  lastHour: { type: Date, default: undefined, required: true },
  initialHour: { type: Date, default: undefined, required: true },
  displayRules: { type: Array, default: () => [], required: true },
  mode: { type: String, default: undefined, required: false },
});

const emitter = useEmitter();

const store = useStore();

const timeline = ref();
const columnRefs = ref([]);

const columnsByHour = ref({});

const columns = ref([]);

const currentHour = ref();
const timelineTimeout = ref();
const displayRules = ref([]);
const issueActions = ref();

const loading = ref(true);

const filteredDisplayRules = computed(() => {
  return props.displayRules.filter(el => el.name !== "state" && (el.value === true || el.value === "true"));
});

const stateRule = computed(() => props.displayRules.find(el => el.name === "state"));

const emit = defineEmits(["set-loading"]);

const getCardColor = color => {
  return color ? `background: ${color + "33"}` : null;
};
const getStatusColor = color => {
  return color ? `background: ${color}` : null;
};

const initializeColumns = () => {
  const result = [];

  for (let i = 1; i <= 6; i++) {
    if (i > 1) result.push(add(props.firstHour, { hours: i - 1 }));
    else result.push(props.firstHour);
  }

  columns.value = result;
};

const getLayoutIssues = hour => {
  return columnsByHour.value[hour]?.issues;
};

const getInitialColumnsData = async () => {
  for (let i = 0; i < columns.value.length; i++) {
    await getIssuesByColumn(columns.value[i]);
  }
};

const getRequestParams = timeParam => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["issue_center"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  localFilters["type"] = "MaintenanceIssue";
  localFilters["finish_date_plane#from"] = `${getISODate(timeParam)}`;
  localFilters["finish_date_plane#to"] = `${getISODate(sub(add(timeParam, { hours: 1 }), { seconds: 1 }))}`;

  return {
    json: JSON.stringify({
      table: {
        ignore_pagination: true,
        except_filters: {},
        query: localQuery,
        filters: localFilters,
      },
    }),
  };
};

const getIssuesByColumn = async timeParam => {
  try {
    const params = getRequestParams(timeParam);

    const response = await backend.index("api/v3/situational_center/issues", { params });

    issueActions.value = response.data.actions;

    store.commit("setIssueActionsInIssueCenter", issueActions.value);

    columnsByHour.value = {
      ...columnsByHour.value,
      [timeParam]: { issues: response.data.data, count: response.data.count },
    };
  } catch (e) {
    await handleError(e);
  }
};

const getCurrentDate = () => {
  return toDate(new Date());
};

const getISODate = timeParam => {
  return formatISO(
    new Date(
      timeParam.getFullYear(),
      timeParam.getMonth(),
      timeParam.getDate(),
      timeParam.getHours(),
      timeParam.getMinutes(),
      timeParam.getSeconds(),
    ),
  );
};

const getDateWithDay = timeParam => {
  return format(timeParam, "dd.MM.yyyy, HH:mm");
};

const getCountLabel = hour => {
  return columnsByHour.value[hour]?.count || 0;
};

const isDateFields = rule => {
  return (
    rule.name === "finish_date_plane" ||
    rule.name === "created_at" ||
    rule.name === "updated_at" ||
    rule.name === "dead_line"
  );
};

const getCurrentTime = () => {
  const hours = getCurrentDate().getHours();
  const minutes = getCurrentDate().getMinutes();

  const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return `${hoursString}:${minutesString}`;
};

const currentTime = ref(getCurrentTime());

const getCurrentTimeLinePosition = () => {
  const diff = millisecondsToMinutes(getCurrentDate() - props.firstHour);

  return diff;
};

const initializeTimeline = clear => {
  if (timelineTimeout.value && clear) clearTimeout(timelineTimeout.value);
  const currentDate = getCurrentDate();
  currentTime.value = getCurrentTime();
  currentHour.value = currentDate.getHours();

  if (!timeline.value || loading.value) {
    setTimeout(() => initializeTimeline(), 100);
    return;
  }

  const dateWithHours = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
  );

  for (let i of columns.value) {
    if (i - dateWithHours === 0) {
      timeline.value.style.visibility = "visible";
      timeline.value.style.left = (100 / 360) * getCurrentTimeLinePosition() + "%";

      setTimeout(() => initializeTimeline(), 30000);

      return;
    }
  }

  timeline.value.style.visibility = "hidden";

  setTimeout(() => initializeTimeline(), 30000);
};

const changeHours = async prefix => {
  let newDate;

  if (prefix === "+") {
    newDate = add(props.initialHour, { hours: 1 });
  } else {
    newDate = sub(props.initialHour, { hours: 1 });
  }

  changeInitialHours(newDate);
  nextTick(async () => {
    loading.value = true;

    initializeColumns();

    if (prefix === "+") {
      await getIssuesByColumn(props.lastHour);
    } else {
      await getIssuesByColumn(props.firstHour);
    }

    initializeTimeline(true);

    loading.value = false;
  });
};

const getFormattedDate = date => {
  if (!date) return;
  // We need to ensure date is formatted as ISO string.
  const dataArray = date.split("T");

  if (dataArray.length > 1) {
    const dateInstance = new Date(date);

    return format(dateInstance, "dd.MM.yyyy, HH:mm");
  }
};

const openShow = rowValue => {
  emit("openShow", rowValue);
};

const changeInitialHours = (hour = getCurrentDate()) => {
  const initialHour = new Date(hour.getFullYear(), hour.getMonth(), hour.getDate(), hour.getHours());

  const previousDate = props.initialHour;
  const nextDate = initialHour;

  const delta = millisecondsToHours(previousDate - nextDate);

  const firstHour = sub(props.firstHour, { hours: delta });
  const lastHour = sub(props.lastHour, { hours: delta });

  emit("changeHours", {
    initialHour: initialHour,
    firstHour: firstHour,
    lastHour: lastHour,
  });
};

const onIssueArchived = event => {
  const issueDate = new Date(event.finish_date_plane);
  const data = new Date(issueDate.getFullYear(), issueDate.getMonth(), issueDate.getDate(), issueDate.getHours());

  if (!columnsByHour.value[data]) columnsByHour.value[data] = { issues: [], count: 0 };
  if (!columnsByHour.value[data].issues) return;

  const issueIndex = columnsByHour.value[data].issues.findIndex(el => el.id === event.id);

  if (issueIndex === -1) return;

  columnsByHour.value[data].issues.splice(issueIndex, 1);

  columnsByHour.value[data].count -= 1;
};

const onIssueUpdated = event => {
  const issueDate = new Date(event.finish_date_plane);
  const data = new Date(issueDate.getFullYear(), issueDate.getMonth(), issueDate.getDate(), issueDate.getHours());

  let currentIssueDate = null;
  let currentIssueIndex = null;

  for (let i in columnsByHour.value) {
    for (let j = 0; j < columnsByHour.value[i].issues.length; j++) {
      if (columnsByHour.value[i].issues[j].id === event.id) {
        currentIssueDate = i;
        currentIssueIndex = j;
      }
    }
  }

  if (currentIssueDate !== null && currentIssueIndex !== null && currentIssueDate === data) {
    columnsByHour.value[data].issues.splice(currentIssueIndex, 1, event);
  } else if (currentIssueDate !== null && currentIssueIndex !== null) {
    columnsByHour.value[currentIssueDate].issues.splice(currentIssueIndex, 1);
    columnsByHour.value[currentIssueDate].count -= 1;

    if (!columnsByHour.value[data]) columnsByHour.value[data] = { issues: [], count: 0 };
    columnsByHour.value[data].issues.unshift(event);
    columnsByHour.value[data].count += 1;
  }
};

const onIssueCreated = event => {
  const issueDate = new Date(event.finish_date_plane);
  const data = new Date(issueDate.getFullYear(), issueDate.getMonth(), issueDate.getDate(), issueDate.getHours());

  if (!columnsByHour.value[data]) columnsByHour.value[data] = { issues: [], count: 0 };

  const persistedIssue = columnsByHour.value[data].issues.findIndex(el => el.id === event.id);

  if (persistedIssue !== -1) return;

  columnsByHour.value[data].issues.unshift(event);
  columnsByHour.value[data].count += 1;
};

const initializeData = async () => {
  if (props.mode === "timeline") {
    loading.value = true;
    columns.value = [];
    columnsByHour.value = {};

    initializeColumns();
    initializeTimeline();

    await getInitialColumnsData();
    loading.value = false;
  }
};

onMounted(async () => {
  await initializeData();

  emitter.on("issue-center-timeline-refresh-data", initializeData);
  emitter.on("createdNewIssue", e => onIssueCreated(e));
  emitter.on("updatedIssue", e => onIssueUpdated(e));
  emitter.on("archivedIssue", e => onIssueArchived(e));
});

onBeforeUnmount(() => {
  emitter.off("issue-center-timeline-refresh-data");
  emitter.off("createdNewIssue");
  emitter.off("updatedIssue");
  emitter.off("archivedIssue");
});

defineExpose({ changeHours });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/issue_center.scss";

.timeline {
  grid-template-columns: repeat(6, calc(100% / 6));
}
</style>
