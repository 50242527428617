<template lang="pug">
div
  .form-field-label(v-show="visible")
    span {{ label }}
    span.form-field-require-tag(v-show="required", :title="notifies.required_field")
      | *

    q-input.date-input-class(
      v-model="currentField",
      @click.stop="openModal = true",
      :class="currentClass",
      :for="name",
      :mask="currentMask",
      readonly
    )
      template(v-slot:prepend)
        inline-svg.datetime-icon(
          :src="require(`../../../../assets/icons/calendar.svg`)",
          @click.stop="openDateModal()"
        )
      template(v-slot:append)
        q-icon.cancel-datetime-field(name="cancel", v-if="currentField", @click.capture="resetField()")
    q-dialog#q-datetime-modal(v-model="openModal")
      q-card.row.radius(flat)
        q-date(
          v-model="currentDate",
          :mask="mask",
          :locale="currentDatesLocale",
          :default-view="defaultView",
          today-btn
        )
          .row.justify-end
            q-btn.dialog-controls(
              flat,
              @click="openModal = false",
              :label="buttonCancelLabel",
              v-if="type !== 'datetime'"
            )
            q-btn.dialog-controls(flat, @click="checkDate", :label="buttonCloseLabel", v-if="type !== 'datetime'")
        q-time(v-model="currentDate", :mask="mask", format24h, v-if="type === 'datetime'", now-btn)
          .row.justify-end
            q-btn.dialog-controls(
              flat,
              @click="openModal = false",
              :label="buttonCancelLabel",
              v-if="type === 'datetime'"
            )
            q-btn.dialog-controls(flat, @click="checkDate", :label="buttonCloseLabel", v-if="type === 'datetime'")

  .valid-error-message(v-if="visible && currentFieldIsInvalid") {{ currentErrorMessage }}
</template>

<script>
import { Datetime } from "vue-datetime";
import i18n from "@/plugins/vue-i18n/index";
import { format, parse, startOfTomorrow } from "date-fns";
import { add } from "date-fns";
import { formatDateAsISO } from "@/services/formatDateAsISO";
import { handleError } from "@/services/handleErrors";

export default {
  components: {
    Datetime,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      method: this.parentData.method,
      method_limit: this.parentData.method_limit,
      grid: this.parentData.grid,
      type: this.parentData.data[0].type,
      value: this.parentData.data[0].value,
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      depend_from: this.parentData.data[0].depend_from || {},
      conditions: this.parentData.data[0].conditions || [],
      required: this.parentData.data[0].require,
      watch: this.parentData.data[0].watch || {},
      minDate: this.parentData.data[0].min_date,
      visible: this.parentData.data[0].visible === false ? false : true,
      valid_error_message: null,
      main_class: "form-field form-date",
      phrases: {},
      monthPicker: this.parentData.data[0].monthPicker,
      mask:
        this.parentData.data[0].mask || this.parentData.data[0].type === "datetime"
          ? "DD.MM.YYYY, HH:mm"
          : "DD.MM.YYYY",
      openModal: false,
      defaultView: this.parentData.data[0].defaultView || "Calendar",
      currentDate:
        this.parentData.data[0].type === "datetime"
          ? this.getCurrentDate("DD.MM.YYYY, HH:mm")
          : this.getCurrentDate("DD.MM.YYYY"),
    };
  },

  computed: {
    currentMask() {
      return this.mask === "DD.MM.YYYY, HH:mm" ? "##.##.####, ##:##" : "##.##.####";
    },

    currentClass() {
      return this.currentFieldIsInvalid ? this.main_class + " valid-error" : this.main_class;
    },

    currentDatesLocale() {
      return i18n["messages"][this.current_locale]["date"];
    },

    today() {
      const dateTime = new Date();
      return format(dateTime, "yyyy/MM/dd");
    },

    buttonCloseLabel() {
      return i18n["messages"][this.current_locale]["buttonCloseLabel"];
    },

    buttonCancelLabel() {
      return i18n["messages"][this.current_locale]["buttonCancelLabel"];
    },

    currentField: {
      get() {
        let form_field = this.currentForm[this.name];

        if (!form_field || !form_field["field"]) {
          return undefined;
        }

        // we need to ensure date is formatted as ISO string
        const dateArr = form_field["field"].split("T");

        if (dateArr.length > 1) {
          const dateInstance = new Date(form_field["field"]);
          if (this.type === "datetime") {
            return format(dateInstance, "dd.MM.yyyy, HH:mm");
          } else {
            return format(dateInstance, "dd.MM.yyyy");
          }
        } else {
          return form_field["field"];
        }
      },

      set(value) {
        let result = {};

        if (value) {
          // we need to ensure date is formatted as ISO string
          value = formatDateAsISO(value);
        }

        if (value && value.length > 0) {
          result["field"] = value;
          result["invalid"] = this.invalid(value);
        } else {
          result["invalid"] = this.invalid();
        }

        this.$store.commit("updateFormField", { grid_name: this.grid, field: this.name, value: result });

        if (!result["invalid"]) {
          this.$store.commit("resetFormFieldValue", {
            grid_name: this.grid,
            field: "invalid_fields",
            value: this.name,
          });
        }
      },
    },

    fieldDependency() {
      return this.checkDependency();
    },

    // Errors messages we set from backend
    customErrorMessage() {
      const invalidFieldsErrors = this.currentForm.invalid_fields_errors;

      if (!invalidFieldsErrors) {
        return "";
      }

      return invalidFieldsErrors[this.name];
    },

    // Order of fields is important: valid_error_message must be first to preserve required errors
    currentErrorMessage() {
      return this.valid_error_message || this.customErrorMessage;
    },
  },

  watch: {
    fieldDependency(newVal, oldVal) {},
  },

  created() {
    this.watchSimpleFieldParents();
  },

  beforeMount() {
    this.$store.commit("createFormField", { grid_name: this.grid, field: this.name });
    this.setField(this.value);
    this.setDateFieldLocale();
    this.setDateFieldPhrases();
  },

  mounted() {
    this.temporaryFixSafariPopupBug("open", "form");
  },

  methods: {
    onInput(val) {
      if (this.watch && this.watch["conditions"] && this.watch["conditions"].length > 0) {
        this.watch["conditions"].forEach(condition => {
          if (!(this.value && this.value === this.currentField)) {
            if (val && val.length > 0) {
              if (!this.checkCondition(condition, val)) {
                this.$nextTick(() => {
                  this.resetField();
                });
              }
            }
          }
        });
      }
    },

    onClose() {
      this.temporaryFixSafariPopupBug("close", "form");
    },

    setField(val) {
      this.currentField = val;
    },

    resetField() {
      this.currentField = "";
    },

    stackLabelCheck() {
      return this.currentField !== null && this.currentField !== undefined && this.currentField !== "";
    },

    loadValue(params, parent_params) {
      if (Object.keys(parent_params).length > 0) {
        this.$backend
          .index(this.$store.state.paths[parent_params["path"]] + parent_params["action"], { params: params })
          .then(({ data }) => {
            // In watchSimpleFieldParents it's if/else condition (loadValue). However it seems like we don't need that
            if (parent_params["fn"]) {
              this[parent_params["fn"]](data);
            }

            this.currentField = data[parent_params["result_key"]];
          })
          .catch(async error => {
            await handleError();
            this.error = true;
          });
      }
    },

    checkCondition(condition, current_value) {
      if (Object.keys(condition).length === 0) {
        return true;
      } else {
        let result = false;
        let operator = condition["expect_result"];
        let message = condition["message"];
        let condition_value = this.getConditionValueByField(condition["condition_field"]);
        let current_date = parse(current_value, "dd.MM.yyyy, HH:mm", new Date()).getTime();

        if (operator && condition_value) {
          if (operator === "<") {
            result = current_date < condition_value;
            if (!result) {
              this.$q.notify(message);
            }
            return result;
          } else if (operator === ">") {
            result = current_date > condition_value;
            if (!result) {
              this.$q.notify(message);
            }
            return result;
          } else if (operator === "<=") {
            result = current_date <= condition_value;
            if (!result) {
              this.$q.notify(message);
            }
            return result;
          } else if (operator === ">=") {
            result = current_date >= condition_value;
            if (!result) {
              this.$q.notify(message);
            }
            return result;
          } else if (operator === "===") {
            result = current_date === condition_value;
            if (!result) {
              this.$q.notify(message);
            }
            return result;
          } else {
            return result;
          }
        } else {
          return true;
        }
      }
    },

    getConditionValueByField(val) {
      if (val === "current_datetime") {
        let now = new Date();
        return now.setMinutes(now.getMinutes() - 5);
      } else if (val === "start_of_tomorrow") {
        return startOfTomorrow().getTime();
      } else {
        let field = this.currentForm[val];
        if (field) {
          return parse(field["field"], "yyyy-MM-dd'T'HH:mm:ssXXX", new Date()).getTime();
        }
      }
    },

    invalid(val = undefined) {
      if (this.required) {
        if (val && val.length > 0) {
          this.valid_error_message = null;
          return false;
        } else {
          this.valid_error_message = this.notifies.not_empty;
          return true;
        }
      } else {
        this.valid_error_message = null;
        return false;
      }
    },

    getCurrentDate(mask) {
      let resp = "";
      if (this.currentField) {
        resp = this.currentField;
      } else {
        const dateTime = new Date();
        if (mask === "DD.MM.YYYY, HH:mm") {
          resp = format(dateTime, "dd.MM.yyyy, HH:mm");
        } else {
          resp = format(dateTime, "dd.MM.yyyy");
        }
      }
      return resp;
    },

    openDateModal() {
      this.openModal = true;
      this.currentDate = this.getCurrentDate(this.mask);
    },

    checkDate() {
      this.currentField = this.currentDate;
      this.onInput(this.currentField);
      this.openModal = !this.openModal;
    },

    showField(data) {
      if (data.show) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/date";

.date-input-class {
  border: none !important;
  cursor: pointer;
  .q-field__prepend {
    padding-left: 15px;
    height: 41px;
    path {
      fill: var(--datetime-icon-fill);
    }
  }
  .q-field__append {
    height: 41px;
    i {
      margin-top: 0;
      margin-right: -1px;
      font-size: 14px;
    }
  }
  .q-field__control,
  .q-field__control:before,
  .q-field__control:hover:before {
    color: transparent;
    border: none;
  }
  input {
    font-size: 16px;
    margin-top: 0 !important;
  }
}
#q-datetime-modal {
  .radius {
    border-radius: 20px;
  }
  .q-time,
  .q-date {
    height: 430px;
    box-shadow: none;
    background: var(--vdatetime-popup-background);
    border-radius: 0;
    color: var(--vdatetime-popup-color);
    .q-date__header,
    .q-time__header {
      background-color: var(--vdatetime-popup-day-background-selected);
    }
    .q-time__clock-pointer {
      color: var(--vdatetime-popup-day-background-selected);
    }
    .q-time__clock-position--active {
      background-color: var(--vdatetime-popup-day-background-selected);
    }
    .q-date__content {
      .q-btn {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
    .q-date__calendar-item {
      .bg-primary {
        background-color: var(--vdatetime-popup-day-background-selected) !important;
      }
    }
    .dialog-controls {
      padding-left: 15px;
      padding-right: 15px;
      color: var(--vdatetime-popup-day-background-selected);
      &:nth-child(1) {
        color: var(--delete-button-color);
      }
    }
  }
  .q-date {
    width: 300px;
    .bg-primary {
      background-color: var(--vdatetime-popup-day-background-selected) !important;
    }
  }
  .q-time {
    .q-time__now-button {
      background-color: var(--vdatetime-popup-day-background-selected);
    }
  }
}
</style>

<style scoped lang="scss">
.valid-error-message {
  position: static;
}
</style>
