<template lang="pug">
q-card#form.modal-form-card
  q-card-section
    form-header(
      ref="form-header",
      @close-form="closeForm",
      :parentData="{ grid: grid, title: header_title, title_icon: 'checklist.svg' }"
    )

  .spinner-container.modal-spinner(v-if="!dataHasLoaded")
    q-spinner(color="primary", size="3em")

  q-card-section.checklist-logs(v-show="dataHasLoaded")
    .print-logs-button
      q-btn(flat, no-caps, @click="onPrint")
        template(slot="default")
          inline-svg(:src="require(`../../../assets/icons/show/actions/print.svg`)")
        template(slot="default")
          span.label Печать

    .checklist-logs-title
      span {{ data["checklist_title"] }}

    .checklist-logs-title
      span {{ data["building_title"] }}

    .checklist-logs-table
      table(cellspacing="0", cellpadding="0")
        tr(v-for="row in default_rows")
          td.title-row
            span {{ row["title"] }}
          td.user-row
            span
          td.date-row
            span {{ data[row["key"]] }}
          td.value-row
            span

        tr(v-for="row in data['form_params']")
          td.title-row
            span {{ row["title"] }}
          td.user-row
            span {{ row["user"] }}
          td.date-row
            span {{ row["created_at"] }}
          td.value-row
            log-card(:parentData="{ title: row['title'], data: row['values'] }")

    .checklist-logs-title
      span {{ data["result_time"] }}

  #print-checklist-logs(v-if="dataHasLoaded", v-show="false")
    print-logs(:parentData="{ data: data }")
</template>

<script>
import formHeader from "../../shared/forms/formHeader";
import logCard from "./logCard";
import printLogs from "./print_logs";

export default {
  components: {
    formHeader,
    logCard,
    printLogs,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      data: [],
      default_rows: [
        {
          title: "Открыт",
          key: "started_at",
        },
        {
          title: "Завершен",
          key: "finished_at",
        },
      ],

      dataHasLoaded: false,
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },

    header_title() {
      return this.parentData.header_title;
    },
  },

  created() {
    this.loadLogsData();
  },

  methods: {
    onPrint() {
      let attr = {
        html_element_id: "print-checklist-logs",
        html_title: "",
        css_key: "print_checklist_logs",
      };

      this.print(attr);
    },

    closeForm() {
      this.$emit("close-form");
    },

    loadLogsData() {
      let path = this.path + "/logs_data";
      let params = {
        issue_id: this.row.id,
        checklist_name: this.row.checklist_name,
      };

      this.$backend
        .index(path, { params: params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/modal-form";
@import "../../../assets/styles/checklists/logs";
</style>
