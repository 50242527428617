<template lang="pug">
q-dialog(:value="isFilterModalOpen", @hide="hideFilterModal")
  q-card.horizontal-filters-form__card
    .row
      .col-10
        span.text-h6.text-bold.horizontal-filters-form__card__title {{ title }}
          q-icon.text-h6.horizontal-filters__icon-reset(
            name="refresh",
            @click="resetAllFields",
            v-if="currentFields.length > 0"
          )
      .col-2.horizontal-filters-form__card__close-icon
        q-btn(flat, round, icon="close", @click="hideFilterModal")
    .horizontal-filters-form__card__scroll
      .row
        .col-12
          .horizontal-filters-form__field(v-for="(field, i) in fields", :key="field.id")
            component(
              :is="getFieldComponent(field.type)",
              :field="field",
              :currentFieldState="getCurrentFieldState(field)",
              :ref="el => { fieldsRefs[i] = el; }",
              @answerSet="onAnswerSet",
              @setDisabled="setButtonValue(true)",
              @setEnabled="setButtonValue(false)"
            )
    .row
      .col-6.horizontal-filters-form__card__button
        q-btn.horizontal-filters-form__card__button--close(flat, no-caps, @click="hideFilterModal") {{ techPassportFilterLocales.cancel }}
      .col-6.horizontal-filters-form__card__button
        q-btn.horizontal-filters-form__card__button--accept(
          flat,
          no-caps,
          :disable="disableButton",
          @click="filterResults"
        ) {{ techPassportFilterLocales.accept }}
</template>

<script setup>
import { onBeforeUpdate, onMounted, ref } from "vue";

import { techPassportFilterLocales } from "@/services/useLocales";

import HorizontalFiltersSelectField from "./horizontalFiltersFormFields/HorizontalFiltersSelectField";
import HorizontalFiltersMinMaxField from "./horizontalFiltersFormFields/HorizontalFiltersMinMaxField";
import HorizontalFiltersRadioField from "./horizontalFiltersFormFields/HorizontalFiltersRadioField";

const props = defineProps({
  isFilterModalOpen: { type: Boolean, default: false },
  title: { type: String, default: "" },
  fields: { type: Array, default: () => [] },
  currentFields: { type: Array, default: () => [] },
});

const emit = defineEmits(["hideFilterModal", "filterResults", "resetAllFields"]);

const disableButton = ref(false);

const fieldsRefs = ref([]);

const hideFilterModal = () => {
  emit("hideFilterModal");
};

const getFieldComponent = type => {
  if (type === "integer") {
    return HorizontalFiltersMinMaxField;
  } else if (type === "dropdown") {
    return HorizontalFiltersSelectField;
  } else if (type === "radio" || type === "boolean") {
    return HorizontalFiltersRadioField;
  }
};

const onAnswerSet = val => {
  emit("answerSet", val);
};

const resetAllFields = () => {
  emit("resetAllFields");
  fieldsRefs.value.forEach(elem => {
    elem.onAllFilterReset();
  });
};

const submitForm = () => {
  if (!disableButton.value) {
    filterResults();
  }
};

const filterResults = () => {
  emit("filterResults");
  hideFilterModal();
};

const getCurrentFieldState = field => {
  if (field) {
    const fieldIndex = props.currentFields.findIndex(elem => elem.field_id === field.field_id);
    if (fieldIndex !== -1) {
      return props.currentFields[fieldIndex];
    } else {
      return {};
    }
  } else {
    return {};
  }
};

const setButtonValue = val => {
  disableButton.value = val;
};

onMounted(() => {
  window.addEventListener("keyup", event => {
    if (event.key === "Enter") {
      if (!disableButton.value) {
        filterResults();
      }
    }
  });
});

onBeforeUpdate(() => {
  fieldsRefs.value = [];
});
</script>

<style lang="scss">
.horizontal-filters {
  &-form__card {
    background: var(--horizontal-filters-form-card-background) !important;
    padding: 20px;
    border-radius: 20px !important;
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
    .horizontal-filters-form__card__scroll {
      overflow-y: auto;
      height: 100%;
    }

    .form-field {
      border: var(--horizontal-filters-form-field-border);
      .q-field__append {
        color: var(--horizontal-filters-form-reset-icon);
        height: 40px;
        cursor: pointer;
      }
      .q-field__native {
        margin-top: 0 !important;
      }
    }

    &__title {
      color: var(--horizontal-filters-form-card-color);
    }

    &__close-icon {
      display: flex;
      justify-content: flex-end;
      color: var(--horizontal-filters-form-card-color);
      align-items: flex-start;
      margin-top: -4px;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;

      &--close {
        background: none;
        color: var(--horizontal-filters-form-cancel-button-color);
        border: 1px solid var(--horizontal-filters-form-cancel-button-color);
      }

      &--accept {
        background: #5b7aff;
        color: white;
      }

      .q-btn {
        padding: 0 25px;
        border-radius: 8px;
      }
    }
  }
  &__field-label {
    color: var(--horizontal-filters-form-card-color);
    word-wrap: break-word;
  }
  &__radio-field {
    color: var(--horizontal-filters-form-card-color);
    .q-radio__bg {
      color: var(--horizontal-filters-form-radio-bg);
    }
  }
  &__icon-reset {
    margin-top: -2px;
    margin-left: 10px;
    cursor: pointer;
    color: var(--horizontal-filters-form-reset-icon);
  }
}
</style>
