export default {
  light: {
    grid_border_color: "#8C8CED",
    fore_color: "#E5E7EB",
  },

  dark: {
    grid_border_color: "#8C8CED",
    fore_color: "#E5E7EB",
  },
};
