<template lang="pug">
div(v-if="multiselectValue")
  div(:class="multiselectClass")
    multiselect(
      :value="multiselectValue",
      track-by="id",
      label="title",
      :multiple="false",
      placeholder="",
      :show-labels="false",
      :options="multiselectOptions",
      closeOnSelect,
      hideSelected,
      @select="onSelect"
    )
      span(slot="noResult")
        i {{ notifies.no_search_result }}
    q-icon.cancel-field.template-cancel(
      name="cancel",
      v-if="multiselectValue.value",
      @click.stop="resetField(); emitResetField()"
    )
  .multiselect-fields(v-if="multiselectValue.id === 'dropdown'")
    .multiselect-fields-header.row
      .col-5 ExternalId
      .col-5 {{ templateLocales.list_choices }}
    .multiselect-fields-item.row(v-for="(item, indexSelect) in dropdownOptions")
      q-input.field-input.col-5(
        filled,
        no-error-icon,
        v-model="dropdownOptions[indexSelect].external_id",
        :label-slot="false",
        autocomplete="off"
      )
      q-input.field-input.col-5(
        filled,
        no-error-icon,
        v-model="dropdownOptions[indexSelect].name",
        :label-slot="false",
        autocomplete="off"
      )
        inline-svg.multiselect-fields-item-remove(
          :src="require(`../../../../assets/icons/object_pass/templates/remove_field.svg`)",
          @click="removeMultiselectItem(indexSelect)"
        )
    .multiselect-fields-add(@click="addDropdownVariable") {{ templateLocales.another_option }}
  .multiselect-fields(v-if="isMetrics && metricsOptions.length > 0")
    .multiselect-fields-header {{ templateLocales.choose_child_configuration }}
    .form-multiselect.form-field
      multiselect(
        v-model="metricsVal",
        track-by="id",
        label="title",
        :multiple="false",
        placeholder="",
        :show-labels="false",
        :options="metricsOptions",
        closeOnSelect,
        hideSelected,
        @select="onSelectMetrics"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}
  .multiselect-fields(v-if="isMetrics && metricsVal.id")
    .multiselect-fields-header {{ metricsHeader() }}
    .form-multiselect.form-field(v-if="metricsChildOptions.length > 0")
      multiselect(
        v-model="metricsChildVal",
        track-by="id",
        label="title",
        :multiple="false",
        placeholder="",
        :show-labels="false",
        :options="metricsChildOptions",
        closeOnSelect,
        hideSelected,
        @select="onSelectMetricsChild"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}
  .multiselect-fields(v-if="isReference && referenceOptions.length > 0")
    .multiselect-fields-header {{ templateLocales.choose_child_configuration }}
    .form-multiselect.form-field
      multiselect(
        v-model="referenceVal",
        track-by="id",
        label="title",
        :multiple="false",
        placeholder="",
        :show-labels="false",
        :options="referenceOptions",
        closeOnSelect,
        hideSelected,
        @select="onSelectReferences"
      )
        span(slot="noResult")
          i {{ notifies.no_search_result }}

  //- .multiselect-fields(v-if="isReference && referenceVal.id")
  //-   .multiselect-fields-header {{ metricsHeader() }}
  //-   .form-multiselect.form-field(v-if="metricsChildOptions.length > 0")
  //-     multiselect(
  //-       v-model="referenceChildVal",
  //-       track-by="id",
  //-       label="title",
  //-       :multiple="false",
  //-       placeholder="",
  //-       :show-labels="false",
  //-       :options="referenceChildOptions",
  //-       closeOnSelect,
  //-       hideSelected,
  //-       @select="onSelectReferenceChild"
  //-     )
  //-       span(slot="noResult")
  //-         i {{ notifies.no_search_result }}
</template>

<script setup>
import Multiselect from "vue-multiselect";
import { templateLocales, notifies } from "@/services/useLocales";
import { ref, onMounted, computed, watch, nextTick } from "vue";
import backend from "@/api";

const props = defineProps({
  multiselectClass: {
    type: String,
    default() {
      return "form-field form-multiselect";
    },
  },
  multiselectOptions: {
    type: Array,
    default() {
      return [];
    },
  },
  multiselectValue: {
    type: Object,
    default() {
      return {};
    },
  },
  dropdownOptions: {
    type: Array,
    default() {
      return [];
    },
  },
  templateId: {
    type: Number,
    default() {
      return 0;
    },
  },
  formType: {
    type: String,
    default() {
      return "";
    },
  },
  metricsValues: {
    type: Object,
    default() {
      return {};
    },
  },
  referenceValue: {
    type: Number,
    default() {
      return null;
    },
  },
});

const emit = defineEmits([
  "setSelectValue",
  "resetField",
  "addDropdownVariable",
  "removeMultiselectItem",
  "setMetricsValue",
  "setMetricsChildValue",
  "resetMetrics",
]);

const isMetrics = ref(false);
const isReference = ref(false);

const metricsOptions = ref([]);
const referenceOptions = ref([]);
const metricsVal = ref({});
const referenceVal = ref({});
const metricsChildOptions = ref([]);
const referenceChildOptions = ref([]);
const metricsChildVal = ref({});
const referenceChildVal = ref({});

onMounted(() => {
  checkMetrics();

  checkReferences();
});

const onSelect = val => {
  emit("setSelectValue", val);
  nextTick(() => {
    checkMetrics(val);
    checkReferences(val);
  });
};

const checkMetrics = async (val = props.multiselectValue) => {
  if (val.params_type === "metric") {
    await loadMetrics();
  } else {
    resetField();
    emit("resetMetrics", props.index);
    return;
  }

  if (props.metricsValues.calculated_field && props.metricsValues.calculated_child) {
    metricsVal.value = metricsOptions.value.find(option => option.id == props.metricsValues.calculated_child);
    metricsChildOptions.value = await getMetricsChildOptions(props.metricsValues.calculated_child);
    metricsChildVal.value = metricsChildOptions.value.find(option => option.id == props.metricsValues.calculated_field);
  }
};

const checkReferences = async (val = props.multiselectValue) => {
  if (val.params_type === "reference") {
    await loadReferences();
  } else {
    resetField();
    return;
  }

  if (props.referenceValue) {
    referenceVal.value = referenceOptions.value.find(option => option.id == props.referenceValue);
  }
};

const loadMetrics = async () => {
  try {
    const { data } = await backend.index(
      `/api/v3/tech_passport/templates/${props.templateId}/available_metric_childs?template_id=${props.templateId}`,
    );
    metricsOptions.value = data.options;
    isMetrics.value = true;
  } catch (e) {
    await handleError(e);
  }
};

const loadReferences = async () => {
  try {
    const { data } = await backend.index(
      `/api/v3/tech_passport/templates/${props.templateId}/available_bind_templates`,
    );
    referenceOptions.value = data.options;
    isReference.value = true;
  } catch (e) {
    await handleError(e);
  }
};

const resetField = () => {
  isMetrics.value = false;
  isReference.value = false;
  metricsOptions.value = [];
  metricsVal.value = {};
  metricsChildOptions.value = [];
  metricsChildVal.value = {};
};

const emitResetField = () => {
  emit("resetField", props.index, "type");
};

const addDropdownVariable = () => {
  emit("addDropdownVariable");
};

const removeMultiselectItem = indexSelect => {
  emit("removeMultiselectItem", indexSelect);
};

const metricsHeader = () => {
  if (metricsChildOptions.value.length > 0) {
    return templateLocales.value.choose_metric;
  }
  return templateLocales.value.no_available_metrics;
};

const getMetricsChildOptions = async id => {
  try {
    const { data } = await backend.index(
      `/api/v3/tech_passport/templates/${props.templateId}/available_metric_fields?calculated_child=${id}&field_type=${props.formType}`,
    );
    return data.options;
  } catch (e) {
    await handleError(e);
  }
};

const onSelectMetrics = async val => {
  const id = val.id;
  metricsChildOptions.value = await getMetricsChildOptions(id);
  emit("setMetricsChildValue", id);
};

const onSelectReferences = async val => {
  const id = val.id;
  emit("setReferencesValue", id);
};

const onSelectMetricsChild = val => {
  const id = val.id;
  emit("setMetricsValue", id);
};
</script>
