<template lang="pug">
index-page(
  @set-loading="setLoading",
  :parentData="{ grid: grid, path: path, grid_key: grid_key, report_class: report_class }"
)
</template>

<script>
import indexPage from "../../shared/indexPage";

export default {
  components: {
    indexPage,
  },
  data: function () {
    return {
      grid: "employees",
      report_class: "employee",
      path: this.$store.state.paths["employee"],
      grid_key: "data",
    };
  },

  beforeMount() {
    // this.$emit('set-loading', false)
    this.$emit("expand-class", { val: false, class: "" });
  },

  methods: {
    setLoading(val) {
      this.$emit("set-loading", val);
    },
  },
};
</script>

<style lang="scss"></style>
