<template lang="pug">
div
  #project-filter-form-button.project-filter-form-button
    q-btn(flat, no-caps, @click="openForm()")
      template(slot="default")
        span.project-filter-form-button-label {{ label }}

  q-dialog(v-model="modal")
    project-filters-form(
      ref="project-filters-form",
      @load-form-data="loadFormData",
      @close-form="closeForm",
      @load-options="loadSelectOptions",
      @filters-form-reset="filtersFormReset",
      @submit="data => $emit('submit', data)",
      :parentData="{ data: filters_form_data, path: path[item], grid: grid, item: item, header_title: label, options_data: options_data }"
    )
</template>

<script>
import projectFiltersForm from "./filters_form";
import { handleError } from "@/services/handleErrors";

export default {
  components: {
    projectFiltersForm,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: "project_filters", // set own namespace to avoid collisions in filters (for example, we don't expect facility_id in filters in dynamic_issues page since it breaks selection_tabs)
      item: this.parentData.item,
      load_form_params: this.parentData.load_filters_params,
      encodeNestedData: this.parentData.encodeNestedData,
      path: this.$store.state.paths,
      filters_form_data: {},
      appendPath: this.parentData.appendPath || "project_filter_data",

      options_data: {},
      options: [],

      modal: false,
    };
  },

  computed: {
    data() {
      return this.filters_form_data.filters_data;
    },

    label() {
      return this.locales["old_dashboard"][this.current_locale].projects;
    },
  },

  created() {
    this.$store.commit("initialGridsState", { grid: this.grid, attr: {} });
  },

  beforeDestroy() {
    this.filtersFormReset();
  },

  methods: {
    openForm() {
      this.modal = true;
    },

    closeForm() {
      this.modal = false;
    },

    async loadFormData() {
      try {
        const { data } = await this.$backend.index(
          `${this.path[[this.item]]}/${this.appendPath}`,
          { params: this.load_form_params },
          { encodeNestedData: this.encodeNestedData },
        );
        this.filters_form_data = data;
      } catch (error) {
        handleError(error);
      } finally {
        this.$refs["project-filters-form"].setDataHasLoaded(true);
      }
    },

    setOptionsData(data, filter_name) {},

    setLoading(val, filter_name) {},

    filtersLoaded(filter) {},

    filtersFormReset() {
      this.$store.commit("resetAllFilters", { grid_name: this.grid, filters: Object.keys(this.filters) });
    },

    loadSelectOptions(params) {
      const defaultValue = params.default_value;
      const { default_value, ...paramsWithoutDefaultVal } = params;

      if (defaultValue !== null && defaultValue !== undefined) {
        this.loadSelectFilterOptions(paramsWithoutDefaultVal, defaultValue);
      } else {
        this.loadSelectFilterOptions(paramsWithoutDefaultVal);
      }
    },
  },
};
</script>
