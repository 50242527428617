<template lang="pug">
.csi-chart.full-height
  .spinner-container.grid-spinner(v-if="loading")
    q-spinner(color="white", size="2em") 

  .dashboard-card-label.csi-chart-label(v-else)
    span {{ board_data.title }}
  apex-chart(:parentData="{ chart_data: board_data }")
</template>

<script>
import apexChart from "../charts/apexChart.vue";

export default {
  components: {
    apexChart,
  },

  data: function () {
    return {
      board_data: {},
      name: "rates_csi_by_day",
      loading: true,
    };
  },

  computed: {
    content() {
      return this.board_data;
    },
  },

  mounted() {
    this.$root.$on("locale-changed", () => {
      this.loadBoardData();
    });
  },

  created() {
    this.loadBoardData();
  },

  methods: {
    reloadData(params) {
      this.loadBoardData(params);
    },

    loadBoardData(params = {}) {
      this.loading = true;

      this.$backend
        .index(`${this.$store.state.paths.dashboard}/${this.name}`, { params: params })
        .then(({ data }) => {
          if (data && Object.keys(data).length > 0) {
            this.board_data = data;
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.spinner-container.grid-spinner {
  height: 100% !important;
}

.csi-chart {
  background: linear-gradient(
    to bottom,
    #6644ff,
    #694efc,
    #6c57f9,
    #6f5ff5,
    #7367f1,
    #7269f1,
    #706bf0,
    #6f6df0,
    #666af4,
    #5b67f7,
    #4f65fb,
    #3e62ff
  );
  border-radius: inherit; // othwerside it get's removed

  .csi-chart-label {
    color: white;
  }
}
</style>
