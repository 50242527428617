/*
 * Entities are data objects displayed on the map.
 * They come from backend and are essentially just wrappers around json.
 * This class defines methods common for all entities (otherwise they differ from each other a lot).
 */
class AbstractEntity {
  constructor(data) {
    this.data = data;
  }

  getCoords() {
    return this.data.coords;
  }

  getId() {
    return this.data.id;
  }

  getType() {
    throw `.getType() not implemented`;
  }

  getEntityKey() {
    return `${this.getType()}_${this.getId()}`;
  }
}

export default AbstractEntity;
