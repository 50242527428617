<template lang="pug">
q-card.modal-form-card.telemetry-modal
  q-card-section(v-if="loading")
    .spinner-container.modal-spinner(v-if="loading")
      q-spinner(color="primary", size="3em")

  q-card-section(v-if="!loading")
    component(
      :is="getBoard(component)",
      @load-chart-data="modalCallback({ ref: 'indications-by-hours', fn: 'loadChartData' })",
      :parentData="parentData"
    )
</template>

<script>
import boards from "./boards/boards";

export default {
  components: {
    boards,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      component: this.parentData.component,
      loading: false,
    };
  },

  computed: {},

  watch: {},

  methods: {
    getBoard(component) {
      return boards[component];
    },

    modalCallback(attr) {
      let data = {
        ref: attr.ref,
        fn: attr.fn,
        params: attr,
      };
      this.$emit("modal-callback", data);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/modal-form";

.telemetry-modal {
  background: var(--dashboard-card-background) !important;

  width: 90% !important;
  /*height: 85% !important;*/
  height: fit-content !important;
  min-height: 500px;

  .spinner-container.modal-spinner {
    height: 650px !important;
  }
}
</style>
