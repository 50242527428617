<template lang="pug">
.indications-block
  .card
    .card-label
      span {{ data.title }}

    .spinner-container.grid-spinner(v-if="loading")
      q-spinner(color="primary", size="3em")

    .indications-wrapper.main-swiper(v-show="!loading && indications.length > 0", ref="indicationsSwiper")
      div(
        :class="`indication-card indication-badge ${i === 0 ? 'first-badge' : ''}`",
        v-for="(indication, i) in indications",
        :key="indication.id",
        :id="`indication-badge-${indication.id}`",
        :style="{ background: `linear-gradient(180deg, ${indication.color}40 0%, rgba(246, 247, 251, 0) 70%)` }",
        @click="onClickIndication(indication)"
      )
        .card-wrapper
          .card-top
            //- .icon(:style="{ 'background-color': indication.color }")
            .icon
              inline-svg(:src="indication.icon.url")
            .values
              //span(class="value") {{`${indication.value || '144'} ${indication.measure_unit || ''}`}}
              .value-data
                span.value {{ !indication.value && indication.value !== 0 ? "" : indication.value }}
                span.unit {{ indication.measure_unit || "" }}
              span.current-time {{ indication.current_time }}
            .deviation(v-if="indication.deviation")
              inline-svg(:src="require(`../../../../assets/icons/telemetry/deviation.svg`)")
              p
                | Отклонение
                br
                span {{ `min ${indication.min_value} ${indication.measure_unit} max ${indication.max_value} ${indication.measure_unit}` }}

          .title
            span {{ indication.title }}
            span.no-data {{ !indication.value && indication.value !== 0 ? "нет показаний" : "" }}

      .empty-indication-card(v-if="emptyIndications.length > 0", v-for="(_, i) in emptyIndications", :key="i")
        .card-wrapper

    grid-hidden-msg(
      v-show="!loading && !indications.length > 0",
      ref="indications-hidden-msg",
      :parentData="{ hide_table: tableHidden }"
    )
</template>

<script>
import { Swiper } from "vue-awesome-swiper";
import gridHiddenMsg from "../../../shared/grid/gridHiddenMsg";

export default {
  components: {
    Swiper,
    gridHiddenMsg,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      intervals: [],
      indications: [],
      item: {},
      sensorId: "",
      emptyIndications: [],

      loading: true,

      tableHidden: false,
    };
  },

  computed: {
    data() {
      return this.parentData.data;
    },

    indication: {
      get() {
        return this.item;
      },
      set(value) {
        this.item = value;
      },
    },

    sensor() {
      return this.sensorId;
    },
  },

  watch: {
    sensor(n) {
      if (n && !this.intervals.length > 0) {
        this.intervals.push(this.indicationsInterval());
      }
    },
  },

  mounted() {
    this.intervals.push(this.indicationsInterval());
  },

  beforeDestroy() {
    this.destroyIntervals();
  },

  methods: {
    onClickIndication(indication) {
      if (indication) {
        let attr = {
          item: "indication",
          new_item: indication,
        };
        this.activeBadge(attr);
        this.indication = indication;
        this.$emit("load-indications-by-hours", { sensor_id: this.sensor, id: this.indication.id });
      }
    },

    hideTable(val) {
      this.tableHidden = val;
      if (this.$refs["indications-hidden-msg"]) {
        this.$refs["indications-hidden-msg"].hideTable(val);
      }
    },

    getTimezone() {
      const currentDate = new Date();
      const currentTimezoneOffset = currentDate.getTimezoneOffset() / 60;
      return -currentTimezoneOffset;
    },

    loadIndications(params = {}) {
      if (!params["interval_reload"]) {
        this.loading = true;
      }

      if (params["sensor_id"]) {
        this.sensorId = params["sensor_id"];
        params["time_zone"] = this.getTimezone();

        this.$backend
          .collection(`${this.$store.state.paths.telemetry}/indications`, params)
          .then(({ data }) => {
            this.emptyIndications = [];
            this.indications = data;

            if (!params["interval_reload"]) {
              this.indication = this.indications[0];
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }
          })
          .finally(() => {
            this.loading = false;

            if (this.indications.length > 0) {
              this.hideTable(false);
              let attr = {
                item: "indication",
                new_item: this.indications[0],
              };

              if (!params["interval_reload"]) {
                this.activeBadge(attr);
                this.fillTitleIcons();
              }
              this.addEmptyIndications();

              let child_params = { sensor_id: this.sensor, id: this.indication.id };
              child_params["interval_reload"] = params["interval_reload"] || false;
              this.$emit("load-indications-by-hours", child_params);
            } else {
              this.hideTable(true);
            }
          });
      } else {
        this.loading = false;
        this.emptyIndications = [];
        this.indications = [];
        this.indication = {};
        this.sensorId = "";
        this.hideTable(true);

        this.destroyIntervals();

        this.$emit("load-indications-by-hours", { sensor_id: this.sensor, id: "" });
      }
    },

    fillTitleIcons() {
      setTimeout(() => {
        this.indications.forEach(i => {
          let el = document.getElementById(`title-icon-${i.id}`);
          if (el) {
            let path = el.getElementsByTagName("path")[0];
            if (path) {
              path.style.fill = i.color;
            }
          }
        });
      }, 500);
    },

    addEmptyIndications() {
      if (this.indications.length < 2) {
        let count = 2 - this.indications.length;
        this.emptyIndications = [...Array(count)].map(() => {
          return {};
        });
      }
    },

    indicationsInterval() {
      return setInterval(() => {
        if (this.sensor) {
          let params = {
            sensor_id: this.sensor,
            id: this.indication.id,
            interval_reload: true,
          };
          this.loadIndications(params);
        }
      }, 15000);
    },
  },
};
</script>

<style lang="scss">
.indications-block {
  height: 100%;

  .card-label {
    padding-bottom: 30px !important;
  }

  .card {
    padding: 20px;
    height: inherit;

    .hidden-tabs-message {
      height: 90%;

      span {
        padding-top: initial;
      }
    }

    .spinner-container.grid-spinner {
      height: auto;
    }

    .main-swiper {
      height: 365px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-wrap: wrap;
      overflow: auto;

      .swiper-pagination-bullet {
        background: #007aff;
      }
    }

    .empty-indication-card {
      width: 150px;
      height: 120px;
      background: transparent;
      /*border: 1px solid #E4E4E4;*/
      /*box-sizing: border-box;*/
      /*border-radius: 20px;*/

      .card-wrapper {
        /*padding: 15px;*/
        /*text-align: center;*/

        /*.icon {*/
        /*margin-top: 5px;*/

        /*svg {*/

        /*path {*/
        /*fill: #E4E4E4;*/
        /*}*/
        /*}*/
        /*}*/

        /*.title {*/
        /*padding: 10px 0 10px 0;*/

        /*span {*/
        /*font-style: normal;*/
        /*font-weight: 600;*/
        /*font-size: 12px;*/
        /*line-height: 18px;*/
        /*color: #E4E4E4;*/
        /*}*/
        /*}*/
      }
    }

    .active-badge {
      /*border: 1px solid #454545 !important;*/
      background: linear-gradient(205.31deg, #ffecb5 -23.7%, #a8b3ec -23.69%, #6644ff 83.95%) !important;

      .current-time {
        color: #ffffff !important;
      }

      .value-data {
        .value {
          color: #ffffff !important;
        }

        .unit {
          color: #ffffff !important;
        }
      }

      .title {
        span {
          color: #ffffff !important;
        }

        .no-data {
          color: #ffffff !important;
        }
      }
    }

    .first-badge {
      margin-top: 0 !important;
    }

    .indication-card {
      width: 150px;
      height: 125px;
      /*background: var(--indication-card-background);*/
      border: var(--indication-card-border);
      box-sizing: border-box;
      border-radius: 20px;
      cursor: pointer;
      position: relative;

      .card-wrapper {
        padding: 15px;

        .card-top {
          display: flex;

          .values {
            position: absolute;
            top: 15px;
            right: 15px;
            display: grid;

            .value-data {
              .value {
                white-space: nowrap;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 12px;
                color: var(--indication-value-data);
              }

              .unit {
                white-space: nowrap;
                font-style: normal;
                font-weight: normal;
                font-size: 9px;
                line-height: 12px;
                color: var(--indication-value-data);
                padding-left: 5px;
              }
            }

            .current-time {
              font-style: normal;
              font-weight: normal;
              font-size: 11px;
              line-height: 12px;
              color: var(--indication-current-time);
            }
          }
        }

        .icon {
          position: relative;
          width: 28px;
          height: 28px;
          border-radius: 8px;

          svg {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: inherit;
            width: inherit;
            background: var(--dashboard-card-background);
            border-radius: 50%;
            padding: 4px;
            box-sizing: content-box;
          }
        }

        .bottom-icon {
          position: absolute;
          bottom: 5px;
        }

        .deviation {
          position: absolute;
          right: 5px;

          > p {
            display: none;
            position: absolute;
            width: fit-content;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            background-color: #343442;
            color: #e8e8e8;
            z-index: 1;
            box-shadow: 0 1px 2px rgba(89, 89, 89, 0.16), 0 2px 4px rgba(0, 0, 0, 0.08), 0 1px 8px rgba(73, 73, 73, 0.1);
            border-radius: 5px;
            padding: 2px 8px;
            margin-top: 5px;

            span {
              white-space: nowrap;
            }

            &::before {
              content: "";
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid #343442;
              left: 50%;
              transform: translateX(-50%);
              top: -8px;
              position: absolute;
            }
          }

          &:hover {
            > p {
              display: block;
            }
          }
        }

        .title {
          padding: 15px 0 10px 0;
          display: grid;

          span {
            white-space: wrap;
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 12px;
            color: var(--indication-title);
          }

          .no-data {
            position: absolute;
            bottom: 15px;
            white-space: nowrap;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            color: var(--indication-no-data);
          }
        }
      }
    }
  }
}
</style>
