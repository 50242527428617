<template lang="pug">
.form-step-four.form-step
  .form-step-four-text.title {{ schedulerLocales["step4"] }}
    span {{ schedulerLocales["events"] }}
  event-el(v-for="(event, index) in events", :key="event.id", :index="index", :event="event")
  .form-step-four-add(@click="addEvent")
    .form-step-four-add-icon
    .form-step-four-add-text {{ schedulerLocales["addEvent"] }}
</template>

<script setup>
import { computed, ref, toRefs, onBeforeMount, onMounted } from "vue";
import i18n from "@/plugins/vue-i18n";
import { currentLocale } from "@/services/useLocales";
import eventEl from "./event";

const props = defineProps({
  events: { type: Array, default: () => [] },
});
const emit = defineEmits(["add-event"]);

const { events } = toRefs(props);

const schedulerLocales = computed(() => i18n["messages"][currentLocale.value]["scheduler"]);

const addEvent = () => {
  emit("add-event");
};
</script>
