import { render, staticRenderFns } from "./IssueStateBadge.vue?vue&type=template&id=edac0a88&lang=pug&"
import script from "./IssueStateBadge.vue?vue&type=script&lang=js&"
export * from "./IssueStateBadge.vue?vue&type=script&lang=js&"
import style0 from "./IssueStateBadge.vue?vue&type=style&index=0&id=edac0a88&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports