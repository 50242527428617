<template lang="pug">
.columns__container
  .columns(:style="columnsRepeatStyle")
    template(v-if="!loading")
      .columns__column(v-for="(filter, index) in data", :key="filter.id", :ref="el => (columnRefs[index] = el)")
        .columns__column__header
          .columns__column__header__time 
            span(:title="filter.title") {{ filter.title }}
            .columns__column__header__time__count(
              :class="{ 'columns__column__header__time__count--empty': filter.count === 0 }"
            ) {{ filter.count }}
          q-btn.columns__column__header__edit(icon="tune", unelevated, round, dense)
            q-menu
              q-list.columns__column__header__edit__list
                q-item(clickable, v-close-popup, @click="openShowColumn(filter.id)")
                  q-item-section {{ issueCenterLocales.edit }}
                q-item(clickable, v-close-popup, @click="openDeleteColumn(filter.id)")
                  q-item-section {{ issueCenterLocales.delete }}

          q-dialog(v-model="confirmDeleteFilter", seamless, persistent)
            q-card.columns__confirm-dialog__card
              q-card-section.row.items-center 
                | {{ issueCenterLocales.confirmation_delete }}
              q-card-actions(align="center")
                q-btn.columns__confirm-dialog__card__cancel(
                  flat,
                  no-caps,
                  :label="issueCenterLocales.no",
                  v-close-popup
                )
                q-btn.columns__confirm-dialog__card__delete(
                  flat,
                  no-caps,
                  :label="issueCenterLocales.yes",
                  v-close-popup,
                  @click="deleteColumn()"
                )

        .columns__column__content
          .columns__row(v-for="issue in filter.issues", :key="issue.id")
            .columns__card(@click="openShow(issue)", :style="getCardColor(issue.status_color)")
              .columns__card__header
                .columns__card__header__id №{{ issue.id }}
                .columns__card__header__status(v-if="stateRule.value", :style="getStatusColor(issue.status_color)") {{ issue.state }}

              .columns__card__content
                div(v-for="rule in filteredDisplayRules", :key="issue.id + rule.name")
                  b {{ `${rule.label}: ` }}
                  span(v-if="isDateFields(rule)") {{ getFormattedDate(issue[rule.name]) || "-" }}
                  div(v-else-if="rule.name === 'description'", v-html="issue[rule.name] || '-'")
                  span(v-else) {{ issue[rule.name] || "-" }}

    template(v-else)
      .columns__column(v-for="(filter, index) in [0, 1, 2, 3, 4, 5]", :key="filter.toString()")
        .columns__column__header
          .columns__column__header__time 
            q-skeleton(type="text", width="100px")

        .columns__column__content
          .columns__row(v-for="issue in [0, 1, 2, 3, 4]", :key="issue")
            .columns__card.columns__card--skeleton
              .columns__card__header
                .columns__card__header__id 
                  q-skeleton(type="text", width="20px")

              .columns__card__content
                q-skeleton(type="text")
  .footer
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, computed } from "vue";

import backend from "@/api";
import { issueCenterLocales } from "@/services/useLocales";
import { useEmitter } from "@/services/useEmitter";

import { handleError } from "@/services/handleErrors";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";

import { toDate, format, formatISO, add, sub, millisecondsToHours, millisecondsToMinutes } from "date-fns";

const props = defineProps({
  displayRules: { type: Array, default: () => [], required: true },
  mode: { type: String, default: undefined, required: false },
});

const emitter = useEmitter();

const store = useStore();

const columnRefs = ref([]);

const columns = ref([]);
const data = ref();

const displayRules = ref([]);
const issueActions = ref();

const confirmDeleteFilter = ref(false);
const deleteFilterId = ref(null);

const loading = ref(true);

const filteredDisplayRules = computed(() => {
  // с бэка может прийти как true так и "true"
  return props.displayRules.filter(el => el.name !== "state" && (el.value === true || el.value === "true"));
});

const stateRule = computed(() => props.displayRules.find(el => el.name === "state"));

const columnsRepeatStyle = computed(() =>
  columns.value?.length > 0 && !loading.value
    ? `grid-template-columns: repeat(${columns.value.length}, calc(100% / ${columns.value.length}));`
    : "grid-template-columns: repeat(6, calc(100% / 6));",
);

const emit = defineEmits(["set-loading", "openShowColumn", "openDeleteColumn"]);

const getCardColor = color => {
  return color ? `background: ${color + "33"}` : null;
};

const getStatusColor = color => {
  return color ? `background: ${color}` : null;
};

const initializeColumns = async () => {
  try {
    const response = await backend.index("api/v3/situational_center/issue_filters");

    columns.value = response.data;
  } catch (e) {
    await handleError(e);
  }
};

const getLayoutIssues = param => {
  return data.value[param]?.issues;
};

const getInitialColumnsData = async () => {
  for (let i = 0; i < columns.value.length; i++) {
    await getIssuesByColumn(columns.value[i]);
  }
};

const getRequestParams = param => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["issue_center"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  localFilters["type"] = "MaintenanceIssue";
  localFilters["filter_id"] = param.id;

  return {
    json: JSON.stringify({
      table: {
        ignore_pagination: true,
        except_filters: {},
        query: localQuery,
        filters: localFilters,
      },
    }),
  };
};

const getIssuesByColumn = async param => {
  try {
    const params = getRequestParams(param);

    const response = await backend.index("api/v3/situational_center/issues", { params });

    issueActions.value = response.data.actions;

    store.commit("setIssueActionsInIssueCenter", issueActions.value);

    data.value = {
      ...data.value,
      [param.id]: { id: param.id, title: param.title, issues: response.data.data, count: response.data.count },
    };
  } catch (e) {
    await handleError(e);
  }
};

const getCurrentDate = () => {
  return toDate(new Date());
};

const getISODate = timeParam => {
  return formatISO(new Date(timeParam.getFullYear(), timeParam.getMonth(), timeParam.getDate(), timeParam.getHours()));
};

const getDateWithDay = timeParam => {
  return format(timeParam, "dd.MM.yyyy, HH:mm");
};

const getCountLabel = hour => {
  return dataa.value[hour]?.count || 0;
};

const isDateFields = rule => {
  return (
    rule.name === "finish_date_plane" ||
    rule.name === "created_at" ||
    rule.name === "updated_at" ||
    rule.name === "dead_line"
  );
};

const getFormattedDate = date => {
  if (!date) return;
  // We need to ensure date is formatted as ISO string.
  const dataArray = date.split("T");

  if (dataArray.length > 1) {
    const dateInstance = new Date(date);

    return format(dateInstance, "dd.MM.yyyy, HH:mm");
  }
};

const openShow = rowValue => {
  emit("openShow", rowValue);
};

const openShowColumn = rowValue => {
  emit("openShowColumn", rowValue);
};

const openDeleteColumn = id => {
  confirmDeleteFilter.value = true;
  deleteFilterId.value = id;
};

const deleteColumn = () => {
  emit("deleteColumn", deleteFilterId.value);
  deleteFilterId.value = null;
};

const onIssueArchived = event => {
  const toRemove = [];

  // нам нужно собрать со всех фильтров заявки с указанным идентификатором, потом снести их

  for (let i in data.value) {
    for (let j = 0; j < data.value[i].issues.length; j++) {
      if (data.value[i].issues[j].id === event.issue.id) {
        toRemove.push({ i, j });
      }
    }
  }

  toRemove.forEach(el => {
    data.value[el.i].issues.splice(el.j, 1);
    data.value[el.i].count--;
  });
};

const onIssueUpdated = event => {
  const toRemove = [];

  // нам нужно собрать со всех фильтров заявки с указанным идентификатором, потом снести их и на их место поставить обновленные
  for (let i in data.value) {
    for (let j = 0; j < data.value[i].issues.length; j++) {
      if (data.value[i].issues[j].id === event.issue.id) {
        toRemove.push({ i, j });
      }
    }
  }

  toRemove.forEach(el => {
    data.value[el.i].issues.splice(el.j, 1);
    data.value[el.i].count--;
  });

  event.filter_ids.forEach(el => {
    data.value[el].count += 1;
    data.value[el].issues.unshift(event.issue);
  });
};

const onIssueCreated = event => {
  // по идентификаторам в ивенте вставляем в нужные фильтры заявки, которые пришли

  event.filter_ids.forEach(el => {
    data.value[el].count += 1;
    data.value[el].issues.unshift(event.issue);
  });
};

const onColumnUpdated = async () => {
  await initializeData();
};

const initializeData = async () => {
  loading.value = true;
  columns.value = [];
  data.value = {};

  await initializeColumns();

  await getInitialColumnsData();

  loading.value = false;
};

onMounted(async () => {
  await initializeData();

  emitter.on("issue-center-columns-refresh-data", initializeData);
  emitter.on("createdNewCustomIssue", e => onIssueCreated(e));
  emitter.on("updatedCustomIssue", e => onIssueUpdated(e));
  emitter.on("archivedCustomIssue", e => onIssueArchived(e));
});

onBeforeUnmount(() => {
  emitter.off("issue-center-columns-refresh-data");
  emitter.off("createdNewCustomIssue");
  emitter.off("updatedCustomIssue");
  emitter.off("archivedCustomIssue");
});

defineExpose({ onColumnUpdated });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/issue_center.scss";
.columns {
  &__confirm-dialog {
    &__card {
      box-shadow: 0 0 1px 0px gray;
      background: var(--show-card-background);
      border-radius: 18px;
      color: var(--header-title-color);

      &__cancel {
        border: 1px solid gray;
        color: gray;
        border-radius: 8px;
        height: 36px;
      }

      &__delete {
        background-color: #3e62ff;
        color: white;
        border-radius: 8px;
        height: 36px;
      }
    }
  }

  &__column {
    &__header {
      display: flex;
      align-items: center;
      padding: 0 5px;
      border-bottom: 1px solid var(--issue-center-border-color);

      &__time {
        width: calc(100% - 30px);
        border-bottom: unset !important;

        span {
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          width: calc(100% - 56px);
        }
      }

      &__edit {
        font-size: 12px;
        color: gray;

        &__list {
          color: var(--header-title-color);
        }
      }
    }
  }
}
</style>
