import _ from "lodash";

const deepMapKeys = map => {
  return function (obj, fn) {
    return map(
      _.mapValues(obj, function (v) {
        return _.isPlainObject(v) ? deepMapKeys(map)(v, fn) : v;
      }),
      fn,
    );
  };
};

export { deepMapKeys };
