<template lang="pug">
.expansion-item
  .expansion-item__head
    .expansion-item__head__title(
      v-for="column in columns",
      :key="column.name + values.id",
      :style="{ width: column.width }"
    )
      .primary(v-if="column.name === 'title'") 
        inline-svg.expansion-item__head__building-icon(
          :src="require('../../../../assets/icons/object_pass/object.svg')"
        )
        .header
          .header__main {{ values.title }}
          .header__secondary {{ values.subtitle }}
          .header__secondary {{ `№ ${values.id}` }}
      .expansion-item__head__title(v-else) {{ values[column.name] }}
    .expansion-item__head__expand(style="width: 2%", v-if="!isIssuesEmpty(values)")
      Transition(name="rotate", mode="out-in")
        inline-svg.expand-icon(:src="openIcon", @click="changeOpen", :key="isOpen.toString()")

  Transition(name="slide")
    .expansion-item__content(v-if="isOpen", :key="`content-${isOpen.toString()}`")
      expansion-row-item-content(:buildingId="values.id")
</template>

<script setup>
import { ref, computed } from "vue";

import ExpansionRowItemContent from "./ExpansionRowItemContent.vue";

import DownArrow from "@/assets/icons/grid/down_arrow.svg";
import UpArrow from "@/assets/icons/grid/up_arrow.svg";

const props = defineProps({
  values: {
    type: Object,
    default: () => {},
  },
  columns: {
    type: Array,
    default: () => [],
  },
});

const isOpen = ref(false);

const openIcon = computed(() => (isOpen.value ? UpArrow : DownArrow));

const isIssuesEmpty = values => {
  return Number(values.issues_count) === 0 && Number(values.ppr_issues_count) === 0;
};

const changeOpen = () => {
  isOpen.value = !isOpen.value;
};
</script>

<style lang="scss" scoped>
.expansion-item {
  border-radius: 24px;
  background-color: var(--expenses-content-background-color);
  overflow-y: hidden;

  &__head {
    padding: 16px;
    display: flex;
    gap: 16px;

    &__title,
    &__expand {
      display: flex;
      align-items: center;
    }

    :deep(.expand-icon) {
      width: 20px;
      height: 20px;
      cursor: pointer;

      path {
        fill: var(--expenses-main-text-color);
      }
    }

    &__title {
      color: var(--expenses-expansion-text-color);
    }

    .primary {
      width: 100%;
      height: 110px;
      border-radius: 16px;
      background-color: var(--expenses-expansion-primary-block-background-color);
      display: flex;
      align-items: center;
      gap: 12px;
      padding-right: 15px;

      .header {
        height: 100%;
        width: calc(100% - 64px - 12px);
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__main {
          font-size: 12x;
          color: #5b7aff;
          font-weight: 600;
        }

        &__secondary {
          font-size: 12px;
          color: var(--expenses-main-text-color);
        }
      }
    }
  }

  &__content {
    border-top: 2px solid var(--expenses-expansion-separator-color);
    max-height: 392px;
    overflow-y: auto;
    padding: 0 16px 16px;

    &__children--no-data {
      display: flex;
      justify-content: center;
      font-style: italic;
    }
  }
}

:deep(.expansion-item__head__building-icon) {
  width: 64px;
  height: auto;
}

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.3s ease;
}

.rotate-enter-from,
.rotate-leave-to {
  transform: rotate(0.5turn);
}

:deep(.q-toggle__inner--truthy) {
  .q-toggle__thumb:after {
    color: #5b7aff;
  }
  .q-toggle__track {
    color: #adbdff;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: transform 0.3s ease;
}
.slide-enter {
  transform: translateY(100%);
}
.slide-leave-to {
  transform: translateY(100%);
}
</style>
