import Vue from "vue";
import XLSX from "xlsx";

Vue.mixin({
  methods: {
    simpleExportExcel(data) {
      let workbook = XLSX.utils.book_new();
      let success = true;
      data.forEach(sheet => {
        let columns = sheet.columns;

        this.sheet_data = sheet.data.map(el => {
          return columns.reduce((result, column) => {
            result[column.label] = el[column.name];
            return result;
          }, {});
        });

        let worksheet = XLSX.utils.json_to_sheet(this.sheet_data);

        if (worksheet) {
          XLSX.utils.book_append_sheet(workbook, worksheet, sheet.worksheet);
        } else {
          this.$q.notify("По заданным критериям ничего не найдено");
          success = false;
        }
      });
      if (success) {
        XLSX.writeFile(workbook, this.grid + ".xlsx");
      }
    },
  },
});
