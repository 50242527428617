<template lang="pug">
div
  .justify-center(v-if="dataHasLoaded")
    handle-form(
      @close-form="closeForm",
      :parentData="{ method: 'update', item: 'cleaning_work', item_id: row.id, title_icon_path: titleIconPath, form_title: `${formData.form_header.title.edit} №${row.id}`, path, grid: 'cleaning_works', data: formData }"
    )
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "@/store";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { currentTimezoneOffset } from "@/services/useTimezoneHelpers";
import HandleForm from "../../shared/forms/Form";

const props = defineProps({
  /**
   * Проп родительских параметров приходяших в форму
   * Приходят параметры:
   * callback_params - не используемый в этом компоненте,
   * grid - имя грида
   * row - текущий объект
   */
  parentData: { type: Object, default: () => {} },
});

const store = useStore();
const emit = defineEmits(["close-form", "row-is-active"]);

const titleIconPath = "edit_header.svg";

const formData = ref([]);
const dataHasLoaded = ref(false);

const row = computed(() => props.parentData.row);
const path = computed(() => store.state.paths["cleaning_work"]);

const closeForm = data => {
  emit("close-form", data);
};

const loadFormData = async val => {
  const params = row.value ? { id: row.value.id, time_zone: currentTimezoneOffset() } : {};

  try {
    const response = await backend.index(`${path.value}/form_data`, { params });
    formData.value = response.data;
  } catch (error) {
    handleError(error);
  } finally {
    dataHasLoaded.value = true;
  }
};

onMounted(async () => {
  await loadFormData();
  emit("row-is-active", true);
});
</script>
