<template lang="pug">
.form-field__indications(v-if="facilityFieldLoaded")
  .form-field-label
    span {{ label }}
  .form-field__indications-fields(v-for="(indicator, index) in currentField", :key="indicator.key")
    .row
      .form-field__indications-field(
        v-for="field in indicatorFields",
        :key="field.name + indicator.key",
        :class="getFieldClass(field)"
      )
        component(
          :is="getIndicatorField(field.type)",
          :label="field.label",
          :initialValue="indicator[field.name]",
          step="any",
          :optionsPath="getFieldPath(field)",
          :optionsParams="getFieldParams(field)",
          :autogrow="true",
          :multiple="getFieldMultiple(field)",
          :watch="getFieldWatch(field, indicator)",
          :required="field.require",
          :invalid="currentFieldIsInvalid(field.require, indicator[field.name])",
          :errorMessage="notifies.not_empty",
          :formLoaded="formLoaded",
          @change="val => { changeIndicatorField(val, index, field.name); }"
        )

      .form-field__indications-fields-remove(@click="removeIndicator(index)")
        q-icon(name="close")

  .form-field__indications-add(@click="addIndicator()") {{ telemetryLocales.sensor_form_add_indicator }}
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "@/store";
import fields from "../../general_components/fields";
import { notifies, telemetryLocales } from "@/services/useLocales";
import { getDynamicWatch } from "@/services/useWatchParent";

const props = defineProps({
  /**
   * Проп родительских параметров приходяших из default_form
   * Приходят параметры:
   * allFields, включающий в себя все остальные поля формы,
   * method, update или create,
   * method_limit, не используемый в этом компоненте,
   * grid, имя грида передаваемое с index файла,
   * data, данные поля приходящие с form_data,
   * item_id, не используемый в этом компоненте,
   * building_id, не используемый в этом компоненте,
   * formLoaded, нужный для передачи в компонент textField
   */
  parentData: { type: Object, default: null, required: true },
});

const store = useStore();

const indicatorKey = ref(0);

const gridName = computed(() => props.parentData.grid);
const initialValue = computed(() => props.parentData.data[0].value || []);
const indicatorFields = computed(() => props.parentData.data[0].fields);
const defaultIcon = computed(() => props.parentData.data[0].icon);
const requiredFields = computed(() =>
  indicatorFields.value
    .filter(field => field.require)
    .map(item => {
      return item.name;
    }),
);
const label = computed(() => props.parentData.data[0].label);
const name = computed(() => props.parentData.data[0].name);
const currentForm = computed(() => store.state.grid[gridName.value].form);
const facilityFieldLoaded = computed(() => currentForm.value.facility_id);
const formLoaded = computed(() => props.parentData.formLoaded);

const currentField = computed({
  get: () => {
    let formField = currentForm.value[name.value];
    if (formField) {
      return formField["field"];
    } else {
      return undefined;
    }
  },

  set: value => {
    let result = {
      field: value,
      invalid: invalid(value),
    };
    store.commit("updateFormField", { grid_name: gridName.value, field: name.value, value: result });
  },
});

onBeforeMount(() => {
  console.log(props.parentData);
  store.commit("createFormField", { grid_name: gridName.value, field: name.value });
  setInitialValue();
});

const getFieldClass = field => {
  return field.width ? field.width : "col-6";
};

const getFieldPath = field => {
  return field.options_path ? field.options_path : "";
};

const getFieldParams = field => {
  return field.options_params ? field.options_params : "";
};

const getFieldMultiple = field => {
  return field.multiple ? field.multiple : false;
};

const invalid = indicators => {
  let invalid = false;
  if (indicators && indicators.length > 0) {
    indicators.forEach(indicator => {
      requiredFields.value.forEach(fieldName => {
        if (!indicator[fieldName]) {
          invalid = true;
        }
      });
    });
  }
  return invalid;
};

const currentFieldIsInvalid = (required, value) => {
  return (
    currentForm.value &&
    currentForm.value["invalid_fields"] &&
    currentForm.value["invalid_fields"][name.value] &&
    required &&
    !value
  );
};

const setInitialValue = () => {
  if (initialValue.value.length > 0) {
    currentField.value = initialValue.value.map(item => {
      item.key = indicatorKey.value;
      indicatorKey.value++;
      return item;
    });
  } else {
    currentField.value = [];
    addIndicator();
  }
};

const addIndicator = () => {
  const newIndicator = Object.fromEntries(indicatorFields.value.map(t => [t.name, ""]));
  newIndicator["key"] = indicatorKey.value;
  newIndicator["icon"] = defaultIcon.value;
  indicatorKey.value++;
  currentField.value = [...currentField.value, ...[newIndicator]];
};

const removeIndicator = index => {
  currentField.value = currentField.value.filter((item, indexField) => indexField !== index);
};

const getIndicatorField = type => {
  return fields[type];
};

// need for changing sensor indicators array
const changeIndicatorField = (val, index, fieldName) => {
  currentField.value = currentField.value.map((field, indexField) => {
    // check index in array
    if (indexField === index) {
      field[fieldName] = val.value;
    }
    return field;
  });
};

const getFieldWatch = (field, indicator) => {
  if (field.type === "select" && field.watch) {
    const parent = field.watch.parents[0].parent;
    const parentValue = currentForm.value[parent] ? currentForm.value[parent].field.value : indicator[parent];
    return getDynamicWatch(parent, parentValue);
  }
  return null;
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/text";
</style>

<style lang="scss" scoped>
.form-field__indications {
  margin-top: 38px;

  &-add {
    color: #8ca2ff;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 16px;

    &::before {
      content: "+";
      font-weight: 500;
      font-size: 22px;
      margin-right: 5px;
    }
  }
}

.form-field-label {
  margin-bottom: 20px;
  span {
    font-size: 16px;
  }
}

.form-field__indications-fields {
  background-color: var(--indication-background);
  padding: 32px 16px 16px;
  border-radius: 16px;
  position: relative;
  margin-bottom: 12px;

  &-remove {
    color: #78839c;
    font-size: 18px;
    position: absolute;
    line-height: 100%;
    right: 22px;
    top: 16px;
    transition: all 0.2s ease-out;
    cursor: pointer;

    &:hover {
      font-size: 22px;
      top: 13px;
      right: 20px;
    }
  }

  .form-field__indications-field {
    padding: 6px;
  }
}
</style>
