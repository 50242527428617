<template lang="pug">
.column.relative_position_materials_search
  q-input.search-all-filter-field(
    filled,
    debounce="500",
    v-model="searchFieldValue",
    :label="searchFieldLabel",
    @input="searchAndSortMaterials()"
  )
    q-icon.cancel-search-all-filter(name="cancel", v-if="searchFieldValue", @click.stop="resetForm()")
  .equipment-tech-map
    .tech-map-card
      .tabs-block
        table#components-table.tech-map-tab-table.components-table.stores
          thead
            tr
              th(@click="changeSortParams('id')")
                span.column-header-title {{ tabTitles.id }}
                  q-icon(:name="iconName()", v-if="activeSort === 'id'")
              th(@click="changeSortParams('material_title')")
                span.column-header-title {{ tabTitles.material_title }}
                  q-icon(:name="iconName()", v-if="activeSort === 'material_title'")
              th(@click="changeSortParams('material_external_id')")
                span.column-header-title {{ tabTitles.material_external_id }}
                  q-icon(:name="iconName()", v-if="activeSort === 'material_external_id'")
              th(@click="changeSortParams('cost')")
                span.column-header-title {{ tabTitles.cost }}
                  q-icon(:name="iconName()", v-if="activeSort === 'cost'")
              th(@click="changeSortParams('count')")
                span.column-header-title {{ tabTitles.count }}
                  q-icon(:name="iconName()", v-if="activeSort === 'count'")
              th(@click="changeSortParams('total_cost')")
                span.column-header-title {{ tabTitles.total_cost }}
                  q-icon(:name="iconName()", v-if="activeSort === 'total_cost'")
              th(@click="changeSortParams('store_sender_title')")
                span.column-header-title {{ tabTitles.store_sender_title }}
                  q-icon(:name="iconName()", v-if="activeSort === 'store_sender_title'")
              th(@click="changeSortParams('store_motion_title')")
                span.column-header-title {{ tabTitles.store_motion_title }}
                  q-icon(:name="iconName()", v-if="activeSort === 'store_motion_title'")
          tbody
            template(v-if="tab_data[0].data.length")
              tr(
                v-if="editable",
                v-for="(item, i) in editable_data",
                :key="'editable' + item.id",
                :style="{ cursor: 'pointer' }",
                :class="{ 'active-components-row': checkable && checkable.index === i }",
                @click="setCheckable(item, i)"
              )
                td(v-for="key in Object.keys(item)", :key="'data' + key + item.id")
                  techMapInput(
                    v-if="isCountOrCost(key)",
                    :parentData="{ tech_map_key, grid, index: i, value: item[key], value_key: key }"
                  )
                  techMapInput(
                    v-else-if="isTitles(key)",
                    :parentData="{ tech_map_key, grid, index: i, value: item[key], value_key: key }"
                  )
                  template(v-else) {{ item[key] }}
              tr(v-if="!editable", v-for="item in tab_data[0].data", :key="'material' + item.id")
                td {{ item.id }}
                td {{ item.material_title }}
                td {{ item.material_external_id }}
                td {{ item.cost }}
                td {{ item.count }}
                td {{ item.total_cost }}
                td {{ item.store_sender_title }}
                td {{ item.store_motion_title }}
            template(v-else)
              tr
                td.text-center(colspan="6") {{ notifies.data_not_found }}
</template>

<script>
import techMapInput from "./helpers/input";
import handleButtons from "./helpers/buttons.vue";
import { navbarLocales } from "@/services/useLocales";

export default {
  components: {
    techMapInput,
    handleButtons,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      row_id: this.parentData.row_id,
      tab_data: this.parentData.data,
      checkable: undefined,
      tech_map_key: "components",
      searchFieldValue: "",
      activeSort: "id",
      activeDirection: "down",
    };
  },

  computed: {
    editable() {
      return this.parentData.editable;
    },

    data() {
      let data = this.parentData.data;
      return data ? data : [];
    },

    buttons() {
      return this.parentData.buttons;
    },

    searchFieldLabel() {
      return navbarLocales.search;
    },

    tabTitles() {
      return this.tab_data[0].titles;
    },

    editable_data: {
      get() {
        let grid = this.$store.state.grid[this.grid];
        if (grid) {
          return grid["materials_list"][this.tech_map_key];
        } else {
          return undefined;
        }
      },
      set(value) {
        this.$store.commit("addStoringMaterials", {
          grid_name: this.grid,
          tech_map_key: this.tech_map_key,
          attr: value[0].data,
        });
      },
    },
  },

  watch: {
    editable(newVal) {
      if (!newVal) {
        this.checkable = undefined;
      }
    },

    data(newVal) {
      this.editable_data = newVal;
    },
  },

  methods: {
    onClickFn(button) {
      this[button.onclick]();
    },

    resetForm() {
      this.searchFieldValue = "";
      this.searchAndSortMaterials();
    },

    iconName() {
      return this.activeDirection === "up" ? "expand_less" : "expand_more";
    },

    isCountOrCost(key) {
      return key === "count" || key === "cost";
    },
    isTitles(key) {
      return key === "store_motion_title" || key === "store_sender_title";
    },

    changeSortParams(val) {
      if (val === this.activeSort) {
        if (this.activeDirection === "down") {
          this.activeDirection = "up";
        } else {
          this.activeDirection = "down";
        }
      } else {
        this.activeSort = val;
        this.activeDirection = "down";
      }

      this.searchAndSortMaterials();
    },

    searchAndSortMaterials() {
      this.$emit("search-or-sort", {
        sort: this.activeSort,
        sort_direction: this.activeDirection,
        search: this.searchFieldValue,
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/show/ppr/equipment/main";
.relative_position_materials_search {
  .search-all-filter-field {
    position: relative;
    top: -55px;
    margin-bottom: 40px;
    width: 50%;
    .equipment-tech-map {
      margin-top: 15px;
    }
  }
}
</style>
