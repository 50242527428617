<template lang="pug">
.pie
  .pie__loader(v-if="loading")
    q-spinner(color="primary", size="3em")
  .pie__wrapper(v-else)
    .pie__header
      .pie__label {{ values?.legend?.title }}

    .pie__content
      .pie__content-chart
        .pie__header__text
          span.pie__percentage {{ values?.legend?.result?.value }}
          span.pie__description {{ values?.legend?.result?.label }}
        .pie__chart-container
          apexchart(type="donut", :height="200", :options="chartOptions", :series="values?.data", v-if="!zeroValues")
          span(v-else) Нет данных
      .pie__legend
        .pie__legend__by-params(v-for="item in values?.legend.text", :key="item.title")
          .pie__legend__title {{ item.title }}
          .pie__legend__item(
            v-for="legendItem in getLegendItemsByType(item.type)",
            :key="`${legendItem.type}_${legendItem.label}`"
          )
            .pie__legend__item__circle(:style="`background-color: ${legendItem.color}`")
            .pie__legend__item__content 
              .pie__legend__item__label {{ legendItem.label }}
              .pie__legend__item__value 
                | {{ legendItem.percent_value }}
                span {{ `(${legendItem.value})` }}
          .pie__legend__separator
          .pie__legend__all
            .pie__legend__all__item(v-for="resultItem in getResultItemsByType(item.type)", :key="resultItem.label")
              .pie__legend__all__item__label {{ resultItem.label }}
              .pie__legend__all__item__value
                | {{ resultItem.percent_value }}
                span {{ `(${resultItem.value})` }}
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { backend } from "@/api";
import { useEmitter } from "@/services/useEmitter";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";
import { handleError } from "@/services/handleErrors";

const emitter = useEmitter();

const store = useStore();

const props = defineProps({
  type: { type: String, required: true },
});

const chartOptions = ref({
  chart: {
    type: "donut",
    toolbar: {
      show: false,
    },
    events: {
      // donut onmouse events
      dataPointMouseEnter: ({ target }, ctx) => {
        // get target from event. This is svg donut and check if they have clicked attr
        if (target.getAttribute("data:pieClicked") !== "true") {
          // get need slice from donut
          const sliceIndex = parseInt(target.attributes.j.value);
          // set clicked attr for needed slice
          ctx.pie.pieClicked(sliceIndex);
        }
      },
      // the opposite situation for mouseleave event
      dataPointMouseLeave: ({ target }, ctx) => {
        if (target.getAttribute("data:pieClicked") === "true") {
          const sliceIndex = parseInt(target.attributes.j.value);
          ctx.pie.pieClicked(sliceIndex);
        }
      },
      click: ({ target }, ctx) => {
        const sliceIndex = parseInt(target.attributes.j.value);
        ctx.pie.pieClicked(sliceIndex);
      },
    },
  },
  states: {
    hover: {
      filter: { type: "none" },
    },
    active: {
      filter: { type: "none" },
    },
  },
  tooltip: {
    enabled: true,
    custom: function (opts) {
      // candlestick standard
      const data = opts.series[opts.seriesIndex];
      const color = opts.w.config.colors[opts.seriesIndex];
      let text = `<div class='column-chart__tooltip' style='background-color: ${color}BF;', background'><span class='column-chart__tooltip__label'>${data} ₽</span></div>`;

      return text;
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -2,
      },
      donut: {
        size: "55%",
        background: "transparent",
      },
    },
  },
  colors: [],
  legend: {
    show: false,
  },
});

const values = ref();
const zeroValues = ref();
const loading = ref(true);

const generateParams = () => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["expenses_dashboard"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  const params = {
    query: localQuery,
    filters: localFilters,
    type: props.type,
  };

  return params;
};

const getData = async () => {
  try {
    loading.value = true;
    const params = generateParams();
    const { data } = await backend.index("api/v3/expenses_dashboard/charts", { params }, { encodeNestedData: true });
    values.value = data;
    chartOptions.value.colors = values.value.items.map(item => {
      return item.color;
    });
    if (values.value.data.filter(el => el !== 0).length === 0) zeroValues.value = true;
  } catch (e) {
    handleError(e);
  } finally {
    loading.value = false;
  }
};

const getLegendItemsByType = type => {
  return values.value.items.filter(item => item.type === type);
};

const getResultItemsByType = type => {
  return values.value.result.filter(item => item.type === type);
};

onMounted(async () => {
  await getData();

  emitter.on("refresh-expenses-dashboard-data", getData);
});

onBeforeUnmount(() => {
  emitter.off("refresh-expenses-dashboard-data");
});
</script>

<style lang="scss">
.pie {
  width: 50%;
  background-color: var(--expenses-content-background-color);
  border-radius: 20px;
  padding: 24px;

  &__header {
    &__text {
      margin: 10px 0 20px;
      @media screen and (max-width: 1590px) {
        display: flex;
        align-self: flex-start;
        align-items: center;
      }
    }
  }

  &__label {
    color: var(--expenses-main-text-color);
    font-size: 20px;

    margin-left: 0;
  }

  &__percentage {
    color: var(--expenses-main-text-color);
    font-weight: 600;
    font-size: 16px;
    display: block;

    @media screen and (max-width: 1590px) {
      order: 2;
      display: inline;
      margin-left: 5px;
    }
  }

  &__description {
    font-size: 14px;
    font-weight: 500;
    color: #aeb5c4;

    @media screen and (max-width: 1590px) {
      order: 1;
      margin-left: 0;
    }
  }

  &__content {
    height: calc(100% - 51px - 25px);

    display: flex;
    justify-content: space-between;
    gap: 32px;

    @media screen and (max-width: 1590px) {
      flex-direction: column;
    }

    &-chart {
      @media screen and (max-width: 1590px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &__chart-container {
    width: 200px;
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    box-shadow: var(--expenses-pie-chart-box-shadow);
  }

  &__legend {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: min-content;
    justify-content: space-between;
    gap: 15px;

    @media screen and (max-width: 1590px) {
      gap: 0;
    }

    &__title {
      font-size: 16px;
      margin-bottom: 24px;
      color: var(--expenses-main-text-color);
    }

    &__separator {
      border: 0.5px solid #b5bbc9;
      margin: 4px 0;
    }

    &__by-params {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 48%;
      padding: 20px;
      background-color: var(--expenses-pie-chart-legend-background-color);
      border-radius: 17px;

      @media screen and (max-width: 1590px) {
        background-color: initial;
        width: 50%;
        padding: 20px 13px;
      }
    }

    &__all {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-left: 13px;

      &__item {
        font-size: 14px;

        &__label {
          font-weight: 500;
          color: #a5a5a5;
        }

        &__value {
          font-weight: 500;
          font-size: 16px;
          color: var(--expenses-main-text-color);

          span {
            font-size: 14px;
            margin-left: 3px;
          }
        }
      }
    }

    &__item {
      min-width: 45%;

      display: flex;
      gap: 5px;

      &__circle {
        width: 8px;
        height: 8px;

        margin-top: 6px;

        border-radius: 50%;

        background-color: #5b7aff;
      }

      &__label {
        font-weight: 500;
        color: #a5a5a5;
      }

      &__value {
        font-weight: 500;
        font-size: 16px;
        color: var(--expenses-main-text-color);

        span {
          font-size: 14px;
          margin-left: 3px;
        }
      }
    }
  }

  path {
    stroke: unset !important;
  }

  &__loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.column-chart__tooltip {
  padding: 10px 12px;
  color: #fff;
  backdrop-filter: blur(2px);

  &__label {
    font-size: 15px;
  }
}

.apexcharts-tooltip.apexcharts-theme-dark {
  background-color: initial !important;
}
</style>
