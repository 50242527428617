<template lang="pug">
.column-chart
  .column-chart__loader(v-if="loading")
    q-spinner(color="primary", size="3em")
  .column-chart__content(v-else)
    .column-chart__header
      .column-chart__label {{ legend?.title }}
      .column-chart__buttons(v-if="canScrollLeft || canScrollRight")
        .column-chart__buttons-item(:class="canScrollLeft ? 'active' : ''", @click="scrollChart('left')") 
          q-icon(name="arrow_back_ios")
        .column-chart__buttons-item(:class="canScrollRight ? 'active' : ''", @click="scrollChart('right')")
          q-icon(name="arrow_forward_ios")

    apexchart(ref="expensesDynamic", type="candlestick", :height="300", :options="chartOptions", :series="series")
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { backend } from "@/api";
import { useEmitter } from "@/services/useEmitter";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";
import { handleError } from "@/services/handleErrors";
import Multiselect from "vue-multiselect";

import { currentLocale } from "@/services/useLocales";

import i18n from "@/plugins/vue-i18n";

const emitter = useEmitter();

const store = useStore();

const props = defineProps({
  type: { type: String, required: true },
});

const loading = ref(true);

const chartOptions = ref({
  chart: {
    type: "candlestick",
    height: 350,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    events: {
      mouseMove: (e, chartContext, config) => {
        if (!loading.value) {
          const xAxis = chartContext.el.querySelector(".apexcharts-xaxis-texts-g");
          const crosshairs = chartContext.el.querySelector(".apexcharts-xcrosshairs");
          const tooltip = chartContext.el.querySelector(".apexcharts-tooltip");
          const barIndex = config.dataPointIndex;
          const resetXAxis = () => {
            xAxis.childNodes.forEach(item => {
              item.style.fill = "var(--header-subtitle-color)";
            });
          };
          if (tooltip) {
            tooltip.style.visibility = "visible";
            if (config.dataPointIndex === -1 || config.dataPointIndex === series.value[0].data.length) {
              tooltip.style.visibility = "hidden";
              resetXAxis();
              return;
            }
          }
          const currentBar = chartContext.el.querySelector(".apexcharts-series").childNodes[barIndex];
          const barWidth = Number(currentBar.getAttribute("barWidth"));
          const barHeight = Number(currentBar.getBoundingClientRect().height);
          const offsetY = Number(currentBar.getAttribute("cy"));
          const chartOffsetX = chartContext.el.querySelector(".apexcharts-graphical").getBoundingClientRect().left;
          const chartWrapperMargin =
            chartOffsetX - chartContext.el.querySelector(".apexcharts-canvas").getBoundingClientRect().left;
          const barMarginLeft =
            currentBar.getBoundingClientRect().left -
            chartContext.el.querySelector(".apexcharts-graphical").getBoundingClientRect().left;

          crosshairs.setAttribute("y", offsetY + barHeight);
          if (crosshairs.getAttribute("y2") - offsetY - barHeight > 0) {
            crosshairs.setAttribute("height", crosshairs.getAttribute("y2") - offsetY - barHeight);
          } else {
            crosshairs.setAttribute("height", "0");
          }
          crosshairs.setAttribute("width", barWidth);
          crosshairs.style.transform = `translateX(-${barWidth / 2 - 2}px)`;
          tooltip.style.marginTop = `${currentBar.getAttribute("cy")}px`;
          tooltip.style.marginLeft = `${chartWrapperMargin + barMarginLeft + barWidth / 2}px`;
          if (window.innerWidth - currentBar.getBoundingClientRect().left < 140) {
            tooltip.style.transform = `translate(calc(-100% + ${barWidth / 2}px), -80%)`;
            tooltip.style.setProperty("--arrow-margin", `${188 - barWidth / 2}px`);
          } else {
            tooltip.style.setProperty("--arrow-margin", `50%`);
            tooltip.style.transform = `translate(-50%, -80%)`;
          }
          resetXAxis();
          if (e.x > chartOffsetX) {
            xAxis.childNodes[barIndex].style.fill = "#0EC262";
            tooltip.style.visibility = "visible";
          } else {
            tooltip.style.visibility = "hidden";
          }
        }
      },
      mouseLeave: (e, chartContext) => {
        if (!loading.value) {
          const xAxis = chartContext.el.querySelector(".apexcharts-xaxis-texts-g");
          xAxis.childNodes.forEach(item => {
            item.style.fill = "var(--header-subtitle-color)";
          });
        }
      },
    },
  },
  colors: ["#7C95FF"],
  zoom: {
    enabled: true,
  },
  states: {
    hover: {
      filter: { type: "none" },
    },
    active: {
      filter: { type: "none" },
    },
  },
  yaxis: {
    tooltip: {
      enabled: false,
    },
    min: 0,
    tickAmount: 4,
  },
  xaxis: {
    type: "category",
    tickPlacement: "between",
    tooltip: {
      enabled: false,
    },
    crosshairs: {
      show: true,
      position: "front",
      width: 2,
      fill: {
        color: "#0EC262",
        type: "gradient",
        gradient: {
          colorFrom: "#0EC262",
          colorTo: "#0EC262",
          opacityFrom: 0.1,
          opacityTo: 0.7,
        },
      },
      stroke: {
        width: 0,
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  plotOptions: {
    candlestick: {
      colors: {
        upward: "#859DFF",
        downward: "#71C79F",
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -12,
    formatter: function (val, opts) {
      const open = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].y[0];
      const close = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].y[3];
      return close - open;
    },
  },
  tooltip: {
    followCursor: false,
    fixed: {
      enabled: true,
      position: "topLeft",
    },
    shared: false,
    custom: function (opts) {
      const date = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].x;
      const open = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].y[0];
      const close = opts.w.config.series[0].data[parseInt(opts.dataPointIndex)].y[3];

      let text =
        "<div class='column-chart__tooltip'><div class='column-chart__tooltip__header'><span class='column-chart__tooltip__circle'></span>";
      text += `<span class='column-chart__tooltip__label'>${close - open} ₽</span></div>`;
      text += `<span class='column-chart__tooltip__value'>${date}</span></div>`;

      return text;
    },
  },
});

const fullSeries = ref([
  // example
  // {
  //   x: "Янв 2023",
  //   y: [0, 0, 0, 5],
  // },
  // {
  //   x: "Фев 2023",
  //   y: [5, 5, 10, 10],
  // },
]);

const series = ref([
  // example
  // {
  //   name: "dynamics",
  //   data: [],
  // }
]);

const currentScrollPosition = ref([]);
const canScrollLeft = ref(false);
const canScrollRight = ref(false);
const legend = ref(null);

const maxSeriesLength = 13;
const seriesScrollStep = 4;

const getMonth = month => {
  return i18n["messages"][currentLocale.value]["date"]["monthsShort"][month];
};

const generateParams = () => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["expenses_dashboard"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  const params = {
    query: localQuery,
    filters: localFilters,
    type: props.type,
  };

  return params;
};

const getData = async () => {
  const params = generateParams();
  loading.value = true;

  try {
    const { data } = await backend.index("api/v3/expenses_dashboard/charts", { params }, { encodeNestedData: true });
    legend.value = data.legend;
    chartOptions.value.yaxis.max = data.total.data;

    let lastStart = 0;

    const newArr = [];

    data.data.forEach(item => {
      const currentEnd = Math.ceil(item.y + lastStart);
      newArr.push({
        x: getMonth(new Date(item.x).getMonth()),
        y: [lastStart, lastStart, currentEnd, currentEnd],
      });
      lastStart = currentEnd;
    });

    newArr.push({
      x: data.total.label,
      y: [0, 0, Math.ceil(data.total.data), Math.ceil(data.total.data)],
    });

    fullSeries.value = newArr;

    if (fullSeries.value.length <= maxSeriesLength) {
      const positionStart = 0;
      const positionEnd = fullSeries.value.length;
      setNewSeries(positionStart, positionEnd);
    } else {
      const positionStart = fullSeries.value.length - maxSeriesLength;
      const positionEnd = fullSeries.value.length;
      setNewSeries(positionStart, positionEnd);
    }
  } catch (e) {
    handleError(e);
  } finally {
    loading.value = false;
  }
};

const setNewSeries = (start, end) => {
  if (start < 0) {
    end = end - start;
    start = 0;
  }
  if (end > fullSeries.value.length) {
    start = start - (end - fullSeries.value.length);
    end = fullSeries.value.length;
  }
  currentScrollPosition.value = [start, end];
  series.value = [
    {
      name: "dynamics",
      data: fullSeries.value.slice(start, end),
    },
  ];
  start === 0 ? (canScrollLeft.value = false) : (canScrollLeft.value = true);
  end === fullSeries.value.length ? (canScrollRight.value = false) : (canScrollRight.value = true);
};

const scrollChart = direction => {
  if (canScrollRight.value || canScrollLeft.value) {
    if (direction === "right" && canScrollRight.value) {
      currentScrollPosition.value = currentScrollPosition.value.map(item => {
        return item + 4;
      });
    }
    if (direction === "left" && canScrollLeft.value) {
      currentScrollPosition.value = currentScrollPosition.value.map(item => {
        return item - 4;
      });
    }
    setNewSeries(currentScrollPosition.value[0], currentScrollPosition.value[1]);
  }
};

onMounted(async () => {
  await getData();

  emitter.on("refresh-expenses-dashboard-data", getData);
});

onBeforeUnmount(() => {
  emitter.off("refresh-expenses-dashboard-data");
});
</script>

<style lang="scss">
.column-chart {
  width: 100%;

  background-color: var(--expenses-content-background-color);

  border-radius: 20px;

  &__header {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .multiselect {
      width: 100px;
    }
  }

  &__legend {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      align-items: center;
      gap: 5px;

      &__circle {
        width: 8px;
        height: 8px;

        border-radius: 50%;

        background-color: #5b7aff;
      }
      &__label {
        font-weight: 40;
        font-size: 14px;
        color: #8a9099;
      }
    }
  }

  &__label {
    color: var(--expenses-main-text-color);
    font-size: 20px;
  }
  & .apexcharts-datalabel {
    font-weight: 400;
    fill: #5b7aff !important;
  }

  & .apexcharts-data-labels {
    rect {
      display: none;
    }
  }
  & .apexcharts-series path {
    clip-path: inset(0 0 0 0 round 8px);
  }
  & .apexcharts-xaxis-label,
  & .apexcharts-yaxis-label {
    fill: var(--header-subtitle-color);
  }

  .apexcharts-xcrosshairs {
    position: relative;
  }

  .column-chart__buttons {
    display: flex;
    width: 40px;
    justify-content: space-between;
    user-select: none;

    &-item {
      opacity: 0.5;
      cursor: default;
      color: var(--expenses-main-text-color);

      &.active {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .apexcharts-tooltip {
    border-radius: 12px !important;
    box-shadow: var(--expenses-charts-tooltip-box-shadow) !important;
    border: none !important;
    --arrow-margin: 50%;
    overflow: visible;
    background-color: var(--expenses-content-background-color) !important;

    &::after {
      content: "";
      position: absolute;
      border: 11px solid transparent;
      border-top: 11px solid var(--expenses-content-background-color);
      bottom: -22px;
      left: var(--arrow-margin);
      transform: translateX(-50%);
    }
    .column-chart__tooltip {
      padding: 8px 20px;
      background-color: var(--expenses-content-background-color);
      width: 188px;
      height: 60px;
      text-align: center;
      border-radius: 12px !important;

      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }

      &__circle {
        width: 8px;
        height: 8px;

        border-radius: 50%;

        background-color: #5b7aff;
      }

      &__label {
        font-weight: 400;
        font-size: 14px;
        color: var(--expenses-main-text-color);
      }
      &__value {
        font-weight: 400;
        font-size: 14px;
        color: var(--expenses-charts-tooltips-subtext-color);
      }
    }
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 365px;
  }
}
</style>
