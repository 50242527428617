export const openTabAndPrintContent = ({ html, css, closeModalFunction = undefined }) => {
  const windowForPrint = window.open();
  const outputHtml = `
      <html>
        <head>
          <title>${window.document.title}</title>
          <style type="text/css">${css}</style>
        </head>
        <body>
          ${html}
        </body>
      </html>
    `;

  windowForPrint.document.write(outputHtml);

  setTimeout(() => {
    windowForPrint.document.close();
    windowForPrint.focus();
    windowForPrint.print();

    if (closeModalFunction) {
      closeModalFunction();
    }
  }, 500);
};
